<template>
  <transition name="bounce" v-if="enabled">
    <button
      class="btn btn-sm btn-circle btn-link mt-3"
      :disabled="!enabled"
      @click="certification()"
    >
      <i class="flaticon-medal text-warning pulse-animation"></i> Certificar
    </button>
  </transition>
</template>
<script>
import * as $ from "jquery";
export default {
  props: {
    enabled: {
      type: Boolean,
      default: () => false,
    },
    info: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    certification() {
      const [app] = this.$root.$children;
      const multipane = app.$children[2];
      const [menu] = multipane.$children;

      let current = this.searchInData(menu.data, this.info.num_ot);
      menu.current = current;
      menu.selectMenuRight(11);
    },
    searchInData(data, num_ot) {
      const content = $("#main-tree-information");
      var ot = null;
      const search = (pos) => {
        try {
          data[pos]?.children?.map((sector) => {
            sector.children.map((subestacion) => {
              subestacion.children.map((circuito) => {
                circuito.children.map((order) => {
                  if (order.num_ot == num_ot) {
                    data[pos].opened = true;
                    sector.opened = true;
                    subestacion.opened = true;
                    circuito.opened = true;
                    order.selected = true;

                    const scroll = content.scrollTop();
                    const scrollClass = $(`.OT_${num_ot}`).offset().top + 100;
                    content.animate(
                      {
                        scrollTop: scroll < scrollClass ? scroll : scrollClass,
                      },
                      2000
                    );
                    ot = order;
                  }
                });
              });
            });
          });
        } catch (error) {
          console.log(error);
        }
      };

      search(7);
      if (!ot) {
        search(8);
      }
      if (!ot) {
        search(9);
      }
      if (!ot) {
        search(10);
      }
      if (!ot) {
        search(11);
      }
      if (!ot) {
        search(12);
      }
      if (!ot) {
        search(13);
      }
      if (!ot) {
        search(14);
      }

      return ot;
    },
  },
};
</script>
