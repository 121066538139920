<template>
  <div class="main-draw-dark">
    <div id="drawingmap-dark-mood">
      <div class="btn-group-vertical">
        <button
          class="btn btn-sm btn-link dark-mood bg-white"
          @click="
            () => {
              setTheme(!dark);
            }
          "
        >
          <transition name="bounce">
            <i class="fas fa-sun text-warning" v-if="dark"></i>
            <i class="fas fa-moon text-dark" v-else></i>
          </transition>
        </button>
      </div>
    </div>
  </div>
</template>
<style>
#drawingmap-dark-mood {
  float: right;
  margin: auto;
  display: flow-root;
  padding: 8px;
  text-align: right;
  border-radius: 2px !important;
}

.main-draw-dark {
  margin-top: 5%;
  height: 1px;
  float: right;
  width: 0%;
  border-radius: 2px !important;
}
</style>
<script>
import can from "@/permission";
import { mapActions, mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters({
      dark: "mode/getTheme",
    }),
  },
  methods: {
    ...mapActions({
      setTheme: "mode/setTheme",
    }),
    can: can,
  },
};
</script>
