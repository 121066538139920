<template>
  <transition name="slide-fade">
    <div v-if="showOt">
      <b-card class="w-full" v-if="ot.length">
        <b>OT</b>
        <DxDataGrid
          :data-source="ot"
          :show-borders="true"
          :show-column-lines="true"
          :show-row-lines="true"
          :row-alternation-enabled="true"
          :columnAutoWidth="true"
          :allow-column-resizing="true"
        >
          <DxPaging :page-size="10" />
          <DxHeaderFilter :visible="false" />
          <DxFilterRow :visible="true">
            <DxOperationDescriptions
              equal="Igual a"
              notEqual="Diferente de"
              startsWith="Empieza con"
              endsWith="Termina con"
              contains="Contiene"
              between="Entre"
              notContains="No contiene"
              lessThanOrEqual="Menor o igual"
              greaterThanOrEqual="Mayor o igual"
              lessThan="Menor que"
              greaterThan="Mayor que"
            />
          </DxFilterRow>
          <DxColumn
            data-field="num_ot"
            caption="N° OT"
            data-type="number"
            alignment="center"
          />
          <DxColumn data-field="brigada" caption="Brigada" />
          <DxColumn
            data-field="fecha_asigna"
            caption="Fecha asignación"
            data-type="date"
            format="dd/MM/yyyy, HH:mm:ss"
          />
          <DxColumn data-field="status" caption="Estado" />
        </DxDataGrid>
      </b-card>
      <b-card v-else>
        <b-row>
          <b-col>
            <div class="text-center">
              <img src="assets/img/empty.png" alt="Empty" />
              <p class="text-muted">No hay información para mostrar</p>
            </div>
          </b-col>
        </b-row>
      </b-card>
    </div>
  </transition>
</template>

<script>
import {
  DxDataGrid,
  DxPaging,
  DxHeaderFilter,
  DxFilterRow,
  DxOperationDescriptions,
  DxColumn,
} from "devextreme-vue/data-grid";

export default {
  name: "table-ot",
  components: {
    DxDataGrid,
    DxPaging,
    DxHeaderFilter,
    DxFilterRow,
    DxOperationDescriptions,
    DxColumn,
  },
  props: {
    ot: {
      type: Array,
      default: () => [],
    },
    showOt: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      pageSizes: [5, 10, 15, 30],
    };
  },
};
</script>
