<template>
  <div class="position">
    <button
      @click="onClick"
      class="btn btn-danger d-flex justify-content-center align-items-center"
    >
      <i class="flaticon-delete-1"></i>
    </button>
  </div>
</template>

<script>
export default {
  name: "DeleteBrigadeRoute",
  props: {
    onClick: Function,
  },
};
</script>

<style scoped>
.position {
  position: absolute;
  top: 20vh;
  left: 0.5vw;
}
</style>>


