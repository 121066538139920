<template>
  <b-row class="mt-3" v-if="aDataSource.length">
    <b-col>
      <DxDataGrid
        :data-source="aDataSource"
        :show-borders="true"
        :show-column-lines="true"
        :show-row-lines="true"
        :row-alternation-enabled="true"
        :columnAutoWidth="true"
        :allow-column-resizing="true"
      >
        <DxPaging :page-size="30" />
        <DxHeaderFilter :visible="false" />
        <DxFilterRow :visible="true">
          <DxOperationDescriptions
            equal="Igual a"
            notEqual="Diferente de"
            startsWith="Empieza con"
            endsWith="Termina con"
            contains="Contiene"
            between="Entre"
            notContains="No contiene"
            lessThanOrEqual="Menor o igual"
            greaterThanOrEqual="Mayor o igual"
            lessThan="Menor que"
            greaterThan="Mayor que"
          />
        </DxFilterRow>
        <DxSearchPanel :visible="false" :width="240" placeholder="Buscar..." />
        <DxHeaderFilter :visible="true" />
        <DxFilterRow :visible="true" />
        <DxPager
          :visible="true"
          infoText="Página {0} of {1} ({2} registros)"
          :allowed-page-sizes="pageSizes"
          display-mode="full"
          :show-page-size-selector="true"
          :show-info="true"
          :show-navigation-buttons="true"
        />
        <DxColumn data-field="titulo" caption="Titulo" alignment="left" />
        <DxColumn
          data-field="detalle"
          caption="Detalle"
          alignment="left"
          width="75%"
          cell-template="cellTemplate"
        />
        <template #cellTemplate="{ data: cellData }">
          <div class="cell">
            {{ cellData.data.detalle }}
          </div>
        </template>
      </DxDataGrid>
    </b-col>
  </b-row>
  <b-row v-else>
    <b-col class="text-center">
      <img src="assets/img/empty.png" alt="Empty" />
      <p class="text-muted">No hay información de aviso</p>
    </b-col>
  </b-row>
</template>
<script>
import {
  DxDataGrid,
  DxFilterRow,
  DxHeaderFilter,
  DxPager,
  DxPaging,
  DxSearchPanel,
  DxOperationDescriptions,
  DxColumn,
} from "devextreme-vue/data-grid";
export default {
  components: {
    DxDataGrid,
    DxFilterRow,
    DxHeaderFilter,
    DxPager,
    DxPaging,
    DxSearchPanel,
    DxOperationDescriptions,
    DxColumn,
  },
  props: {
    aDataSource: {
      type: Array,
      default: () => [],
    },
    pageSizes: {
      type: Array,
      default: () => [5, 10, 15, 30, 50, 100],
    },
  },
};
</script>
<style>
.cell {
  width: 100% !important;
  height: auto !important;
  overflow: hidden !important;
  white-space: normal !important;
  padding: 3px;
  text-align: justify;
}
</style>