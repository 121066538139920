<template>
  <b-row class="mt-3" v-if="aData.length">
    <b-col>
      <h5>Acciones realizadas</h5>
      <DxDataGrid
        :data-source="aData"
        :show-borders="true"
        :show-column-lines="true"
        :show-row-lines="true"
        :row-alternation-enabled="true"
        :columnAutoWidth="true"
        :allow-column-resizing="true"
      >
        <DxHeaderFilter :visible="false" />
        <DxFilterRow :visible="true">
          <DxOperationDescriptions
            equal="Igual a"
            notEqual="Diferente de"
            startsWith="Empieza con"
            endsWith="Termina con"
            contains="Contiene"
            between="Entre"
            notContains="No contiene"
            lessThanOrEqual="Menor o igual"
            greaterThanOrEqual="Mayor o igual"
            lessThan="Menor que"
            greaterThan="Mayor que"
          />
        </DxFilterRow>
        <DxColumn
          data-field="descripcion"
          caption="Descripción"
          alignment="center"
        />
        <DxColumn
          data-field="fecha_ejecuta"
          caption="Fecha ejecución"
          data-type="date"
          format="dd/MM/yyyy, HH:mm:ss"
        />
      </DxDataGrid>
    </b-col>
  </b-row>
  <b-row v-else>
    <b-col class="text-center">
      <img src="assets/img/empty.png" alt="Empty" />
      <p class="text-muted">No hay información de <strong>acciones</strong></p>
    </b-col>
  </b-row>
</template>
<script>
import {
  DxDataGrid,
  DxFilterRow,
  DxHeaderFilter,
  DxColumn,
  DxOperationDescriptions,
} from "devextreme-vue/data-grid";
export default {
  components: {
    DxDataGrid,
    DxFilterRow,
    DxHeaderFilter,
    DxColumn,
    DxOperationDescriptions,
  },
  props: {
    aData: {
      type: Array,
      default: () => [],
    },
    pageSizes: {
      type: Array,
      default: () => [5, 10, 15, 30, 50, 100],
    },
  },
};
</script>