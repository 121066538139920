<template>
  <div class="main-draw">
    <div id="drawingmap">
      <div class="btn-group-vertical">
        <button
          class="btn btn-sm btn-warning"
          data-container="body"
          data-toggle="tooltip"
          data-placement="left"
          title="Rectangulo"
          v-if="rectangle"
          @click="$emit('drawingmode_changed', 'rectangle')"
        >
          <i class="fa fa-square"></i>
        </button>
        <button
          class="btn btn-sm btn-success"
          data-container="body"
          data-toggle="tooltip"
          data-placement="left"
          title="Circulo"
          v-if="circle"
          @click="$emit('drawingmode_changed', 'circle')"
        >
          <i class="fa fa-circle text-white"></i>
        </button>
        <button
          class="btn btn-sm btn-secondary"
          data-container="body"
          data-toggle="tooltip"
          data-placement="left"
          title="Linea"
          v-if="line"
          @click="createPolygon()"
        >
          <i class="fa fa-slash text-dark"></i>
        </button>
        <button
          class="btn btn-sm btn-info"
          data-container="body"
          data-toggle="tooltip"
          data-placement="left"
          title="Poligono"
          v-if="polygon && false"
          @click="$emit('drawingmode_changed', 'polygon')"
        >
          <i class="fa fa-draw-polygon"></i>
        </button>
        <a
          class="btn btn-sm btn-danger text-center"
          data-container="body"
          data-toggle="tooltip"
          data-placement="left"
          title="Eliminar selección"
          :disabled="deleted"
          v-if="polygon"
          @click="$emit('delete_selection')"
        >
          <i class="fa fa-trash-alt"></i>
        </a>
        <a
          class="btn btn-sm btn-primary"
          data-container="body"
          data-toggle="tooltip"
          data-placement="left"
          title="Guardar"
          :disabled="markers.length != 0"
          @click="$emit('save')"
          v-if="can('ubicar-avisos')"
        >
          <i class="fa fa-save"></i>
        </a>
      </div>
    </div>
  </div>
</template>
<style>
#drawingmap {
  float: right;
  margin: auto;
  background-color: rgba(255, 255, 255, 0.2);
  display: flow-root;
  padding: 8px;
  text-align: right;
  border-radius: 10px;
}

.main-draw {
  margin-top: 20%;
  height: 1px;
  float: right;
  width: 0%;
}
</style>
<script>
import can from "@/permission";
export default {
  props: {
    rectangle: {
      type: Boolean,
      default: () => false,
    },
    circle: {
      type: Boolean,
      default: () => false,
    },
    line: {
      type: Boolean,
      default: () => false,
    },
    polygon: {
      type: Boolean,
      default: () => false,
    },
    deleted: {
      type: Boolean,
      default: () => false,
    },
    saved: {
      type: Boolean,
      default: () => true,
    },
    markers: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {};
  },
  methods: {
    can: can,
  },
  createPolygon() {
    this.$emit("createPolygon", "Polygon");
    this.$emit("drawingmode_changed", "polyline");
  },
};
</script>
