<template>
  <b-modal
    title="Crear usuario"
    v-model="show"
    size="xl"
    no-close-on-backdrop
    scrollable
  >
    <b-row>
      <b-col md="3">
        <div class="form-group">
          <label>Nombre</label>
          <input
            v-model="form.nombre"
            type="text"
            class="form-control form-control-sm"
            placeholder="Nombre de usuario"
          />
        </div>
      </b-col>
      <b-col md="3">
        <div class="form-group">
          <label>N° de documento</label>
          <input
            v-model="form.usuario"
            type="number"
            min="0"
            class="form-control form-control-sm"
            placeholder="N° de documento"
          />
        </div>
      </b-col>
      <b-col md="3">
        <div class="form-group">
          <label>Contraseña</label>
          <input
            v-model="form.pass"
            type="password"
            class="form-control form-control-sm"
            autocomplete="off"
            autofocus="off"
          />
        </div>
      </b-col>
      <b-col md="3">
        <div class="form-group">
          <label>Perfil</label>
          <select class="form-control form-control-sm" v-model="form.id_nivel">
            <option :value="null">Seleccione</option>
            <option
              v-for="(rol, idx) in roles"
              :key="idx"
              :value="rol.id"
              :disabled="
                user.roles.name != 'SuperAdmin' && rol.name == 'SuperAdmin'
              "
            >
              {{ rol.name }}
            </option>
          </select>
        </div>
      </b-col>
      <b-col md="3">
        <div class="form-group">
          <label>Télefono</label>
          <input
            v-model="form.telefono"
            type="tel"
            class="form-control form-control-sm"
            placeholder="0000000000"
            maxlength="10"
          />
        </div>
      </b-col>
      <b-col md="3">
        <div class="form-group">
          <label>Correo</label>
          <input
            v-model="form.email"
            type="email"
            class="form-control form-control-sm"
            placeholder="example@email.com"
          />
        </div>
      </b-col>
      <b-col md="3">
        <transition name="fade">
          <div
            class="form-group"
            v-if="form.id_nivel == 6 || form.id_nivel == 5"
          >
            <label>Contratista</label>
            <select
              class="form-control form-control-sm"
              v-model="form.codempresa"
            >
              <option :value="null">Seleccione</option>
              <option
                v-for="(contrata, idx) in contratas"
                :key="idx + '_C_'"
                :value="contrata.codempresa"
              >
                {{ contrata.cliente }}
              </option>
            </select>
          </div>
        </transition>
      </b-col>
    </b-row>

    <template #modal-footer>
      <div class="form-group">
        <button class="btn btn-sm btn-circle" @click="() => (show = false)">
          <i class="la la-reply"></i> Cancelar
        </button>
        <button class="btn btn-sm btn-success" @click="create()">
          <i class="la la-save"></i> {{ loading ? "Cargando..." : "Guardar" }}
        </button>
      </div>
    </template>
  </b-modal>
</template>
<script>
import axios from "axios";
import { mapGetters } from "vuex";
import toastr from "toastr";
const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};
export default {
  data() {
    return {
      show: false,
      loading: false,
      form: {
        nombre: null,
        usuario: null,
        pass: null,
        id_nivel: null,
        codempresa: null,
        telefono: null,
        email: null,
      },
    };
  },
  computed: {
    ...mapGetters({
      roles: "Roles/getRoles",
      contratas: "Contratas/getContratas",
      user: "auth/user",
    }),
  },
  methods: {
    reset() {
      this.form = {
        nombre: null,
        usuario: null,
        pass: null,
        id_nivel: null,
        codempresa: null,
        telefono: null,
        email: null,
      };
    },
    create() {
      if (validateEmail(this.form.email) && this.form.email) {
        if (this.form.codempresa) {
          const base = this.contratas.filter(
            (f) => f.codempresa == this.form.codempresa
          )[0];
          this.form.base = base.nombre;
        }
        if (this.form.usuario) {
          this.loading = true;
          axios
            .post("/users", this.form)
            .then((res) => {
              if (res.data.message == "success") {
                this.show = false;
                this.loading = false;
                this.$emit("loadItems", { load: true });
              }
            })
            .catch((e) => {
              this.loading = false;
              console.log(e);
            });
        } else {
          toastr.info("El N° de documento es obligatorio");
        }
      } else {
        toastr.info("Asegurese de ingresar un correo válido");
      }
    },
  },
};
</script>
