<template>
  <b-row v-if="aData.length">
    <b-col>
      <h6 class="mb-3 mt-3"> Información de movilidad </h6>
      <DxDataGrid
        :data-source="aData"
        :show-borders="true"
        :show-column-lines="true"
        :show-row-lines="true"
        :row-alternation-enabled="true"
        :columnAutoWidth="true"
        :allow-column-resizing="true"
      >
        <DxPaging :page-size="30" />
        <DxHeaderFilter :visible="true" />
        <DxFilterRow :visible="true">
          <DxOperationDescriptions
            equal="Igual a"
            notEqual="Diferente de"
            startsWith="Empieza con"
            endsWith="Termina con"
            contains="Contiene"
            between="Entre"
            notContains="No contiene"
            lessThanOrEqual="Menor o igual"
            greaterThanOrEqual="Mayor o igual"
            lessThan="Menor que"
            greaterThan="Mayor que"
          />
        </DxFilterRow>
        <DxPager
          :visible="true"
          infoText="Página {0} of {1} ({2} registros)"
          :allowed-page-sizes="pageSizes"
          display-mode="full"
          :show-page-size-selector="true"
          :show-info="true"
          :show-navigation-buttons="true"
        />
      </DxDataGrid>
    </b-col>
  </b-row>
  <b-row v-else>
    <b-col class="text-center">
      <img src="assets/img/empty.png" alt="Empty" />
      <p class="text-muted">No hay información de <strong> Movilidad </strong></p>
    </b-col>
  </b-row>
</template>
<script>
import {
  DxDataGrid,
  DxFilterRow,
  DxHeaderFilter,
  DxPager,
  DxPaging,
  DxOperationDescriptions
} from "devextreme-vue/data-grid";
export default {
  components: {
    DxDataGrid,
    DxFilterRow,
    DxHeaderFilter,
    DxPager,
    DxPaging,
    DxOperationDescriptions
  },
  props: {
    aData: {
      type: Array,
      default: () => [],
    },
    pageSizes: {
      type: Array,
      default: () => [5, 10, 15, 30, 50, 100],
    },
  },
};
</script>