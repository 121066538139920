<template>
  <b-modal
    title="Consulta de trabajos"
    ref="workConsult"
    id="workConsult"
    v-model="isShow"
    :size="size"
    no-close-on-backdrop
    scrollable
  >
    <transition name="fade">
      <b-row class="mb-3" v-if="size == 'md'">
        <b-col>
          <div class="d-flex justify-content-between align-items-center">
            <label for="searchWork" class="w-50 m-0">Buscar Trabajos :</label>
            <select class="form-control form-control-sm" v-model="searchWork">
              <option value="1">Pendientes</option>
              <option value="2">Precerrados</option>
              <option value="3">Todos</option>
              <option value="4">Rechazados</option>
            </select>
          </div>
          <div
            class="d-flex justify-content-between align-items-center mt-2"
            v-if="searchWork == 1 || searchWork == 2"
          >
            <label for="searchCriterio" class="w-50 m-0">Criterio :</label>
            <select
              class="form-control form-control-sm"
              v-model="searchCriterio"
            >
              <option value="created_at">Fecha de Creación</option>
              <!-- <option value="2">Fecha de programación</option> -->
            </select>
          </div>
          <div class="d-flex justify-content-between align-items-center mt-2">
            <label class="w-50 m-0">Fecha Inicial :</label>
            <input
              type="date"
              class="form-control form-control-sm w-50"
              v-model="fechai"
            />
            <input
              type="time"
              class="form-control form-control-sm w-25"
              v-model="horai"
            />
          </div>
          <div class="d-flex justify-content-between align-items-center mt-2">
            <label class="w-50 m-0">Fecha Final :</label>
            <input
              type="date"
              class="form-control form-control-sm w-50"
              v-model="fechaf"
            />
            <input
              type="time"
              class="form-control form-control-sm w-25"
              v-model="horaf"
            />
          </div>
          <div class="d-flex justify-content-between align-items-center mt-2">
            <label for="typeWork" class="w-50 m-0">Tipo de Trabajo :</label>
            <select
              @keyup.enter="confirmar()"
              class="form-control form-control-sm"
              v-model="typeWork"
            >
              <option
                v-for="(item, idx) in worktypes"
                :key="idx"
                :selected="idx == 1"
                :value="item.id"
              >
                {{ item.nombre }}
              </option>
            </select>
          </div>
          <div class="d-flex justify-content-between align-items-center mt-2">
            <label for="typeWork" class="w-50 m-0">Brigada : </label>
            <Select2
              class="select2 select2-tech mb-3"
              v-model="brigada"
              :options="techs"
              placeholder="Brigada"
            />
          </div>
        </b-col>
      </b-row>
      <b-row v-else>
        <b-col md="12">
          <div>
            <b-row>
              <b-col>
                <button
                  class="btn btn-outline-primary btn-sm mb-3 text-center"
                  @click="() => (size = 'md')"
                >
                  <i class="fas fa-filter"></i> Filtrar
                </button>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <DxPivotGrid
                  id="sales"
                  :height="570"
                  :show-borders="true"
                  :data-source="dataSource"
                  :texts="MainText"
                >
                  <DxFieldChooser
                    title="Genera tu reporte"
                    :layout="2"
                    :texts="pivotText"
                    :allowSearch="true"
                    :enabled="true"
                    :height="700"
                    :width="700"
                  />
                  <DxFieldPanel :visible="true" />
                  <DxExport :enabled="true" />
                  <DxScrolling mode="virtual" />
                  <!-- <DxStateStoring
                    :enabled="true"
                    type="localStorage"
                    storage-key="dx-widget-gallery-pivotgrid-storing"
                  /> -->
                </DxPivotGrid>
              </b-col>
            </b-row>
          </div>
        </b-col>
      </b-row>
    </transition>
    <template #modal-footer>
      <div class="form-group">
        <button class="btn btn-sm btn-link" @click="isShow = false">
          <i class="la la-reply"></i> Cancelar
        </button>
        <button
          v-if="size == 'md'"
          @click="confirmar()"
          class="btn btn-success btn-sm"
        >
          <i class="la la-sync"></i>
          {{ !isLoading ? "Confirmar" : "Consultando..." }}
        </button>
      </div>
    </template>
  </b-modal>
</template>
<style>
#table-example {
  width: 2000px !important;
}
.select2-tech {
  width: 100% !important;
}
#workConsult___BV_modal_body_ {
  overflow-x: hidden !important;
}
/* .table-management-ot{ */
/* width: 220% !important; */
/* overflow-x: auto !important; */
/* } */
#__BVID__418 > thead > tr > th:nth-child(5) {
  width: 200px !important;
}
#__BVID__418 > tbody > tr > td:nth-child(5) {
  width: 200px !important;
}
.table-management-ot > thead > tr > th[aria-sort="ascending"]::after {
  opacity: 1;
  content: "\2191";
}
.table-management-ot > thead > tr > th[aria-sort="descending"]::after {
  opacity: 1;
  content: "\2193";
}
.clipInput {
  width: 1px !important;
  opacity: 0;
}
</style>
<script>
import axios from "axios";
import { mapGetters } from "vuex";
import moment from "moment";
import Select2 from "v-select2-component";
import * as $ from "jquery";
import toastr from "toastr";
import _ from "lodash";
import DxPivotGrid, {
  DxFieldChooser,
  DxFieldPanel,
  //   DxStateStoring,
} from "devextreme-vue/pivot-grid";
import PivotGridDataSource from "devextreme/ui/pivot_grid/data_source";
import { DxExport, DxScrolling } from "devextreme-vue/data-grid";
export default {
  name: "modal-work-consult",
  components: {
    Select2,
    DxPivotGrid,
    DxFieldChooser,
    DxFieldPanel,
    // DxStateStoring,
    DxExport,
    DxScrolling,
  },
  data() {
    return {
      isShow: false,
      isLoading: false,
      searchWork: 1,
      searchCriterio: "created_at",
      fechai: moment().format("YYYY-MM-DD"),
      horai: "08:00:00",
      fechaf: moment().format("YYYY-MM-DD"),
      horaf: "23:59:59",
      typeWork: 1,
      worktypes: [],
      size: "md",
      brigada: -1,
      techs: [],

      /**TABLA**/
      transProps: {
        // Transition name
        name: "flip-list",
      },
      items: [],
      brigadas: [],
      filtersSearch: {},
      gridRefName: "dataGrid",
      dataSource: [],
      pivotText: {
        allFields: "Todos los campos",
        columnFields: "Columnas",
        dataFields: "Valores",
        filterFields: "Campos de filtro",
        rowFields: "Filas",
      },
      MainText: {
        noData: "No hay información",
        grandTotal: "Total",
        collapseAll: "Desplegar todo",
        expandAll: "Expandir todo",
        exportToExcel: "Exportar a Excel",
        removeAllSorting: "Quitar orden",
        showFieldChooser: "Mostrar selector de campo",
        sortColumnBySummary: "Ordenar {0} por esta columna",
        sortRowBySummary: "Ordenar {0} por esta fila",
      },
      texts: {
        editRow: "Editar",
        saveRowChanges: "Guardar",
        saveAllChanges: "Guardar cambios",
        deleteRow: "Eliminar",
        cancelRowChanges: "Cancelar",
        confirmDeleteTitle: "Información",
        confirmDeleteMessage: "¿Realmente desea eliminar el registro?",
        cancelAllChanges: "Descartar cambios",
      },
    };
  },
  filters: {
    date(values) {
      return moment(values).format("LLL");
    },
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
  },
  methods: {
    actionEventPDF() {},
    onContextMenuPreparing(e) {
      const dataSource = e.component.getDataSource();
      const sourceField = e.field;

      if (sourceField) {
        if (!sourceField.groupName || sourceField.groupIndex === 0) {
          e.items.push({
            text: "Hide field",
            onItemClick() {
              let fieldIndex;
              if (sourceField.groupName) {
                fieldIndex = dataSource.getAreaFields(sourceField.area, true)[
                  sourceField.areaIndex
                ].index;
              } else {
                fieldIndex = sourceField.index;
              }

              dataSource.field(fieldIndex, {
                area: null,
              });
              dataSource.load();
            },
          });
        }

        if (sourceField.dataType === "number") {
          const setSummaryType = function (args) {
            dataSource.field(sourceField.index, {
              summaryType: args.itemData.value,
            });

            dataSource.load();
          };
          const menuItems = [];

          e.items.push({ text: "Summary Type", items: menuItems });

          ["Sum", "Avg", "Min", "Max"].forEach((summaryType) => {
            const summaryTypeValue = summaryType.toLowerCase();

            menuItems.push({
              text: summaryType,
              value: summaryType.toLowerCase(),
              onItemClick: setSummaryType,
              selected: e.field.summaryType === summaryTypeValue,
            });
          });
        }
      }
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    reset() {
      var interval = setInterval(() => {
        $("#workConsult___BV_modal_content_").attr("tabindex", null);
      }, 1000);

      setTimeout(() => {
        clearInterval(interval);
      }, 3000);
      this.searchWork = 1;
      this.searchCriterio = "created_at";
      this.fechai = moment().format("YYYY-MM-DD");
      this.horai = "08:00:00";
      this.fechaf = moment().format("YYYY-MM-DD");
      this.horaf = "23:59:59";
      this.worktypes = [];
      this.items = [];
      this.size = "md";
      this.loadType();
    },
    loadType() {
      axios.get("tipos-trabajos").then((res) => {
        this.worktypes = res.data;
      });
    },
    async loadTech() {
      $("#workConsult___BV_modal_content_").attr("tabindex", null);
      let response = await axios.post("/tecnico-bol-all", {
        sectores: JSON.parse(sessionStorage.sectores),
      });
      this.techs = [];
      this.techs.push({
        id: -1,
        text: "Todos",
      });
      response.data.map((el) => {
        this.techs.push({
          id: el.usuario,
          text: `${el.vehiculo_info ? el.vehiculo_info.placa : ""} - ${
            el.nombre
          } - ${el.cliente ? el.cliente.movil : ""}`.toUpperCase(),
        });
      });
    },
    clipboard(item) {
      this.$nextTick(() => {
        var copyText = document.getElementById("clipboardInput" + item.id_ot);
        if (copyText) {
          copyText.select();
          navigator.clipboard.writeText(copyText.value);
          toastr.success("Copiado al portapapeles");
        }
      });
    },
    confirmar() {
      let config = {
        brigada: this.brigada,
        buscaTrabajos: this.searchWork,
        criterio: this.searchCriterio,
        fechai: this.fechai + " " + this.horai,
        fechaf: this.fechaf + " " + this.horaf,
        tipoTrabajo: this.typeWork,
      };

      this.isLoading = true;
      axios
        .post("consulta-trabajos", config)
        .then((res) => {
          this.isLoading = false;
          if (JSON.parse(this.brigada) != 0) {
            this.items = res.data.filter(
              (f) => f.tipo_trabajo == this.typeWork
            );
            this.brigadas = _.groupBy(this.items, "brigada");
          } else {
            this.items = res.data.filter(
              (f) =>
                f.tipo_trabajo == this.typeWork && f.brigada == this.brigada
            );
            this.brigadas = _.groupBy(this.items, "brigada");
          }
          if (this.items.length != 0) {
            this.size = "xl";
            this.totalRows = this.items.length;
          } else {
            this.$swal.fire({
              title: "Información",
              text: "No se han encontrado registros para esta busqueda",
              icon: "info",
            });
          }
          this.filtersSearch = config;
          config.brigada = this.techs.filter(
            (f) => f.id == this.brigada
          )[0].text;
          config.tipoTrabajo = this.worktypes.filter(
            (f) => f.id == config.tipoTrabajo
          )[0].nombre;
          console.log(this.items, "<< items");
          this.dataSource = new PivotGridDataSource({
            fields: [
              {
                caption: "OT",
                width: 120,
                dataField: "num_ot",
                dataType: 'number',
                area: "row",
                expanded: true,
              },
              {
                caption: "Num aviso",
                width: 120,
                dataField: "num_aviso",
                dataType: 'number',
                area: "data",
              },
              {
                caption: "Brigada",
                dataField: "tvpnt",
                area: "column",
                expanded: true,
              },
              {
                caption: "Estado",
                dataField: "estado",
                area: "column",
                expanded: true,
              }
              //   {
              //     caption: "City",
              //     dataField: "city",
              //     width: 150,
              //     area: "row",
              //   },
              //   {
              //     dataField: "date",
              //     dataType: "date",
              //     area: "column",
              //   }
              ,
                {
                  groupName: "Brigada",
                  groupInterval: "tvpnt",
                  visible: true,
                },
              //   {
              //     caption: "Total",
              //     dataField: "amount",
              //     dataType: "number",
              //     summaryType: "sum",
              //     format: "currency",
              //     area: "data",
              //   },
            ],
            store: this.items,
          });
        })
        .catch((e) => {
          console.log(e);
          this.isLoading = false;
        });
    },
  },
};
</script>
