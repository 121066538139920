const MENU = [
    [
        // assign_ot - 0
        { id: 1, text: "Gestión de peligro" },
        { id: 2, text: "Cambiar Cor/Sector" },
        { id: 4, text: "Generar OT" },
        { id: 5, text: "Declarar Improcedente" },
        { id: 6, text: "Más Información" },
    ],
    [
        // sin alimentación - 1
        { id: 1, text: "Gestión de peligro" },
        { id: 2, text: "cambiar Cor/Sector" },
        { id: 5, text: "Declarar Improcedente" },
        { id: 6, text: "Más información" },
    ],
    [
        // OTTECH - 2
        { id: 7, text: "Prioritario" },
        { id: 8, text: "Inicio de ruta" },
        { id: 9, text: "Quitar Trabajo" },
        { id: 10, text: "Cerrar Trabajo" },
        { id: 1, text: "Gestión de peligro" },
        { id: 11, text: "Certificar OT" },
        { id: 5, text: "Declarar Improcedente" },
        { id: 6, text: "Más Información" },
    ],
    [
        // OTTECH CERRADO - 3
        { id: 12, text: "Reabrir Caso" },
        { id: 11, text: "Certificar OT" },
        { id: 6, text: "Más información" },
    ],
    [
        // technical - 4
        { id: 13, text: "Actualizar" },
        { id: 14, text: "Cerrar Turno" },
        { id: 15, text: "Trabajo Online" },
        { id: 22, text: "Consulta de Trabajos" },
        // { id: 29, text: "Ver OT Certificadas" },
        { id: 17, text: "Estadísticas" },
        { id: 18, text: "Trasladar trabajos" },
        { id: 19, text: "Cambiar orden de trabajos" },
        { id: 20, text: "Ordenamiento automático de trabajos" },
        { id: 21, text: "Recuperar Pendiente" },
        { id: 6, text: "Más Información" },
    ],
    [
        // circuit - 5
        { id: 5, text: "Declarar Improcedentes" },
        { id: 25, text: "Generar OT" },
        { id: 26, text: "Generar OT másivo" },
    ],
    [
        // tecnical reportes - 6
        { id: 22, text: "Consulta de Trabajos" },
        // { id: 23, text: "Histórico Recorrido" },
        { id: 24, text: "Información del turno" },
    ],
    [
        // AVISO GESTIONADO - 7
        { id: 5, text: "Declarar Improcedente" },
        { id: 6, text: "Más Información" },
    ],
    [
        // OT GESTIONADAS - 8
        { id: 10, text: "Cerrar Trabajo" },
        { id: 30, text: "Asignar OT" },
        { id: 5, text: "Declarar Improcedente" },
        { id: 6, text: "Más Información" },
    ],
    [
        // TRABAJOS PARTICULAR - 9
        { id: 27, text: "Activar aviso" },
        { id: 6, text: "Más Información" },
    ],
    [
        // TRABAJOS PARTICULAR - 10
        { id: 27, text: "Gestionar aviso" },
        { id: 6, text: "Más Información" },
    ],
    [
        // SUBESTACIÓN - 11
        { id: 28, text: "Expandir todo" },
    ],
    [
        // SUBESTACIÓN - 12
        { id: 16, text: "Reportes" },
    ],
    [
        // tecnical reportes - 13
        // { id: 22, text: "Consulta de Trabajos" },
        { id: 23, text: "Histórico Recorrido" },
        // { id: 24, text: "Información del turno" },
    ],
    // AVISOS PENDIENTE - POS[10]-POS[14] CONTENENEDOR PODA -> - (14)
    [
        { id: 10, text: "Cerrar Trabajo" },
        // { id: 11, text: "Certificar OT" },
        // { id: 5, text: "Declarar Improcedente" },
        // { id: 6, text: "Más Información" },
    ],
    [
        // technical - 4
        { id: 13, text: "Actualizar" },
        { id: 15, text: "Trabajo Online" },
        { id: 29, text: "Ver OT Certificadas" },
        { id: 17, text: "Estadísticas" },
        { id: 22, text: "Consulta de Trabajos" },
        { id: 18, text: "Trasladar trabajos" },
        { id: 19, text: "Cambiar orden de trabajos" },
        { id: 20, text: "Ordenamiento automático de trabajos" },
        { id: 21, text: "Recuperar Pendiente" },
        { id: 6, text: "Más Información" },
    ],
    // AVISOS PENDIENTE - POS[10]-POS[14] CONTENENEDOR PODA -> - (16)
    [
        { id: 12, text: "Reabrir Caso" },
        { id: 11, text: "Certificar OT" },
        // { id: 5, text: "Declarar Improcedente" },
        { id: 6, text: "Más Información" },
    ],
    // AVISOS RECHAZADOS (17)
    [
        { id: 12, text: "Reabrir Caso" },
        // { id: 5, text: "Declarar Improcedente" },
        { id: 6, text: "Más Información" },
    ],
    // CIRCUITO_OT - 18
    [
        { id: 5, text: "Declarar Improcedente" },
        { id: 30, text: "Asignar de forma másiva" },
    ],
    // BRIGADAS DISPONIBLES - 19
    [
        { id: 31, text: "Generar informe." },
        // brigadas_disponibles_all
    ]
    // causas()
];

export default MENU;