<template>
    <b-modal 
        title="Creación de OT de forma másiva"
        no-close-on-backdrop
        size="xl"
        hide-footer
        v-model="show"
    >
        <div class="d-block text-center">
            <div v-if="info.length" class="mt-2">
                <div>
                    <div class="row">
                        <div class="col-md-12 mb-3 text-right">
                            <label> 
                                Procesos seleccionados 
                                <span class="badge badge-primary">
                                    {{ avisos.length }}
                                </span> 
                            </label>
                        </div>
                        <b-col md="12" class="table table-responsive alert alert-secondary" style="max-height: 500px !important; overflow-y: auto">
                            <table class="table table-hover table-sm scrolling-wrappe" >
                                <thead class="thead-dark">
                                    <tr>
                                        <td></td>
                                        <td>Aviso de referencia</td>
                                        <td>Tipo</td>
                                        <td>Icono</td>
                                        <td>Tipo de aviso</td>
                                        <td>Dirección</td>
                                        <td>Barrio</td>
                                        <td>Referencia</td>
                                        <td>Descripción</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr 
                                        v-for="(item, i) in info" 
                                        :key="i">
                                        <td>
                                            <div class="checkbox-inline">
                                                <label class="checkbox checkbox-outline checkbox-outline-2x checkbox-primary">
                                                    <input 
                                                        type="checkbox" 
                                                        name="Checkboxes16"
                                                        :value="item.id_aviso"
                                                        v-model="avisos">
                                                    <span></span>
                                                </label>
                                            </div>
                                        </td>
                                        <td> {{ item.num_aviso }}  </td>
                                        <td> {{item.tipo_trabajo_nombre}} </td>
                                        <td>
                                            <div v-html="item.imgIcon"></div>
                                        </td>
                                        <td>{{item.tipo_aviso}}</td>
                                        <td> {{ item.dir_suscriptor }} </td>
                                        <td> {{ item.barrio }} </td>
                                        <td> {{ item.dir_ref }} </td>
                                        <td class="text-justify"> {{ item.observacion }} </td>
                                    </tr>
                                </tbody>
                            </table>
                        </b-col>
                        <b-col md="6" class="text-left">
                            <label v-if="isAssign">Seleccione brigada</label>
                            <Select2 
                                v-if="isAssign"
                                class="select2 mb-3"
                                v-model="worker"
                                :options="configOptionSelect"
                            />
                        </b-col>
                        <b-col md="12">
                            <b-form-group class="text-left">
                                <label>Observaciones</label>
                                <b-form-textarea
                                    v-model="description"
                                    placeholder="Observaciones"
                                    rows="3"
                                    columns="3"
                                ></b-form-textarea>
                            </b-form-group>
                        </b-col>

                        <b-col md="12" v-if="isLoading">
                            <b-progress 
                                :max="100"
                                :animated="true">
                                <b-progress-bar
                                    :value="value" 
                                    variant="primary" 
                                    striped  
                                    show-progress
                                    :precision="1"
                                    :label-html="`<b>Progreso ${value} % </b>`"
                                />
                            </b-progress>
                            <br>
                            <span class="text-muted" v-if="value == 100">
                            Por favor espere, ya estamos terminando... :)
                            </span>
                        </b-col>

                        <div class="col-md-6 mt-3">
                            <div class="form-group text-left">
                                <b-button 
                                    class="btn btn-xs btn-sm mr-3" 
                                    variant="primary"
                                    v-if="!isNewWarnings"
                                    @click="handleOk()"
                                    ref="buttonLoader">
                                    <i class="la la-play"></i> Generar OT
                                </b-button>
                                <label 
                                    class="checkbox checkbox-outline checkbox-success ml-2">
                                    <input 
                                        type="checkbox" 
                                        v-model="isAssign"> ¿Desea asignar la OT?
                                    <span></span>
                                </label>
                            </div>
                        </div>
                        
                    </div>
                </div>
                <div class="separator separator-dashed mt-3 mb-3"></div>
            </div>
            <div v-else>
                <div class="alert alert-secondary">
                    <h3>No hay avisos para generar OT</h3>
                </div>
            </div>
        </div>
    </b-modal>
    
</template>
<style>
 .modal-backdrop {
    opacity: .65;
 }
 span.select2 {
    width: 100% !important;
 }
</style>
<script>
    import toastr from 'toastr'
    import Select2 from 'v-select2-component'
    import axios from 'axios'
    import { mapGetters } from 'vuex'
    // function sleep(ms) {
    //     return new Promise(res => setTimeout(res, ms));
    // }
    export default {
        components: { Select2 },
        mounted() {
            if(this.configOptionSelect.length == 1) {
                this.isAssign = true
                this.worker = this.configOptionSelect[0].id
            }
            this.info.forEach(el => {
                this.avisos.push(el.id_aviso)
            });
        },
        data() {
            return {
                info: [],
                workers: [],
                isNewWarnings: false,
                show: false,
                brigade: null, 
                favorite: null, 
                selected: [],
                allSelected: false,
                avisos: [],
                description: null,
                service: [], 
                worker: -1,
                oldValue: null,
                isAssign: null,
                isLoading: false,
                counter: 0,
                value: 0
            }
        },
        computed: {
            ...mapGetters({
                user: 'auth/user'
            }),
            configOptionSelect() {
                let options = []
                if(this.workers.length > 1) {
                    options.push({
                        id: -1,
                        text: 'Seleccione'
                    })
                } 
                
                this.workers.forEach(el => {
                    const text = `${el.cliente.vehiculo} - ${el.nombre} - ${el.cliente.movil}`
                    options.push({
                        id: el.usuario,
                        text: text ? text.toUpperCase() : el.nombre
                    })
                })
                return options;
            },
        },
        watch: {
            counter() {
                this.value = ((this.counter/this.avisos.length) * 100).toFixed(2)
            }
        },
        methods: {
            loadTechBol() {
                axios.post('tecnico-bol-all')
                    .then((res) => {
                        this.workers = res.data;
                    })
            },
            genAndAssign() {
                this.isAssign = true
            },
            checkAll() {
                this.avisos = this.info.map((a) => {
                    return a.id_aviso
                })
            },
            async handleOk() {
                this.value = 0;
                this.counter = 0;
                if(this.avisos.length != 0) {
                    this.loaderSpinClasss()
                    this.isLoading = true
                    var resAcum = []

                    for(let i = 0; i < this.avisos.length; i++){
                        let ot = {
                            usuario_genera: this.user.usuario,
                            avisos: [this.info[i].id_aviso],
                            aviso_ref: this.info[i].id_aviso,
                            id_estado: this.worker == -1 ? 1 : 2,
                            observacion: this.description ? this.description : '' ,
                            geojson: '',
                            brigada: this.worker == -1 ? null : this.worker
                        }
                        const  response = await axios.post('/ots', ot);
                        this.counter = this.counter + 1 ;
                        const data = response.data.ot;
                        if(response.data.message == "failed") {
                            toastr.info(JSON.stringify(response.data.error))  
                        } else {
                            resAcum.push(data);
                        }

                    }
                    this.isLoading = false
                    this.removeLoaderSpinClass()
                    setTimeout(() => {
                        this.show = false
                        this.$emit('generateOTMasive', {res: resAcum})
                    }, 2000)
                    // this.loaderSpinClasss()
                } else {
                    toastr.warning('Debe seleccionar al menos un aviso para crear una OT')
                }
                
            },

            addFavorite(id) {
                if(this.favorite == id) {
                    this.favorite = null 
                } else {
                    this.favorite = id
                    // if(this.avisos.indexOf(id) == -1 && id != null){
                    //     this.avisos.push(id)
                    // }
                }

            },
            resetMarker() {
                this.$emit('resetMarker', [])
            },
            loaderSpinClasss(){
                this.isLoading = !this.isLoading
                const submitButton = this.$refs.buttonLoader;
                submitButton.classList.add("disabled","spinner", "spinner-light", "spinner-left");
            },
            removeLoaderSpinClass() {
                this.isLoading = !this.isLoading
                const submitButton = this.$refs.buttonLoader;
                submitButton.classList.remove("disabled","spinner", "spinner-light", "spinner-left");
            }
        }
    }
</script>