<template>
  <div
    :class="`aside aside-left aside-fixed d-flex flex-column flex-row-auto kt_aside menu_kt_aside ${
      isMinimized ? 'isMinimized' : ''
    }`"
    id="kt_aside"
    ref="kt_aside"
  >
    <div class="brand flex-column-auto mr-3" id="kt_brand">
      <router-link
        :to="{ name: 'home' }"
        class="brand-logo siprem-logo"
        ref="logo"
        v-if="!isMinimized"
      >
        <img alt="Logo" src="images/logo_sipremcol.png" />
      </router-link>
      <select
        class="mt-3 ml-3 select"
        v-model="timer"
        v-if="can('counter-timer') && !isMinimized"
        :disabled="!checkTimer"
        @change="changeInterval"
      >
        <option v-for="(item, idx) in timers" :key="idx + '_TI_'" :value="item">
          {{ item }} Min
        </option>
      </select>
      <label
        class="checkbox checkbox-outline checkbox-primary ml-3"
        v-if="can('counter-timer') && !isMinimized"
      >
        <input type="checkbox" v-model="checkTimer" />
        <span></span>
      </label>

      <button
        class="brand-toggle btn btn-sm px-0"
        id="kt_aside_toggle"
        @click="minimizeAsideMenu()"
        v-if="false"
      >
        <span class="svg-icon svg-icon svg-icon-xl">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            width="24px"
            height="24px"
            viewBox="0 0 24 24"
            version="1.1"
          >
            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
              <polygon points="0 0 24 0 24 24 0 24" />
              <path
                d="M5.29288961,6.70710318 C4.90236532,6.31657888 4.90236532,5.68341391 5.29288961,5.29288961 C5.68341391,4.90236532 6.31657888,4.90236532 6.70710318,5.29288961 L12.7071032,11.2928896 C13.0856821,11.6714686 13.0989277,12.281055 12.7371505,12.675721 L7.23715054,18.675721 C6.86395813,19.08284 6.23139076,19.1103429 5.82427177,18.7371505 C5.41715278,18.3639581 5.38964985,17.7313908 5.76284226,17.3242718 L10.6158586,12.0300721 L5.29288961,6.70710318 Z"
                fill="#000000"
                fill-rule="nonzero"
                transform="translate(8.999997, 11.999999) scale(-1, 1) translate(-8.999997, -11.999999)"
              />
              <path
                d="M10.7071009,15.7071068 C10.3165766,16.0976311 9.68341162,16.0976311 9.29288733,15.7071068 C8.90236304,15.3165825 8.90236304,14.6834175 9.29288733,14.2928932 L15.2928873,8.29289322 C15.6714663,7.91431428 16.2810527,7.90106866 16.6757187,8.26284586 L22.6757187,13.7628459 C23.0828377,14.1360383 23.1103407,14.7686056 22.7371482,15.1757246 C22.3639558,15.5828436 21.7313885,15.6103465 21.3242695,15.2371541 L16.0300699,10.3841378 L10.7071009,15.7071068 Z"
                fill="#000000"
                fill-rule="nonzero"
                opacity="0.3"
                transform="translate(15.999997, 11.999999) scale(-1, 1) rotate(-270.000000) translate(-15.999997, -11.999999)"
              />
            </g>
          </svg>
        </span>
      </button>
    </div>
    <div
      class="aside-menu-wrapper flex-column-fluid"
      id="kt_aside_menu_wrapper"
      v-show="!isMinimized"
    >
      <div class="ml-2 mb-1" v-if="can('change-zone')">
        <span class="badge badge-secondary"
          >AVISOS
          <small>{{ cor | spliter }} </small>
        </span>
      </div>
      <p class="ml-2" v-if="can('change-zone')">
        <span
          ><b>Sectores: </b>
          <small>{{ sects }} </small>
        </span>
        <br />
      </p>
      <div
        id="kt_aside_menu"
        class="aside-menu my-4 scroll ps ps--active-y"
        data-menu-vertical="1"
        data-menu-scroll="1"
        data-menu-dropdown-timeout="500"
      >
        <div class="d-flex justify-content-between bg-secondary opacity-90">
          <div>
            <button
              v-if="can('buscar-trabajos')"
              title="Buscar"
              class="btn btn-sm btn-link mr-2 font-weight-bold"
              @click="searchOT()"
            >
              <b><i class="la la-search text-primary"></i></b>
            </button>
            <button
              title="Filtrar sector"
              v-if="can('change-zone')"
              @click="showModalSectors()"
              class="btn btn-sm btn-link mr-2 font-weight-bold"
            >
              <b><i class="la la-tasks text-primary"></i></b>
            </button>
            <button
              title="Recargar"
              v-if="can('tree') || can('procesos-administrativo')"
              @click="loadMenu()"
              class="btn btn-sm btn-link mr-2"
            >
              <i
                :class="
                  'text-info ' +
                  (loading ? 'fas fa-circle-notch fa-spin' : 'la la-sync')
                "
              ></i>
            </button>
            <button
              title="Tipología de iconos"
              @click="showWindowLegends()"
              class="btn btn-link btn-sm"
              v-if="can('tipologia-iconos')"
            >
              <b>
                <i class="flaticon2-information text-primary"></i>
              </b>
            </button>
            <button
              :title="activeTech ? 'Ocultar brigadas' : 'Mostrar brigadas'"
              class="btn btn-link btn-sm active-tech-button"
              @click="() => setActive(!activeTech)"
            >
              <transition name="bounce">
                <i v-if="activeTech" class="la la-eye-slash text-danger"></i>
                <i v-else class="flaticon-eye text-success"></i>
              </transition>
            </button>
            <transition name="bounce">
              <button
                :disabled="isSearch"
                class="btn btn-link btn-sm"
                @click="searchPending()"
                v-if="can('search-pending') && $route.path == '/works'"
              >
                <transition name="bounce">
                  <i class="fa fa-search text-success"></i>
                </transition>
              </button>
            </transition>
            <div class="dropdown dropdown-inline" v-if="false">
              <button
                type="button"
                title="Más opciones"
                class="btn btn-link btn-sm btn-icon btn-sm"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <i class="flaticon-layer text-primary"></i>
              </button>
              <div class="dropdown-menu">
                <a class="dropdown-item showCertified">
                  {{ !showCertificated ? "Ver" : "Ocultar" }} OT certificadas en
                  el mapa
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="text-center bodyLoading" v-if="loading">
          <div class="sp">
            <div class="sp__square"></div>
            <div class="sp__square"></div>
            <div class="sp__square"></div>
            <div class="sp__square"></div>
          </div>
        </div>

        <div>
          <!-- <div> -->
          <p style="text-align: left" class="mb-3" v-if="false">
            <input
              placeholder="Filtrar"
              class="form-control form-control-sm"
              type="search"
              style="width: 100%"
              @keyup="inputKeyUp"
              v-model="searchText"
            />
          </p>
          <b-tabs id="tab-tree" class="mt-3" small>
            <b-tab title="Avisos" v-if="can('tree')">
              <VJstree
                id="main-tree-information"
                ref="tree"
                @item-click="itemClick"
                @item-drop="itemDrop"
                @item-drop-before="itemDropBefore"
                :item-events="itemEvents"
                multiple
                allow-batch
                draggable
                class="mt-5 mb-3 main-tree-default-vue"
                :data="data"
                show-checkbox
              >
                <template slot-scope="_">
                  <div
                    style="display: inherit; width: 200px"
                    v-on:contextmenu="openMenu"
                    v-if="_.model.slug == 'not_ubicated_second'"
                    @click.right="
                      indexDataMenu = 1;
                      current = _.model;
                    "
                  >
                    <i
                      :class="_.vm.themeIconClasses"
                      role="presentation"
                      v-if="!_.model.loading"
                    ></i>
                    <span v-html="_.model.text"></span>
                    <button
                      style="
                        border: 0px;
                        background-color: transparent;
                        cursor: pointer;
                      "
                      @click="customItemClick(_.vm, _.model, $event)"
                    >
                      <i class="fa fa-remove"></i>
                    </button>
                  </div>
                  <div
                    style="display: inherit; width: 200px"
                    v-on:contextmenu="openMenu"
                    v-else-if="_.model.slug == 'assign_ot'"
                    @click.right="
                      indexDataMenu = 0;
                      current = _.model;
                    "
                  >
                    <i
                      :class="_.vm.themeIconClasses"
                      role="presentation"
                      v-if="!_.model.loading"
                    ></i>
                    <span v-html="_.model.text"></span>
                    <button
                      style="
                        border: 0px;
                        background-color: transparent;
                        cursor: pointer;
                      "
                      @click="customItemClick(_.vm, _.model, $event)"
                    >
                      <i class="fa fa-remove"></i>
                    </button>
                  </div>
                  <div
                    style="display: inherit; width: 200px"
                    v-on:contextmenu="openMenu"
                    v-else-if="_.model.slug == 'circuit'"
                    @click.right="
                      indexDataMenu = 5;
                      current = _.model;
                    "
                  >
                    <i
                      :class="_.vm.themeIconClasses"
                      role="presentation"
                      v-if="!_.model.loading"
                    ></i>
                    <span v-html="_.model.text"></span>
                    <button
                      style="
                        border: 0px;
                        background-color: transparent;
                        cursor: pointer;
                      "
                      @click="customItemClick(_.vm, _.model, $event)"
                    >
                      <i class="fa fa-remove"></i>
                    </button>
                  </div>
                  <div
                    style="display: inherit; width: 200px"
                    v-on:contextmenu="openMenu"
                    v-else-if="_.model.slug == 'ot_managed_tree'"
                    :class="'OT_' + _.model.num_ot"
                    @click.right="
                      indexDataMenu = _.model.id_estado == 9 ? 3 : 8;
                      current = _.model;
                    "
                  >
                    <!-- _.model.id_estado == 9 ? 3  : -->
                    <i
                      :class="_.vm.themeIconClasses"
                      role="presentation"
                      v-if="!_.model.loading"
                    ></i>
                    <span v-html="_.model.text"></span>
                    <button
                      style="
                        border: 0px;
                        background-color: transparent;
                        cursor: pointer;
                      "
                      @click="customItemClick(_.vm, _.model, $event)"
                    >
                      <i class="fa fa-remove"></i>
                    </button>
                  </div>
                  <div
                    style="display: inherit; width: 200px"
                    v-on:contextmenu="openMenu"
                    v-else-if="_.model.slug == 'OTTECH'"
                    :class="'OT_' + _.model.num_ot"
                    @click.right="
                      () => {
                        indexDataMenu =
                          _.model.id_estado != 8 &&
                          _.model.id_estado != 5 &&
                          _.model.id_estado != 9
                            ? 2
                            : 3;
                        current = _.model;
                      }
                    "
                  >
                    <i
                      :class="_.vm.themeIconClasses"
                      role="presentation"
                      v-if="!_.model.loading"
                    ></i>
                    <span v-html="_.model.text"></span>
                    <button
                      style="
                        border: 0px;
                        background-color: transparent;
                        cursor: pointer;
                      "
                      @click="customItemClick(_.vm, _.model, $event)"
                    >
                      <i class="fa fa-remove"></i>
                    </button>
                  </div>
                  <div
                    style="display: inherit; width: 200px"
                    v-on:contextmenu="openMenu"
                    v-else-if="_.model.slug == 'trabajos_particular'"
                    @click.right="
                      () => {
                        indexDataMenu = _.model.id_estado == 6 ? 9 : 10;
                        current = _.model;
                      }
                    "
                  >
                    <i
                      :class="_.vm.themeIconClasses"
                      role="presentation"
                      v-if="!_.model.loading"
                    ></i>
                    <span v-html="_.model.text"></span>
                    <button
                      style="
                        border: 0px;
                        background-color: transparent;
                        cursor: pointer;
                      "
                      @click="customItemClick(_.vm, _.model, $event)"
                    >
                      <i class="fa fa-remove"></i>
                    </button>
                  </div>
                  <div
                    style="display: inherit; width: 200px"
                    v-on:contextmenu="openMenu"
                    v-else-if="_.model.slug == 'subestacion_avisos'"
                    @click.right="
                      () => {
                        indexDataMenu = 11;
                        current = _.model;
                      }
                    "
                  >
                    <i
                      :class="_.vm.themeIconClasses"
                      role="presentation"
                      v-if="!_.model.loading"
                    ></i>
                    <span v-html="_.model.text"></span>
                    <button
                      style="
                        border: 0px;
                        background-color: transparent;
                        cursor: pointer;
                      "
                      @click="customItemClick(_.vm, _.model, $event)"
                    >
                      <i class="fa fa-remove"></i>
                    </button>
                  </div>

                  <!-- AVISO GESTIONADO -->
                  <div
                    style="display: inherit; width: 200px"
                    v-on:contextmenu="openMenu"
                    v-else-if="_.model.slug == 'aviso_gestionado'"
                    @click.right="
                      () => {
                        indexDataMenu = 7;
                        current = _.model;
                      }
                    "
                  >
                    <i
                      :class="_.vm.themeIconClasses"
                      role="presentation"
                      v-if="!_.model.loading"
                    ></i>
                    <span v-html="_.model.text"></span>
                    <button
                      style="
                        border: 0px;
                        background-color: transparent;
                        cursor: pointer;
                      "
                      @click="customItemClick(_.vm, _.model, $event)"
                    >
                      <i class="fa fa-remove"></i>
                    </button>
                  </div>
                  <!-- FIN AVISO GESTIONADO -->
                  <div
                    style="display: inherit; width: 200px"
                    v-on:contextmenu="openMenu"
                    v-else-if="_.model.slug == 'technical_delegation'"
                    @click.right="
                      () => {
                        indexDataMenu = 12;
                        current = _.model;
                      }
                    "
                  >
                    <i
                      :class="_.vm.themeIconClasses"
                      role="presentation"
                      v-if="!_.model.loading"
                    ></i>
                    <span v-html="_.model.text"></span>
                    <button
                      style="
                        border: 0px;
                        background-color: transparent;
                        cursor: pointer;
                      "
                      @click="customItemClick(_.vm, _.model, $event)"
                    >
                      <i class="fa fa-remove"></i>
                    </button>
                  </div>
                  <div
                    style="display: inherit; width: 200px"
                    v-on:contextmenu="openMenu"
                    v-else-if="_.model.slug == 'technical'"
                    @click.right="
                      () => {
                        indexDataMenu =
                          _.model.idSlug == 'fuera_turno' ? 15 : 4;
                        current = _.model;
                      }
                    "
                  >
                    <i
                      :class="_.vm.themeIconClasses"
                      role="presentation"
                      v-if="!_.model.loading"
                    ></i>
                    <span v-html="_.model.text"></span>
                    <button
                      style="
                        border: 0px;
                        background-color: transparent;
                        cursor: pointer;
                      "
                      @click="customItemClick(_.vm, _.model, $event)"
                    >
                      <i class="fa fa-remove"></i>
                    </button>
                  </div>
                  <div
                    style="display: inherit; width: 200px"
                    v-on:contextmenu="openMenu"
                    v-else-if="_.model.slug == 'aviso_pendiente'"
                    @click.right="
                      () => {
                        indexDataMenu = _.model.id_estado == 9 ? 16 : 14;
                        current = _.model;
                      }
                    "
                  >
                    <i
                      :class="_.vm.themeIconClasses"
                      role="presentation"
                      v-if="!_.model.loading"
                    ></i>
                    <span v-html="_.model.text"></span>
                    <button
                      style="
                        border: 0px;
                        background-color: transparent;
                        cursor: pointer;
                      "
                      @click="customItemClick(_.vm, _.model, $event)"
                    >
                      <i class="fa fa-remove"></i>
                    </button>
                  </div>
                  <div
                    style="display: inherit; width: 200px"
                    v-on:contextmenu="openMenu"
                    v-else-if="_.model.slug == 'CIRCUITO_OT'"
                    @click.right="
                      () => {
                        indexDataMenu = 18;
                        current = _.model;
                      }
                    "
                  >
                    <i
                      :class="_.vm.themeIconClasses"
                      role="presentation"
                      v-if="!_.model.loading"
                    ></i>
                    <span v-html="_.model.text"></span>
                    <button
                      style="
                        border: 0px;
                        background-color: transparent;
                        cursor: pointer;
                      "
                      @click="customItemClick(_.vm, _.model, $event)"
                    >
                      <i class="fa fa-remove"></i>
                    </button>
                  </div>
                  <div
                    style="display: inherit; width: 200px"
                    v-on:contextmenu="openMenu"
                    v-else-if="_.model.slug == 'brigadas_disponibles_all'"
                    @click.right="
                      () => {
                        indexDataMenu = 19;
                        current = _.model;
                      }
                    "
                  >
                    <i
                      :class="_.vm.themeIconClasses"
                      role="presentation"
                      v-if="!_.model.loading"
                    ></i>
                    <span v-html="_.model.text"></span>
                    <button
                      style="
                        border: 0px;
                        background-color: transparent;
                        cursor: pointer;
                      "
                      @click="customItemClick(_.vm, _.model, $event)"
                    >
                      <i class="fa fa-remove"></i>
                    </button>
                  </div>
                  <div style="display: inherit; width: 200px" v-else>
                    <i
                      :class="_.vm.themeIconClasses"
                      role="presentation"
                      v-if="!_.model.loading"
                    ></i>
                    <span v-html="_.model.text"></span>
                    <button
                      style="
                        border: 0px;
                        background-color: transparent;
                        cursor: pointer;
                      "
                      @click="customItemClick(_.vm, _.model, $event)"
                    >
                      <i class="fa fa-remove"></i>
                    </button>
                  </div>
                </template>
              </VJstree>
            </b-tab>
            <b-tab title="Procesos" v-if="can('procesos-administrativo')">
              <TreeAdmin ref="PADMIN" />
            </b-tab>
          </b-tabs>
        </div>
        <ul
          id="right-click-menu"
          tabindex="-1"
          ref="right"
          v-on:blur="closeMenu"
          v-if="viewMenu"
          :style="'top:' + top + ';left:' + left"
        >
          <li
            v-for="(item, index) in dataMenuRight[indexDataMenu]"
            :key="index"
            @click="selectMenuRight(item.id)"
            :class="'rightMenu' + item.id"
          >
            <div
              v-if="item.id == 16"
              class="tooltipRightMenu d-flex justify-content-between"
            >
              {{ item.text }}
              <i class="la la-chevron-right"></i>
              <ul class="tooltiptextRightMenu">
                <li
                  v-for="(i, index) in dataMenuRight[6]"
                  :key="index + '2'"
                  @click="selectMenuRight(i.id)"
                >
                  {{ i.text }}
                </li>
              </ul>
            </div>
            <div v-else>{{ item.text }}</div>
          </li>
        </ul>
      </div>
    </div>
    <b-modal
      ref="assignation"
      id="assignation"
      title="Asignación de OT"
      no-close-on-backdrop
      size="xl"
      hide-footer
    >
      <div class="d-block text-center">
        <Info
          ref="assignation2"
          id="assignation2"
          @generateOT="generateOT"
          :info="getInformationWarning"
          :workers="getTecnical"
        />
      </div>
    </b-modal>
    <ModalAvisoPendiente ref="aviso_pendiente" @loadMenu="loadMenu" />
    <MasivOT
      ref="assignationMasiv"
      id="assignationMasiv"
      @generateOTMasive="generateOTMasive"
      :info="getInformationWarning"
      :workers="getTecnical"
    />
    <ModalTraslate
      :showTraslate="showTraslate"
      :techs="getTecnical"
      :treeAssign="true"
      :infoTraslate="getInformation"
      @emitLoadItems="emitLoadItems"
      @hideModalTraslate="hideModalTraslate($event)"
    />

    <ModalAddChildOt ref="showAddChild" @closeWindowModal="closeWindowModal" />
    <ModalLegends ref="show-legends" @closeWindowLegends="closeWindowLegends" />
    <ModalFilterBookmarks
      ref="show-Filter-Bookmarks"
      @closeWindowLegends="closeWindowLegends"
    />
    <ModalAssingOT
      @windowCloseAssign="windowCloseAssign"
      @loadTree="loadTree"
      ref="modalAssignOT"
    />
    <transition name="slide-fade">
      <button-floating-notification v-if="false" />
    </transition>
    <ModalImproperWarning ref="IMP" />
    <InformacionAviso ref="INFO" />
    <GestionPeligro @emitGestionPeligro="emitGestionPeligro" ref="GEST_P" />
    <CorSector ref="CS" />
    <InformacionBrigada ref="INFOBRI" />
    <BrigadeConsultateJob ref="CBRIGADE" @brigadeData="brigadeData = $event" />
    <MoveOT ref="MOVEOT" @moveOTclose="moveOTclose" />
    <InformacionOT ref="INFOOT" />
    <selectSectors ref="sectors" @loadTree="loadMenu($event)" />
    <ModalCertificateOT
      @loadMenu="loadMenu($event)"
      @hideModalCertificate="hideModalCertificate"
      ref="certificateOT"
    />
    <ModalWorkConsult ref="workConsult" />
    <SearchOT
      ref="SEARCH_OT"
      @openActive="openActive"
      @openTreeAction="openTreeAction"
    />
    <TurnOffModal @loadMenu="loadMenu" ref="turn-off" />
    <ActiveAvisoCert ref="AAC" @loadMenu="loadMenu" />

    <!-- CERRAR TURNO - MODAL PARA CERRAR TURNO EN CASO ESPECIFICO (AVISO EN EJECUCIÓN) -->
    <CerrarTrabajo ref="CT" @loadMenu="loadMenu" />

    <ReportTech ref="RTCH" />
    <SModalCertificacion ref="rcertificar"/>
  </div>
</template>
<style>
:root {
  --blue: white;
  --dark-opacity: rgb(0, 0, 0, 0.3);
}
@import url("css/menuComponent.css");
.isMinimized {
  min-width: 9% !important;
  width: 9% !important;
}
</style>
<script>
import Vue from "vue";
import axios from "axios";
import VJstree from "vue-jstree";
import { mapActions, mapGetters } from "vuex";
import Info from "@/components/processes/works/processInfo.vue";
import ModalTraslate from "@/components/processes/management/modals/ModalTraslate";
import toastr from "toastr";
import TreeAdmin from "@/components/admin/tree/TreeAdmin.vue";
import ModalAddChildOt from "@/components/processes/management/modals/ModalAddChildOt.vue";
import ModalLegends from "@/components/processes/management/modals/ModalLegends.vue";
import selectSectors from "@/components/helpers/modals/selectSectors.vue";
import ModalCertificateOT from "@/components/helpers/modals/ModalCertificateOT.vue";
import ModalFilterBookmarks from "@/components/processes/management/modals/ModalFilterBookmarks.vue";
import ModalAssingOT from "@/components/processes/management/modals/ModalAssingOT.vue";
import ModalWorkConsult from "@/components/helpers/modals/ModalWorkConsult.vue";
import ButtonFloatingNotification from "./notifications/buttonFloatingNotification.vue";
import ModalImproperWarning from "@/components/processes/management/modals/ModalImproperWarning.vue";
import InformacionAviso from "./modals/informacionAviso.vue";
import GestionPeligro from "./modals/gestionPeligro.vue";
import CorSector from "./modals/corSector.vue";
import InformacionOT from "./modals/informacionOT.vue";
import InformacionBrigada from "./modals/informacionBrigada.vue";
import BrigadeConsultateJob from "./modals/BrigadeConsultateJob.vue";
import MoveOT from "./modals/moveOT.vue";
import SearchOT from "./modals/searchOT.vue";
import * as $ from "jquery";
const BASE_URL_KML =
  "http://138.197.75.131:9000/serviciosCLD/GeneradorKML/Subestaciones";

const removeAccents = (str) => {
  return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
};
import _ from "lodash";
import moment from "moment";
import MasivOT from "./modals/MasivOT.vue";
import TurnOffModal from "./modals/turnOffModal.vue";
import ActiveAvisoCert from "./modals/ActiveAvisoCert.vue";
import ModalAvisoPendiente from "./modals/ModalAvisoPendiente.vue";
import CerrarTrabajo from "./modals/CerrarTrabajo.vue";
import MENU from "@/assets/menu/menu";

import SModalCertificacion from '@/components/helpers/modals/SModalCertificacion.vue'
// import * as $ from "jquery";
function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}
import can from "@/permission";
import ReportTech from "./modals/ReportTech.vue";
// var gzip = require("gzip-js");
export default {
  components: {
    VJstree,
    TreeAdmin,
    Info,
    ModalTraslate,
    ModalAddChildOt,
    ModalLegends,
    ModalFilterBookmarks,
    ModalAssingOT,
    ButtonFloatingNotification,
    ModalImproperWarning,
    InformacionAviso,
    GestionPeligro,
    CorSector,
    InformacionBrigada,
    BrigadeConsultateJob,
    MoveOT,
    InformacionOT,
    selectSectors,
    ModalCertificateOT,
    ModalWorkConsult,
    SearchOT,
    MasivOT,
    TurnOffModal,
    ActiveAvisoCert,
    ModalAvisoPendiente,
    CerrarTrabajo,
    ReportTech,
    // certificación siemens
    SModalCertificacion
  },
  mounted() {
    if (this.can("tree")) {
      this.treeMenu();
    }
    this.sects = sessionStorage.sectores
      ? _.join(JSON.parse(sessionStorage.sectores), ", ")
      : [];
    this.$emit("brigadeData", this.brigadeData);
    // const interval = () => {
    //   setInterval(() => {
    //     console.clear();
    //   }, 30*1000);
    // };
    // interval();
  },
  data() {
    return {
      /***********************************************************************/
      timer: 3,
      checkTimer: false,
      interval: null,
      timers: [1, 2, 3, 4, 5, 6, 10],
      /***********************************************************************/
      rightClick: false,
      viewMenu: false,
      top: "0px",
      left: "0px",
      indexDataMenu: 1,
      current: {},
      currentOver: {},
      dataMenuRight: MENU,

      searchText: "",
      dataCor: null,
      info: sessionStorage.cor,
      items: [],
      data: [],
      proccess: [],
      reload: null,
      loading: false,
      markerSelected: [],
      showTraslate: false,
      getInformation: [],
      itemEvents: {
        mouseover(event) {
          if (event.model.slug == "assign_ot") {
            //code
          }
        },
        dblclick(event) {
          const  app = event.$root.$children[0];
          const slug = event.model.slug;
          if ( ["assign_ot", "not_ubicated_second", "aviso_gestionado"].includes(slug) ) {
            app.$children[3].search(event.model.num_aviso);
            app.$children[3].show = true;
          }
          if ( ["ot_managed_tree", "OTTECH", "trabajos_particular", "aviso_pendiente"].includes(slug) ) {
            app.$children[4].load(event.model.id_ot);
            app.$children[4].show = true;
          }
        },
      },
      sects: "",
      brigadeData: {},
      isSearching: false,
      isMinimized: false,
    };
  },
  computed: {
    searchMap() {
      return this.$route.path == "/works";
    },
    out() {
      const roles = ["administrador", "superadmin", "operador"];
      return !roles.includes(this.user.roles.name.toLowerCase());
    },
    cor: {
      set(val) {
        this.dataCor = val;
        // this.cor = val
      },
      get() {
        return this.dataCor ? this.dataCor : sessionStorage.cor;
      },
    },
    sectors() {
      return sessionStorage.sectores;
    },
    progressNum() {
      return this.progress;
    },
    ...mapGetters({
      getInformationWarning: "warnings/getInformationWarning",
      getTecnical: "technical/getTecnical",
      user: "auth/user",
      getChildren: "circuitTreeNode/getChildren",
      showCertificated: "warningsOT/getOTCertificated",
      activeTech: "technical/getActive",
      isSearch: "mode/getSearching",
    }),
    modal() {
      return this.$refs.assignation2;
    },
  },
  watch: {
    cor() {
      this.treeMenu();
    },
    checkTimer: function (val) {
      if (val) {
        clearInterval(this.interval);
        this.changeInterval();
      } else {
        clearInterval(this.interval);
      }
    },
  },
  filters: {
    capitalize: function (value) {
      if (!value) return "";
      value = value.toString();
      return value.charAt(0).toUpperCase() + value.slice(1);
    },
    uppercase: function (value) {
      if (!value) return "";
      value = value.toString();
      return value.toUpperCase();
    },
    spliter(values) {
      if (values) {
        return values.replaceAll("'", "");
      }
    },
    joinSplit(values) {
      return JSON.parse(values).join();
    },
  },
  methods: {
    ...mapActions({
      loadToCreationOT: "warnings/loadToCreationOT",
      changeValueTech: "technical/changeValueTech",
      resetWarning: "location/resetWarning",
      loadCircuit: "circuitTreeNode/loadCircuit",
      showCertificatedAction: "warningsOT/showCertificatedAction",
      setActive: "technical/setActive",
      showSearch: "mode/setSearching",
    }),
    can: can,

    searchPending() {
      this.showSearch(true);
    },
    searchOT() {
      let search = this.$refs.SEARCH_OT;
      search.show = true;
      search.codcierre = false;
      search.loadAllOT();
      search.reset();
    },
    openTreeAction(event) {
      let avisos = event.aviso;
      let ots = event.ot;
      // console.log(event);
      var avisoSel = {};
      let aviso = (pos) => {
        if (avisos.length != 0) {
          this.data[pos].children.map((g) => {
            g.children.map((s) => {
              s.children.map((su) => {
                su.children.map((a) => {
                  if (avisos[0].num_aviso === a.num_aviso) {
                    this.data[pos].opened = true;
                    g.opened = true;
                    s.opened = true;
                    su.opened = true;
                    a.selected = true;
                    avisoSel = a;
                  }
                });
              });
            });
          });
          let app = this.$root.$children[0];
          app.case = aviso.slug;
          app.locatePlaceMarker(avisoSel);
          //6707542
        }
      };
      let IS_PENDING = false;
      let OTS = (pos) => {
        if (ots.length != 0) {
          var otsSelected = {};
          this.data[pos].children.map((g) => {
            g.children.map((s) => {
              s.children.map((su) => {
                su.children.map((a) => {
                  if (ots[0].num_ot === a.num_ot) {
                    otsSelected = a;
                    this.data[pos].opened = true;
                    g.opened = true;
                    s.opened = true;
                    su.opened = true;
                    a.selected = true;
                  }
                });
              });
            });
          });

          let app = this.$root.$children[0];
          app.loadInformationOT(otsSelected);
          app.case = otsSelected.slug;
        }
      };

      let DEFINE_OPENED = (pos, status) => {
        if (ots.length != 0) {
          this.data[pos].children.map((g) => {
            g.children.map((s) => {
              s.children.map((su) => {
                su.children.map((a) => {
                  if (ots[0].num_ot === a.num_ot) {
                    IS_PENDING = a.id_estado == status ? true : false;
                  }
                });
              });
            });
          });
        }
      };
      aviso(1);
      aviso(2);
      aviso(3);
      const prom = new Promise((resolve) => {
        if (this.can("tree")) {
          resolve(this.treeMenu(this.data));
        }
      });
      DEFINE_OPENED(7, 1);
      if (!IS_PENDING) {
        prom.then(() => {
          OTS(8);
        });
      } else {
        prom.then(() => {
          OTS(7);
        });
      }
      if (event.iscodCierre) {
        prom.then(() => {
          OTS(9);
        });

        if (!this.loading) {
          prom.then(() => {
            OTS(9);
          });
        }
      }

      DEFINE_OPENED(10, 13);
      if (IS_PENDING) {
        prom.then(() => {
          OTS(10);
        });
      }
      DEFINE_OPENED(11, 13);
      if (IS_PENDING) {
        prom.then(() => {
          OTS(11);
        });
      }

      DEFINE_OPENED(12, 13);
      if (IS_PENDING) {
        prom.then(() => {
          OTS(12);
        });
      }
      DEFINE_OPENED(13, 13);
      if (IS_PENDING) {
        prom.then(() => {
          OTS(13);
        });
      }

      DEFINE_OPENED(14, 13);
      if (IS_PENDING) {
        prom.then(() => {
          OTS(14);
        });
      }
    },
    changeInterval() {
      if (this.checkTimer) {
        clearInterval(this.interval);
        this.interval = setInterval(() => {
          let config = this.data;
          this.treeMenu(config);
        }, this.timer * 60000);
      } else {
        clearInterval(this.interval);
      }
    },
    hideModalCertificate(values) {
      this.showCertificate = values;
      if (this.can("tree")) {
        this.treeMenu(this.data);
      }
    },

    showModel(data) {
      console.log(data);
    },
    emitGestionPeligro(event) {
      if (!event.close) {
        if (this.can("tree")) {
          this.treeMenu(this.data);
        }
        toastr.success(event.message);
      } else {
        toastr.info(event.message);
      }
    },
    moveOTclose(event) {
      if (!event) {
        this.$refs.MOVEOT.show = event;
        if (this.can("tree")) {
          this.treeMenu(this.data);
        }
      }
    },
    selectMenuRight(id) {
      var curr = this.current;
      // console.log({curr: curr});ß

      switch (id) {
        case 1: // Gestión de peligro
          if (curr.slug == "assign_ot" || curr.slug == "not_ubicated_second") {
            let ref = this.$refs.GEST_P;
            ref.num_aviso = [curr.num_aviso];
            ref.estado = curr.estado;
            ref.isShow = true;
            ref.selectCheckedC = "-1";
            ref.selectCheckedA = "-1";
            this.$nextTick(() => {
              ref.isDanger = curr.ind_peligro == 0 ? true : false;
              ref.accion = curr.ind_peligro == 0 ? 1 : 0;
            });
            ref.selectUnchecked = "-1";
            ref.isDanger = false;
          }
          break;
        case 2: // Cambiar Cor/Sector
          if (curr.slug == "assign_ot" || curr.slug == "not_ubicated_second") {
            let ref = this.$refs.CS;
            ref.num_aviso = curr.num_aviso;
            ref.isShow = true;
          }
          break;
        case 4: // Generar OT
          if (curr.slug == "assign_ot") {
            var assignation = this.$refs.assignation;
            var ids = [];
            ids.push(curr.id_aviso);
            axios.post("getavisos", { avisos: ids }).then((res) => {
              this.loadToCreationOT(res.data);
            });
            assignation.show();
          }
          break;
        case 5:
          // Declarar Improcedente
          //numero del aviso
          var IMP = this.$refs.IMP;
          IMP.observacion = "";
          IMP.anexo.model = "";
          IMP.selected = -1;
          if (
            curr.slug == "assign_ot" ||
            curr.slug == "aviso_gestionado" ||
            curr.slug == "ot_managed_tree" ||
            curr.slug == "not_ubicated_second" ||
            curr.slug == "aviso_pendiente"
          ) {
            if (
              curr.id_estado &&
              (curr.id_estado == 4 ||
                curr.id_estado == 5 ||
                curr.id_estado == 9)
            ) {
              toastr.info(`Este aviso ya se encuentra ejecutado`);
            } else {
              let idx = [];
              idx.push(curr.num_aviso);
              IMP.num_aviso = idx;
              IMP.id = curr.id_aviso;
              if (curr.num_ot) {
                IMP.ot = curr.num_ot;
                IMP.num_aviso = [curr.num_aviso];
              }

              //seleccion del select
              IMP.selected = -1;
              //abre el modal
              IMP.showModal = true;
              this.viewMenu = false;
              if (IMP.showModal) {
                sleep(3000);
                IMP.deleteTabIndex();
              }
            }
          }
          if (curr.slug == "circuit") {
            // circuito avisos no gestionados
            let idx = [];
            curr.children.map((el) => {
              if (el.selected) {
                idx.push(el.num_aviso);
              }
            });
            if (idx.length != 0) {
              this.$refs.IMP.num_aviso = idx;
              this.$refs.IMP.id = curr.id_aviso;
              //seleccion del select
              this.$refs.IMP.selected = -1;
              //abre el modal
              this.$refs.IMP.showModal = true;
              this.viewMenu = false;
              if (this.$refs.IMP.showModal) {
                sleep(3000);
                this.$refs.IMP.deleteTabIndex();
              }
            } else {
              toastr.info("Seleccione al menos un aviso");
            }
          }
          if (curr.slug == "CIRCUITO_OT") {
            let idx = [];
            curr.children.map((el) => {
              if (el.selected) {
                idx.push(el.num_aviso);
              }
            });

            if (idx.length) {
              this.$refs.IMP.num_aviso = idx;
              this.$refs.IMP.id = curr.id_aviso;
              this.$refs.IMP.ot = curr.num_ot;
              //seleccion del select
              this.$refs.IMP.selected = -1;
              //abre el modal
              this.$refs.IMP.showModal = true;
              this.viewMenu = false;
              if (this.$refs.IMP.showModal) {
                sleep(3000);
                this.$refs.IMP.deleteTabIndex();
              }
            } else {
              toastr.info("NO HAY NINGÚN AVISO SELECCIONADO !! ");
            }
          }

          if (curr.slug == "OTTECH") {
            if (curr.id_estado == 4) {
              toastr.info(`Esta orden se encuentra en ejecución :) `);
              this.viewMenu = false;
            } else {
              let idx = [];
              curr.avisos.map((el) => {
                idx.push(el.num_aviso);
              });

              if (idx.length) {
                this.$refs.IMP.num_aviso = idx;
                this.$refs.IMP.id = curr.id_aviso;
                this.$refs.IMP.ot = curr.num_ot;
                //seleccion del select
                this.$refs.IMP.selected = -1;
                //abre el modal
                this.$refs.IMP.showModal = true;
                this.viewMenu = false;
                if (this.$refs.IMP.showModal) {
                  sleep(3000);
                  this.$refs.IMP.deleteTabIndex();
                }
              } else {
                toastr.info("NO HAY NINGÚN AVISO SELECCIONADO !! ");
              }
            }
          }
          break;
        case 6: // Más información
          if (
            curr.slug == "assign_ot" ||
            curr.slug == "not_ubicated_second" ||
            curr.slug == "aviso_gestionado"
          ) {
            let info = this.$refs.INFO;

            info.show = true;
            info.search(curr.num_aviso);
            // info.load(curr.id_aviso)
          }
          if (curr.slug == "technical") {
            let info = this.$refs.INFOBRI;
            let user = [
              {
                nombre: curr.nombre,
                telefono: curr.telefono,
                usuario: curr.usuario,
                fechaacceso: curr.fechaacceso,
                vehiculo: curr.cliente ? curr.cliente.vehiculo : "N/A",
              },
            ];
            info.isShowInfo = true;
            info.load(curr.usuario);
            info.user = user;
          }
          if (
            curr.slug == "OTTECH" ||
            curr.slug == "ot_managed_tree" ||
            curr.slug == "trabajos_particular" ||
            curr.slug == "aviso_pendiente"
          ) {
            let info = this.$refs.INFOOT;
            info.show = true;
            info.load(curr.id_ot);
          }
          break;
        case 7: // Prioritario
          break;
        case 8: // Inicio de ruta
          break;
        case 9: // Quitar trabajo
          if (curr.slug == "OTTECH") {
            this.viewMenu = false;
            if (curr.id_estado == 5 || curr.id_estado == 9) {
              toastr.info("Esta OT ya se encuentra cerrada");
            } else if (curr.id_estado == 4) {
              toastr.info("Esta OT se encuentra en ejecución...");
            } else {
              if (window.confirm("¿Desea quitar este trabajo?")) {
                var operador = `${this.user.usuario} - ${this.user.nombre}`;
                axios
                  .put(`ots/${curr.id_ot}`, {
                    brigada: null,
                    brigadaDesasigna: curr.brigada,
                    num_ot: curr.num_ot,
                    id_estado: 1,
                    bitacora: true,
                    descripcion: `EL OPERADOR [${operador}] QUITA TRABAJO A BRIGADA [${curr.usuario_tech}]`,
                    fecha_bitacora: moment().format("YYYY-MM-DD H:mm:ss"),
                  })
                  .then((res) => {
                    if (res.data.message == "success") {
                      if (this.can("tree")) {
                        this.treeMenu(this.data);
                      }
                    }
                  });
              }
            }
          }
          break;
        case 10:
          // Cerrar trabajo
          if (
            curr.slug == "OTTECH" ||
            curr.slug == "ot_managed_tree" ||
            curr.slug == "aviso_pendiente"
          ) {
            this.viewMenu = false;
            if (
              curr.id_estado == 1 ||
              curr.id_estado == 2 ||
              curr.id_estado == 3 ||
              curr.id_estado == 13
            ) {
              if (window.confirm("¿Desea cerrar este trabajo?")) {
                const operador = `${this.user.usuario} - ${this.user.nombre}`;
                axios
                  .put(`ots/${curr.id_ot}`, {
                    brigada: curr.slug == "aviso_pendiente" ? "" : curr.brigada,
                    num_ot: curr.num_ot,
                    id_estado: 9,
                    bitacora: true,
                    nodo: curr.slug == "ot_managed_tree" ? 7 : null,
                    descripcion:
                      "NOVEDAD:  TRABAJO CERRADO POR EL OPERADOR " +
                      ` [${operador}]`,
                    fecha_bitacora: moment().format("YYYY-MM-DD H:mm:ss"),
                    observacion: curr.observacion,
                  })
                  .then((res) => {
                    if (res.data.message == "success") {
                      if (this.can("tree")) {
                        this.treeMenu(this.data);
                      }
                    }
                  });
              }
            } else {
              this.$refs.CT.show = true;
              this.$refs.CT.reset();
              this.$refs.CT.curr = curr;
              this.$refs.CT.loadTech();
              // toastr.info("No puede cerrar este trabajo");
            }
          }
          break;
        case 11: // Certificar OT
          if (
            curr.slug == "OTTECH" ||
            curr.slug == "ot_managed_tree" ||
            curr.slug == "aviso_pendiente"
          ) {
            // var ref = this.$refs.certificateOT;
            // ref.reset();
            // ref.loadCausasPendiente();
            // let dataAviso = {};
            // // si la orden está cerrada por el operador - estado 9
            // // el objeto trae el aviso de referencia
            // if (curr.id_estado == 9) {
            //   ref.info_aviso = curr.id_aviso_ref;
            // } else {
            //   curr.avisos.map((a) => {
            //     if (a.pivot.id_aviso_ref == a.id_aviso) {
            //       dataAviso = a;
            //     }
            //   });
            // }
            // // console.log(curr);
            // ref.info_aviso = dataAviso;
            // ref.form.certificate.checked = false;
            // ref.form.certificate.value = null;
            // ref.form.check.checked = false;
            // ref.form.check.value = false;
            // ref.current = curr;
            // ref.disabled =
            //   curr.id_estado != 5 && curr.id_estado != 4 && curr.id_estado != 9;
            // ref.disabledImp = curr.id_estado === 4;
            // ref.disabledAv = curr.id_estado === 4;

            // ref.showCertificate = true;
            // ref.num_ot = curr.num_ot;
            // ref.brigada = curr.brigada ? curr.brigada : "1234567890";
            // ref.data.accion = 0;
            // ref.data.danio = 0;
            // ref.data.usuario = this.user.usuario;
            // ref.data.num_ot = curr.num_ot;
            // ref.id_ot = curr.id_ot;
            // ref.data.brigada = curr.brigada;

            // ref.loadInfo();
            // ref.searchImages(curr.num_ot);
            // Nuevo modal para certificar
            const certificar = this.$refs.rcertificar;
            const [ oAviso ] = curr.avisos;
            certificar.bShow = true;
            certificar.oAviso = oAviso;
            certificar.onConsultarTareas();
          }
          break;
        case 12: // Reabrir caso
          var referent = this.$refs.aviso_pendiente;

          referent.show = true;
          referent.reset();
          referent.loadCausas();
          referent.load(curr.id_ot);
          this.viewMenu = false;
          break;
        case 13:
          // Actualizar
          this.viewMenu = false;
          var curr2 = this.current;
          var config = {
            tree: this.data,
            cor: this.cor,
            sectores:
              sessionStorage.sectores != undefined
                ? JSON.parse(sessionStorage.sectores)
                : [],
          };
          curr2.loading = true;
          axios.post("actualizar-brigada", config).then((res) => {
            res.data.brigadas.children.map((el) => {
              el.children.map((st) => {
                st.children.map((us) => {
                  if (us.usuario == curr2.usuario) {
                    this.$nextTick(() => {
                      curr2.children = us.children;
                      curr2.loading = false;
                    });
                  }
                });
              });
            });
          });

          break;
        case 14: // Cerrar turno
          var off = this.$refs["turn-off"];
          off.show = true;
          off.reset();
          off.loadItems();
          off.current = curr;
          break;
        case 15: // Trabajo Online
          break;
        case 16: // Reportes
          // try {
          //     if(curr.slug == "technical") {
          //         if(this.$router.path != '/reports') {
          //             this.$router.replace('/reports')
          //             this.viewMenu = false
          //         }
          //     }
          // } catch (error) {
          //     console.log('');
          // }
          break;
        case 17: // Estadísticas
          break;
        case 18: // Trasladar trabajos
          if (curr.slug == "technical") {
            let info = this.$refs.MOVEOT;
            let idx = [];
            curr.children.map((el) => {
              idx.push(el.id_ot);
            });
            let data = { ots: idx };
            info.show = true;
            info.loadOT(data);
            info.loadTech();
            info.worker = curr.usuario;
            sleep(2000);
            info.removeTabIndex();
          }
          break;
        case 19: // Cambiar orden de trabajos
          break;
        case 20: // ordenamiento automático de trabajos
          break;
        case 21: // Recuperar Pendiente
          break;
        case 22: // Consulta de Trabajos
          if (curr.slug == "technical_delegation") {
            let ref = this.$refs.workConsult;
            ref.isShow = true;
            ref.brigada = curr.usuario;
            let techs = [];
            techs.push({
              id: -1,
              text: "Todos",
            });
            let all = [];
            curr.children.map((el) => {
              el.children.map((tech) => {
                all.push(tech.usuario);
                let text = tech.usuario;
                if (tech.cliente) {
                  text = `${tech.cliente.vehiculo} - ${tech.nombre} - ${tech.cliente.movil}`;
                }
                techs.push({
                  id: tech.usuario,
                  text: text.toUpperCase(),
                });
              });
            });
            techs[0].id = JSON.stringify(all);
            ref.reset();
            ref.techs = techs;
          } else if (curr.slug == "technical") {
            let info = this.$refs.CBRIGADE;
            let user = [
              {
                nombre: curr.nombre,
                telefono: curr.telefono,
                usuario: curr.usuario,
                fechaacceso: curr.fechaacceso,
                vehiculo: curr.cliente ? curr.cliente.vehiculo : "N/A",
              },
            ];
            info.show = true;
            info.user = user;
          }
          break;
        case 23: // Historico Recorrido
          if (this.$router.path != "/reports") {
            this.$router.replace("/reports");
            this.viewMenu = false;
          }
          break;
        case 24:
          //Información de turnos
          if (curr.slug == "technical_delegation") {
            let ref = this.$refs.workConsult;
            ref.isShow = true;
            ref.brigada = curr.usuario;
            ref.reset();
            ref.loadTech();
          }
          break;

        case 25: // Historico Recorrido
          curr.children.map((el) => {
            // console.log(el);
            el.icono = {
              icono: "",
            };
            el.icono.icono = el.tipo_aviso_icono;
          });
          this.$refs.assignation.show();
          this.loadToCreationOT(curr.children);
          // toastr.info("asignación másiva de OT")
          break;
        case 26:
          var masiv = this.$refs.assignationMasiv;
          masiv.show = true;
          masiv.isloading = false;
          masiv.counter = 0;
          masiv.info = curr.children;
          masiv.checkAll();
          masiv.loadTechBol();
          break;
        case 27:
          var aac = this.$refs.AAC;
          aac.show = true;
          aac.current = curr;
          if (curr.id_estado == 6) {
            aac.observation = "";
          }
          aac.loadItems();
          break;
        case 28:
          curr.opened = true;
          curr.children.map((el) => {
            el.opened = true;
          });
          this.viewMenu = false;
          break;
        case 29:
          this.viewMenu = false;
          toastr.success("hello word");
          break;
        case 30:
          var info = this.$refs.MOVEOT;
          var idx = [];
          if (curr.children && curr.children.length) {
            idx = curr.children.map((el) => {
              return el.id_ot;
            });
          } else {
            idx = [curr.id_ot];
          }
          var data = { ots: idx };
          info.assignUnique = !idx.length;
          info.show = true;
          info.loadOT(data);
          info.loadTech();
          info.worker = curr.usuario;
          sleep(2000);
          info.removeTabIndex();
          break;
        case 31:
          var RTCH = this.$refs.RTCH;
          RTCH.show = true;
          // RTCH.items = curr.children;
          RTCH.columns = [];
          curr.children.map((el) => {
            RTCH.columns.push({ caption: el.caption });
          });
          RTCH.items = this.reportTurn(curr.children);
          break;
      }
    },
    reportTurn(data) {
      if (!_.isArray(data)) return [];
      var info = [];
      data.map((brigada) => {
        brigada.children.map((ot) => {
          info.push({
            brigada: brigada.caption,
            num_ot: ot.num_ot,
            aviso: ot.aviso,
            circuito_sm: ot.circuito_sm,
            direccion: ot.direccion,
            estado: ot.estado_o_t_s.descripcion,
          });
        });
      });

      return info;
    },
    setMenu: function (top, left) {
      // let bot = window.innerHeight - top;
      let offsetHeight =
        document.getElementById("right-click-menu").offsetHeight;
      let dif = window.innerHeight - top;
      // let largestWidth = window.innerWidth - this.$refs.right.offsetWidth - 25;

      // if (top > largestHeight) top = largestHeight;

      // if (left > largestWidth) left = largestWidth;
      // top = top - 10;
      if (dif < offsetHeight) top = top - offsetHeight;
      this.top = top + "px";
      this.left = left + "px";
    },
    closeMenu() {
      this.viewMenu = false;
      this.current = {};
    },
    openMenu(e) {
      this.viewMenu = true;

      Vue.nextTick(
        function () {
          this.$refs.right.focus();

          this.setMenu(e.y, e.x);
        }.bind(this)
      );
      e.preventDefault();
    },
    onUrl(url) {
      return `${BASE_URL_KML}/${url}`;
      // ?dummy=${(new Date()).getTime()}`
    },
    openActive(event) {
      var aac = this.$refs.AAC;
      aac.show = true;
      aac.current = event;
      aac.loadItems();
    },
    //SOLO DIOS Y YO SABEMOS COMO FUNCIONA
    onElements(data, substation) {
      if (substation.length != 0) {
        let option = [];
        substation.map((item) => {
          item.circuitos.map((c) => {
            c.elemento.map((el) => {
              data.map((dat) => {
                if (dat == el.elemento) {
                  let qa = parseInt(el.total / 1000) + 1;
                  for (let i = 1; i <= qa; i++) {
                    if (el) {
                      let url = `${item.value}/${c.codigo_circuito}/${dat}@siprem@${qa}/${dat}${i}.KML`;
                      option.push({ url: this.onUrl(url), selected: true });
                    }
                  }
                }
              });
            });
          });
        });
        this.$emit("loadKML", option);
      } else {
        this.$emit("loadKML", []);
      }
    },
    onCircuits(data) {
      let option = [];
      if (data.length !== 0) {
        data.map((item) => {
          item.circuitos.map((c) => {
            c.elemento.map((el) => {
              let qa = parseInt(el.total / 1000) + 1;
              el.elemento = removeAccents(el.elemento);
              for (let i = 1; i <= qa; i++) {
                // console.log(el.elemento);
                // || el.elemento == "Interruptores"
                if (el.elemento == "Tramos") {
                  let url = `${item.value}/${c.codigo_circuito}/${el.elemento}@siprem@${qa}/${el.elemento}${i}.KML`;
                  option.push({ url: this.onUrl(url) });
                }
              }
            });
          });
        });
      }
      this.$emit("loadKML", option);
      // console.log(option);
    },
    inputKeyUp() {
      var text = this.searchText.toLowerCase();
      const patt = new RegExp(text);
      this.$refs.tree.handleRecursionNodeChilds(
        this.$refs.tree,
        function (node) {
          if (text !== "" && node.model !== undefined) {
            const str = node.model.text.toLowerCase();
            if (patt.test(str)) {
              node.$el.querySelector(".tree-anchor").style.color = "red";
            } else {
              node.$el.querySelector(".tree-anchor").style.color = "#000";
            }
          } else {
            node.$el.querySelector(".tree-anchor").style.color = "#000";
          }
        }
      );
    },
    loadMenu(values = {}) {
      if (values.eventTurnOff) {
        this.$refs["turn-off"].show = false;
      }
      if (this.can("tree")) {
        this.treeMenu(this.data);
      }
      if (this.can("procesos-administrativo")) {
        this.$refs.PADMIN.loadTree();
      }
    },

    async treeMenu(old = []) {
      this.sects = sessionStorage.sectores
        ? _.join(JSON.parse(sessionStorage.sectores), ", ")
        : [];
      if (this.cor != undefined) {
        this.loading = true;
        let config = {
          tree: old,
          cor: this.cor,
          sectores:
            sessionStorage.sectores != undefined
              ? JSON.parse(sessionStorage.sectores)
              : [],
        };
        let URL = "";
        var response = null;
        if (this.can("tree")) {
          URL = "/tree";
          // out will be a JavaScript Array of bytes
          // var out = gzip.zip(old);
          // console.log(out)
          response = await axios.post(URL, config);
          var load = () => {
            let R = response.data;
            // console.log(R);
            if (R) {
              this.data = [];
              this.data.push(R.avisos_sin_alimentacion);
              if (R.avisos_sin_alimentacion.children) {
                R.avisos_sin_alimentacion.children.map((sector) => {
                  if (sector.children) {
                    sector.children = _.uniqBy(sector.children, "num_aviso");
                  }
                });
              }
              // R.avisos_sin_alimentacion.children = _.uniqBy(R.avisos_sin_alimentacion.children, 'num_aviso')
              this.data.push(R.avisos_no_gestionados);
              this.data.push(R.incidencias_no_gestionadas);
              this.data.push(R.descargos_no_gestionados);

              this.data.push(R.avisos_gestionados);
              this.data.push(R.incidencias_gestionadas);
              this.data.push(R.descargos_gestionados);
              this.data.push(R.ots_gestionadas);

              R.brigadas.children.map((el) => {
                el.children.map((sector) => {
                  sector.children = _.uniqBy(sector.children, "usuario");
                });
              });
              this.data.push(R.brigadas);

              this.data.push(R.getTrabajosParticulares);

              this.data.push(R.getPendientePoda);
              this.data.push(R.brigadasTension);
              this.data.push(R.carroCanasta);
              this.data.push(R.TrafoQuemado);
              this.data.push(R.TrafoBotandoAceite);
              this.data.push(R.carroEscalera);
              this.data[8].children.map((del) => {
                del.dragDisabled = false;
                del.dropDisabled = false;
                del.children[0].dragDisabled = false;
                del.children[0].dropDisabled = false;
              });
              this.data[9].children.map((del) => {
                del.dragDisabled = true;
                del.dropDisabled = true;
              });
              this.data.push(R.works);
              this.loading = false;
            }
          };
          load();
        }
      }
    },
    loadTree(value) {
      if (value.load) {
        this.$nextTick(() => {
          this.dataCor = value.cor;
          if (this.can("tree")) {
            this.treeMenu(this.data);
          }
          if (this.can("procesos-administrativo")) {
            this.sects = sessionStorage.sectores
              ? _.join(JSON.parse(sessionStorage.sectores), ", ")
              : [];
          }
        });
      }
    },
    setCor(val) {
      this.dataCor = val;
      if (this.can("tree")) {
        this.treeMenu();
      }
      if (this.can("procesos-administrativo")) {
        this.$refs.PADMIN.treeMenuAdmin();
      }
    },
    message(title, text, icon) {
      this.$swal.fire({
        title: title,
        text: text,
        icon: icon,
      });
    },
    generateOTMasive(values) {
      console.log(values);
      if (this.can("tree")) {
        this.treeMenu(this.data);
      }
    },
    generateOT(values) {
      let [init] = values;
      let ot = {
        usuario_genera: this.user.usuario,
        fullBrigada: init.full_name,
        full_user: `${this.user.usuario} - ${this.user.nombre}`,
        avisos: init.avisos,
        aviso_ref: init.aviso_referencia,
        id_estado: init.trabajador == null ? 1 : 2,
        observacion: init.observacion,
        geojson: this.polygonGeojson,
        brigada: init.trabajador,
      };

      axios.post("/ots", ot).then((res) => {
        this.infoWinOpen = false;
        if (res.data.message == "failed") {
          toastr.info(JSON.stringify(res.data.error));
          this.$refs.Info.removeLoaderSpinClass();
        } else {
          if (this.can("tree")) {
            this.treeMenu(this.data);
          }
          toastr.success("se ha generado la OT");
          this.$emit("loadMenu", { load: 1 });
          this.resetWarning([]);
          this.$refs.assignation.hide();
        }
      });
    },
    hideModalTraslate(event) {
      this.showTraslate = event;
    },
    emitLoadItems(event) {
      if (event) {
        this.treeMenu();
      }
    },
    closeWindowModal(event) {
      if (!event) {
        this.$refs.showAddChild.addChildShow = event;
      }
    },
    closeWindowLegends(event) {
      if (!event) {
        this.$refs.showAddChild.addChildShow = event;
      }
    },
    showFilterBookmarks() {
      if (this.$route.name == "ProcessesWorks") {
        let modal = this.$refs["show-Filter-Bookmarks"];
        modal.cor = this.cor;
        modal.loadSubStations();
        modal.showLegends = true;
      }
      // modal.loadIcons()
    },
    showWindowLegends() {
      let modal = this.$refs["show-legends"];
      modal.showLegends = true;
      modal.loadIcons();
    },
    showModalSectors() {
      let modal = this.$refs["sectors"];

      let c = sessionStorage.cor
        ? sessionStorage.cor.replaceAll("'", "").split(",")
        : [];
      modal.loadSector();
      modal.cors = _.uniq(c);
      modal.defaultInfo();
      modal.isShow = true;
    },
    windowCloseAssign(event) {
      if (!event) {
        this.$refs.modalAssignOT.isAssignation = event;
      }
    },
    itemDropBefore(node, item, draggedItem) {
      try {
        console.log("ITEM-DROP-BEFORE");
        if (draggedItem.slug == "technical") {
          if (item.slug == "brigadas_disponibles_all") {
            let data = {
              usuario: draggedItem.usuario,
            };
            axios.post("update-status", data).then((res) => {
              if (res.data.record == 0) {
                toastr.info("Este usuario no tiene sesión iniciada");
              }
              if (this.can("tree")) {
                this.treeMenu(this.data);
              }
            });
          }
        }
        let AV_NG_TIP = (pos) => {
          return this.data[pos].children[pointsDraggedItem[0]].children[
            pointsDraggedItem[1]
          ].children[pointsDraggedItem[2]];
        };
        let ORDER_AV = (pos) => {
          AV_NG_TIP(pos).children = _.uniqBy(
            _.orderBy(AV_NG_TIP(pos).children, ["id"], ["asc"]),
            "id"
          );
        };
        let pointsItem = item.points.split("@").map((item) => {
          return parseInt(item);
        });
        let pointsDraggedItem = draggedItem.points.split("@").map((item) => {
          return parseInt(item);
        });
        let searchOtInformation = async (id) => {
          let response = await axios.get(`ots/${id}`);
          return response;
        };
        /**
         * SI SE ARRASTRA UN AVISO NO GESTIONADO A UNA ORDEN DE TRABAJO
         * RESULTADO: ABRIR UNA VENTANA MODAL PARA PODER AGREGAR EL AVISO A ESA OT
         */
        if (
          item.slug === "ot_managed_tree" &&
          draggedItem.slug === "assign_ot"
        ) {
          let otNode =
            this.data[7].children[pointsItem[0]].children[pointsItem[1]]
              .children[pointsItem[2]].children;

          if (draggedItem.slug === "assign_ot") {
            if (
              otNode[pointsItem[3]].children[0].tipo_trabajo_id === 1 &&
              draggedItem.tipo_trabajo_nombre !== "AVISO"
            ) {
              this.message(
                "Información1",
                "No puede agrupar avisos con Incidencias o Descargos",
                "warning"
              );
            } else if (
              otNode[pointsItem[3]].children[0].tipo_trabajo_id === 2 &&
              draggedItem.tipo_trabajo_nombre !== "INCIDENCIA"
            ) {
              this.message(
                "Información2",
                "No puede agrupar avisos con Incidencias o Descargos",
                "warning"
              );
            } else if (
              otNode[pointsItem[3]].children[0].tipo_trabajo_id === 3 &&
              draggedItem.tipo_trabajo_nombre !== "DESCARGO"
            ) {
              this.message(
                "Información3",
                "No puede agrupar avisos con Incidencias o Descargos",
                "warning"
              );
            } else {
              this.$refs.showAddChild.addChildShow = true;
              this.$refs.showAddChild.informationOT(
                item.id_ot,
                draggedItem.id_aviso
              );
              if (this.$refs.showAddChild.isConfirm) {
                if (draggedItem.idSlug == "aviso_no_gestionado") {
                  AV_NG_TIP(1).children.splice(pointsDraggedItem[3], 1);
                  ORDER_AV(1);
                }
                if (draggedItem.idSlug == "incidencia_no_gestionada") {
                  AV_NG_TIP(2).children.splice(pointsDraggedItem[3], 1);
                  ORDER_AV(2);
                }
                if (draggedItem.idSlug == "descargo_no_gestionado") {
                  AV_NG_TIP(3).children.splice(pointsDraggedItem[3], 1);
                  ORDER_AV(3);
                }
                otNode[pointsItem[3]].addChild(draggedItem);
                this.$refs.showAddChild.addChildShow = false;
              }
            }
          }
        }
        if (draggedItem.slug == "technical") {
          if (item.slug == "ot_managed_tree") {
            if (draggedItem.estado === "DISPONIBLE") {
              if (
                item.id_estado != 1 &&
                item.id_estado != 2 &&
                item.id_estado != 8
              ) {
                this.message(
                  "Información",
                  "La OT ya se encuentra gestionada (Sólo se podrá asignar cuando su estado sea Asignado o Pendiente)",
                  "error"
                );
              } else {
                if (item.brigada === draggedItem.usuario) {
                  this.message(
                    "Información",
                    "La Orden de trabajo ya se encuentra asignada a esta brigada",
                    "info"
                  );
                } else if (item.brigada == null) {
                  this.$refs.modalAssignOT.loadOT(item.id_ot);
                  let tech = [];
                  tech.push({
                    id: draggedItem.usuario,
                    text: draggedItem.nombre,
                  });
                  this.$refs.modalAssignOT.setTech(tech);
                  this.$refs.modalAssignOT.isAssignation = true;
                } else if (item.brigada != draggedItem.usuario) {
                  this.$swal
                    .fire({
                      title: "¿Desea realizar el cambio de brigada para la OT?",
                      text: "La OT ya se encuentra asignada a otra brigada",
                      showCancelButton: true,
                      confirmButtonText: "Si, Trasladar OT",
                    })
                    .then((result) => {
                      if (result.isConfirmed) {
                        let tech = [];
                        tech.push({
                          id: draggedItem.usuario,
                          text: draggedItem.nombre,
                        });
                        searchOtInformation(item.id_ot).then((res) => {
                          this.getInformation = res.data;
                        });
                        this.changeValueTech(tech);
                        this.showTraslate = true;
                      }
                    });
                  // this.message('Información', 'La OT ya se encuentra asignada a otra brigada', 'error')
                }
              }
            } else {
              this.message(
                "Información",
                "No puede asignar una OT a una brigada que no se encuentre disponible",
                "error"
              );
            }
          }
          if (item.slug == "childrenOTWarnings") {
            this.data[4].children[pointsDraggedItem[0]].children[
              pointsDraggedItem[1]
            ].addChild(draggedItem);
          }
        }
        if (draggedItem.slug == "assign_ot") {
          // let pointsItem = item.points.split('@').map(item => {
          //     return parseInt(item)
          // })

          /*****************************************************************************
           * FUNCIONES QUE OBTIENEN AVISOS - INCIDENCIAS - DESCARGOS (NO GESTIONADOS)
           ****************************************************************************/
          let AV_NG_TIP = (pos) => {
            return this.data[pos].children[pointsDraggedItem[0]].children[
              pointsDraggedItem[1]
            ].children[pointsDraggedItem[2]];
          };
          let ORDER_AV = (pos) => {
            AV_NG_TIP(pos).children = _.uniqBy(
              _.orderBy(AV_NG_TIP(pos).children, ["id"], ["asc"]),
              "id"
            );
          };
          /*****************************************************************************
           * VALIDACIONES AL ARRASTRAR AVISOS - INCIDENCIAS - DESCARGOS (NO GESTIONADOS)
           ****************************************************************************/
          if (draggedItem.idSlug == "aviso_no_gestionado") {
            if (item.slug == "assign_ot") {
              if (item.idSlug == "aviso_no_gestionado") {
                AV_NG_TIP(1).addChild(draggedItem);
                if (item.children != undefined) {
                  item.children = [];
                }
                ORDER_AV(1);
              }

              if (item.idSlug == "incidencia_no_gestionada") {
                AV_NG_TIP(1).addChild(draggedItem);
                if (item.children != undefined) {
                  item.children = [];
                }
                ORDER_AV(1);
              }
              if (item.idSlug == "descargo_no_gestionado") {
                AV_NG_TIP(1).addChild(draggedItem);
                if (item.children != undefined) {
                  item.children = [];
                }
                ORDER_AV(1);
              }
            }

            if (item.slug == "aviso_gestionado") {
              AV_NG_TIP(1).addChild(draggedItem);
              if (item.children != undefined) {
                let idx = item.children.findIndex(
                  (f) => f.id_aviso == draggedItem.id_aviso
                );
                if (idx != -1) {
                  item.children.splice(idx, 1);
                }
              }
              ORDER_AV(1);
            }
            if (item.slug == "technical") {
              AV_NG_TIP(1).children.push(draggedItem);
              if (item.children != undefined) {
                let idx = item.children.findIndex(
                  (f) => f.id_aviso == draggedItem.id_aviso
                );
                if (idx != -1) {
                  item.children.splice(idx, 1);
                }
                // item.children = []
              }
              ORDER_AV(1);
              if (item.estado == "DISPONIBLE") {
                let ids = [];
                ids.push(draggedItem.id_aviso);
                let tech = [];
                tech.push({ usuario: item.usuario, nombre: item.nombre });
                this.changeValueTech(tech);
                axios.post("getavisos", { avisos: ids }).then((res) => {
                  this.loadToCreationOT(res.data);
                });
                this.$refs["assignation"].show();
              } else {
                this.message(
                  "Información",
                  "Esta acción solo se permite para brigadas disponibles",
                  "warning"
                );
              }
            }
          }
          //INDICENCIAS NO GESTIONADAS
          if (draggedItem.idSlug == "incidencia_no_gestionada") {
            if (item.slug == "assign_ot") {
              if (item.idSlug == "aviso_no_gestionado") {
                AV_NG_TIP(2).addChild(draggedItem);
                if (item.children != undefined) {
                  item.children = [];
                }
                ORDER_AV(2);
              }

              if (item.idSlug == "incidencia_no_gestionada") {
                AV_NG_TIP(2).addChild(draggedItem);
                if (item.children != undefined) {
                  item.children = [];
                }
                ORDER_AV(2);
              }
              if (item.idSlug == "descargo_no_gestionado") {
                AV_NG_TIP(2).addChild(draggedItem);
                if (item.children != undefined) {
                  item.children = [];
                }
                ORDER_AV(2);
              }
            }

            if (item.slug == "aviso_gestionado") {
              AV_NG_TIP(2).addChild(draggedItem);
              if (item.children != undefined) {
                item.children = [];
              }
              ORDER_AV(2);
            }

            if (item.slug == "technical") {
              AV_NG_TIP(2).addChild(draggedItem);
              if (item.children != undefined) {
                let idx = item.children.findIndex(
                  (f) => f.id_aviso == draggedItem.id_aviso
                );
                if (idx != -1) {
                  item.children.splice(idx, 1);
                }
              }
              ORDER_AV(2);
              if (item.estado == "DISPONIBLE") {
                let ids = [];
                ids.push(draggedItem.id_aviso);
                let tech = [];
                tech.push({ usuario: item.usuario, nombre: item.nombre });
                this.changeValueTech(tech);
                axios.post("getavisos", { avisos: ids }).then((res) => {
                  this.loadToCreationOT(res.data);
                });
                this.$refs["assignation"].show();
              } else {
                this.message(
                  "Información",
                  "Esta acción solo se permite para brigadas disponibles",
                  "warning"
                );
              }
            }
          }
          //DESCARGOS NO GESTIONADOS
          if (draggedItem.idSlug == "descargo_no_gestionado") {
            if (item.slug == "assign_ot") {
              if (item.idSlug == "aviso_no_gestionado") {
                AV_NG_TIP(3).children.push(draggedItem);
                if (item.children != undefined) {
                  let idx = item.children.findIndex(
                    (f) => f.id_aviso == draggedItem.id_aviso
                  );
                  if (idx != -1) {
                    item.children.splice(idx, 1);
                  }
                }
                ORDER_AV(3);
              }

              if (item.idSlug == "incidencia_no_gestionada") {
                AV_NG_TIP(3).children.push(draggedItem);
                if (item.children != undefined) {
                  let idx = item.children.findIndex(
                    (f) => f.id_aviso == draggedItem.id_aviso
                  );
                  if (idx != -1) {
                    item.children.splice(idx, 1);
                  }
                }
                ORDER_AV(3);
              }
              if (item.idSlug == "descargo_no_gestionado") {
                AV_NG_TIP(3).children.push(draggedItem);
                if (item.children != undefined) {
                  let idx = item.children.findIndex(
                    (f) => f.id_aviso == draggedItem.id_aviso
                  );
                  if (idx != -1) {
                    item.children.splice(idx, 1);
                  }
                }
                ORDER_AV(3);
              }
            }

            if (item.slug == "aviso_gestionado") {
              AV_NG_TIP(3).children.push(draggedItem);
              if (item.children != undefined) {
                item.children = [];
              }
              ORDER_AV(3);
            }

            if (item.slug == "technical") {
              AV_NG_TIP(3).children.push(draggedItem);
              if (item.children != undefined) {
                item.children = [];
              }
              ORDER_AV(3);
              if (item.estado == "DISPONIBLE") {
                let ids = [];
                ids.push(draggedItem.id_aviso);
                let tech = [];
                tech.push({ usuario: item.usuario, nombre: item.nombre });
                this.changeValueTech(tech);
                axios.post("getavisos", { avisos: ids }).then((res) => {
                  this.loadToCreationOT(res.data);
                });
                this.$refs["assignation"].show();
              } else {
                this.message(
                  "Información",
                  "Esta acción solo se permite para brigadas disponibles",
                  "warning"
                );
              }
            }
          }
        }
      } catch (e) {
        // console.log('....');
      }
    },
    itemDrop(node, item, draggedItem) {
      console.log("ITEM-DROP");

      try {
        /*********************************************************************************************
         * pointsDraggedItem: SON LOS INDICES REFERENTES A LA POSICIÓN ESPECIFICA DEL ITEM ARRASTRADO
         * Ej: [0,0,1]
         ****************************************************************************************/
        let pointsDraggedItem = draggedItem.points.split("@").map((item) => {
          return parseInt(item);
        });
        this.$nextTick(() => {
          if (draggedItem.slug == "assign_ot") {
            // let pointsItem = item.points.split('@').map(item => {
            //     return parseInt(item)
            // })

            /*****************************************************************************
             * FUNCIONES QUE OBTIENEN AVISOS - INCIDENCIAS - DESCARGOS (NO GESTIONADOS)
             ****************************************************************************/
            let AV_NG_TIP = (pos) => {
              return this.data[pos].children[pointsDraggedItem[0]].children[
                pointsDraggedItem[1]
              ].children[pointsDraggedItem[2]];
            };
            let ORDER_AV = (pos) => {
              AV_NG_TIP(pos).children = _.uniqBy(
                _.orderBy(AV_NG_TIP(pos).children, ["id"], ["asc"]),
                "id"
              );
            };
            /*****************************************************************************
             * VALIDACIONES AL ARRASTRAR AVISOS - INCIDENCIAS - DESCARGOS (NO GESTIONADOS)
             ****************************************************************************/
            if (draggedItem.idSlug == "aviso_no_gestionado") {
              if (item.slug == "assign_ot") {
                if (item.idSlug == "aviso_no_gestionado") {
                  AV_NG_TIP(1).addChild(draggedItem);
                  if (item.children != undefined) {
                    item.children = [];
                  }
                  ORDER_AV(1);
                }

                if (item.idSlug == "incidencia_no_gestionada") {
                  AV_NG_TIP(1).addChild(draggedItem);
                  if (item.children != undefined) {
                    item.children = [];
                  }
                  ORDER_AV(1);
                }
                if (item.idSlug == "descargo_no_gestionado") {
                  AV_NG_TIP(1).addChild(draggedItem);
                  if (item.children != undefined) {
                    item.children = [];
                  }
                  ORDER_AV(1);
                }
              }

              if (item.slug == "aviso_gestionado") {
                AV_NG_TIP(1).addChild(draggedItem);
                if (item.children != undefined) {
                  let idx = item.children.findIndex(
                    (f) => f.id_aviso == draggedItem.id_aviso
                  );
                  if (idx != -1) {
                    item.children.splice(idx, 1);
                  }
                }
                ORDER_AV(1);
              }
              if (item.slug == "technical") {
                AV_NG_TIP(1).children.push(draggedItem);
                if (item.children != undefined) {
                  let idx = item.children.findIndex(
                    (f) => f.id_aviso == draggedItem.id_aviso
                  );
                  if (idx != -1) {
                    item.children.splice(idx, 1);
                  }
                  // item.children = []
                }
                ORDER_AV(1);
                if (item.estado == "DISPONIBLE") {
                  let ids = [];
                  ids.push(draggedItem.id_aviso);
                  let tech = [];
                  tech.push({ usuario: item.usuario, nombre: item.nombre });
                  this.changeValueTech(tech);
                  axios.post("getavisos", { avisos: ids }).then((res) => {
                    this.loadToCreationOT(res.data);
                  });
                  this.$refs.assignation.show();
                  const [children] = this.$refs.assignation.$children;

                  const [assignation2] = children.$children;
                  assignation2.isAssign = true;
                  assignation2.loadTech();
                  setTimeout(() => {
                    assignation2.worker = item.usuario;
                  }, 3000);
                } else {
                  this.message(
                    "Información",
                    "Esta acción solo se permite para brigadas disponibles",
                    "warning"
                  );
                }
              }
            }
            //INDICENCIAS NO GESTIONADAS
            if (draggedItem.idSlug == "incidencia_no_gestionada") {
              if (item.slug == "assign_ot") {
                if (item.idSlug == "aviso_no_gestionado") {
                  AV_NG_TIP(2).addChild(draggedItem);
                  if (item.children != undefined) {
                    item.children = [];
                  }
                  ORDER_AV(2);
                }

                if (item.idSlug == "incidencia_no_gestionada") {
                  AV_NG_TIP(2).addChild(draggedItem);
                  if (item.children != undefined) {
                    item.children = [];
                  }
                  ORDER_AV(2);
                }
                if (item.idSlug == "descargo_no_gestionado") {
                  AV_NG_TIP(2).addChild(draggedItem);
                  if (item.children != undefined) {
                    item.children = [];
                  }
                  ORDER_AV(2);
                }
              }

              if (item.slug == "aviso_gestionado") {
                AV_NG_TIP(2).addChild(draggedItem);
                if (item.children != undefined) {
                  item.children = [];
                }
                ORDER_AV(2);
              }

              if (item.slug == "technical") {
                AV_NG_TIP(2).addChild(draggedItem);
                if (item.children != undefined) {
                  let idx = item.children.findIndex(
                    (f) => f.id_aviso == draggedItem.id_aviso
                  );
                  if (idx != -1) {
                    item.children.splice(idx, 1);
                  }
                }
                ORDER_AV(2);
                if (item.estado == "DISPONIBLE") {
                  let ids = [];
                  ids.push(draggedItem.id_aviso);
                  let tech = [];
                  tech.push({ usuario: item.usuario, nombre: item.nombre });
                  this.changeValueTech(tech);
                  axios.post("getavisos", { avisos: ids }).then((res) => {
                    this.loadToCreationOT(res.data);
                  });
                  this.$refs["assignation"].show();
                } else {
                  this.message(
                    "Información",
                    "Esta acción solo se permite para brigadas disponibles",
                    "warning"
                  );
                }
              }
            }
            //DESCARGOS NO GESTIONADOS
            if (draggedItem.idSlug == "descargo_no_gestionado") {
              if (item.slug == "assign_ot") {
                if (item.idSlug == "aviso_no_gestionado") {
                  AV_NG_TIP(3).children.push(draggedItem);
                  if (item.children != undefined) {
                    let idx = item.children.findIndex(
                      (f) => f.id_aviso == draggedItem.id_aviso
                    );
                    if (idx != -1) {
                      item.children.splice(idx, 1);
                    }
                  }
                  ORDER_AV(3);
                }

                if (item.idSlug == "incidencia_no_gestionada") {
                  AV_NG_TIP(3).children.push(draggedItem);
                  if (item.children != undefined) {
                    let idx = item.children.findIndex(
                      (f) => f.id_aviso == draggedItem.id_aviso
                    );
                    if (idx != -1) {
                      item.children.splice(idx, 1);
                    }
                  }
                  ORDER_AV(3);
                }
                if (item.idSlug == "descargo_no_gestionado") {
                  AV_NG_TIP(3).children.push(draggedItem);
                  if (item.children != undefined) {
                    let idx = item.children.findIndex(
                      (f) => f.id_aviso == draggedItem.id_aviso
                    );
                    if (idx != -1) {
                      item.children.splice(idx, 1);
                    }
                  }
                  ORDER_AV(3);
                }
              }

              if (item.slug == "aviso_gestionado") {
                AV_NG_TIP(3).children.push(draggedItem);
                if (item.children != undefined) {
                  item.children = [];
                }
                ORDER_AV(3);
              }

              if (item.slug == "technical") {
                AV_NG_TIP(3).children.push(draggedItem);
                if (item.children != undefined) {
                  item.children = [];
                }
                ORDER_AV(3);
                if (item.estado == "DISPONIBLE") {
                  let ids = [];
                  ids.push(draggedItem.id_aviso);
                  let tech = [];
                  tech.push({ usuario: item.usuario, nombre: item.nombre });
                  this.changeValueTech(tech);
                  axios.post("getavisos", { avisos: ids }).then((res) => {
                    this.loadToCreationOT(res.data);
                  });
                  this.$refs["assignation"].show();
                } else {
                  this.message(
                    "Información",
                    "Esta acción solo se permite para brigadas disponibles",
                    "warning"
                  );
                }
              }
            }
          }

          /*************************************************
           * VALIDACIONES AL ARRASTRAR UN AVISO GESTIONADO
           *************************************************/
          if (draggedItem.slug == "aviso_gestionado") {
            let AV_GEST = (pos) => {
              return this.data[pos].children[pointsDraggedItem[0]].children[
                pointsDraggedItem[1]
              ].children[pointsDraggedItem[2]];
            };
            let ORDER_AV = (pos) => {
              AV_GEST(pos).children = _.uniqBy(
                _.orderBy(AV_GEST(pos).children, ["id"], ["asc"]),
                "id"
              );
            };
            let idx = 0;
            if (draggedItem.idSlug == "aviso_gestionado") {
              idx = 4;
            }
            if (draggedItem.idSlug == "incidencia_gestionada") {
              idx = 5;
            }
            if (draggedItem.idSlug == "descargo_gestionado") {
              idx = 6;
            }

            AV_GEST(idx).children.push(draggedItem);
            if (item.slug === "technical") {
              const tech = item.obs_tech ? item.obs_tech : item.caption;
              const operador = `${this.user.usuario} - ${this.user.nombre}`;
              let ot = draggedItem.info_ot;

              if (
                ot.id_estado == 1 ||
                ot.id_estado == 8 ||
                ot.id_estado == 13
              ) {
                let data = {
                  num_ot: ot.num_ot,
                  usuario_genera: this.user.usuario,
                  brigada: item.usuario,
                  id_estado: 2,
                  bitacora: true,
                  descripcion: `NOVEDAD: EL OPERADOR [${operador}] ASIGNA LA OT A LA BRIGADA [${tech}]`,
                  observacion: `${moment().format(
                    "YYYY-MM-DD HH:mm:ss"
                  )} ${tech}`,
                };
                this.loading = true;
                axios.put(`ots/${ot.id_ot}`, data).then((res) => {
                  if (res.data.message == "success") {
                    toastr.info(
                      `Se ha asignado la OT a la brigada [${item.usuario}]`
                    );
                    this.loading = false;
                    if (this.can("tree")) {
                      this.treeMenu(this.data);
                    }
                  }
                });
              }
            } else {
              if (item.children != undefined) {
                item.children = [];
              }
            }
            ORDER_AV(idx);
          }

          let searchOtInformation = async (id) => {
            let response = await axios.get(`ots/${id}`);
            return response;
          };

          /*********************************************************
           * OPCIONES DE VALIDACIÓN CUANDO SE ARRASTRA UNA BRIGADA
           ***************************************************/
          if (draggedItem.slug === "technical") {
            let BRIGAD = (pos) => {
              return this.data[pos].children[pointsDraggedItem[0]].children[
                pointsDraggedItem[1]
              ];
            };
            let ORDER_BRI = (pos) => {
              BRIGAD(pos).children = _.uniqBy(
                _.orderBy(BRIGAD(pos).children, ["id"], ["asc"]),
                "id"
              );
            };

            if (item.slug == "technical") {
              BRIGAD(8).children.push(draggedItem);
              if (item.children) {
                item.children = [];
              }
              ORDER_BRI(8);
            }
            /**************************************************
             * SI SE ARRASTRA UNA BRIGADA A UNA OT
             **************************************************/

            if (item.slug === "ot_managed_tree") {
              BRIGAD(8).addChild(draggedItem);
              let last = _.last(item.children);
              if (draggedItem.estado === "DISPONIBLE") {
                if (last.nombre != undefined && last.slug == "technical") {
                  item.children.pop();
                }
                if (
                  item.id_estado != 1 &&
                  item.id_estado != 2 &&
                  item.id_estado != 8
                ) {
                  this.message(
                    "Información",
                    "La OT ya se encuentra gestionada (Sólo se podrá asignar cuando su estado sea Asignado - Pendiente - Cancelada)",
                    "error"
                  );
                } else {
                  /**
                   * SI LA BRIGADA QUE SE ESTÁ ARRASTRANDO ES LA MISMA QUE TIENE LA BRIGADA
                   */
                  if (item.brigada === draggedItem.usuario) {
                    this.message(
                      "Información",
                      "La Orden de trabajo ya se encuentra asignada a esta brigada",
                      "info"
                    );
                  } else if (item.brigada == null) {
                    /**
                     * SI LA OT NO TIENE BRIGADA
                     */
                    this.$refs.modalAssignOT.loadOT(item.id_ot);
                    let tech = [];
                    tech.push({
                      id: draggedItem.usuario,
                      text: draggedItem.nombre,
                    });
                    this.$refs.modalAssignOT.setTech(tech);
                    this.$refs.modalAssignOT.isAssignation = true;
                    // this.message('Información', 'Asignación OT ', 'info')
                  } else if (item.brigada != draggedItem.usuario) {
                    /**
                     * SI LA BRIGADA QUE SE ESTÁ ARRASTRANDO ES DIFERENTE QUE TIENE LA BRIGADA
                     */
                    this.$swal
                      .fire({
                        title:
                          "¿Desea realizar el cambio de brigada para la OT?",
                        text: "La OT ya se encuentra asignada a otra brigada",
                        showCancelButton: true,
                        confirmButtonText: "Si, Trasladar OT",
                      })
                      .then((result) => {
                        if (result.isConfirmed) {
                          let tech = [];
                          // tech.push({usuario: draggedItem.usuario, nombre: draggedItem.nombre})
                          tech.push({
                            id: draggedItem.usuario,
                            text: draggedItem.nombre,
                          });
                          this.changeValueTech(tech);
                          searchOtInformation(item.id_ot).then((res) => {
                            this.getInformation = res.data;
                          });
                          this.showTraslate = true;
                        }
                      });
                  }
                }
              } else {
                if (last.nombre != undefined && last.slug == "technical") {
                  item.children.pop();
                }
                this.message(
                  "Información",
                  "No puede asignar una OT a una brigada que no se encuentre disponible",
                  "error"
                );
              }
            }

            /**************************************************
             * SI SE ARRASTRA UNA BRIGADA A UN AVISO GESTIONADO
             **************************************************/
            if (item.slug == "aviso_gestionado") {
              BRIGAD(8).addChild(draggedItem);
              if (item.children) {
                item.children = [];
              }
              ORDER_BRI(8);
            }

            /**************************************************
             * SI SE ARRASTRA UNA BRIGADA A UN AVISO GESTIONADO
             **************************************************/
            if (item.slug == "assign_ot") {
              BRIGAD(8).addChild(draggedItem);
              if (item.children) {
                item.children = [];
              }
              ORDER_BRI(8);
              if (draggedItem.estado == "DISPONIBLE") {
                let ids = [];
                ids.push(item.id_aviso);
                let tech = [];
                tech.push({
                  usuario: draggedItem.usuario,
                  nombre: draggedItem.nombre,
                });
                this.changeValueTech(tech);
                axios.post("getavisos", { avisos: ids }).then((res) => {
                  this.loadToCreationOT(res.data);
                });
                this.$refs["assignation"].show();
              } else {
                this.message(
                  "Información",
                  "Esta acción solo se permite para brigadas disponibles",
                  "warning"
                );
              }
            }
          }

          /***************************************************
           * OPCIONES DE VALIDACIÓN CUANDO SE ARRASTRA UNA OT
           ***************************************************/
          if (draggedItem.slug == "ot_managed_tree") {
            let OT = (pos) => {
              return this.data[pos].children[pointsDraggedItem[0]].children[
                pointsDraggedItem[1]
              ].children[pointsDraggedItem[2]];
            };
            let ORDER_OT = (pos) => {
              OT(pos).children = _.uniqBy(
                _.orderBy(OT(pos).children, ["id"], ["asc"]),
                "id"
              );
            };
            let addOt = () => {
              let key = draggedItem.id_ot;
              let idx = item.children.findIndex((f) => f.id_ot == key);
              let point = pointsDraggedItem;
              if (idx != -1) {
                item.children.splice(idx, 1);
              }
              this.data[7].children[point[0]].children[point[1]].children[
                point[2]
              ].children.push(draggedItem);
              ORDER_OT(7);
            };

            if (item.slug == "brigadas_disponibles_all") {
              addOt();
            }
            /****************************************************/
            if (item.slug == "assign_ot") {
              addOt();
            }
            /****************************************************/
            if (item.slug == "ot_managed_tree") {
              addOt();
            }
            /****************************************************/
            if (item.slug == "not_ubicated_second") {
              addOt();
            }
            /****************************************************/
            if (item.slug == "aviso_gestionado") {
              addOt();
            }
            /****************************************************/
            if (item.slug == "technical") {
              if (
                item.idSlug == "no_disponible" ||
                item.idSlug == "fuera_turno"
              ) {
                this.message(
                  "Información",
                  "Esta brigada no se encuentra disponible",
                  "error"
                );
                let key = draggedItem.id_ot;
                let idx = item.children.findIndex((f) => f.id_ot == key);
                let point = draggedItem.points.split("@").map((el) => {
                  return parseInt(el);
                });
                if (idx != -1) {
                  item.children.splice(idx, 1);
                }
                this.data[7].children[point[0]].children[point[1]].children[
                  point[2]
                ].children.push(draggedItem);
                ORDER_OT(7);
              } else {
                if (draggedItem.brigada === item.usuario) {
                  this.message(
                    "Información",
                    "La Orden de trabajo ya se encuentra asignada a esta brigada",
                    "info"
                  );
                  let idx = item.children.filter(
                    (f) => f.num_ot == draggedItem.num_ot
                  );
                  if (idx.length == 2) item.children.pop();
                } else if (
                  draggedItem.brigada != item.usuario &&
                  (draggedItem.id_estado == 1 ||
                    draggedItem.id_estado == 2 ||
                    draggedItem.id_estado == 8)
                ) {
                  const tech = item.obs_tech ? item.obs_tech : item.caption;
                  const operador = `${this.user.usuario} - ${this.user.nombre}`;
                  let data = {
                    id_estado: 2,
                    num_ot: draggedItem.num_ot,
                    brigada: item.usuario,
                    bitacora: true,
                    descripcion: `NOVEDAD: ORDEN DE TRABAJO ASIGNADA POR EL OPERADOR [${operador}]  A [${tech}]`,
                    observacion: `${moment().format(
                      "YYYY-MM-DD HH:mm:ss"
                    )} ${tech}`,
                  };
                  this.loading = true;
                  // --- falta organizar la observacion para el metodo de asignar SGI ---
                  axios.put(`ots/${draggedItem.id_ot}`, data).then(() => {
                    if (this.can("tree")) {
                      this.treeMenu(this.data);
                    }
                  });
                } else if (
                  draggedItem.brigada != item.usuario &&
                  (draggedItem.id_estado == 3 || draggedItem.id_estado == 4)
                ) {
                  this.message(
                    "Información",
                    "La Orden de trabajo ya se encuentra en gestión",
                    "info"
                  );
                } else if (
                  draggedItem.brigada != item.usuario &&
                  draggedItem.id_estado == 5
                ) {
                  this.message(
                    "Información",
                    "La Orden de trabajo ya se encuentra en ejecutada",
                    "info"
                  );
                  item.children.pop();
                }
                addOt();
              }
            }
            if (item.slug == "OTTECH") {
              addOt();
            }
            /****************************************************/
          }

          /***************************************************
           * OPCIONES DE VALIDACIÓN CUANDO SE ARRASTRA UNA OT
           ***************************************************/
          if (draggedItem.slug == "aviso_pendiente") {
            let OT = (pos) => {
              return this.data[pos].children[pointsDraggedItem[0]].children[
                pointsDraggedItem[1]
              ].children[pointsDraggedItem[2]];
            };
            let ORDER_OT = (pos) => {
              OT(pos).children = _.uniqBy(
                _.orderBy(OT(pos).children, ["id"], ["asc"]),
                "id"
              );
            };
            let addOt = () => {
              let key = draggedItem.id_ot;
              let idx = item.children.findIndex((f) => f.id_ot == key);
              let point = pointsDraggedItem;
              if (idx != -1) {
                item.children.splice(idx, 1);
              }
              let ix = 10;

              if (draggedItem.idSlug == "aviso_pendiente_1") {
                ix = 10;
              } else if (draggedItem.idSlug == "aviso_pendiente_2") {
                ix = 11;
              } else if (draggedItem.idSlug == "aviso_pendiente_3") {
                ix = 12;
              } else if (draggedItem.idSlug == "aviso_pendiente_4") {
                ix = 13;
              } else if (draggedItem.idSlug == "aviso_pendiente_5") {
                ix = 14;
              }
              this.data[ix].children[point[0]].children[point[1]].children[
                point[2]
              ].children.push(draggedItem);
              ORDER_OT(ix);
            };

            if (item.slug == "brigadas_disponibles_all") {
              addOt();
            }
            /****************************************************/
            if (item.slug == "assign_ot") {
              addOt();
            }
            /****************************************************/
            if (item.slug == "ot_managed_tree") {
              addOt();
            }
            /****************************************************/
            if (item.slug == "not_ubicated_second") {
              addOt();
            }
            /****************************************************/
            if (item.slug == "aviso_gestionado") {
              addOt();
            }
            /****************************************************/
            if (item.slug == "technical") {
              if (draggedItem.id_estado == 9) {
                addOt();
              }
              if (
                item.idSlug == "no_disponible" ||
                item.idSlug == "fuera_turno"
              ) {
                this.message(
                  "Información",
                  "Esta brigada no se encuentra disponible",
                  "error"
                );
                let key = draggedItem.id_ot;
                let idx = item.children.findIndex((f) => f.id_ot == key);
                let point = draggedItem.points.split("@").map((el) => {
                  return parseInt(el);
                });
                if (idx != -1) {
                  item.children.splice(idx, 1);
                }

                let ix = 10;

                if (draggedItem.idSlug == "aviso_pendiente_1") {
                  idx = 10;
                } else if (draggedItem.idSlug == "aviso_pendiente_2") {
                  idx = 11;
                } else if (draggedItem.idSlug == "aviso_pendiente_3") {
                  idx = 12;
                } else if (draggedItem.idSlug == "aviso_pendiente_4") {
                  idx = 13;
                } else if (draggedItem.idSlug == "aviso_pendiente_5") {
                  idx = 14;
                }

                this.data[ix].children[point[0]].children[point[1]].children[
                  point[2]
                ].children.push(draggedItem);
                ORDER_OT(ix);
              } else {
                if (draggedItem.brigada === item.usuario) {
                  this.message(
                    "Información",
                    "La Orden de trabajo ya se encuentra asignada a esta brigada",
                    "info"
                  );
                  let idx = item.children.filter(
                    (f) => f.num_ot == draggedItem.num_ot
                  );
                  if (idx.length == 2) item.children.pop();
                }
                // else if (draggedItem.brigada == null) {
                //   this.$refs.modalAssignOT.loadOT(draggedItem.id_ot);
                //   let tech = [];
                //   tech.push({ id: item.usuario, text: `${item.cliente.vehiculo.toUpperCase()} - ${item.nombre} - ${item.cliente.movil}` });
                //   this.$refs.modalAssignOT.setTech(tech);
                //   this.$refs.modalAssignOT.current = item;
                //   this.$refs.modalAssignOT.isAssignation = true;
                //   item.children.pop();
                // }
                else if (
                  draggedItem.brigada != item.usuario &&
                  draggedItem.id_estado == 13
                ) {
                  const tech = item.obs_tech ? item.obs_tech : item.caption;
                  const operador = `${this.user.usuario} - ${this.user.nombre}`;
                  let data = {
                    id_estado: 2,
                    num_ot: draggedItem.num_ot,
                    brigada: item.usuario,
                    bitacora: true,
                    descripcion: `NOVEDAD: ORDEN DE TRABAJO ASIGNADA POR EL OPERADOR [${operador}] A [${tech}]`,
                    observacion: `${moment().format(
                      "YYYY-MM-DD HH:mm:ss"
                    )} ${tech}`,
                  };
                  this.loading = true;
                  axios.put(`ots/${draggedItem.id_ot}`, data).then(() => {
                    if (this.can("tree")) {
                      this.treeMenu(this.data);
                    }
                  });
                } else if (
                  draggedItem.brigada != item.usuario &&
                  (draggedItem.id_estado == 3 || draggedItem.id_estado == 4)
                ) {
                  this.message(
                    "Información",
                    "La Orden de trabajo ya se encuentra en gestión",
                    "info"
                  );
                } else if (
                  draggedItem.brigada != item.usuario &&
                  draggedItem.id_estado == 5
                ) {
                  this.message(
                    "Información",
                    "La Orden de trabajo ya se encuentra en ejecutada",
                    "info"
                  );
                  item.children.pop();
                }
                addOt();
              }
            }

            if (item.slug == "OTTECH") {
              addOt();
            }
            /****************************************************/
          }

          /*****************************************************************
           * OPCIONES DE VALIDACIÓN CUANDO SE ARRASTRA UN AVISO DE UNA OT
           ****************************************************************/

          if (draggedItem.slug == "OTTECH") {
            let OT = (pos) => {
              return this.data[pos].children[pointsDraggedItem[0]].children[
                pointsDraggedItem[1]
              ].children[pointsDraggedItem[2]];
            };
            let OTDRAG = (pos) => {
              return this.data[pos].children[pointsDraggedItem[0]].children[
                pointsDraggedItem[1]
              ].children[pointsDraggedItem[2]];
            };
            let ORDER_AV_OT = (pos) => {
              OT(pos).children = _.uniqBy(
                _.orderBy(OT(pos).children, ["id"], ["asc"]),
                "id"
              );
            };

            if (item.slug == "ot_managed_tree") {
              OTDRAG(8).children.push(draggedItem);
              if (item.children) {
                item.children = [];
              }
              ORDER_AV_OT(8);
            }
            if (item.slug == "technical") {
              if (item.idSlug != "disponible") {
                OTDRAG(8).children.push(draggedItem);
                toastr.info("No se puede hacer este movimiento");
                const order = draggedItem.id_ot;
                let result = item.children.findIndex((f) => f.id_ot == order);
                if (result != -1) {
                  item.children.splice(result, 1);
                }
              } else {
                const tech = item.obs_tech ? item.obs_tech : item.caption;
                const operador = `${this.user.usuario} - ${this.user.nombre}`;
                let data = {
                  id_estado: 2,
                  num_ot: draggedItem.num_ot,
                  brigada: item.usuario,
                  bitacora: true,
                  descripcion: `NOVEDAD: EL OPERADOR [${operador}] HA REASIGNADO LA OT A LA BRIGADA ${tech} `,
                  observacion: `${moment().format(
                    "YYYY-MM-DD HH:mm:ss"
                  )} ${tech}`,
                };
                if (draggedItem.id_estado != 5 && draggedItem.id_estado != 9) {
                  axios.put(`ots/${draggedItem.id_ot}`, data).then((res) => {
                    if (res.data.message == "success") {
                      toastr.success(
                        `SE HA ASIGNADO LA OT A LA BRIGADA ${item.nombre}`
                      );
                      if (this.can("tree")) {
                        this.treeMenu(this.data);
                      }
                    }
                  });
                } else {
                  this.$swal.fire({
                    title: "Información",
                    text: "No se puede realizar esta operación",
                    icon: "error",
                  });
                  let idx = item.children.findIndex(
                    (f) => f.num_ot == draggedItem.num_ot
                  );
                  if (idx != -1) {
                    OTDRAG(8).children.push(draggedItem);
                    item.children.splice(idx, 1);
                  }
                }
              }
            }
          }
        });
      } catch (e) {
        console.log(e);
      }
    },
    removeNode() {
      if (this.editingItem.id !== undefined) {
        var index = this.editingNode.parentItem.indexOf(this.editingItem);
        this.editingNode.parentItem.splice(index, 1);
      }
    },
    itemClick(node) {
      let global = () => {
        node.model.selected = false;
        node.model.children.map((el) => {
          el.selected = false;
          el.children.map((sector) => {
            sector.selected = false;
            sector.children.map((sub) => {
              sub.selected = false;
              sub.children.map((c) => {
                c.selected = false;
                if (c.children) {
                  c.children.map((a) => {
                    a.selected = false;
                  });
                }
              });
            });
          });
        });
      };

      let slug = node.model.slug;
      // console.log(node.model);
      if (slug != undefined) {
        switch (slug) {
          case "GLOBAL_ANG":
            global();
            break;
          case "GLOBAL_AG":
            global();
            break;
          case "GLOBAL_OT":
            global();
            break;
          case "not_ubicated":
            if (!node.model.selected) {
              for (let i = 0; i < node.model.children.length; i++) {
                node.model.children[i].selected = false;
              }
            }
            break;
          case "not_ubicated_second":
            if (node.model.parent != undefined) {
              if (node.model.selected) {
                var war = this.data[0].children[node.model.parent].children;
                for (let i = 0; i < war.length; i++) {
                  if (war[i].id != node.model.id) {
                    war[i].selected = false;
                  }
                }
                this.data[0].children[node.model.parent].children = war;
              }
            }
            this.$emit("locatePlaceMarker", node.model ? node.model : null);
            break;

          case "assign_ot":
            // var uncheck = (pos) => {
            //     this.data[pos].children.map(sector => {
            //         sector.children.map(sub => {
            //             sub.children.map(circuito => {
            //                 circuito.children.map(ot => {
            //                     ot.selected = false
            //                     ot.children.map(el => {
            //                         el.selected = false
            //                     })
            //                 })
            //             })
            //         })
            //     })
            // }
            // uncheck(4)
            // uncheck(5)
            // uncheck(6)
            // uncheck(7)
            this.$emit(
              "locatePlaceMarker",
              node.model.selected
                ? node.model
                : {
                    alternative: "assign_ot",
                    value: node.model,
                  }
            );
            break;
          case "assign_ot_all":
            this.$emit(
              "locatePlaceMarker",
              node.model.selected ? node.model : null
            );
            break;

          case "circuit":
            this.$emit("loadMarkerCircuit", node.model ? node.model : null);
            break;
          case "brigadas_disponibles_all":
            // console.log(node.model);
            break;
          case "GBRI":
            node.model.selected = false;
            node.model.children.map((sector) => {
              sector.selected = false;
              sector.children.map((estados) => {
                estados.selected = false;
                estados.children.map((bri) => {
                  bri.selected = false;
                  bri.children.map((ot) => {
                    ot.selected = false;
                  });
                });
              });
            });
            break;
          case "technical_delegation":
            node.model.selected = false;
            node.model.children.map((del) => {
              del.selected = false;
              del.children.map((estados) => {
                estados.selected = false;
                estados.children.map((bri) => {
                  bri.selected = false;
                });
              });
            });
            break;
          case "technical":
            this.$emit("loadTech", node.model);
            break;
          case "aviso_gestionado":
            // var unCheck = (pos) => {
            //     this.data[pos].children.map(sector => {
            //         sector.children.map(sub => {
            //             sub.children.map(circuito => {
            //                 circuito.children.map(ot => {
            //                     ot.selected = false
            //                     ot.children.map(el => {
            //                         el.selected = false
            //                     })
            //                 })
            //             })
            //         })
            //     })
            // }
            // unCheck(1)
            // unCheck(2)
            // unCheck(3)
            this.$emit("loadWarningManaged", node.model ? node.model : null);
            break;
          case "subestacion_aviso_gestionado":
            this.$emit(
              "loadWarningManagedCircuit",
              node.model ? node.model : null
            );
            break;
          case "circuito_aviso_gestionado":
            this.$emit(
              "loadWarningManagedCircuit",
              node.model ? node.model : null
            );
            break;
          case "ot_managed_tree":
            this.$emit("loadInformationOT", node.model ? node.model : null);
            break;
          case "CIRCUITO_OT":
            this.$emit("loadInformationOT", node.model ? node.model : null);
            break;
          case "SUBESTACION_OT":
            this.$emit("loadInformationOT", node.model ? node.model : null);
            break;
          case "OTTECH":
            this.$emit("loadInformationOT", node.model ? node.model : null);
            break;
          case "subestacion_avisos":
            this.$emit("loadMarkerSubstation", node.model ? node.model : null);
            break;
          case "sector_avisos":
            this.$emit("loadMarkerSector", node.model ? node.model : null);
            break;
          case "trabajos_particular":
            this.$emit("loadInformationOT", node.model ? node.model : null);
            break;
          case "aviso_pendiente":
            this.$emit("loadInformationOT", node.model ? node.model : null);
            break;
          case "PODA":
            global();
            break;
          case "BTENSION":
            global();
            break;
          case "CCANASTA":
            global();
            break;
          case "TQUEMADO":
            global();
            break;
          case "TBOTANDOACEITE":
            global();
            break;
          default:
            break;
        }
      }
    },
    minimizeAsideMenu() {
      const body = $("body");
      if (body.hasClass("aside-fixed")) {
        body.addClass("aside-minimize");
        body.removeClass("aside-fixed");
        this.isMinimized = true;
      } else {
        body.removeClass("aside-minimize");
        body.addClass("aside-fixed");
        this.isMinimized = false;
      }
    },
  },
};
</script>
