require('./store/subscriber')
require('./store/jwtInterceptor')

import 'jquery/dist/jquery.min.js';

import "datatables.net-dt/js/dataTables.dataTables"
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router'
import router from './router'
import store from './store'
import axios from 'axios'
import * as GmapVue from 'gmap-vue'
import Select2 from 'v-select2-component';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';

import VModal from 'vue-js-modal'

import GmapCluster from "vue2-google-maps/dist/components/cluster";
import VueApexCharts from 'vue-apexcharts'
import moment from 'moment'
import GoTop from '@inotom/vue-go-top';
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'

// devExtreme global styles 
import 'devextreme/dist/css/dx.light.compact.css';

import '@fullcalendar/core/vdom' // solves problem with Vite

import toastr from 'toastr'

// Este componente implica barra de carga de las peticiones...
import VueProgressBar from 'vue-progressbar'
const configOptions = {
  color: '#286baa',
  failedColor: '#874b4b',
  thickness: '6px',
  transition: {
    speed: '0.2s',
    opacity: '0.6s',
    termination: 300
  },
  // autoRevert: true,
  location: 'top',
  inverse: false
}
 
Vue.use(VueProgressBar, configOptions)
moment.locale('es')
//***************************************/
Vue.use(VueApexCharts)
Vue.use(BootstrapVue)
Vue.use(VModal, { componentName: 'modal-draggable' });
Vue.use(IconsPlugin)
Vue.use(GoTop);
Vue.use(VueSweetalert2);
Vue.use(GmapVue, {
  load: {
    // PRODUCTION
    // key: 'AIzaSyAscnmqJITs5PODngyY8M15Tc3Kgy9XetY',
    // key: 'AIzaSyAscnmqJITs5PODngyY8M15Tc3Kgy9XetY',

    // key: 'AIzaSyDcwGyRxRbcNGWOFQVT87A1mkxEOfm8t0w',
    key: 'AIzaSyDW_p10NjPm2ru42ZNu_Oj-UgdV1BVcZuc',
    libraries: 'places,geometry,drawing',
    installComponents: true,
    options: {
      gestureHandling: 'greedy'
    }
  }
})
//** ---------------------------------------------------------- **/

Vue.component('GmapCluster', GmapCluster);
Vue.component('google-map', GmapVue.Map);
Vue.component('google-marker', GmapVue.Marker);
Vue.component('google-kml-layer', GmapVue.KmlLayer);
Vue.component('Select2', Select2);
Vue.component('apexchart', VueApexCharts)
/* ---------------------------------------------------------- **/
// axios configuration 
//----------------------------------------------------------------
const axiosConfig = {
  'Content-Type': 'application/json;charset=UTF-8',
  "Access-Control-Allow-Origin": "*"
};
import ENV from './environment'
axios.defaults.baseURL = process.env.NODE_ENV == "development" ? ENV.API_DEV : ENV.API
console.log(process.env.NODE_ENV.toUpperCase());
axios.defaults.headers.common = axiosConfig


/**
 * Se crea un axios.interceptors para monitorear las respuestas que se  bajo el uso de axios.
 */
axios.interceptors.response.use(
  (response) => {
    return response
  },
  (err) => {
    const {
      response: { status, data }
    } = err;
    if (!window.navigator.onLine) {
      toastr.info('Problemas de conexión a internet :(');
    }
    if(status){
      if (status === 401) {
        window.location.href = ''
      }
      if (process.env.NODE_ENV == "development") {
        console.log(status, data);
      }
    }
  }
);
//** ------------------------------------------------------------------------- **/
// Solamente se deja en true cuando el entorno sea desarrollo
Vue.config.devtools = (process.env.NODE_ENV == "development");
Vue.config.productionTip = false
Vue.use(VueRouter)
store.dispatch('auth/attempt', sessionStorage.getItem('token')).then(() => {
  new Vue({
    router,
    store,
    render: h => h(App),
  }).$mount('#app')
})

