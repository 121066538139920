<template>
  <b-modal
    size="lg"
    title="Consulta de Trabajo"
    v-model="show"
    ref="cbrigade"
    id="cbrigrade"
    no-close-on-backdrop
    scrollable
  >
    <b-row>
      <b-col>
        <b-tab title="Información Brigada" active>
          <table class="table table-sm table-striped table-hover">
            <thead>
              <tr>
                <td colspan="5" class="text-center bg-secondary">
                  INFORMACIÓN BÁSICA DE BRIGADA
                </td>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, idx) in user" :key="idx">
                <td
                  class="d-flex flex-column justify-content-center align-items-center"
                >
                  <span class="my-3">Nombre: {{ item.nombre }}</span>
                  <span class="my-3">Placa: {{ item.vehiculo }}</span>
                  <span class="my-3">Telefono: {{ item.telefono }} </span>
                  <span class="my-3">Usuario: {{ item.usuario }} </span>
                  <span class="my-3"
                    >Fecha: {{ item.fechaacceso | formatDate }}
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </b-tab>
      </b-col>
      <b-col>
        <b-form
          @submit.prevent="onSubmit"
          @reset.prevent="onReset"
          v-if="showForm"
        >
          <b-form-group
            id="input-group-1"
            label="Fecha inicio:"
            label-for="input-1"
          >
            <b-form-input
              id="input-1"
              v-model="form.date_init"
              type="datetime-local"
              required
            ></b-form-input>
          </b-form-group>

          <b-form-group
            id="input-group-2"
            label="Fecha Final:"
            label-for="input-2"
          >
            <b-form-input
              id="input-2"
              :min="form.date_init"
              v-model="form.date_end"
              placeholder="Enter name"
              type="datetime-local"
              required
            ></b-form-input>
          </b-form-group>
          <div
            class="d-flex flex-row justify-content-around align-items-center"
          >
            <b-button type="submit" variant="primary">
              <i class="flaticon-info"></i>
              {{loading ? 'Consultando...' : 'Consultar'}}
            </b-button>
            <b-button type="reset" variant="danger">
              <i class="flaticon2-information"></i>
              Limpiar
            </b-button>
          </div>
        </b-form>
      </b-col>
    </b-row>
    <template #modal-footer class="d-flex justify-content-center">
      <button @click="() => (show = false)" class="btn btn-sm btn-primary">
        <i class="flaticon-close"></i> Cancelar
      </button>
    </template>
  </b-modal>
</template>

<script>
import axios from "axios";
import moment from "moment";
import Swal from "sweetalert2";
import { mapActions } from "vuex";

export default {
  name: "ConsultaTrabajoBrigada",
  data() {
    return {
      show: false,
      loading: false,
      user: [],
      form: {
        date_init: "2022-08-04T18:08",
        date_end: "2022-08-05T18:08",
        uid: "",
      },
      showForm: true,
      brigadeData: {
        polylineBrigade: [],
        markerBrigade: [],
      },
    };
  },
  mounted() {
    this.$emit("brigadeData", this.brigadeData);
  },
  filters: {
    formatDate(value) {
      return moment(value).format("LL");
    },
  },
  methods: {
    ...mapActions({
      setBrigadeData: "technical/setBrigadeData",
    }),
    async onSubmit() {
      if (this.user) {
        this.form.uid = this.user[0].usuario;
      }

      this.loading = true;
      try {
        const response = await axios.post("/posiciones", this.form);
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "La consulta ha sido exitosa!",
          showConfirmButton: false,
          timer: 1500,
        });

        this.brigadeData.polylineBrigade = response.data.map((item) => {
          return {
            lat: parseFloat(item.latitud),
            lng: parseFloat(item.longitud),
          };
        });

        this.setLocationLatLng();
        this.setBrigadeData(this.brigadeData);
        this.clearBrigadeData();
        // this.onReset();
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Algo ha salido mal",
        });
        console.log(error);
      }
      this.loading = false;
    },
    onReset() {
      // Reset our form values
      this.form.date_init = "";
      this.form.date_end = "";
      this.form.uid = "";
      // Trick to reset/clear native browser form validation state
      this.showForm = false;
      this.$nextTick(() => {
        this.showForm = true;
      });
    },
    setLocationLatLng() {
      if (this.brigadeData.polylineBrigade.length > 0) {
        this.brigadeData.polylineBrigade[0];
        const [init] = this.brigadeData.polylineBrigade;
        const last =
          this.brigadeData.polylineBrigade[
            this.brigadeData.polylineBrigade.length - 1
          ];

        this.brigadeData.markerBrigade.push({
          position: {
            lat: parseFloat(init.lat),
            lng: parseFloat(init.lng),
          },
          technical: true,
          icon_inti: "",
          date_init: moment(this.date_init).format("LL"),
          label: "inicio",
          infoText: `<strong>\n 
                      <span style="color: #F64E60; font-weight: bold;">Ruta:</span> Inicio <br> \n 
                      <span style="color: #F64E60; font-weight: bold;">Brigada:</span> ${
                        this.user[0].nombre
                      }  <br> \n
                      <span style="color: #F64E60; font-weight: bold;">Vehiculo:</span> ${
                        this.user[0].vehiculo
                      } <br> \n 
                      <span style="color: #F64E60; font-weight: bold;">Fecha:</span> ${moment(
                        this.date_init
                      ).format("LL")} <br> \n 
                    </strong>`,
        });

        this.brigadeData.markerBrigade.push({
          position: {
            lat: parseFloat(last.lat),
            lng: parseFloat(last.lng),
          },
          technical: true,
          icon_end: "",
          date_end: moment(this.date_end).format("LL"),
          label: "fin",
          infoText: `<strong>\n 
                      <span style="color: #F64E60; font-weight: bold;">Ruta:</span> Fin <br> \n 
                      <span style="color: #F64E60; font-weight: bold;">Brigada:</span> ${
                        this.user[0].nombre
                      }  <br> \n
                      <span style="color: #F64E60; font-weight: bold;">Vehiculo:</span> ${
                        this.user[0].vehiculo
                      } <br> \n 
                      <span style="color: #F64E60; font-weight: bold;">Fecha:</span> ${moment(
                        this.date_init
                      ).format("LL")} <br> \n 
                    </strong>`,
        });
      } else {
        Swal.fire(
          "No hay recorido",
          "No hay recorido para esta brigada",
          "info"
        );
      }
    },
    clearBrigadeData() {
      this.brigadeData = {
        polylineBrigade: [],
        markerBrigade: [],
      };
    },
  },
};
</script>
