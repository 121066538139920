<template>
  <b-card class="w-full" v-if="aviso">
   <b>AVISO</b>
    <DxDataGrid
      id="no-scroll-2"
      :data-source="aviso"
      :show-borders="true"
      :show-column-lines="true"
      :show-row-lines="true"
      :row-alternation-enabled="true"
      :columnAutoWidth="true"
      :allow-column-resizing="true"
    >
      <DxSearchPanel 
        :visible="true" 
        :width="240" 
        placeholder="Buscar..." 
      />
      
      <DxPaging :page-size="30" />
      <DxHeaderFilter :visible="true" />
      <DxFilterRow :visible="true" />
      <DxPager
        :visible="true"
        infoText="Página {0} of {1} ({2} registros)"
        :allowed-page-sizes="pageSizes"
        display-mode="full"
        :show-page-size-selector="true"
        :show-info="true"
        :show-navigation-buttons="true"
      />
    </DxDataGrid>
  </b-card>
   <b-card v-else>
    <b-row>
      <b-col>
        <div class="text-center">
          <img src="assets/img/empty.png" alt="Empty" />
          <p class="text-muted">No hay información para mostrar</p>
        </div>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  DxDataGrid,
  DxSearchPanel,
  DxPaging,
  DxHeaderFilter,
  DxFilterRow,
  DxPager,
} from "devextreme-vue/data-grid";

export default {
  components: {
    DxDataGrid,
    DxSearchPanel,
    DxPaging,
    DxHeaderFilter,
    DxFilterRow,
    DxPager,
  },
  props: {
    aviso: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      pageSizes: [5, 10, 15, 30],
    };
  },
};
</script>
