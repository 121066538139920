import _ from 'lodash'

export default {
    namespaced: true,

    state: {
        warnings: [],
        warningsId: []
    },
    getters: {
        getIds(state) {
            return state.warningsId
        },
        getWarning(state) {
            return state.warnings
        }
    },
    mutations: {
        SET_WARNING(state, val) {
            if (val != null) {
                for (let i = 0; i < val.length; i++) {
                    state.warnings.push(val[i])
                }
                state.warnings = _.uniqBy(state.warnings, 'id')
            }
        },
        LOAD_WARNINGS_MANAGED(state, val) {
            state.warnings = val
        },
        SET_IDS(state, val) {
            if (val != null && val != undefined) {
                if (val.selected) {
                    if (state.warningsId.indexOf(val.id_aviso) == -1) {
                        state.warningsId.push(val.id_aviso)
                    }
                } else {
                    let position = state.warningsId.findIndex(war => war === val.id_aviso)
                    state.warningsId.splice(position, 1)
                    const result = state.warnings.filter(x => state.warningsId.includes(Number(x.id)))
                    state.warnings = result
                }
            }
        },
        SET_DEFAULT_WARNING(state, val) {
            state.warnings = val
            state.warningsId = []
        },
        LOAD_ALL_WARNINGS(state, value) {
            // console.log(value);
            if (value != null) {
                value.map(el => {
                    state.warningsId.push(el)
                });
                state.warningsId = _.uniq(state.warningsId)
            } else {
                state.warningsId = []
                state.warnings = []
            }
        },

        REMOVE_WARNINGS(state, value) {
            if (JSON.stringify(value) == JSON.stringify(state.warningsId)) {
                state.warningsId = []
                state.warnings = []
            } else {
                state.warningsId = _.difference(state.warningsId, value)
                const result = state.warnings.filter(x => state.warningsId.includes(Number(x.id)))
                state.warnings = result
            }
        },
        SET_STATIC_ID(state, val) {
            state.warningsId = _.uniq(val)
        }
    },
    actions: {
        setStaticId({ commit }, val) {
            commit('SET_STATIC_ID', val)
        },
        resetWarning({ commit }, value) {
            value = []
            commit('SET_DEFAULT_WARNING', value)
        }
        ,
        changeValue({ commit }, value) {
            commit('SET_WARNING', value)
        },
        loadCircuit({ commit }, val) {
            commit('LOAD_ALL_WARNINGS', val)
        },
        removeWarningCircuit({ commit }, val) {
            commit('REMOVE_WARNINGS', val)
        },
        changeIds({ commit }, value) {
            commit('SET_IDS', value)
        },
        changeWarningManaged({ commit }, value) {
            commit('LOAD_WARNINGS_MANAGED', value)
        }
    }
}