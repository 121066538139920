import axios from 'axios'
import store from './index'
axios.defaults.baseURL = 'http://165.227.218.137:83/api/'

const jwtInterceptor = axios.create({})

jwtInterceptor.interceptors.request.use((config) => {
    
    const auth = store.getters['auth/user'];
    if(auth == null ){
        return config;
    }
    config.headers.common['Authorization'] = `Bearer ${auth}`
    return config;
})

jwtInterceptor.interceptors.response.use(
    (response) => {
        return response;
    },
    async (error) => {
         if(error.response.status == 401) {
            const auth = store.getters['auth/user'];
            const payload = {
                token: auth.token,
                refresh_token: auth.refreshToken
            }
            var response = await axios.post( 'auth/refresh', payload)
            // console.log(payload, "<<token");
            await store.dispatch('attempt', response.data)
            error.config.headers['Authorization'] = `Bearer ${response.data.access_token}`
            return axios(error.config)
         } else {
             return Promise.reject(error);
         }
    }
)

export default jwtInterceptor;