<template>
  <div>
    <b-card
      class="w-full"
      v-if="computedWork.length || computedWorkTask.length"
    >
      <div v-if="computedWork.length">
        <b>Evento</b>
        <DxDataGrid
          id="no-scroll-2"
          :data-source="computedWork"
          :show-borders="true"
          :show-column-lines="true"
          :show-row-lines="true"
          :row-alternation-enabled="true"
          :columnAutoWidth="true"
          :allow-column-resizing="true"
        >
          <DxSearchPanel :visible="true" :width="240" placeholder="Buscar..." />

          <DxPaging :page-size="30" />
          <DxHeaderFilter :visible="true" />
          <DxFilterRow :visible="true" />
          <DxPager
            :visible="true"
            infoText="Página {0} of {1} ({2} registros)"
            :allowed-page-sizes="pageSizes"
            display-mode="full"
            :show-page-size-selector="true"
            :show-info="true"
            :show-navigation-buttons="true"
          />
        </DxDataGrid>
      </div>
      <br />
      <br />
      <div v-if="computedWorkTask.length">
        <b>Tarea</b>
        <DxDataGrid
          id="no-scroll-2"
          :data-source="computedWorkTask"
          :show-borders="true"
          :show-column-lines="true"
          :show-row-lines="true"
          :row-alternation-enabled="true"
          :columnAutoWidth="true"
          :allow-column-resizing="true"
        >
          <DxSearchPanel :visible="true" :width="240" placeholder="Buscar..." />

          <DxPaging :page-size="30" />
          <DxHeaderFilter :visible="true" />
          <DxFilterRow :visible="true" />
          <DxPager
            :visible="true"
            infoText="Página {0} of {1} ({2} registros)"
            :allowed-page-sizes="pageSizes"
            display-mode="full"
            :show-page-size-selector="true"
            :show-info="true"
            :show-navigation-buttons="true"
          />
        </DxDataGrid>
      </div>
    </b-card>
    <b-card v-else>
      <b-row>
        <b-col v-if="!isLoading">
          <div class="text-center">
            <img src="assets/img/empty.png" alt="Empty" />
            <p class="text-muted">No hay información para mostrar</p>
          </div>
        </b-col>
        <b-col v-else>
          <div class="text-center">
            <img src="assets/img/loader.gif" alt="Loading..." />
          </div>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import {
  DxDataGrid,
  DxSearchPanel,
  DxPaging,
  DxHeaderFilter,
  DxFilterRow,
  DxPager,
} from "devextreme-vue/data-grid";

export default {
  components: {
    DxDataGrid,
    DxSearchPanel,
    DxPaging,
    DxHeaderFilter,
    DxFilterRow,
    DxPager,
  },
  props: {
    work: {
      type: null || Object,
      default: () => null,
    },
    workTask: {
      type: null || Object,
      default: () => null,
    },
  },
  mounted() {},
  computed: {
    computedWork() {
      const data =
        this.work !== null && this.work !== undefined
          ? Object.entries(this.work).map(([key, value]) => ({
              titulo: key.replaceAll("_", " ").toUpperCase(),
              detalle: value,
            }))
          : {};
      return data;
    },
    computedWorkTask() {
      const data =
        this.workTask !== null && this.workTask !== undefined
          ? Object.entries(this.workTask).map(([key, value]) => ({
              titulo: key.replaceAll("_", " ").toUpperCase(),
              detalle: value,
            }))
          : {};
      return data;
    },
  },
  data() {
    return {
      pageSizes: [5, 10, 15, 30],
      isLoading: false,
    };
  },
};
</script>
