<template>
  <b-card>
    <div card-header>
      <b-row>
        <b-col md="12">
          <div class="d-flex flex-row-reverse">
            <router-link
              class="btn btn-sm btn-link"
              :to="{ name: 'managementOT' }"
            >
              <i class="fas fa-reply"></i> Volver
            </router-link>
          </div>
        </b-col>
      </b-row>
    </div>
    <b-row class="mb-5 mt-3">
      <b-col md="12">
        <showDetailsMap
          :zoom="14"
          :markers="markers"
          :center="center"
          :geojson="geojson"
          ref="mapa"
        />
        <div class="separator separator-dashed mt-3 mb-3"></div>

        <OrderDetails :idOt="parseInt(id)" :showOt="true"/> 
      </b-col>
    </b-row>

    <go-top :size="35" :bg-color="'#0094FF'" :right="25" :bottom="30" />
  </b-card>
</template>
<style>
.ot tbody tr td:nth-child(1) {
  padding-left: 2%;
  width: 30%;
}
</style>
<script>
import axios from "axios";
import showDetailsMap from "../../map/showPolygon.vue";
import moment from "moment";
import GoTop from "@inotom/vue-go-top";
import ENV from '@/environment'

import OrderDetails from '../../admin/map/modal/OrderDetails.vue';
// import TableOT from '../../helpers/tables/TableOT.vue';


export default {
  components: {
    showDetailsMap,
    GoTop,
    // TableOT,
    OrderDetails,
  },
  mounted() {
    this.loadOT();
  },
  data() {
    return {
      id: this.$route.params.id,
      // servidor: "http://138.197.75.131:9000",
      servidor: process.env.NODE_ENV == "development" ? ENV.SERVER_DEV : ENV.SERVER, 
      items: [],
      markers: [],
      ot: [],
      status: null,
      center: {
        lat: null,
        lng: null,
      },
      geojson: null,
      created_at: null,
      referent: {},
      binnacle: [],
      movil: {
        causa: [],
        reglas_oro: [],
        accion: [],
      },
      manoObra: [],
      materiales: [],
      galeria: [],
      info_aviso: null,
      certificate: [],
      reglas_oro: [],
      activation: [],
      pendiente: [],
    };
  },
  filters: {
    replace_(value) {
      return value.replace("_", " ");
    },
    upperCase(value) {
      return value.toUpperCase();
    },
    upperCaseWithReplace(value) {
      return value.replace(/_/g, " ").toUpperCase();
    },
    format(values) {
      return moment(values).format("LLL");
    },
    simpleFormat(value) {
      return moment(value).format("l") + " " + moment(value).format("LTS");
    },
  },
  methods: {
    hidePlacesMap() {
      this.$refs.map.$mapPromise.then((map) => {
        const trafficLayer = new window.google.maps.TrafficLayer();
        trafficLayer.setMap(map);
        map.setOptions({
          styles: [
            {
              featureType: "poi.business",
              stylers: [{ visibility: "off" }],
            },
            {
              featureType: "transit",
              elementType: "labels.icon",
              stylers: [{ visibility: "off" }],
            },
          ],
        });
      });
    },
    loadOT() {
      this.items = [];
      axios
        .get("/otavisos/" + this.id)
        .then((res) => {
          let data = res.data;

          this.pendiente = data.historico;
          this.certificate = data.certificate_info;
          this.binnacle = data.bitacora;
          this.movil = data.movil;

          this.materiales = data.materiales;
          this.galeria = data.anexos;
          this.items = data.ot.avisos;
          this.ot.push(data.ot);
          this.reglas_oro = data.reglas_oro;
          this.manoObra = data.accion;
          this.activation = data.activation;
          this.markers = [];
          this.items.forEach((m) => {
            if (m.id_aviso == m.pivot.id_aviso_ref) {
              this.referent = m;
              this.markers.push({
                id: m.id_aviso,
                position: {
                  lat: parseFloat(m.latitud),
                  lng: parseFloat(m.longitud),
                },
                address: m.dir_suscriptor,
                description: m.observacion,
                priority: m.ind_peligro,
                ordenNumber: m.num_aviso,
                dir_ref: m.dir_ref,
                infoText: ` <div style='width: 500px !important;'>
                                                    <strong>
                                                        <span style="color: #F64E60; font-weight: bold;">N° Orden :</span> ${
                                                          m.num_aviso
                                                        } <br>
                                                        <span style="color: #F64E60; font-weight: bold;">VIP :</span> ${
                                                          m.ind_cli_vip == 0
                                                            ? "NO"
                                                            : "SI"
                                                        } <br>
                                                        <span style="color: #F64E60; font-weight: bold;">Tipo trabajo :</span> ${
                                                          m.tipo_trabajo_id == 1
                                                            ? "AVISO"
                                                            : m.tipo_trabajo_id ==
                                                              2
                                                            ? "INCIDENCIA"
                                                            : "DESCARGO"
                                                        } <br>
                                                        <span style="color: #F64E60; font-weight: bold;">Sector :</span> ${
                                                          m.sector
                                                        } <br>
                                                        <span style="color: #F64E60; font-weight: bold;">Subestación :</span> ${
                                                          m.subestacion
                                                        } <br>
                                                        <span style="color: #F64E60; font-weight: bold;">Circuito :</span> ${
                                                          m.circuito_sm
                                                        } <br> 
                                                        <span style="color: #F64E60; font-weight: bold;">Municipio :</span> ${
                                                          m.municipio
                                                        } <br>
                                                        <span style="color: #F64E60; font-weight: bold;">Tipo de aviso :</span> ${
                                                          m.tipo_aviso
                                                        } <br> 
                                                        <span style="color: #F64E60; font-weight: bold;">Descripción:</span> ${
                                                          m.observacion
                                                        } ${
                  m.tipo_trabajo_id == 3 || m.tipo_trabajo_id == 2
                    ? m.obs_estado_incidencia
                    : ""
                } <br> 
                                                        <span style="color: #F64E60; font-weight: bold;">Referencia:</span> ${
                                                          m.dir_ref
                                                        } <br> 
                                                        <span style="color: #F64E60; font-weight: bold;">Dirección:</span> ${
                                                          m.dir_suscriptor
                                                        } 
                                                    </strong>
                                                <div>`,
              });
              this.center = {
                lat: parseFloat(m.latitud),
                lng: parseFloat(m.longitud),
              };
            }
          });
          this.status = this.ot[0].estado_o_t_s.descripcion;
          this.created_at = moment(this.ot[0].created_at).fromNow();
          this.geojson = this.ot[0].geojson;
          this.ot[0].status = this.ot[0].estado_o_t_s.descripcion;
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
};
</script>
