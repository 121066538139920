export default {
    namespaced: true,
    state: {
        informationWarning: [],
    },
    getters: {
        getInformationWarning(state) {
            return state.informationWarning
        },
        getSearching(state) {
            return state.bSearching
        }
    },
    mutations: {
        SET_WARNING(state, val) {
            state.informationWarning = val
        }
    },
    actions: {
        loadToCreationOT({ commit }, value) {
            commit('SET_WARNING', value)
        }
    }
}