const infoWinCertOT = (el) => {
    // definimos el html para el tipo de cierre
    const cert_causal = `<br><br><span style="color: #F64E60; font-weight: bold;">Tipo de cierre:</span> <strong>${el.cert_causales_descripcion ? el.cert_causales_descripcion : ''}</strong>`;
    // definimos html para la observación que digita la brigada
    const observacion_bol = `<br><span style="color: #F64E60; font-weight: bold;">Observación bol:</span> <strong>${el.informacion ? el.informacion : (el.observacion_bol ? el.observacion_bol : '')}</strong>`;
    // definimos html para observación del cor - si la orden fue certificada
    const cor = `<br><br><span style="color: #F64E60; font-weight: bold;">Observación COR:</span> <strong>${el.observacion_cor ? el.observacion_cor : ''}</strong>`;
    // definimos html para la observación de los avisos improcedentes
    const improcedente = `<br><br><span style="color: #F64E60; font-weight: bold;">Observación:</span> <strong>${el.observacion ? el.observacion.split(':').join(', ') : ''}</strong>`;
    return ` <div id="content" style="width: 600px !important;">
                <div id="bodyContent">
                    <strong>
                        <span style="color: #F64E60; font-weight: bold;">Num de OT :</span> ${el.num_ot} <br> 
                        <span style="color: #F64E60; font-weight: bold;">Observación de OT :</span> ${el.estado_o_t_s.descripcion} <br> <hr>
                        <span style="color: #F64E60; font-weight: bold;">N° Orden :</span> ${el.num_aviso} <br> 
                        <span style="color: #F64E60; font-weight: bold;">Tipo de aviso :</span> ${el.tipo_aviso} <br> 
                        <span style="color: #F64E60; font-weight: bold;">Sector :</span> ${el.sector} <br> 
                        <span style="color: #F64E60; font-weight: bold;">Subestación :</span> ${el.subestacion} <br> 
                        <span style="color: #F64E60; font-weight: bold;">Descripción:</span> ${el.observacion} <br> 
                        <span style="color: #F64E60; font-weight: bold;">Referencia:</span> ${el.dir_ref} <br> 
                        <span style="color: #F64E60; font-weight: bold;">Dirección:</span> ${el.dir_suscriptor}
                    </strong>
                    <br>
                    <div class="mt-1">
                        <span style="color: #F64E60; font-weight: bold;">
                            Estado: 
                        </span> 
                        <i class="${el.estado_o_t_s.icono}"></i> 
                        <strong>${el.estado_o_t_s.descripcion} </strong>
                        ${el.informacion || el.observacion_bol ? observacion_bol : ''}
                        ${el.observacion_cor ? cor : ''}
                        ${el.cert_causales_descripcion && (el.cert_causales_causa_codigo >= 41 && el.cert_causales_causa_codigo <= 47) ? cert_causal : ''}
                        ${el.estado == 4 ? improcedente : ''}
                    </div>
                </div>
            </div>`
}
export default infoWinCertOT