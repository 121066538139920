<template>
  <b-modal
    title="Activar aviso"
    v-model="show"
    size="xl"
    no-close-on-backdrop
    scrollable
  >
    <div>
      <b-tabs card small>
        <b-tab active title="Agregar observación">
          <b-row>
            <b-col md="12">
              <div class="form-group">
                <label>Observación</label>
                <textarea
                  class="form-control form-control-sm"
                  v-model="observation"
                  cols="5"
                  rows="5"
                ></textarea>
              </div>
              <div class="form-group mt-3">
                <button
                  class="btn btn-outline-danger btn-sm"
                  v-if="current.id_estado == 11"
                  @click="closeDef()"
                >
                  <i class="la la-power-off"></i>
                  {{ isLoading ? "Cargando..." : "Cerrar definitivamente" }}
                </button>

                <button
                  class="btn btn-outline-success btn-sm ml-2"
                  v-if="current.id_estado == 11"
                  @click="updateInformation()"
                >
                  <i class="la la-edit"></i>
                  {{ isLoading ? "Cargando..." : "Actualizar" }}
                </button>

                <button
                  class="btn btn-outline-primary btn-sm"
                  v-if="current.id_estado == 6"
                  @click="active()"
                >
                  <i class="la la-plus"></i>
                  {{ isLoading ? "Cargando..." : "Agregar" }}
                </button>
              </div>
            </b-col>
          </b-row>
        </b-tab>
        <b-tab title="Aviso" v-if="items && items.length == 1">
          <b-row>
            <b-col md="12">
              <DxDataGrid
                :data-source="ref"
                :show-borders="true"
                :show-column-lines="true"
                :show-row-lines="true"
                :row-alternation-enabled="true"
                :columnAutoWidth="true"
                :allow-column-resizing="true"
              >
                <DxPaging :page-size="30" />
                <DxHeaderFilter :visible="true" />
                <DxFilterRow :visible="true">
                  <DxOperationDescriptions
                    equal="Igual a"
                    notEqual="Diferente de"
                    startsWith="Empieza con"
                    endsWith="Termina con"
                    contains="Contiene"
                    between="Entre"
                    notContains="No contiene"
                    lessThanOrEqual="Menor o igual"
                    greaterThanOrEqual="Mayor o igual"
                    lessThan="Menor que"
                    greaterThan="Mayor que"
                  />
                </DxFilterRow>
                <DxPager
                  :visible="true"
                  infoText="Página {0} of {1} ({2} registros)"
                  :allowed-page-sizes="pageSizes"
                  display-mode="full"
                  :show-page-size-selector="true"
                  :show-info="true"
                  :show-navigation-buttons="true"
                />
              </DxDataGrid>
            </b-col>
          </b-row>
        </b-tab>
        <b-tab title="Bitacora" v-if="binnacle.length">
          <DxDataGrid
            :data-source="binnacle"
            :show-borders="true"
            :show-column-lines="true"
            :show-row-lines="true"
            :row-alternation-enabled="true"
            :columnAutoWidth="true"
            :allow-column-resizing="true"
          >
            <DxPaging :page-size="10" />
            <DxHeaderFilter :visible="true" />
            <DxFilterRow :visible="true">
              <DxOperationDescriptions
                equal="Igual a"
                notEqual="Diferente de"
                startsWith="Empieza con"
                endsWith="Termina con"
                contains="Contiene"
                between="Entre"
                notContains="No contiene"
                lessThanOrEqual="Menor o igual"
                greaterThanOrEqual="Mayor o igual"
                lessThan="Menor que"
                greaterThan="Mayor que"
              />
            </DxFilterRow>
            <DxPager
              :visible="true"
              infoText="Página {0} of {1} ({2} registros)"
              :allowed-page-sizes="pageSizes"
              display-mode="full"
              :show-page-size-selector="true"
              :show-info="true"
              :show-navigation-buttons="true"
            />
            <DxColumn
              data-field="fecha"
              caption="Fecha"
              data-type="date"
              format="dd/MM/yyyy, HH:mm:ss"
            />
            <DxColumn data-field="detalle" caption="Detalle" />
          </DxDataGrid>
        </b-tab>
        <b-tab lazy title="Información de precierre" v-if="movil">
          <!-- v-if="movil[0].accion" -->
          <table class="table table-sm table-striped" v-if="movil.accion">
            <thead>
              <tr>
                <td colspan="2" class="text-center bg-secondary">
                  MANO DE OBRA
                </td>
              </tr>
              <tr>
                <td>Descripción</td>
                <td>Fecha ejecuta</td>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, idx) in movil.accion" :key="idx">
                <td>{{ item.descripcion }}</td>
                <td>{{ item.fecha_ejecuta | format }}</td>
              </tr>
            </tbody>
          </table>
          <table
            class="table table-sm table-striped"
            v-if="movil.causa && movil.causa.length != 0"
          >
            <thead>
              <tr>
                <td colspan="2" class="text-center bg-secondary">CAUSAS</td>
              </tr>
              <tr>
                <td>TITULO</td>
                <td>DETALLE</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>¿Hubo daño?</td>
                <td>{{ movil.causa[0].principal_id == 1 ? "SI" : "NO" }}</td>
              </tr>
              <tr>
                <td>Fecha recepción</td>
                <td>{{ movil.causa[0].fecha_recepcion }}</td>
              </tr>
              <tr>
                <td>Fecha arribo</td>
                <td>{{ movil.causa[0].fecha_arribo_sitio }}</td>
              </tr>
              <tr>
                <td>Fecha causa</td>
                <td>{{ movil.causa[0].fecha_causa }}</td>
              </tr>
              <tr>
                <td>Fecha finaliza</td>
                <td>{{ movil.causa[0].fecha_finaliza }}</td>
              </tr>
              <tr>
                <td>Coordenadas</td>
                <td>
                  <span class="badge badge-secondary mr-2"
                    ><b>Lat:</b> {{ movil.causa[0].latitud }}
                  </span>
                  <span class="badge badge-secondary">
                    <b>Lng:</b> {{ movil.causa[0].longitud }}
                  </span>
                </td>
              </tr>

              <tr>
                <td>Observación BOL</td>
                <td>
                  {{ movil.causa[0].observacion }}
                </td>
              </tr>
            </tbody>
          </table>
          <table
            class="table table-sm table-striped table-pane"
            v-if="acciones && acciones.length != 0"
          >
            <thead>
              <tr>
                <td colspan="3" class="text-center bg-secondary">ACCIONES</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="text-center">Código</td>
                <td class="text-center">Descripción</td>
                <td class="text-center">Fecha ejecuta</td>
              </tr>
              <tr v-for="(item, idx) in acciones" :key="'_ACCION_' + idx">
                <td class="text-center">{{ idx + 1 }}</td>
                <td class="text-center">{{ item.descripcion }}</td>
                <td class="text-center">{{ item.fecha_ejecuta }}</td>
              </tr>
            </tbody>
          </table>
          <table
            class="table table-sm table-striped"
            v-if="movil.reglas_oro && movil.reglas_oro.length != 0"
          >
            <thead>
              <tr>
                <td colspan="3" class="text-center bg-secondary">
                  REGLAS DE ORO
                </td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="text-center">Código</td>
                <td class="text-center">Descripción</td>
                <td class="text-center">Fecha ejecuta</td>
              </tr>
              <tr>
                <td class="text-center">{{ movil.reglas_oro[0].codigo }}</td>
                <td class="text-center">
                  {{ movil.reglas_oro[0].descripcion }}
                </td>
                <td class="text-center">
                  {{ movil.reglas_oro[0].fechaEjecuta }}
                </td>
              </tr>
            </tbody>
          </table>
        </b-tab>
        <b-tab
          lazy
          title="Materiales"
          v-if="materiales && materiales.length != 0"
        >
          <DxDataGrid
            :data-source="materiales"
            :show-borders="true"
            :show-column-lines="true"
            :show-row-lines="true"
            :row-alternation-enabled="true"
            :columnAutoWidth="true"
            :allow-column-resizing="true"
          >
            <DxPaging :page-size="10" />
            <DxHeaderFilter :visible="true" />
            <DxFilterRow :visible="true">
              <DxOperationDescriptions
                equal="Igual a"
                notEqual="Diferente de"
                startsWith="Empieza con"
                endsWith="Termina con"
                contains="Contiene"
                between="Entre"
                notContains="No contiene"
                lessThanOrEqual="Menor o igual"
                greaterThanOrEqual="Mayor o igual"
                lessThan="Menor que"
                greaterThan="Mayor que"
              />
            </DxFilterRow>
            <DxPager
              :visible="true"
              infoText="Página {0} of {1} ({2} registros)"
              :allowed-page-sizes="pageSizes"
              display-mode="full"
              :show-page-size-selector="true"
              :show-info="true"
              :show-navigation-buttons="true"
            />
            <DxColumn data-field="codmaterial" caption="Código" />
            <DxColumn data-field="observaciones" caption="Observación" />
            <!-- <DxColumn
              data-field="fecha"
              caption="Fecha"
              data-type="date"
              format="dd/MM/yyyy, HH:mm:ss"
            /> -->
          </DxDataGrid>
        </b-tab>
        <b-tab title="Galeria" v-if="galeria && galeria.length != 0">
          <b-container fluid class="p-4 bg-secondary">
            <b-row>
              <b-col
                md="6"
                class="mb-3"
                v-for="(item, index) in galeria"
                :key="index"
              >
                <b-img
                  thumbnail
                  fluid
                  :src="servidor + item.path"
                  :alt="item.nombre"
                ></b-img>
              </b-col>
            </b-row>
          </b-container>
        </b-tab>
        <b-tab
          lazy
          title="Datos de certificación"
          v-if="certificate && certificate.length"
        >
          <table class="table table-sm table-striped table-hover">
            <thead>
              <tr>
                <th class="text-center" colspan="2">
                  INFORMACIÓN DE CERTIFICACIÓN
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Observación operador</td>
                <td>{{ certificate[0].observacion_cor }}</td>
              </tr>
              <tr>
                <td>Observación brigada</td>
                <td>{{ certificate[0].observacion_bol }}</td>
              </tr>
              <tr>
                <td>¿Usuario queda con servicio?</td>
                <td>
                  {{ certificate[0].usuario_con_servicio == 1 ? "SI" : "NO" }}
                </td>
              </tr>
              <tr>
                <td>Fecha de asignación</td>
                <td>{{ certificate[0].fecha_asigna }}</td>
              </tr>
              <tr>
                <td>Fecha recibe</td>
                <td>{{ certificate[0].fecha_recibe }}</td>
              </tr>
              <tr>
                <td>Fecha de arribo al sitio</td>
                <td>{{ certificate[0].fecha_arribo }}</td>
              </tr>
              <tr>
                <td>Fecha de resolución</td>
                <td>{{ certificate[0].fecha_resolucion }}</td>
              </tr>
              <tr>
                <td>Tipo incidencia</td>
                <td>{{ certificate[0].tipo_incidencia_name }}</td>
              </tr>
              <tr>
                <td>Tipo instalación</td>
                <td>{{ certificate[0].tipo_instalacion_name }}</td>
              </tr>
              <tr>
                <td>Tipo</td>
                <td>{{ certificate[0].tipo_name }}</td>
              </tr>
              <tr>
                <td>Causa</td>
                <td>{{ certificate[0].causa_cert_name }}</td>
              </tr>
              <tr class="bg-secondary font-weight-bold">
                <td>Usuario certifica</td>
                <td>{{ certificate[0].usuario | upperCase }}</td>
              </tr>
            </tbody>
          </table>
        </b-tab>
      </b-tabs>
    </div>

    <template #modal-footer>
      <div class="form-group">
        <button
          class="btn btn-sm btn-outline-secondary"
          @click="() => (show = false)"
        >
          Cancelar
        </button>
      </div>
    </template>
  </b-modal>
</template>
<script>
import axios from "axios";
import moment from "moment";
import toastr from "toastr";
import { mapGetters } from "vuex";
import {
  DxDataGrid,
  DxPaging,
  DxColumn,
  DxOperationDescriptions,
  DxFilterRow,
  DxHeaderFilter,
  DxPager,
} from "devextreme-vue/data-grid";
import ENV from '@/environment'
export default {
  name: "active-aviso-cert",
  components: {
    DxDataGrid,
    DxPaging,
    DxColumn,
    DxOperationDescriptions,
    DxFilterRow,
    DxHeaderFilter,
    DxPager,
  },
  data() {
    return {
      servidor: process.env.NODE_ENV == "development" ? ENV.SERVER_DEV : ENV.SERVER,
      // "http://138.197.75.131:9000",

      show: false,
      current: {},
      isLoading: false,
      observation: "",
      items: [],
      showBitacora: false,
      binnacle: [],
      materiales: [],
      acciones: [],
      galeria: [],
      certificate: [],
      movil: {},
      activation: {},
      ref: [],
      pageSizes: [5, 10, 15, 30, 50],
    };
  },
  filters: {
    upperCase(values) {
      return values.toUpperCase();
    },
    simpleFormat(values) {
      return moment(values).format("LTS");
    },
    replace_(values) {
      return values.replace("_", " ");
    },
    upperCaseWithReplace(value) {
      return value.replace(/_/g, " ").toUpperCase();
    },
    format(values) {
      return moment(values).format("LLL");
    },
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
  },
  methods: {
    loadItems() {
      let config = {
        criterio: 2,
        busqueda: this.current.num_ot,
      };
      this.items = [];
      axios.post("buscar-trabajos", config).then((res) => {
        let data = res.data;
        this.activation = res.data.activation;
        if (this.activation) {
          this.observation = this.activation.observacion;
        }
        this.certificate = data.certificate_info;
        this.binnacle = data.bitacora_ot;
        this.movil = data.movil;
        this.materiales = data.materiales;
        this.acciones = data.acciones;
        this.galeria = data.anexos.filter((f) => f.path != "");
        this.items = data.records[0].avisos;
        let main = [];
        let contain = [
          "id_aviso",
          "tipo_trabajo_id",
          "ind_peligro",
          "ind_cli_vip",
          "tipo_coordenada",
          "codigo_subestacion",
          "codigo_circuito",
          "estado",
          "obs_estado_incidencia",
          "created_at",
          "updated_at",
          "icon",
          "pivot",
          "icono",
          "codigo_zona",
        ];
        this.items.map((el) => {
          for (const key in el) {
            if (!contain.includes(key)) {
              main.push({
                informacion: key.replace("_", " ").toUpperCase(),
                detalle: el[key],
              });
            }
          }
        });
        this.ref = main;
      });
    },
    updateInformation() {
      if (this.observation == "") {
        toastr.info("La observación de cierre es obligatoria");
      } else {
        let config = {
          num_ot: this.current.num_ot,
          observacion: this.observation,
        };
        this.isLoading = true;
        axios
          .put(`close-certified/${this.activation.id}`, config)
          .then((res) => {
            if (res.data.message == "success") {
              toastr.success("Se ha actualizado la información del aviso");
              this.loadItems();
              this.$emit("loadMenu", {});
              this.isLoading = false;
            } else {
              toastr.error(res.data.message);
            }
          })
          .catch((e) => {
            console.log(e);
          });
      }
    },
    closeDef() {
      if (this.observation == "") {
        toastr.info("La observación de cierre es obligatoria");
      } else {
        let config = {
          id_estado: 12,
          num_ot: this.current.num_ot,
          descripcion: `NOVEDAD: SE HA CERRADO DE FORMA DEFINITIVA EL AVISO POR EL OPERADOR [${this.user.nombre}]`,
          observacion: this.observation,
        };
        this.isLoading = true;
        axios
          .put(`close-certified/${this.activation.id}`, config)
          .then((res) => {
            if (res.data.message == "success") {
              this.$emit("loadMenu", {});
              this.isLoading = false;
              this.show = false;
            } else {
              toastr.error(res.data.message);
            }
          })
          .catch((e) => {
            console.log(e);
          });
      }
    },
    active() {
      if (this.observation == "") {
        toastr.info("La observación es obligatoria");
      } else {
        let config = {
          id_estado: 11,
          num_ot: this.current.num_ot,
          descripcion: `NOVEDAD: SE HA ACTIVADO EL AVISO POR EL OPERADOR [${this.user.nombre}]`,
          observacion: this.observation,
        };
        this.isLoading = true;
        axios
          .post("active-certified", config)
          .then((res) => {
            if (res.data.message == "success") {
              this.$emit("loadMenu", {});
              this.isLoading = false;
              this.show = false;
            } else {
              toastr.error(res.data.message);
            }
          })
          .catch((e) => {
            console.log(e);
          });
      }
    },
  },
};
</script>
