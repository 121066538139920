const ENV = {
    /** cuando se compila, estas variables son del entorno de producción */
    // ICONS: 'https://sipremsol.co/public/img/icons_cld/',
    // API: 'https://api-cld.sipremsol.co/api/',
    // SERVER: 'https://sipremsol.co/',

    // ICONS_DEV: 'https://sipremsol.co/public/img/icons_cld/',
    // API_DEV: 'https://api-cld.sipremsol.co/api/',
    // SERVER_DEV: 'https://sipremsol.co/',
    


    // entorno de desarrollo
    ICONS_DEV: 'http://138.197.75.131:9009/img/icons_cld/icons/',
    API_DEV: 'http://165.227.218.137:93/api/',
    SERVER_DEV: 'http://138.197.75.131:9000/',

    ICONS: 'http://138.197.75.131:9009/img/icons_cld/icons/',
    API: 'http://165.227.218.137:93/api/',
    SERVER: 'http://138.197.75.131:9000/',
}
export default ENV