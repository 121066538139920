export default {
    namespaced: true,
    state: {
        technicians: [],
        techIds: [],
        techCertified: [],
        techMarkers: [],
        brigadeData: {
            polylineBrigade: [],
            markerBrigade: []
        },
        active: true,
        isGps: false
    },
    getters: {
        getTecnical(state) {
            return state.technicians
        },
        getTechId(state) {
            return state.techIds
        },
        getTechMarker(state) {
            return state.techMarkers
        },
        getTechCertified(state) {
            return state.techCertified
        },
        getBrigadeData(state) {
            return state.brigadeData
        },
        getActive(state) {
            return state.active
        },
        getIsGps(state) {
            return state.isGps
        }
    },
    mutations: {
        SET_TECHNICIANS(state, val) {
            state.technicians = val
        },
        SET_TECH_IDS(state, val) {
            if (val != null && val != undefined) {
                if (val.selected) {
                    if (state.techIds.indexOf(val.id) == -1) {
                        state.techIds.push(val.id)
                    }
                } else {
                    let position = state.techIds.findIndex(war => war === val.id)
                    state.techIds.splice(position, 1)
                    // const result = state.techMarkers.filter(x => state.techIds.includes(Number(x.id)))
                    // state.techMarkers = result
                }
            }
        },
        REMOVE_TECH(state, item) {
            let idx = state.technicians.findIndex(f => f.usuario == item)
            if (idx != -1) {
                state.technicians.splice(idx, 1);
            }
        },
        SET_CERTIFIED_TECH(state, item) {
            let idx = state.techCertified.findIndex(f => f == item)
            if (idx != -1) {
                state.techCertified.splice(idx, 1)
            } else {
                state.techCertified.push(item)
            }
        },
        SET_BRIGADE_DATA(state, item) {
            state.brigadeData = {
                polylineBrigade: item.polylineBrigade,
                markerBrigade: item.markerBrigade
            }
        },
        CLEAR_BRIGADE_DATA(state) {
            state.brigadeData = {
                polylineBrigade: [],
                markerBrigade: []
            }
        },
        SWITCH_ACTIVE(state, val) {
            state.active = val;
        },
        SWITCH_GPS(state, val) {
            state.isGps = val;
        }
    },
    actions: {
        setIsGps({ commit }, value) {
            commit('SWITCH_GPS', value)
        },
        changeValueTech({ commit }, value) {
            commit('SET_TECHNICIANS', value)
        },
        changeTechIds({ commit }, value) {
            commit('SET_TECH_IDS', value)
        },
        setTechCertified({ commit }, value) {
            commit('SET_CERTIFIED_TECH', value)
        },
        setBrigadeData({ commit }, state) {
            commit('SET_BRIGADE_DATA', state)
        },
        clearBrigadeData({ commit }) {
            commit('CLEAR_BRIGADE_DATA')
        },
        setActive({ commit }, state) {
            commit('SWITCH_ACTIVE', state)
        }
    }
}