export default {
    namespaced: true,
    state: {
        tab: 0
    },
    getters: {
        getTab(state) {
            return state.tab
        },
    },
    mutations: {
        SET_TAB(state, value) {
            state.tab = value
        },
    },
    actions: {
        setTab({ commit }, value) {
            commit('SET_TAB', value)
        },

    }
}