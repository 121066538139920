<template>
  <transition name="slide-fade">
    <b-col class="w-full">
      <FullCalendar :options="calendarOptions" id="calendario-x" />
    </b-col>
  </transition>
</template>

<script>
import FullCalendar from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import timeGridPlugin from "@fullcalendar/timegrid";
import esLocale from "@fullcalendar/core/locales/es";

export default {
  name: "Calendar",
  props: ["user"],
  components: {
    FullCalendar,
  },
  data() {
    return {
      calendarOptions: {
        plugins: [dayGridPlugin, interactionPlugin, timeGridPlugin],
        initialView: "dayGridMonth",
        timeZone: "local",
        locale: esLocale,
        height: 430,
        // contentHeight: "auto",
        showNonCurrentDates: false,
      },
    };
  },
};
</script>
<style>
#calendario-x > .fc-header-toolbar > .fc-toolbar-chunk > h2 {
  font-size: 1.2rem !important;
  text-transform: capitalize !important;
}
</style>
