<template>
  <div>
    <div>
      <div
        class="alert"
        :style="`background-color: ${color.background}; color: #FFF;`"
      >
        {{ oMovil && oMovil.pregunta ? oMovil.pregunta.pregunta_desc : "" }}
      </div>
      <div>
        <b>TIPO DE CIERRE:</b>
        {{ oMovil && oMovil.cierre ? oMovil.cierre.descripcion : "N/A" }}
      </div>
    </div>
    <hr />
    <label>Observación BOL</label>
    <textarea
      rows="3"
      :value="oMovil && oMovil.observacion ? oMovil.observacion : ''"
      class="mb-3 form-control form-control-sm"
      readonly
    ></textarea>

    <CAcciones :aData="aAcciones" />
    <PrincipalCausa :aData="aCausas" />
  </div>
</template>
<script>
import PrincipalCausa from "@/components/helpers/modals/certificationTabs/utils/PrincipalCausa.vue";
import CAcciones from "@/components/helpers/modals/certificationTabs/CAcciones.vue";
import _ from "lodash";
export default {
  props: {
    aReglasOro: {
      type: Array,
      default: () => [],
    },
    oMovil: {
      type: Object,
    },
    oMovil2: {
      type: Object,
    },
  },
  components: {
    PrincipalCausa,
    CAcciones,
  },
  data() {
    return {
      aAcciones: [],
      aCausas: [],
    };
  },
  mounted() {
    this.onCargarAcciones();
    this.onCargarCausas();
  },
  methods: {
    // Se emplea para cargar a la variable aAcciones las acciones realizadas si llegan en la prop
    onCargarAcciones() {
      if (this.oMovil && this.oMovil.accion) {
        this.aAcciones = this.oMovil.accion;
      }
    },
    // Se emplea para cargar a la variable aCausas las reglas de oro si llegan en la prop
    onCargarCausas() {
      if (this.oMovil && this.oMovil.causa) {
        const [oPrincipal] = this.oMovil.causa;
        let references = [];
        const excludedKeys = [
          "principal_id",
          "causa_id",
          "tipo_os",
          "resultado",
          "contrata",
          "tipo_cierre",
          "obj",
          "trafo_parcial",
          "created_at",
          "updated_at",
          "deleted_at",
        ];
        if (oPrincipal) {
          Object.keys(oPrincipal).forEach((key) => {
            const value = oPrincipal[key];
            if (!excludedKeys.includes(key) && !_.isObject(value)) {
              references.push({
                titulo: key ? key.replace("_", " ").toUpperCase() : "",
                detalle: value,
              });
            }
            if (key === "pregunta_id") {
              references.push({
                titulo: "¿HUBO DAÑO?",
                detalle: value === 1 ? "NO" : "SI",
              });
            }
            if (key === "trafo_parcial") {
              references.push({
                titulo: "HAY " + key.replace("_", " ").toUpperCase(),
                detalle: value === 1 || value === null ? "NO" : "SI",
              });
            }
          });
          this.aCausas = references;
          // se puede realizar un emit a la vista principal con la información de la movilidad ya procesada 
        }
      }
    },
  },
  computed: {
    color() {
      if (this.oMovil) {
        const colores = {
          1: { background: "#AD631D", color: "#FFF" },
          2: { background: "#185967", color: "#FFF" },
          3: { background: "#156C5A", color: "#FFF" },
          4: { background: "#9V7910", color: "#FFF" },
          5: { background: "#5B3071", color: "#FFF" },
          6: { background: "#189E85", color: "#FFF" },
        };
        const pregunta_id = this.oMovil.pregunta_id;
        const color = colores[pregunta_id] || {
          background: "#FFF",
          color: "#333",
        };
        return color;
      } else {
        return { background: "#FFF", color: "#333" };
      }
    },
  },
};
</script>