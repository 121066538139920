export default {
    namespaced: true,
    state: {
        technicians: [],
        techIds: [],
        techCertified: [],
        techMarkers: [],
        currentTech: -1,
    },
    getters: {
        getTecnical(state) {
            return state.technicians
        },
        getTechId(state) {
            return state.techIds
        },
        getTechMarker(state) {
            return state.techMarkers
        },
        getTechCertified(state) {
            return state.techCertified
        },
        getCurrentTech(state) {
            return state.currentTech
        }
    },
    mutations: {
        SET_TECHNICIANS(state, val) {
            state.technicians = val
        },
        SET_TECH_IDS(state, val) {
            if (val != null && val != undefined) {
                if (val.selected) {
                    if (state.techIds.indexOf(val.id) == -1) {
                        state.techIds.push(val.id)
                    }
                } else {
                    let position = state.techIds.findIndex(war => war === val.id)
                    state.techIds.splice(position, 1)
                }
            }
        },
        REMOVE_TECH(state, item) {
            let idx = state.technicians.findIndex(f => f.usuario == item)
            if (idx != -1) {
                state.technicians.splice(idx, 1);
            }
        },
        SET_CERTIFIED_TECH(state, item) {
            let idx = state.techCertified.findIndex(f => f == item)
            if (idx != -1) {
                state.techCertified.splice(idx, 1)
            } else {
                state.techCertified.push(item)
            }
        },
        SET_CURRENT(state, val) {
            state.currentTech = val;
        }
    },
    actions: {
        changeValueTech({ commit }, value) {
            commit('SET_TECHNICIANS', value)
        },
        changeTechIds({ commit }, value) {
            commit('SET_TECH_IDS', value)
        },
        setTechCertified({ commit }, value) {
            commit('SET_CERTIFIED_TECH', value)
        },
        setCurrentTech({ commit }, value) {
            commit('SET_CURRENT', value);
        }
    }
}