import _ from 'lodash'
export default {
    namespaced: true,
    state: {
        informationOT: [],
        idx: [],
        deleteImproper: {},
        idCert: [],
        showCertificated: false,
        searchCertified: {},
        bSearching: false,
    },
    getters: {
        getInformationOT(state) {
            return state.informationOT
        },
        getIdx(state) {
            return state.idx
        },
        getIdCert(state) {
            return state.idCert
        },
        getOTCertificated(state) {
            return state.showCertificated
        },
        getSearchCert(state) {
            return state.searchCertified
        },
        getDeleteImproper(state) {
            return state.deleteImproper
        },
        getBsearching(state) {
            return state.bSearching;
        }
    },
    mutations: {
        SET_DELETED_IMPROPER(state, val) {
            state.deleteImproper = val
        },
        INSERT_OR_UPDATE(state, val) {
            let index = state.idx.findIndex(i => i == val.id_ot)
            if (index == -1) {
                state.idx.push(val.id_ot)
            } else {
                state.idx.splice(index, 1)
            }
            state.idx = _.uniq(state.idx)
        },
        SET_WARNING_OT(state, val) {
            if (val != null) {
                for (let i = 0; i < val.length; i++) {
                    state.informationOT.push(val[i])
                }
            }
            state.informationOT = _.uniqBy(state.informationOT, 'id')
        },

        SET_DEFAULT_OT_INFORMATION(state, val) {
            state.informationOT = val
        },
        SET_SEARCH_CERTIFIED(state, val) {
            state.searchCertified = val
        },
        SET_IDX_ALL(state, val) {
            state.idx = val
        },
        SET_IDX(state, val) {
            if (val != null && val != undefined) {
                if (val.selected) {
                    if (state.idx.indexOf(val.id_ot) == -1) {
                        state.idx.push(val.id_ot)
                    }
                } else {
                    let position = state.idx.findIndex(war => war === val.id_ot)
                    state.idx.splice(position, 1)
                    const result = state.informationOT.filter(x => state.idx.includes(Number(x.id_ot)))
                    state.informationOT = result
                }
            }
        },
        SET_CERT(state, val) {
            state.idCert.push(val)
            state.idCert = _.uniq(state.idCert)
        },
        SET_SHOW_CERTIFICATED(state, val) {
            state.showCertificated = val
        },
        REMOVE_WARNINGS(state, value) {
            if (JSON.stringify(value) == JSON.stringify(state.warningsId)) {
                state.idx = []
                state.informationOT = []
            } else {
                state.idx = _.difference(state.idx, value)
                const result = state.informationOT.filter(x => state.idx.includes(Number(x.id)))
                state.informationOT = result
            }
        },
        SET_B_SEARCHING(state, val) {
            state.bSearching = val;
        }
    },
    actions: {
        resetInformationOT({ commit }, value) {
            commit('SET_DEFAULT_OT_INFORMATION', value)
        },
        loadWarningFromOT({ commit }, value) {
            commit('SET_WARNING_OT', value)
        },
        setIdOT({ commit }, value) {
            commit('SET_IDX', value)
        },
        setIdxAll({ commit }, value) {
            commit('SET_IDX_ALL', value)
        },
        setIdCert({ commit }, value) {
            commit('SET_CERT', value)
        },
        showCertificatedAction({ commit }, value) {
            commit('SET_SHOW_CERTIFICATED', value)
        },
        setFormSearch({ commit }, value) {
            commit('SET_SEARCH_CERTIFIED', value)
        },
        setSearching({ commit }, value) {
            commit('SET_B_SEARCHING', value);
        },
        insertOrUpdate({ commit }, val) {
            commit('INSERT_OR_UPDATE', val)
        },
        setDeleted({commit}, value) {
            commit('SET_DELETED_IMPROPER', value)
        },
    }
}