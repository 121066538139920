import store from './index'
import axios from 'axios'

import { mapActions } from 'vuex'
store.subscribe((mutation) => {
    switch (mutation.type) {
        // case "auth/SET_USER":
        //     console.log(mutation.payload);
        //     break;
        case 'auth/SET_TOKEN':
            if (mutation.payload) {
                axios.defaults.headers.common['Authorization'] = `Bearer ${mutation.payload}`
                sessionStorage.setItem('token', mutation.payload)
            } else {
                axios.defaults.headers.common['Authorization'] = null
                sessionStorage.removeItem('token')
                mapActions(['signOut'])
            }
            break;
        // case 'auth/SET_DEPARTMENT':
        //     if(mutation.payload) {
        //         sessionStorage.setItem('cor', mutation.payload)
        //         sessionStorage.setItem('sectores', JSON.stringify([]))
        //     } 
        //     break;
    }
})

