<template>
  <b-modal
    ref="legends-modal"
    v-model="showLegends"
    size="xl"
    title="Referencia de iconos"
    scrollable
  >
    <div class="row">
      <div class="col-md-4">
        <table class="table table-sm table-striped table-hover">
          <thead>
            <tr>
              <td colspan="2">COLOR DE AVISOS</td>
            </tr>
            <tr>
              <td class="text-center">Icono</td>
              <td>Descripción</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="text-center">
                <span class="block-color blue-warning"> </span>
              </td>
              <td>Avisos con más de 8 horas</td>
            </tr>
            <tr>
              <td class="text-center">
                <span class="block-color blue-green"> </span>
              </td>
              <td>Avisos rechazados por brigadas</td>
            </tr>
          </tbody>
        </table>
        <DxDataGrid
          :data-source="iconsFixed"
          :show-borders="true"
          :show-column-lines="true"
          :show-row-lines="true"
          :row-alternation-enabled="true"
          :columnAutoWidth="true"
        >
          <DxColumn
            data-field="actions"
            alignment="center"
            caption="Icono"
            type="buttons"
            cell-template="cellTemplate"
          />
          <template #cellTemplate="{ data: cellData }">
            <div>
              <i :class="cellData.data.icon"></i>
            </div>
          </template>
          <DxColumn
            data-field="name"
            alignment="center"
            caption="Descripción"
          />
        </DxDataGrid>
      </div>
      <div class="col-md-8">
        <b-row>
          <b-col>
            <DxDataGrid
              :data-source="legendsMap"
              :show-borders="true"
              :show-column-lines="true"
              :show-row-lines="true"
              :row-alternation-enabled="true"
              :columnAutoWidth="true"
            >
              <DxPaging :page-size="10" />
              <DxSearchPanel
                :visible="true"
                :width="240"
                placeholder="Buscar..."
              />
              <DxHeaderFilter :visible="true" />
              <DxFilterRow :visible="true" />
              <DxPager
                :visible="true"
                infoText="Página {0} of {1} ({2} registros)"
                :allowed-page-sizes="pageSizes"
                display-mode="full"
                :show-page-size-selector="true"
                :show-info="true"
                :show-navigation-buttons="true"
              />
              <DxColumn data-field="descripcion" caption="Descripción" />
              <DxColumn
                data-field="actions"
                alignment="center"
                caption="Icono"
                type="buttons"
                cell-template="cellTemplate"
              />
              <template #cellTemplate="{ data: cellData }">
                <div>
                  <img
                    :src="`${environment}${cellData.data.icono}.png`"
                    :alt="cellData.data.descripcion"
                    width="30"
                    height="30"
                  />
                </div>
              </template>
            </DxDataGrid>
          </b-col>
        </b-row>
      </div>
    </div>

    <template #modal-footer>
      <button
        @click="() => (showLegends = false)"
        class="btn btn-sm btn-secondary"
      >
        <i class="flaticon-close"></i> Cerrar
      </button>
    </template>
  </b-modal>
</template>
<style>
.block-color {
  display: block;
  width: 20px !important;
  height: 20px !important;
  margin: auto;
}
.blue-warning {
  background-color: #264ef8 !important;
}
.blue-green {
  background-color: #1ab450 !important;
}
</style>
<script>
import axios from "axios";
import ENV from "@/environment";
import {
  DxDataGrid,
  DxPaging,
  DxColumn,
  DxSearchPanel,
  DxFilterRow,
  DxHeaderFilter,
  DxPager,
} from "devextreme-vue/data-grid";
export default {
  name: "modal-legends",
  components: {
    DxDataGrid,
    DxPaging,
    DxColumn,
    DxSearchPanel,
    DxFilterRow,
    DxHeaderFilter,
    DxPager,
  },
  data() {
    return {
      ENV: ENV,
      legendsTree: [],
      legendsMap: [],
      showLegends: false,
      pageSizes: [5, 10, 15, 30, 50],
      iconsFixed: [
        {
          name: "OT PENDIENTE",
          icon: "fa fa-fire text-danger",
        },
        {
          name: "OT ASIGNADAS",
          icon: "fa fa-handshake text-primary",
        },
        {
          name: "OT DESARGADA EN EL MOVIL",
          icon: "fa fa-cloud-download-alt",
        },
        {
          name: "OT EN EJECUCIÓN",
          icon: "fa fa-forward text-info",
        },
        {
          name: "OT EJECUTADA",
          icon: "fa fa-check-circle text-success",
        },
        {
          name: "OT CANCELADA",
          icon: "la la-window-close-o text-danger",
        },
        {
          name: "OT CERTIFICADA",
          icon: "la la-certificate text-warning",
        },
        {
          name: "OT CERRADA POR EL OPERADOR",
          icon: "la la-folder-o text-success",
        },
      ],
    };
  },
  computed: {
    environment() {
      return process.env.NODE_ENV == "development" ? ENV.ICONS_DEV : ENV.ICONS;
    },
  },
  methods: {
    loadIcons() {
      axios.get("avisos-iconos").then((res) => {
        this.legendsMap = res.data;
        this.totalRows = res.data.length;
      });
    },
  },
};
</script>
