import axios from 'axios'

export default {
    namespaced: true,
    state: {
        token: null,
        user: null,
        cor: [],
        sectores: []
    },
    getters: {
        getToken(state) {
            return state.token
        },
        getCor(state) {
            return state.cor
        },
        getSectores(state) {
            return state.sectores
        },
        authenticated(state) {
            return state.token && state.user;
        },
        user(state) {
            return state.user
        }
    },
    mutations: {
        SET_TOKEN(state, token) {
            state.token = token
        },
        SET_USER(state, data) {
            state.user = data
        },
        SET_DEPARTMENT(state, data) {
            state.cor = data
        },
        SET_SECTORS(state, data) {
            state.sectores = data
        }

    },
    actions: {

        async signIn({ dispatch }, credentials) {
            const response = await axios.post('/auth/login', credentials)
            return dispatch('attempt', response.data.access_token)
        },

        async attempt({ commit, state }, token) {
            if (token) {
                commit('SET_TOKEN', token)
            }
            if (!state.token) {
                return
            }
            try {
                let response = await axios.post('auth/user-profile')
                const [role] = response.data.roles
                if (role) {
                    response.data.roles = role
                    response.data.permissions = role.permissions
                }
                commit('SET_USER', response.data)
            } catch (e) {
                commit('SET_USER', null)
                commit('SET_TOKEN', null)
            }
        },
        async signOut({ commit }) {
            return axios.post('auth/logout')
                .then(() => {
                    commit('SET_USER', null)
                    commit('SET_TOKEN', null)
                })
        },
        setSectors({ commit }, data) {
            commit('SET_SECTORS', data)
        },
        zoneWork: ({ commit, state }, data) => {
            if (data) {
                commit('SET_DEPARTMENT', data)
                commit('SET_SECTORS', data)
            }
            if (!state.cor) {
                return
            }
        },
        refreshToken: ({ commit }) => {
            return new Promise((resolve, reject) => {
                axios
                    .post(`auth/refresh`)
                    .then(res => {
                        if (res.data.access_token) {
                            resolve(res);
                            commit('SET_TOKEN', res.data.access_token)
                            axios.defaults.headers.common['Authorization'] = `Bearer ${res.data.access_token}`
                            sessionStorage.setItem('token', res.data.access_token)
                            // console.log("∑Token", res.data.access_token);
                            // console.table(res.data);
                        }
                    })
                    .catch(e => {
                        reject(e);
                    });
            });
        }

    }
}