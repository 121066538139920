import _ from 'lodash'
export default {
    namespaced: true,
    state: {
        idx: [],
        currentIdx: -1,
        informationOT: []
    },
    getters: {
        getInformationOT(state) {
            return state.informationOT;
        },
        getIdx(state) {
            return state.idx;
        },
        getCurrentIdx(state) {
            return state.currentIdx;
        }
    },
    mutations: {
        SET_IDX(state, val) {
            if (val != null && val != undefined) {
                if (val.selected) {
                    if (state.idx.indexOf(val.id_ot) == -1) {
                        state.idx.push(val.id_ot)
                    }
                } else {
                    let position = state.idx.findIndex(war => war === val.id_ot)
                    state.idx.splice(position, 1)
                    const result = state.informationOT.filter(x => state.idx.includes(Number(x.id_ot)))
                    state.informationOT = result
                }
            }
        },
        SET_CURRENT_IDX(state, val) {
            state.currentIdx = val;
        },
        SET_WARNING_OT(state, val) {
            if (val != null) {
                for (let i = 0; i < val.length; i++) {
                    state.informationOT.push(val[i])
                }
            }
            state.informationOT = _.uniqBy(state.informationOT, 'id')
        },

    },
    actions: {
        setIdOT({ commit }, value) {
            commit('SET_IDX', value);
        },
        setCurrentIdx({ commit }, value) {
            commit('SET_CURRENT_IDX', value);
        },
        loadWarningFromOT({ commit }, value) {
            commit('SET_WARNING_OT', value)
        },
    }
}