<template>
  <b-card title="Geoanálisis">
    <b-row>
      <b-col md="12" class="mb-3">
        <b-row>
          <b-col md="3">
            <label>Zona</label>
            <b-form-input size="sm" placeholder="Zona" />
          </b-col>
          <b-col md="3">
            <label>COR</label>
            <b-form-input size="sm" placeholder="COR" />
          </b-col>
          <b-col md="3">
            <label>Sector</label>
            <b-form-input size="sm" placeholder="Sector" />
          </b-col>
          <b-col md="3" class="mt-7">
            <b-button variant="primary" size="sm">
              <i class="la la-search"></i> Filtrar
            </b-button>
          </b-col>
        </b-row>
      </b-col>
      <b-col md="12">
        <gmap-map
          :center="center"
          :zoom="zoom"
          style="width: 100%; height: 500px"
          ref="map"
        >
          <gmap-info-window
            :options="infoOptions"
            :position="infoWindowPos"
            :opened="infoWinOpen"
            @closeclick="closeInfoWindow()"
          >
          </gmap-info-window>
          <gmap-cluster
            :position="center"
            :clickable="true"
            :zoomOnClick="true"
            :animation="1"
          >
            <gmap-marker
              :key="i"
              v-for="(m, i) in markers"
              :position="m.position"
              :icon="m.icon"
              :clickable="true"
              :animation="2"
              @click="toggleInfoWindow(m, i)"
            >
            </gmap-marker>
          </gmap-cluster>

          <gmap-polygon
            v-if="paths.length > 0"
            :paths="paths"
            :editable="true"
            @paths_changed="updateEdited($event)"
            @rightclick="handleClickForDelete"
            :options="{
              fillColor: '#012060',
              fillOpacity: 0.3,
              strokeWeight: 2,
              strokeColor: '#01194a',
            }"
            ref="polygon"
          >
          </gmap-polygon>
        </gmap-map>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import axios from "axios";
import _ from "lodash";

// import toastr from 'toastr'
import GmapCluster from "vue2-google-maps/dist/components/cluster";

function closeLoop(path) {
  return path.concat(path.slice(0, 1));
}

export default {
  components: {
    "gmap-cluster": GmapCluster,
  },
  mounted() {
    this.loadMarkers();
  },
  data() {
    return {
      zoom: 12,
      center: {
        lat: 10.9784174,
        lng: -74.8144132,
      },
      infoWindowPos: null,
      infoWinOpen: false,
      currentMidx: null,

      infoOptions: {
        content: "",
        pixelOffset: {
          width: 0,
          height: -35,
        },
      },
      markers: [],
      items: [],
      paths: [],
      mvcPaths: null,
      errorMessage: null,
      polygonGeojson: "",
      markerSelected: [],
      pop: 0,
      referent: null,
    };
  },
  computed: {
    polygonPaths: function () {
      if (!this.mvcPaths) return null;

      let paths = [];
      for (let i = 0; i < this.mvcPaths.getLength(); i++) {
        let path = [];
        for (let j = 0; j < this.mvcPaths.getAt(i).getLength(); j++) {
          let point = this.mvcPaths.getAt(i).getAt(j);
          path.push({ lat: point.lat(), lng: point.lng() });
        }
        paths.push(path);
      }
      return paths;
    },
  },
  watch: {
    polygonPaths: _.throttle(function (paths) {
      if (paths) {
        this.paths = paths;
        this.markerSelected = this.geoMarkerSize();
        this.polygonGeojson = JSON.stringify(
          {
            type: "Polygon",
            coordinates: this.paths.map((path) =>
              closeLoop(path.map(({ lat, lng }) => [lng, lat]))
            ),
          },
          null,
          1
        );
      }
    }, 1000),
  },
  methods: {
    closeInfoWindow() {
      this.removePath();
      this.zoom = 12;
      this.infoWinOpen = false;
    },
    loadMarkers() {
      axios
        .get("/ot-with-reference")
        .then((res) => {
          this.items = res.data;
          let mak = [];
          let dep = sessionStorage.getItem("cor");
          this.items.forEach((el) => {
            let info = el.avisos2.filter((d) => d.departamento == dep);
            info.forEach((av) => {
              if (av.pivot.id_aviso_ref == av.id_aviso) {
                mak.push({
                  id: av.id_aviso,
                  num_ot: el.num_ot,
                  id_ot: el.id_ot,
                  position: {
                    lat: parseFloat(av.latitud),
                    lng: parseFloat(av.longitud),
                  },
                  address: av.dir_suscriptor,
                  description: av.observacion,
                  priority: av.ind_peligro,
                  ordenNumber: av.num_aviso,
                  dir_ref: av.dir_ref,
                  icono: el.icono,
                  icon: el.icono
                    ? `http://138.197.75.131:9009/img/icons_cld/icons/${el.icono}.png`
                    : "",
                  infoText: ` <strong>
                                                        <span style="color: #F64E60; font-weight: bold;">#:</span> ${av.id_aviso} <br> 
                                                        <span style="color: #F64E60; font-weight: bold;">N° Orden :</span> ${av.num_aviso} <br> 
                                                        <span style="color: #F64E60; font-weight: bold;">Descripción:</span> ${av.observacion} <br> 
                                                        <span style="color: #F64E60; font-weight: bold;">Referencia:</span> ${av.dir_ref} <br> 
                                                        <span style="color: #F64E60; font-weight: bold;">Dirección:</span> ${av.dir_suscriptor}</strong>`,
                });
              }
            });
          });

          this.markers = mak;
          this.reloadMarkerCenter(this.markers);
        })
        .catch((e) => {
          console.log(e);
        });
    },
    reloadMarkerCenter(info) {
      if (info.length != 0) {
        let ramdom = Math.floor(Math.random() * info.length);
        this.center = info[ramdom].position;
        if (!isNaN(this.center.lat) && !isNaN(this.center.lng)) {
          this.zoom = 12;
          return;
        } else {
          this.reloadMarkerCenter(info);
        }
      }
    },
    toggleInfoWindow: function (marker, idx) {
      try {
        let info = this.items.filter((f) => f.num_ot == marker.num_ot);
        if (info.length != 0) {
          this.referent = info[0].avisos2[0].pivot.id_aviso_ref;

          this.zoom = 17;
          let geojson = info[0].geojson;
          this.readGeojson(geojson);
          this.center = marker.position;
        }
        this.infoWindowPos = marker.position;
        this.infoOptions.content = marker.infoText;

        if (this.currentMidx == idx) {
          this.infoWinOpen = !this.infoWinOpen;
        } else {
          this.infoWinOpen = true;
          this.currentMidx = idx;
        }
      } catch (e) {
        console.log(e);
      }
    },
    readGeojson(geojson) {
      try {
        this.polygonGeojson = geojson;
        var v = JSON.parse(geojson);
        this.paths = v.coordinates.map((linearRing) =>
          linearRing
            .slice(0, linearRing.length - 1)
            .map(([lng, lat]) => ({ lat, lng }))
        );
      } catch (err) {
        this.paths = [];
      }
    },
    updateEdited: function (mvcPaths) {
      this.mvcPaths = mvcPaths;
    },
    removePath() {
      this.paths.splice(this.paths.length - 1, 1);
      this.markerSelected = [];
    },
    handleClickForDelete($event) {
      if ($event.vertex) {
        this.$refs.polygon.$polygonObject
          .getPaths()
          .getAt($event.path)
          .removeAt($event.vertex);
      }
    },
    removeLastMarker() {
      for (let i = 0; i < this.pop; i++) {
        this.markers.pop();
      }
      this.pop = 0;
    },
    pushMarker(items) {
      let mak = [];

      items.forEach((el) => {
        if (el.id_aviso !== el.pivot.id_aviso_ref) {
          mak.push({
            id: el.id_aviso,
            position: {
              lat: parseFloat(el.latitud),
              lng: parseFloat(el.longitud),
            },
            address: el.dir_suscriptor,
            description: el.observacion,
            priority: el.ind_peligro,
            ordenNumber: el.num_aviso,
            dir_ref: el.dir_ref,
            // icon: `http://138.197.75.131:9009/img/icons_cld/${el.TipoOrden == 'IN' ? 'incidence': 'call'}.png`,
            infoText: ` <strong>
                                            <span style="color: #F64E60; font-weight: bold;">#:</span> ${el.id_aviso} <br> 
                                            <span style="color: #F64E60; font-weight: bold;">N° Orden :</span> ${el.num_aviso} <br> 
                                            <span style="color: #F64E60; font-weight: bold;">Descripción:</span> ${el.observacion} <br> 
                                            <span style="color: #F64E60; font-weight: bold;">Referencia:</span> ${el.dir_ref} <br> 
                                            <span style="color: #F64E60; font-weight: bold;">Dirección:</span> ${el.dir_suscriptor}</strong>`,
          });
        }
      });

      return mak;
    },
  },
};
</script>
