<template>
  <b-modal
    ref="show-Filter-Bookmarks"
    v-model="showLegends"
    size="md"
    hide-footer
    hide-header
    id="prueba"
    scrollable
    hide-backdrop
    content-class="shadow"
    dialog-class="filtro"
    data-backdrop="static"
    data-keyboard="false"
    class="mr-2"
  >
    <div class="container_filters">
      <h5 align="center">Filtro de elementos - {{ cor }}</h5>
      <br />
      <div class="form-group mb-3">
        <div class="checkbox-inline" v-for="(el, idx) in options" :key="idx">
          <label
            :class="
              'checkbox ' +
              (el.disabled
                ? 'checkbox-disabled'
                : 'checkbox-outline checkbox-outline-2x checkbox-primary')
            "
          >
            <input
              v-model="element"
              type="checkbox"
              size="sm"
              :disabled="el.disabled"
              :value="el.text"
            />
            <span></span> {{ el.text | emptyString }}
          </label>
        </div>
      </div>
      <div class="separator separator-dashed mb-2"></div>
      <div
        class="form-group"
        style="max-height: 100% !important"
        v-if="substationAll.length !== 0"
      >
        <!-- <div class="checkbox-inline">
                    <label class="checkbox checkbox-outline checkbox-outline-2x checkbox-success" >
                        <input 
                            ref="checkAll"
                            @click="checkAll()"
                            type="checkbox" />
                        <span></span> Seleccionar todo
                    </label>
                </div> -->

        <div
          class="checkbox-inline d-flex justify-content-between"
          v-for="(sub, idx) in substationAll"
          :key="idx"
        >
          <label
            class="checkbox checkbox-outline checkbox-outline-2x checkbox-primary"
          >
            <input
              v-model="substation"
              type="checkbox"
              name="Checkboxes162"
              :value="sub.value"
            />
            <span></span> {{ sub.text }}
          </label>
          <i class="fa fa-plus mr-2"></i>
        </div>
      </div>
      <div class="text-center" v-else>
        <span class="badge badge-secondary"> Cargando información... </span>
      </div>
    </div>
  </b-modal>
</template>
<style>
.container_filters {
  max-height: 500px;
  width: 105% !important;
  overflow-y: auto;
}
</style>
<script>
import axios from "axios";

const removeAccents = (str) => {
  return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
};
export default {
  name: "show-Filter-Bookmarks",
  data() {
    return {
      cor: sessionStorage.cor,
      element: [], // Must be an array reference!
      optionsCircuitos: [],
      options: [],
      substation: [],
      substationAll: [],
      showLegends: false,
    };
  },
  watch: {
    element() {
      let res = this.substationAll.filter((f) =>
        this.substation.includes(f.value)
      );
      this.$parent.onElements(this.element, res);
    },
    substation() {
      if (this.substation.length !== 0) {
        this.options.map((el) => {
          if (el.descripcion !== "Tramos") {
            el.disabled = false;
          } else {
            el.disabled = true;
          }
        });
        let res = this.substationAll.filter((f) =>
          this.substation.includes(f.value)
        );
        this.$parent.onCircuits(res);
      } else {
        this.element = [];
        this.options.map((el) => {
          if (el.text !== "Tramos") {
            el.disabled = true;
          } else {
            this.element.push(el.text);
            el.disabled = false;
          }
        });
        this.options.map((el) => {
          el.disabled = true;
        });
        // this.element = []
        this.$parent.onCircuits([]);
      }
    },
  },
  computed: {
    circuits() {
      let circuit = [];
      return circuit;
    },
  },
  mounted() {
    this.networkElements();
    this.loadSubStations();
  },
  filters: {
    emptyString(value) {
      if (value) return value.replace(/_/g, " ");
      else return "";
    },
  },
  methods: {
    networkElements() {
      axios.get("/elemento-red").then((res) => {
        let config = [];
        res.data.map((el) => {
          config.push({
            text: removeAccents(
              el.descripcion ? el.descripcion.replace(/ /g, "_") : ""
            ),
            value: el.codigo,
            disabled: el.descripcion !== "Tramos",
          });
          if (el.descripcion == "Tramos") {
            this.element.push(
              removeAccents(
                el.descripcion ? el.descripcion.replace(/ /g, "_") : ""
              )
            );
          }
        });
        this.options = config;
      });
    },
    checkAll() {
      if (this.substationAll.length == this.substation.length) {
        this.substation = [];
      } else {
        this.substation = [];
        this.substationAll.map((el) => {
          this.substation.push(el.value);
        });
      }
    },
    loadSubStations() {
      this.$nextTick(() => {
        if (this.$parent.cor != undefined) {
          axios
            .post("subestaciones", {
              cor: this.$parent.cor ? this.$parent.cor.replaceAll("'", '').split(',') : "",
            })
            .then((res) => {
              let config = [];
              res.data.map((element) => {
                config.push({
                  text: element.nombre_subestacion,
                  value: element.codigo,
                  circuitos: element.circuitos,
                });
              });
              this.substationAll = config;
            });
        }
      });
    },
  },
};
</script>
