<template>
    <b-modal
        title="Información de Brigada"
        ref="inforBri"
        id="inforBri"
        v-model="isShowInfo"
        size="xl"
        no-close-on-backdrop
        scrollable>
        <b-row>
            <b-col md="12">
                <div>
                    <b-tabs content-class="mt-3" small>
                        <b-tab title="Información Básica" active>
                            <table class="table table-sm table-striped table-hover">
                                <thead>
                                    <tr>
                                        <td colspan="5" class="text-center bg-secondary">INFORMACIÓN BÁSICA DE BRIGADA</td>
                                    </tr>
                                    <tr>
                                        <td>NOMBRE</td>
                                        <td> PLACA </td>
                                        <td>TÉLEFONO</td>
                                        <td>USUARIO</td>
                                        <td>FECHA ACCESO</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr 
                                        v-for="(item, idx) in user" 
                                        :key="idx">
                                        <td> {{ item.nombre }} </td>
                                        <td> {{ item.vehiculo  }} </td>
                                        <td> {{ item.telefono }} </td>
                                        <td> {{ item.usuario }} </td>
                                        <td> {{ item.fechaacceso | formatDate }} </td>
                                    </tr>
                                </tbody>
                            </table>
                            <div class="text-center">
                                <img 
                                    width="320"
                                    src="images/information_technical.png" 
                                    alt="Image tech">
                            </div>
                        </b-tab>
                        <b-tab title="Avisos">
                            <table class="table table-sm table-striped table-hover">
                                <thead>
                                    <tr>
                                        <td colspan="11" class="bg-secondary text-center"> INFORMACIÓN DE OT </td>
                                    </tr>
                                    <tr>
                                        <td> N° OT </td>
                                        <td> N° Aviso </td>
                                        <td> Tipo </td>
                                        <td> Subestación </td>
                                        <td> Circuito </td>
                                        <td> Suscriptor </td>
                                        <td> Dirección </td>
                                        <td> Referencia </td>
                                        <td> VIP </td>
                                        <td> Fecha Asignación </td>
                                        <td> Estado </td>

                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(item, idx) in data" :key="idx + '_._' ">
                                        <td> {{ item.num_ot }} </td>
                                        <td> {{ item.num_aviso }} </td>
                                        <td> {{ item.tipo }} </td>
                                        <td> {{ item.subestacion }} </td>
                                        <td> {{ item.circuito_sm }} </td>
                                        <td> {{ item.nombre_suscriptor }} </td>
                                        <td> {{ item.dir_suscriptor }} </td>
                                        <td> {{ item.dir_ref }} </td>
                                        <td> {{ item.ind_cli_vip === 0 ? ' ' : 'SI' }} </td>
                                        <td> {{ item.fecha_asigna | formatDate }} </td>
                                        <td> {{ item.estado | upperCase }} </td>
                                    </tr>
                                </tbody>
                            </table>
                        </b-tab>
                    </b-tabs>
                </div>
            </b-col>
        </b-row>

        <template #modal-footer>
            <button 
                @click="() => isShowInfo = false"
                class="btn btn-sm btn-primary ">
                <i class="flaticon-close"></i> Cerrar
            </button>
        </template>
    </b-modal>
</template>
<script>

    import axios from 'axios'
    import moment from 'moment'
    export default {
        name: 'informacion-brigada',
        data() {
            return {
                isShowInfo: false,
                data: [],
                user: []
            }
        },
        filters: {
            upperCase(value) {
                return value.replace(/_/g, " ").toUpperCase()
            },
            formatDate(value) {
                return moment(value).format('LL')
            }
        },
        methods: {
            load(id) {
                axios.post(`get-ots-brigada/${id}`)
                    .then((res) => {
                        this.data = [] 
                        res.data.map(el => {
                            el.avisos.map(av => {

                                av.num_ot = el.num_ot
                                av.estado = el.estado_o_t_s.descripcion
                                av.observacion_ot = el.observacion
                                av.fecha_asigna = el.fecha_asigna
                                av.tipo = av.tipo_trabajo_id == 1 ? 'AV' : (av.tipo_trabajo_id == 2 ? 'IN' : 'DES')
                                this.data.push(av)
                            })
                        })

                    })
            }
        } 
    }
</script>