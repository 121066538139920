<template>
  <div v-if="info.length" class="mt-2">
    <div>
      <div class="row">
        <b-col class="d-flex justify-content-between">
          <div>
            <label class="text-muted">
              {{ message }}
            </label>
          </div>
          <div>
            <label>
              Procesos seleccionados
              <span class="badge badge-primary">
                {{ info.length }}
              </span>
            </label>
          </div>
        </b-col>
        <b-col md="12" class="table table-responsive alert alert-secondary">
          <table class="table table-hover table-sm scrolling-wrapper">
            <thead class="thead-dark">
              <tr>
                <td></td>
                <td>Aviso de referencia</td>
                <td>N° de orden</td>
                <td>Tipo</td>
                <td>Icono</td>
                <td>Tipo de aviso</td>
                <td>Dirección</td>
                <td>Barrio</td>
                <td>Referencia</td>
                <td>Descripción</td>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, i) in info" :key="i">
                <td>
                  <div class="checkbox-inline">
                    <label
                      class="checkbox checkbox-outline checkbox-outline-2x checkbox-primary"
                    >
                      <input
                        type="checkbox"
                        name="Checkboxes16"
                        :value="item.id_aviso"
                        v-model="avisos"
                      />
                      <span></span>
                    </label>
                  </div>
                </td>
                <td>
                  <button
                    class="btn btn-sm btn-link"
                    @click="addFavorite(item.id_aviso)"
                  >
                    <i
                      :class="
                        'la la-star' +
                        (item.id_aviso == favorite ? ' text-warning' : '')
                      "
                    ></i>
                  </button>
                </td>
                <td>{{ item.num_aviso }}</td>
                <td>{{ item.tipo }}</td>
                <td>
                  <img
                    v-if="item.icono != null || item.icono != undefined"
                    :src="
                      'http://138.197.75.131:9009/img/icons_cld/icons/' +
                      item.icono.icono +
                      '.png'
                    "
                    alt="icon"
                  />
                </td>
                <td>{{ item.tipo_aviso }}</td>
                <td>{{ item.dir_suscriptor }}</td>
                <td>{{ item.barrio }}</td>
                <td>{{ item.dir_ref }}</td>
                <td class="text-justify">{{ item.observacion }}</td>
              </tr>
            </tbody>
          </table>
        </b-col>
        <b-col md="6" class="text-left">
          <label v-if="isAssign">Seleccione brigada</label>

          <Select2
            v-if="isAssign"
            class="select2 mb-3"
            :disabled="loadT"
            v-model="worker"
            :options="configOptionSelect"
          />
          <label v-if="loadT">Cargando brigadas...</label>
          <hr v-if="loadT" />
        </b-col>
        <b-col md="12">
          <b-form-group class="text-left">
            <label>Observaciones</label>
            <b-form-textarea
              v-model="description"
              placeholder="Observaciones"
              rows="3"
              columns="3"
            ></b-form-textarea>
          </b-form-group>
        </b-col>

        <div class="col-md-6 mt-3">
          <div class="form-group text-left">
            <b-button
              class="btn btn-xs btn-sm mr-3"
              variant="primary"
              v-if="!isNewWarnings"
              @click="handleOk()"
              ref="buttonLoader"
            >
              <i class="la la-play"></i> Generar OT
            </b-button>

            <b-button
              v-if="isNewWarnings"
              class="btn btn-xs btn-sm mr-3"
              variant="primary-outline"
              @click="addNewWarning()"
              ref="buttonLoader"
            >
              <i class="la la-play"></i> Generar OT
            </b-button>

            <label class="checkbox checkbox-outline checkbox-success ml-2">
              <input type="checkbox" v-model="isAssign" /> ¿Desea asignar la OT?
              <span></span>
            </label>
          </div>
        </div>
      </div>
    </div>
    <div class="separator separator-dashed mt-3 mb-3"></div>
  </div>
</template>
<style>
.modal-backdrop {
  opacity: 0.65;
}
span.select2 {
  width: 100% !important;
}
</style>
<script>
import toastr from "toastr";
import Select2 from "v-select2-component";
import axios from "axios";
import * as $ from "jquery";
import { mapActions, mapGetters } from "vuex";
import moment from "moment";
export default {
  components: {
    Select2,
  },
  props: {
    info: {
      type: Array,
      default: () => [],
    },
    polygon: {
      type: Array,
      default: () => [],
    },
    workers: {
      type: Array,
      default: () => [],
    },
    isNewWarnings: {
      type: Boolean,
      default: () => false,
    },
  },
  mounted() {
    this.info.forEach((el) => {
      this.avisos.push(el.id_aviso);
    });

    if (this.configOptionSelect.length == 1) {
      this.isAssign = true;
      this.worker = this.configOptionSelect[0].id;
    }
  },

  data() {
    return {
      show: false,
      brigade: null,
      favorite: null,
      selected: [],
      allSelected: false,
      avisos: [],
      description: null,
      service: [],
      worker: -1,
      oldValue: null,
      isAssign: null,
      isLoading: false,
      techs: [],
      loadT: false,
    };
  },

  watch: {
    isAssign() {
      if (!this.isAssign) {
        this.worker = -1;
        this.description = "";
      } else {
        if (!this.techs.length) {
          this.loadTech();
          $("#assignation___BV_modal_content_").attr("tabindex", null);
          var interval = setInterval(() => {
            $("#assignation___BV_modal_content_").attr("tabindex", null);
          }, 1000);

          setTimeout(() => {
            clearInterval(interval);
          }, 3000);
        }
      }
    },
    info: function (newValue, oldValue) {
      this.description = null;
      this.info = newValue;
      this.avisos = [];
      if (this.info.length == 1) {
        this.favorite = this.info[0].id_aviso;
      }
      this.info.forEach((el) => {
        el.tipo = el.tipo_trabajo.nombre;
        this.avisos.push(el.id_aviso);
      });
      this.oldValue = oldValue;
    },
    worker() {
      const text = this.configOptionSelect.find((f) => f.id == this.worker);
      if (text) {
        this.description = `${moment().format("YYYY-MM-DD HH:mm:ss")} - [${
          text.text
        }]`;
      }
    },
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
      getIdx: "location/getIds",
    }),
    message() {
      if (this.$route.path != "/works") {
        if (!this.workers.length) {
          return `Para cargar las brigadas, asegurese de cargar el mapa`;
        } else {
          return null;
        }
      }
      return null;
    },
    configOptionSelect() {
      return this.techs;
    },
  },
  methods: {
    ...mapActions({
      changeIds: "location/changeIds",
    }),
    genAndAssign() {
      this.isAssign = true;
    },
    handleOk() {
      if (this.avisos.length != 0) {
        if (this.favorite != null && this.avisos.includes(this.favorite)) {
          this.loaderSpinClasss();
          this.service = [];
          this.avisos = this.avisos.filter(Number);
          // const tech = this.workers.find((f) => f.value == this.worker);

          const text = this.configOptionSelect.find((f) => f.id == this.worker);
          this.service.push({
            avisos: this.avisos,
            aviso_referencia: this.favorite,
            full_name: text ? text.text : null,
            trabajador: this.worker == -1 ? null : this.worker,
            observacion: this.description == null ? "" : this.description,
          });

          // aplication root
          const root = this.$root;
          // app component
          const [app] = root.$children;
          // multipane resizer component - have 4 interns components
          const multipane = app.$children[1];
          if (multipane) {
            // map or router-view component
            const map = multipane.$children[2];
            if (map) {
              if (map.markers && map.markers.length) {
                this.getIdx.map((id) => {
                  const index = map.markers.findIndex((f) => f.id == id);
                  if (index !== -1) {
                    map.infoWinOpen = false;
                    map.markers.splice(index, 1);
                  }
                });
              }
            }
          }
          this.$emit("generateOT", this.service);

          this.loaderSpinClasss();
        } else {
          toastr.warning("Debe seleccionar un aviso de referencia");
        }
      } else {
        toastr.warning("Debe seleccionar al menos un aviso para crear una OT");
      }
    },

    addFavorite(id) {
      if (this.favorite == id) {
        this.favorite = null;
      } else {
        this.favorite = id;
      }
    },
    resetMarker() {
      this.$emit("resetMarker", []);
    },
    loaderSpinClasss() {
      this.isLoading = !this.isLoading;
      const submitButton = this.$refs.buttonLoader;
      submitButton.classList.add(
        "disabled",
        "spinner",
        "spinner-light",
        "spinner-left"
      );
    },
    removeLoaderSpinClass() {
      this.isLoading = !this.isLoading;
      const submitButton = this.$refs.buttonLoader;
      submitButton.classList.remove(
        "disabled",
        "spinner",
        "spinner-light",
        "spinner-left"
      );
    },
    loadTech() {
      let data = {
        sectores: JSON.parse(sessionStorage.sectores),
      };
      this.techs = [];
      this.loadT = true;
      axios
        .post("tecnico-bol-all")
        .then((res) => {
          this.loadT = false;

          res.data = res.data.filter((r) =>
            data.sectores.includes(r.delegacion)
          );
          res.data.map((el) => {
            this.techs.push({
              id: el.usuario,
              text: `${el.cliente ? el.cliente.vehiculo : ""} - ${
                el.nombre
              } - ${el.cliente ? el.cliente.movil : ""}`,
            });
          });
        })
        .catch((e) => {
          console.log(e);
          this.loadT = false;
        });
    },
  },
};
</script>
