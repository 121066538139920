<template>
  <b-modal
    title="Cambiar COR/SECTOR"
    ref="corsector"
    id="corsector"
    v-model="isShow"
    size="sm"
    no-close-on-backdrop
    scrollable
  >
    <b-row>
      <b-col md="12">
        <div class="form-group">
          <label>Seleccione COR</label>
          <select
            class="form-control form-control-sm"
            @change="changeCorSector()"
            v-model="form.cor"
          >
            <option v-for="(item, idx) in cors" :key="idx" :value="item.nombre">
              {{ item.nombre }}
            </option>
          </select>
        </div>
      </b-col>
      <b-col md="12">
        <div class="form-group">
          <label> {{ sector.descripcion }} </label>
          <select class="form-control form-control-sm" v-model="form.sector">
            <option
              v-for="(item, idx) in sector.data"
              :key="idx + '_'"
              :value="item.sector"
            >
              {{ item.sector }}
            </option>
          </select>
        </div>
      </b-col>
    </b-row>
    <template #modal-footer>
      <div class="form-group">
        <button
          class="btn btn-success btn-sm"
          v-if="form.sector != null"
          @click="sendData()"
        >
          <i class="la la-sync"></i>
          {{ isLoading ? "Cargando..." : "Confirmar" }}
        </button>
      </div>
    </template>
  </b-modal>
</template>
<script>
import axios from "axios";
import { mapGetters } from "vuex";
import toastr from "toastr";
export default {
  name: "cor-sector",
  data() {
    return {
      isShow: false,
      num_aviso: null,
      cors: [],
      form: {
        cor: sessionStorage.cor,
        sector: null,
      },
      sector: [],
      isLoading: false,
    };
  },
  mounted() {
    this.loadCor();
    this.changeCorSector();
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
  },
  methods: {
    loadCor() {
      axios.get("/cors").then((res) => {
        this.cors = res.data;
      });
    },
    changeCorSector() {
      axios.post("/cor-sector", this.form).then((res) => {
        this.form.sector = null;
        this.sector = res.data;
      });
    },
    sendData() {
      let data = {
        num_aviso: this.num_aviso,
        codigo_sector: this.form.sector,
        cor: this.form.cor,
        usuario: this.user.usuario,
      };

      if (this.form.sector == null) {
        toastr.info("Debe escoger un sector");
      } else {
        this.isLoading = true;
        axios
          .post("cambiar-cor-sector", data)
          .then((res) => {
            if (!res.data.error) {
              this.isLoading = !this.isLoading;
              this.isShow = false;
              this.$parent.treeMenu(this.$parent.data);
            } else {
                toastr.info(res.data.message)
                this.isLoading = false;
            }
          })
          .catch((e) => {
            console.log(e);
            this.isLoading = !this.isLoading;
          });
      }
    },
  },
};
</script>
