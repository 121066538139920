<template>
  <b-card class="w-full">
    <b-row v-if="binnacle.length">
      <b-col>
        <b>BITACORA</b>
        <DxDataGrid
          id="no-scroll-3"
          :data-source="binnacle"
          :show-borders="true"
          :show-column-lines="true"
          :show-row-lines="true"
          :row-alternation-enabled="true"
          :columnAutoWidth="true"
          :allow-column-resizing="true"
        >
          <DxSearchPanel :visible="true" :width="240" placeholder="Buscar..." />
          <DxPaging :page-size="10" />
          <DxHeaderFilter :visible="true" />
          <DxFilterRow :visible="true">
            <DxOperationDescriptions
              equal="Igual a"
              notEqual="Diferente de"
              startsWith="Empieza con"
              endsWith="Termina con"
              contains="Contiene"
              between="Entre"
              notContains="No contiene"
              lessThanOrEqual="Menor o igual"
              greaterThanOrEqual="Mayor o igual"
              lessThan="Menor que"
              greaterThan="Mayor que"
            />
          </DxFilterRow>
          <DxPager
            :visible="true"
            infoText="Página {0} of {1} ({2} registros)"
            :allowed-page-sizes="pageSizes"
            display-mode="full"
            :show-page-size-selector="true"
            :show-info="true"
            :show-navigation-buttons="true"
          />
          <DxColumn
            data-field="fecha"
            caption="Fecha"
            data-type="date"
            format="dd/MM/yyyy, HH:mm:ss"
          />
          <DxColumn data-field="detalle" caption="Detalle" />
        </DxDataGrid>
      </b-col>
    </b-row>
     <b-row v-else>
      <b-col>
        <div class="text-center">
          <img src="assets/img/empty.png" alt="Empty" />
          <p class="text-muted">No hay información para mostrar</p>
        </div>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  DxDataGrid,
  DxSearchPanel,
  DxPaging,
  DxHeaderFilter,
  DxFilterRow,
  DxPager,
  DxColumn,
  DxOperationDescriptions,
} from "devextreme-vue/data-grid";

export default {
  components: {
    DxDataGrid,
    DxSearchPanel,
    DxPaging,
    DxHeaderFilter,
    DxFilterRow,
    DxPager,
    DxColumn,
    DxOperationDescriptions,
  },
  props: {
    binnacle: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      pageSizes: [5, 10, 15, 30],
    };
  },
};
</script>
