<template>
  <b-row class="mt-5">
    <b-col>
      <b-card title="Usuarios" v-if="can('/users')">
        <transition name="fade">
          <b-row v-if="items.length">
            <b-col class="dx-viewport">
              <DxDataGrid
                :data-source="items"
                :show-borders="true"
                :show-column-lines="true"
                :show-row-lines="true"
                :row-alternation-enabled="true"
                :columnAutoWidth="true"
                :allow-column-resizing="true"
              >
                <DxExport :enabled="true" />
                <DxGroupPanel
                  :visible="true"
                  emptyPanelText="Arrastre una columna aquí para agrupar"
                />
                <DxSearchPanel
                  :visible="true"
                  :width="240"
                  placeholder="Buscar..."
                />
                <DxPaging :page-size="15" />
                <DxPager
                  :visible="true"
                  infoText="Página {0} de {1} ({2} registros)"
                  :allowed-page-sizes="pageSizes"
                  display-mode="full"
                  :show-page-size-selector="true"
                  :show-info="true"
                  :show-navigation-buttons="true"
                />
                <DxHeaderFilter :visible="true" />
                <DxFilterRow :visible="true">
                  <DxOperationDescriptions
                    equal="Igual a"
                    notEqual="Diferente de"
                    startsWith="Empieza con"
                    endsWith="Termina con"
                    contains="Contiene"
                    between="Entre"
                    notContains="No contiene"
                    lessThanOrEqual="Menor o igual"
                    greaterThanOrEqual="Mayor o igual"
                    lessThan="Menor que"
                    greaterThan="Mayor que"
                  />
                </DxFilterRow>
                <DxColumn
                  data-field="nombre"
                  caption="Nombre"
                  alignment="left"
                />
                <DxColumn
                  data-field="usuario"
                  caption="Usuario"
                  alignment="left"
                />
                <DxColumn
                  data-field="perfil"
                  caption="Perfil"
                  alignment="left"
                />
                <DxColumn
                  data-field="email"
                  caption="Correo"
                  alignment="left"
                />
                <DxColumn
                  data-field="base.cliente"
                  caption="Contrata"
                  alignment="left"
                />
                <DxColumn
                  data-field="base.codempresa"
                  caption="Codempresa"
                  alignment="center"
                  width="100"
                />
                <DxColumn
                  data-field="fechaacceso"
                  caption="Fecha último acceso."
                  data-type="date"
                  format="dd/MM/yyyy, HH:mm:ss"
                />
                <DxColumn caption="" width="150" cell-template="cellTemplate" />
                <template #cellTemplate="{ data: cellData }">
                  <div class="text-center">
                    <button
                      class="btn btn-sm btn-circle"
                      v-if="
                        can('assign-vehicle') &&
                        cellData.data.perfil == 'Tecnico'
                      "
                      @click="assignVehicle(cellData.data)"
                    >
                      <i class="flaticon2-delivery-truck text-primary"></i>
                    </button>
                    <router-link
                      :to="{
                        name: 'usersUpdate',
                        params: { id: cellData.data.id },
                      }"
                      v-if="can('update-user')"
                      class="btn btn-sm btn-circle action"
                    >
                      <i class="flaticon2-pen text-success"></i>
                    </router-link>
                    <button
                      class="btn btn-sm btn-circle action"
                      @click="deleteUser(cellData.data.id)"
                      v-if="
                        cellData.data.usuario != user.usuario &&
                        can('delete-user')
                      "
                    >
                      <i class="flaticon2-rubbish-bin text-danger"></i>
                    </button>
                  </div>
                </template>
                <DxToolbar>
                  <DxItem location="after" template="refreshTemplate" />
                  <DxItem name="columnChooserButton" />
                  <DxItem name="exportButton" />
                  
                  <DxItem name="searchPanel" />
                  <DxItem
                    location="before"
                    template="createTemplate"
                    v-if="can('create-user')"
                  />
                  <DxItem name="groupPanel" />
                </DxToolbar>
                <template #createTemplate>
                  <DxButton
                    type="default"
                    styling-mode="contained"
                    icon="group"
                    text="Agregar"
                    @click="addUser()"
                  />
                </template>
                <template #refreshTemplate>
                  <DxButton icon="refresh" @click="loadUser()" />
                </template>
              </DxDataGrid>
            </b-col>
          </b-row>
          <b-row v-else>
            <b-col>
              <div v-if="!loading">
                <div class="text-center">
                  <img src="assets/img/empty.png" alt="Empty" />
                  <p class="text-muted">No hay información para mostrar</p>
                </div>
              </div>
              <div class="text-center" v-else>
                <img src="assets/img/loader.gif" alt="Loading..." />
              </div>
            </b-col>
          </b-row>
        </transition>
      </b-card>
      <b-card v-else>
        <div class="alert bg-secondary">
          No tiene permisos para esta sección
        </div>
      </b-card>
    </b-col>

    <CreateUser
      ref="add_user"
      @loadItems="loadUser()"
      v-if="can('create-user')"
    />

    <AssignVehicle
      ref="AVU"
      v-if="can('assign-vehicle')"
      @loadItems="loadUser()"
    />
  </b-row>
</template>
<style>
.action:hover i {
  opacity: 0.7 !important;
}
</style>
<script>
import {
  DxDataGrid,
  DxPaging,
  DxColumn,
  DxSearchPanel,
  DxFilterRow,
  DxHeaderFilter,
  DxPager,
  DxOperationDescriptions,
  DxExport,
  DxGroupPanel,
  DxToolbar,
  DxItem,
} from "devextreme-vue/data-grid";
import { DxButton } from "devextreme-vue/button";
import { Workbook } from "exceljs";
import { saveAs } from "file-saver-es";
import { exportDataGrid } from "devextreme/excel_exporter";
import axios from "axios";
import { mapActions, mapGetters } from "vuex";
import CreateUser from "./CreateUser.vue";
import can from "@/permission";
import AssignVehicle from "./AssignVehicle.vue";
export default {
  name: "user-component",
  components: {
    DxDataGrid,
    DxPaging,
    DxColumn,
    DxSearchPanel,
    DxOperationDescriptions,
    DxFilterRow,
    DxHeaderFilter,
    DxPager,
    DxExport,
    DxGroupPanel,
    DxToolbar,
    DxItem,
    DxButton,
    CreateUser,
    AssignVehicle,
  },
  data() {
    return {
      items: [],
      loading: false,
      pageSizes: [5, 10, 15, 30, 50],
      texts: {
        editRow: "Editar",
        saveRowChanges: "Guardar",
        saveAllChanges: "Guardar cambios",
        deleteRow: "Eliminar",
        cancelRowChanges: "Cancelar",
        confirmDeleteTitle: "Información",
        confirmDeleteMessage: "¿Realmente desea eliminar el registro?",
        cancelAllChanges: "Descartar cambios",
      },
    };
  },
  mounted() {
    this.loadUser();
    this.loadRoles();
    this.loadContratas();
  },
  computed: {
    ...mapGetters({
      roles: "Roles/getRoles",
      contratas: "Contratas/getContratas",
      user: "auth/user",
    }),
  },
  methods: {
    ...mapActions({
      setRoles: "Roles/setRoles",
      setContratas: "Contratas/setContratas",
    }),
    can: can,
    assignVehicle(items) {
      const AVU = this.$refs.AVU;
      AVU.show = true;
      console.log(items);
      AVU.item = items;
      AVU.item.cliente = items.base ? items.base.cliente : "";
      AVU.loadVehicles(items.base.nombre);
      AVU.removeTabIndex();
    },
    deleteUser(id) {
      this.$swal
        .fire({
          title: "Información",
          text: "¿Quiere eliminar el usuario?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Si, Eliminar!",
          cancelButtonText: "Cancelar",
        })
        .then((result) => {
          if (result.isConfirmed) {
            axios
              .delete(`/users/${id}`)
              .then((res) => {
                if (res.data.message == "success") {
                  this.$swal.fire(
                    "Información!",
                    res.data.information,
                    "success"
                  );
                  this.loadUser();
                }
              })
              .catch((e) => {
                console.log(e);
              });
          }
        });
    },
    addUser() {
      const add_user = this.$refs.add_user;

      add_user.show = true;
      add_user.reset();
      // code
    },
    loadUser() {
      this.items = [];
      this.loading = true;
      axios
        .get("/users")
        .then((res) => {
          this.items = res.data;
          this.loading = false;
        })
        .catch((e) => {
          console.log(e);
          this.loading = false;
        });
    },

    loadRoles() {
      if (!this.roles.length) {
        axios
          .get("/roles")
          .then((res) => {
            this.setRoles(res.data);
          })
          .catch((e) => {
            console.log(e);
          });
      }
    },
    loadContratas() {
      if (!this.contratas.length) {
        axios
          .get("/contratas")
          .then((res) => {
            this.setContratas(res.data);
          })
          .catch((e) => {
            console.log(e);
          });
      }
    },
    onExporting(e) {
      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet("Information");

      exportDataGrid({
        component: e.component,
        worksheet,
        autoFilterEnabled: true,
      }).then(() => {
        workbook.xlsx.writeBuffer().then((buffer) => {
          saveAs(
            new Blob([buffer], { type: "application/octet-stream" }),
            "Document.xlsx"
          );
        });
      });
      e.cancel = true;
    },
  },
};
</script>
