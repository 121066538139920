<template>
  <div>
    <b-row v-if="eventos && eventos.length">
      <b-col md="6">
        <b-row>
          <b-col md="12">
            <pre>Progreso de los eventos <b>{{ progress }}</b> %</pre>
            <b-progress :max="100" show-progress animated>
              <b-progress-bar :value="progress" :variant="`${variant}`">
                <span
                  ><strong>{{ progress }} %</strong></span
                >
              </b-progress-bar>
            </b-progress>
          </b-col>
        </b-row>
        <div class="separator separator-dashed mt-3 mb-3"></div>
        <h5>TAREAS RELACIONADAS</h5>
        <div>
          <button
            class="btn btn-secondary btn-sm"
            v-if="selectedRowsData.length"
            @click="onUncheckSelect()"
          >
            <i class="fa fa-trash"></i>
          </button>
        </div>
        <DxDataGrid
          ref="datagrid"
          :data-source="eventos"
          :show-borders="true"
          :show-column-lines="true"
          :show-row-lines="true"
          :row-alternation-enabled="true"
          :columnAutoWidth="true"
          :allow-column-resizing="true"
          @selection-changed="onSelectionChanged"
        >
          <DxSelection mode="single" show-check-boxes-mode="always" />
          <DxExport :enabled="true" />
          <DxGroupPanel
            :visible="true"
            emptyPanelText="Arrastre una columna aquí para agrupar"
          />
          <DxSearchPanel
            :visible="false"
            :width="240"
            placeholder="Buscar..."
          />
          <DxPaging :page-size="15" />
          <DxHeaderFilter :visible="true" />
          <DxFilterRow :visible="true">
            <DxOperationDescriptions
              equal="Igual a"
              notEqual="Diferente de"
              startsWith="Empieza con"
              endsWith="Termina con"
              contains="Contiene"
              between="Entre"
              notContains="No contiene"
              lessThanOrEqual="Menor o igual"
              greaterThanOrEqual="Mayor o igual"
              lessThan="Menor que"
              greaterThan="Mayor que"
            />
          </DxFilterRow>
          <DxPager
            :visible="true"
            infoText="Página {0} of {1} ({2} registros)"
            :allowed-page-sizes="[5, 10, 15, 30, 50, 100]"
            display-mode="full"
            :show-page-size-selector="true"
            :show-info="true"
            :show-navigation-buttons="true"
          />
          <DxColumn
            alignment="center"
            caption="Estado"
            cell-template="cellTemplate"
          />
          <template #cellTemplate="{ data: cellData }">
            <div>
              <span><i :class="cellData.data.icono"></i> </span>
              {{ cellData.data.ot_estado_descripcion }}
            </div>
          </template>
          <DxColumn data-field="workTask.numero_aviso" caption="N° Tarea" alignment="center" />
          <DxColumn data-field="num_ot" caption="N° OT" alignment="center" />
          <DxColumn
            data-field="causa_certificacion.descripcion_causa"
            caption="Causa cert."
            alignment="center"
          />

          <DxColumn
            data-field="fecha_alta"
            caption="Fecha"
            format="yyyy-MM-dd"
          />
          <DxColumn
            data-field="ot_fecha_asigna"
            caption="Fecha de asignación"
            format="yyyy-MM-dd"
            alignment="center"
          />
          <DxColumn
            data-field="observacion"
            caption="Observación"
            alignment="center"
            cell-template="templateObservacion"
            :width="500"
          />
          <template #templateObservacion="{ data: cellData }">
            <div class="cell">
              {{ cellData.data.observacion }}
            </div>
          </template>
          <DxColumn
            data-field="work_task_instrucciones"
            caption="Observación tarea"
            alignment="center"
            cell-template="templateObservacionTask"
            :width="500"
          />
          <template #templateObservacionTask="{ data: cellData }">
            <div class="cell">
              {{ cellData.data.work_task_instrucciones }}
            </div>
          </template>
        </DxDataGrid>
      </b-col>
      <b-col md="6">
        <b-row>
          <b-col md="12">
            <transition name="component-fade" mode="out-in">
              <div v-if="bCertificar">
                <b-row class="bg-secondary pt-4">
                  <b-col v-if="sCausaSeleccionada === ''">
                    <h4 class="text-center">
                      Seleccione una tarea para certificar este evento
                    </h4>
                  </b-col>
                  <b-col md="12" class="mt-2" v-if="sCausaSeleccionada !== ''">
                    <p>Observacion</p>
                    <b-form-group :description="'Caracteres: '+observacion_cor.length">
                      <b-form-textarea
                        size="sm"
                        rows="7"
                        v-model="observacion_cor"
                        :state="observacion_cor.length <= 1000"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col class="d-flex border-causa mt-1" md="12" v-if="sCausaSeleccionada !== ''">
                    <label class="strong w-50 mt-2">Causa seleccionada</label>
                    <input
                      type="text"
                      readonly
                      v-model="sCausaSeleccionada"
                      class="form-control"
                    />
                  </b-col>
                  <b-col cols="6" class="mb-2 mt-1" v-if="sCausaSeleccionada !== ''">
                    <label>Razon</label>
                    <Select2
                      class="select2 mb-2"
                      v-model="razon"
                      :options="oFiltersActions.razones"
                      placeholder="Seleccione razón"
                      @change="onChangeRazon($event)"
                    />
                  </b-col>
                  <b-col cols="6" class="mb-2" v-if="razon">
                    <label>Tipo</label>
                    <Select2
                      class="select2 mb-2"
                      v-model="tipo"
                      :options="cmp_aTipos"
                      placeholder="Seleccione tipo"
                      @change="onChangeTipo($event)"
                    />
                  </b-col>
                  <b-col cols="6" class="mb-2" v-if="cmp_aGrupos.length">
                    <label>Grupo</label>
                    <Select2
                      class="select2 mb-2"
                      v-model="grupo"
                      :options="cmp_aGrupos"
                      placeholder="Seleccione grupo"
                      @change="onChangeGrupo($event)"
                    />
                  </b-col>
                  <b-col cols="6" class="mb-2" v-if="cmp_aCausas.length">
                    <label>Causa</label>
                    <Select2
                      class="select2 mb-2"
                      v-model="causa"
                      :options="cmp_aCausas"
                      placeholder="Seleccione causa"
                    />
                    <!-- <b-form-select v-model="causa" :options="oFiltersActions.causas" size="sm" /> -->
                  </b-col>
                </b-row>
                <b-row class="bg-secondary">
                  <b-col cols="12" class="mb-2" v-if="bIsVisibleAve  && cmpMostrarAve">
                    <label>Ave</label>
                    <Select2
                      v-model="selectAve"
                      class="select2 mb-2"
                      :options="aAves"
                      placeholder="Seleccione ave"
                    />
                  </b-col>
                </b-row>
                <b-row class="bg-secondary" v-if="sCausaSeleccionada !== ''">
                  <b-col class="mb-3 text-center mt-5">
                    <b-button
                      variant="primary"
                      size="sm"
                      @click="onCertify()"
                      class="mr-3"
                      :disabled="!bCertificar"
                    >
                      <i class="la la-certificate"></i> Certificar
                    </b-button>
                  </b-col>
                </b-row>
              </div>
              <div v-else-if="bCertificar === false">
                <div class="bg-secondary pt-4">
                  <h4 class="text-center">Asegurese de haber completado todas las tareas</h4>
                </div>
              </div>
            </transition>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-row v-else>
      <b-col class="text-center">
        <img src="assets/img/empty.png" alt="Empty" />
        <p class="text-muted">No hay información</p>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import axios from "axios";
import toastr from "toastr";
import {
  DxDataGrid,
  DxPaging,
  DxColumn,
  DxSearchPanel,
  DxFilterRow,
  DxHeaderFilter,
  DxOperationDescriptions,
  DxPager,
  DxGroupPanel,
  DxExport,
  DxSelection,
} from "devextreme-vue/data-grid";
import * as $ from "jquery";
export default {
  components: {
    DxDataGrid,
    DxPaging,
    DxColumn,
    DxSearchPanel,
    DxFilterRow,
    DxHeaderFilter,
    DxOperationDescriptions,
    DxPager,
    DxGroupPanel,
    DxExport,
    DxSelection
  },
  props: {
    eventos: {
      type: Array,
      default: () => [],
    },
    work_id: {
      type: Number,
    },
    observacion: {
      type: String,
    },
    pageSizes: {
      type: Array,
      default: () => [5, 10, 15, 30, 50, 100],
    },
    aCausas: {
      type: Array,
      default: () => [],
    },
    aAves: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      progress: 0,
      // observacion de certificacion
      observacion_cor: null,
      // values selects
      razon: null,
      tipo: null,
      grupo: null,
      causa: null,
      // end values selects
      oResponseActions: {},
      oFiltersActions: {
        razones: [],
        tipos: [],
        grupos: [],
        causas: [],
      },
      // campos de selección multiple
      selectedRowsData: [],
      oSelectedRowsData: {},
      selectionChangedBySelectBox: false,
      sCausaSeleccionada: "",
      causa_id: null,
      bCertificar: false,
      aInformacionOTCertificada: [],
      selectAve: null,
    };
  },
  mounted() {
    // siemensmodal___BV_modal_content_
    setInterval(() => {
      $("#siemensmodal___BV_modal_content_").attr("tabindex", null);
    }, 2000);
    this.onProgress();
    this.onGetItemsSelects();
  },
  methods: {
    async onSelectionChanged({ selectedRowsData }) {
      
      // const { data } = await axios.get(`works/${this.work_id}`);
      const [oInfo] = selectedRowsData;

      /**
       * 6 - Certificada
       * 10 - Improcedente
       * 14 - Cancelada
       * 15 - Incompleta
       */
      if(oInfo) {
        this.bCertificar = this.eventos.filter((item) => [6,9,10,14,15].includes(item.ot_estado_id)).length === this.eventos.length;

        if (this.bCertificar) {
          const { causa_certificacion, obs_certifiacion } = oInfo;
          if (causa_certificacion) {
            this.observacion_cor = obs_certifiacion;
            this.causa_id = causa_certificacion.id;
            let { 
              descripcion_causa, 
              codigo_razon, 
              codigo_subtipo, 
              codigo_grupo, 
              codigo_causa,
              ave_codigo
            } = causa_certificacion;
            
            this.sCausaSeleccionada = descripcion_causa;

            if(codigo_razon) this.razon = codigo_razon;
            if(codigo_subtipo) this.tipo  = codigo_subtipo;
            if(codigo_grupo) this.grupo = codigo_grupo;
            if(codigo_causa) {
              let oCausa = this.cmp_aCausas.find((item) => item.codigo_causa == codigo_causa)
              if(oCausa)  {
                // filtramos los datos de la computada y le asignamos el idcausa
                this.causa = oCausa.id
              }
            }
            if(ave_codigo) this.selectAve = ave_codigo;
          }
        }
      }  else {
        this.bCertificar = null;
      }
      this.selectedRowsData = selectedRowsData;
    },
    onUncheckSelect() {
      const dataGrid = this.$refs.datagrid.instance;
      dataGrid.clearSelection();
      this.sCausaSeleccionada = "";
      this.causa_id = null;
      this.observacion_cor = null;
    },
    onProgress() {
      let acum = 0;
      this.eventos.map((el) => {
        if ([5, 6, 9, 10, 14, 15].includes(el.ot_estado_id)) {
          acum++;
        }
      });
      const total = Math.round((acum / this.eventos.length) * 100);
      if (!isNaN(total)) {
        this.progress = total;
        // const intervalo = setInterval(() => {
        //   this.progress++;
        //   if (this.progress >= total) clearInterval(intervalo);
        // }, 5);
      } else {
        this.progress = 0;
      }
    },
    async onCertify() {
      try {
        const payload = {
          work_id: this.work_id,
          causa_id: this.causa_id,
          observaciones: this.observacion_cor,
          evento_ave: this.selectAve
        };
        // Este es el objeto del row seleccionado
        let [oSeleccionado] = this.selectedRowsData;
        delete oSeleccionado.observacion;
        // se crea objeto para certificar
        const oCertificar = Object.assign({}, payload, oSeleccionado);
        // console.log(oCertificar);
        // console.log(oSeleccionado);
        if (!payload.work_id || !payload.causa_id || payload.observaciones === null) {
          toastr.info( "Debe ingresar la observacion y llenar los campos de los seleccionables" );
          return;
        }
        if (this.progress < 100) {
          toastr.info("Asegurese de completar todas las tareas");
          return;
        }
        const { data } = await axios.post("/works/certify", oCertificar);
        if (data.error) {
          toastr.warning(data.message);
        } else {
          toastr.success(data.message);
          this.$emit("onCerrarModal", { ap: true });
        }
      } catch (error) {
        console.log(error);
      }
    },
    async onGetItemsSelects() {
      try {
        const response = await axios("/matriz-certificaciones");
        if (response.status === 200) {
          this.oResponseActions = response.data;
          for (const key in this.oResponseActions) {
            this.oResponseActions[key].map((item) => (item.id = item.value));
          }
          this.oFiltersActions.razones = this.oResponseActions.razones;
        }
      } catch (error) {
        console.log(error);
      }
    },
    onChangeRazon(id) {
      id
      if(this.cmp_aTipos.length ===1) {
        this.tipo  = null;
        this.tipo = this.cmp_aTipos[0].id
      } else {
        this.tipo  = null;
      }

      if(this.cmp_aGrupos.length ===1) {
        this.grupo = null;
        this.grupo = this.cmp_aGrupos[0].id
      } else {
        this.grupo = null;
      }

      if(this.cmp_aCausas.length === 1 ) {
        this.causa = null;
        this.causa = this.cmp_aCausas[0].id
      } else {
        this.causa = null;
      }

    },
    onChangeTipo(id){
      id
      if(this.cmp_aGrupos.length ===1) {
        this.grupo = null;
        this.grupo = this.cmp_aGrupos[0].id
      } else {
        this.grupo = null;
      }

      if(this.cmp_aCausas.length === 1 ) {
        this.causa = null;
        this.causa = this.cmp_aCausas[0].id
      } else {
        this.causa = null;
      }
    },
    onChangeGrupo(id){
      id
      if(this.cmp_aCausas.length === 1 ) {
        this.causa = null;
        this.causa = this.cmp_aCausas[0].id
      } else {
        this.causa = null;
      }
    }
  },
  computed: {
    selectedRowKeys() {
      return this.selectedRowsData.map((oData) => oData.aviso_id);
    },
    variant() {
      const oVariant = {
        0: "danger",
        20: "danger",
        50: "warning",
        75: "info",
        100: "success",
      };

      for (const range in oVariant) {
        if (this.progress < range) {
          return oVariant[range];
        }
      }
      // Si el valor de progress es mayor que 100, devuelve "success" por defecto
      return "success";
    },
    cmpMostrarAve() {
      return this.aCausas
        .map((item) => parseInt(item.id))
        .includes(parseInt(this.causa));      
    },
    bIsVisibleAve(){
      return this.causa && this.cmp_aCausas.length;
    },
    cmp_aTipos() {
      return this.oResponseActions.tipos.filter( (tipo) => tipo.codigo_razon === this.razon );
    },
    cmp_aGrupos() {
      return this.oResponseActions.grupos.filter( (grupo) => grupo.codigo_razon === this.razon && grupo.codigo_subtipo === this.tipo);
    },
    cmp_aCausas() {
      return this.oResponseActions.causas
          .filter( (causa) => 
            causa.codigo_razon === this.razon && 
            causa.codigo_subtipo === this.tipo && 
            causa.codigo_grupo === this.grupo);
    }
  },
  watch: {
    eventos() {
      this.onProgress();
    },
    cmpMostrarAve() {
      if (!this.cmpMostrarAve) {
        this.selectAve = null;
      }
    },
  },
};
</script>
<style>
.cell {
  width: 100% !important;
  height: auto !important;
  overflow: hidden !important;
  white-space: normal !important;
  padding: 3px;
  text-align: justify;
}

.strong {
  font-weight: bold;
}

.border-causa {
  padding-bottom: 5px;
  border-bottom: 1px solid rgb(124, 124, 124);
}
</style>
