<template>
  <transition name="slide-fade">
    <b-row>
      <b-col md="12" class="d-flex justify-content-between">
        <button
          class="btn btn-sm btn-circle text-primary mb-3 mr-3"
          @click="loadDataReport()"
        >
          <i class="flaticon-refresh text-primary"></i> Actualizar
        </button>
        <div class="text-right mt-3 content-checkbox" v-if="can('check-cambiar-tabla')">
          <DxCheckBox v-model="altern" /> Cambiar tabla
        </div>
      </b-col>
      <b-col md="12" v-if="altern">
        <DxDataGrid
          :data-source="dataReport"
          :show-borders="true"
          :show-column-lines="true"
          :show-row-lines="true"
          :row-alternation-enabled="true"
          :columnAutoWidth="true"
          :allow-column-resizing="true"
          :allow-column-reordering="true"
          id="settlementTable"
        >
          <DxExport :enabled="true" />
          <DxColumnChooser
            emptyPanelText="Arrastre una columna aquí para ocultar"
            :enabled="true"
            title="Arrastre columna aquí..."
          />
          <DxColumnFixing :enabled="true" />
          <DxGroupPanel
            :visible="true"
            emptyPanelText="Arrastre una columna aquí para agrupar"
          />
          <DxSearchPanel :visible="true" :width="240" placeholder="Buscar..." />
          <DxPaging :page-size="50" />
          <DxPager
            :visible="true"
            infoText="Página {0} de {1} ({2} registros)"
            :allowed-page-sizes="[5, 15, 30, 50]"
            display-mode="full"
            :show-page-size-selector="true"
            :show-info="true"
            :show-navigation-buttons="true"
          />
          <DxHeaderFilter :visible="true" :allowSearch="true" />
          <DxFilterRow :visible="true">
            <DxOperationDescriptions
              equal="Igual a"
              notEqual="Diferente de"
              startsWith="Empieza con"
              endsWith="Termina con"
              contains="Contiene"
              between="Entre"
              notContains="No contiene"
              lessThanOrEqual="Menor o igual"
              greaterThanOrEqual="Mayor o igual"
              lessThan="Menor que"
              greaterThan="Mayor que"
            />
          </DxFilterRow>
          <DxGroupPanel :visible="true" />
          <DxColumn caption="" alignment="center" cell-template="Icon" />
          <template #Icon="{ data: cellData }">
            <i :class="cellData.data.icon"></i>
          </template>
          <DxColumn
            caption="Resumen Avisos"
            alignment="center"
            data-field="container"
            :group-index="0"
          />
          <DxColumn caption="SECTOR" alignment="center" data-field="sector" />
          <DxColumn
            caption="CANTIDAD"
            alignment="center"
            data-field="cantidad"
          />
          <DxSummary>
            <DxGroupItem
              column="cantidad"
              summary-type="sum"
              display-format="{0}"
              :align-by-column="true"
            />
            <DxGroupItem
              column="sector"
              summary-type="count"
              display-format="{0}"
              :align-by-column="true"
            />
          </DxSummary>
        </DxDataGrid>
      </b-col>
      <b-col md="12" v-else>
        <DxPivotGrid
          id="pivotgrid"
          ref="grid"
          :data-source="dataSource"
          :allow-sorting-by-summary="true"
          :allow-filtering="true"
          :show-borders="true"
          :show-column-grand-totals="true"
          :show-row-grand-totals="true"
          :show-row-totals="false"
          :show-column-totals="false"
          alinment="center"
          :texts="MainText"
        >
          <DxExport :enabled="true" />
          <DxFieldChooser
            title="Genera tu reporte"
            :layout="2"
            :texts="pivotText"
            :allowSearch="true"
            :enabled="false"
            :height="400"
          />
          <DxScrolling mode="standard" />
        </DxPivotGrid>
      </b-col>
    </b-row>
  </transition>
</template>
<style>
@import url("./css/homeReport.css");
</style>
<script>
import { mapGetters } from "vuex";
import { DxCheckBox } from "devextreme-vue/check-box";
import icon from "./js/icons";
import iconAdmin from "./js/iconsAdmin";
import can from "@/permission";
import {
  DxDataGrid,
  DxColumn,
  DxGroupPanel,
  DxPager,
  DxPaging,
  DxSearchPanel,
  DxFilterRow,
  DxColumnFixing,
  DxHeaderFilter,
  DxOperationDescriptions,
  DxColumnChooser,
  DxExport,
  DxSummary,
  DxGroupItem,
} from "devextreme-vue/data-grid";
import {
  DxPivotGrid,
  DxFieldChooser,
  DxScrolling,
} from "devextreme-vue/pivot-grid";
import PivotGridDataSource from "devextreme/ui/pivot_grid/data_source";
import _ from "lodash";
export default {
  name: "home-report",
  components: {
    DxCheckBox,
    DxDataGrid,
    DxColumn,
    DxGroupPanel,
    DxPager,
    DxPaging,
    DxSearchPanel,
    DxFilterRow,
    DxColumnFixing,
    DxHeaderFilter,
    DxOperationDescriptions,
    DxColumnChooser,
    DxExport,
    DxSummary,
    DxGroupItem,
    DxPivotGrid,
    DxFieldChooser,
    DxScrolling,
  },
  data() {
    return {
      altern: false,
      dataReport: [],
      MainText: {
        noData: "No hay información",
        grandTotal: "Total",
        collapseAll: "Desplegar todo",
        expandAll: "Expandir todo",
        exportToExcel: "Exportar a Excel",
        removeAllSorting: "Quitar orden",
        showFieldChooser: "Mostrar selector de campo",
        sortColumnBySummary: "Ordenar {0} por esta columna",
        sortRowBySummary: "Ordenar {0} por esta fila",
      },
      pivotText: {
        allFields: "Todos los campos",
        columnFields: "Columnas",
        dataFields: "Valores",
        filterFields: "Campos de filtro",
        rowFields: "Filas",
      },
      dataSource: [],
    };
  },
  mounted() {
    this.loadDataReport();
    this.icon = can('icons-home') ? icon : iconAdmin;
  },
  computed: {
    ...mapGetters({
      getSectores: "auth/getSectores",
      user: "auth/user",
    }),
  },
  methods: {
    can: can,
    icon: icon,
    loadDataReport() {
      const [app] = this.$root.$children;
      const multipane = app.$children[2];
      const [menu] = multipane.$children;
      // if (this.user.roles.id !== 2) {
      let arrayPro = [];
      var data = [];
      if (this.user.roles.id == 2) {
        let process = menu.$refs.PADMIN.proccess;
        data = _.orderBy(process, "id", ["asc"]);
      } else {
        data = _.orderBy(menu.data, "id", ["asc"]);
      }
      if (!data.length) {
        setTimeout(() => {
          this.loadDataReport();
        }, 5000);
      }

      data.map((el) => {
        if (el.value != "Brigadas") {
          if (el.children.length) {
            el.children.map((sectores) => {
              arrayPro.push({
                id: el.id,
                icon: el.icon,
                container: el.value,
                sector: sectores.value,
                cantidad: sectores.total,
              });
            });
          } else {
            arrayPro.push({
              id: el.id,
              icon: el.icon,
              container: el.value,
              cantidad: 0,
            });
          }
        }
      });
      this.dataReport = _.orderBy(arrayPro, ["id"], ["asc"]);
      this.dataSource = new PivotGridDataSource({
        fields: [
          {
            caption: "Cantidad",
            width: 120,
            dataField: "cantidad",
            area: "data",
            summaryType: "sum",
            sortBySummaryField: "Total",
          },
          {
            caption: "sector",
            width: 120,
            dataField: "sector",
            area: "column",
            sortBySummaryField: "Total",
          },
          {
            caption: "Contenedor",
            width: 160,
            dataField: "container",
            area: "row",
            alignment: "center",
            sortBySummaryField: "Total",
          },
        ],
        store: this.dataReport,
      });
      setTimeout(() => {
        this.icon(this.user.roles.id == 4);
        this.icon = can('icons-home') ? icon : iconAdmin;
      }, 300);
      // }
    },
  },
  watch: {
    altern() {
      if(!this.altern) {
        setTimeout(() => {
          this.icon(this.user.roles.id == 4);
        }, 800)
      }
    }
  }
};
</script>
