<template>
  <div class="row">
    <transition name="fade">
      <div class="col-xl-12" v-if="can('/reports')">
        <div class="card">
          <div class="card-body">
            <div class="row mb-1">
              <div class="col-md-5">
                <label>Brigada</label>
                <Select2 
                  v-model="form.usuario" 
                  :options="configTech"
                  :disabled="isLoading" 
                />
              </div>
              <div class="col-lg-4 mb-lg-0">
                <div class="form-group">
                  <label>Seleccione el día:</label>
                  <input
                    v-model="form.fecha"
                    type="date"
                    class="form-control form-control-sm"
                  />
                </div>
              </div>
              <div class="col-md-3 d-flex align-items-between">
                <div class="form-group mr-2">
                  <label>Hora inicio:</label>
                  <input
                    v-model="form.horai"
                    type="time"
                    class="form-control form-control-sm"
                  />
                </div>
                <div class="form-group">
                  <label>Hora fin:</label>
                  <input
                    v-model="form.horaf"
                    type="time"
                    class="form-control form-control-sm"
                  />
                </div>
              </div>
              <div class="col-lg-4">
                <button
                  @click="searchRoute()"
                  class="btn btn-sm btn-primary btn-primary--icon mr-2"
                  id="kt_search"
                >
                  <span>
                    <i class="la la-search"></i>
                    <span>Buscar</span>
                  </span>
                </button>
                <button
                  @click="reset()"
                  class="btn btn-sm btn-secondary btn-secondary--icon"
                >
                  <span>
                    <i class="la la-close"></i>
                    <span>Limpiar</span>
                  </span>
                </button>
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-xl-12">
                <gmap-map
                  id="gmap-map"
                  ref="gmap-map"
                  v-show="!isLoading"
                  v-bind:center="center"
                  v-bind:zoom="zoom"
                  style="width: 100%; height: 70vh"
                >
                  <gmap-info-window
                    :position="infoWindowPos"
                    :opened="infoWinOpen"
                    @closeclick="infoWinOpen = false"
                    style="gmap-info-window-vue"
                  >
                    <div class="infoWindow">
                      <div v-html="infoOptions.content"></div>
                    </div>
                  </gmap-info-window>
                  <gmap-marker
                    v-for="(item, i) in markers"
                    :key="i"
                    :position="item.position"
                    :clickable="true"
                    :animation="2"
                    :icon="item.icon"
                    @click="toggleInfoWindow(item, i)"
                  />
                  <gmap-marker
                    v-for="(item, i) in items"
                    :key="i + '__OT'"
                    :position="item.position"
                    :clickable="true"
                    :animation="2"
                    :icon="item.icon"
                    @click="toggleInfoWindow(item, i)"
                  />
                  <gmap-polyline
                    v-bind:path.sync="path"
                    v-bind:options="options"
                  />
                  <gmap-polyline
                    v-bind:path.sync="pathOt"
                    v-bind:options="optionsOt"
                  />
                </gmap-map>
                <div class="text-center" v-show="isLoading">
                  <img width="400" :src="GIF" alt="Loader" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-12" v-else>
        <div class="alert alert-secondary text-center">
          <img src="assets/img/empty.png" alt="Empty" />
          <p class="text-center">No tiene permisos para esta sección</p>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>

import Select2 from "v-select2-component";

import moment from "moment";
import { mapActions, mapGetters } from "vuex";
import axios from "axios";
import can from "@/permission";
const symbolOne = {
  path: 0,
  strokeColor: "#0B945C",
  fillColor: "#0B945C",
  fillOpacity: 1,
  strokeWeight: 2,
};
const symbolMedium = {
  path: 1,
  strokeColor: "#0B5C6E",
  fillColor: "#0B5C6E",
  strokeWeight: 3,
  fillOpacity: 1,
};

const symbolEnd = {
  path: 0,
  strokeColor: "#C42E10",
  rotation: 45,
  strokeWeight: 2,
};
export default {
  components: { Select2 },
  data() {
    return {
      GIF: "https://i.pinimg.com/originals/c8/a1/76/c8a1765ffc8243f3a7b176c0ca84c3c1.gif",
      isLoading: false,
      center: { lat: 10.9784174, lng: -74.8144132 },
      path: [],
      form: {
        usuario: -1,
        fecha: moment().format("YYYY-MM-DD"),
        horai: null,
        horaf: null,
      },
      zoom: 12,
      options: {
        strokeColor: "rgba(29, 166, 197, 0.7)",
        strokeWeight: 2,
        strokeOpacity: 1.0,
        icons: [
          {
            icon: symbolOne,
            offset: "0%",
          },
          {
            icon: symbolMedium,
            offset: "25%",
          },
          {
            icon: symbolMedium,
            offset: "50%",
          },
          {
            icon: symbolMedium,
            offset: "75%",
          },
          {
            icon: symbolEnd,
            offset: "100%",
          },
        ],
      },
      pathOt: [],
      optionsOt: {
        strokeColor: "rgba(29, 120, 91, 0.7)",
        strokeWeight: 1,
        strokeOpacity: 1.0,
        icons: [
          {
            icon: symbolOne,
            offset: "0%",
          },
          {
            icon: symbolMedium,
            offset: "25%",
          },
          {
            icon: symbolMedium,
            offset: "50%",
          },
          {
            icon: symbolMedium,
            offset: "75%",
          },
          {
            icon: symbolEnd,
            offset: "100%",
          },
        ],
      },
      markers: [],
      items: [],
      infoWindowPos: null,
      infoWinOpen: false,
      currentMidx: null,
      infoOptions: {
        content: "",
        pixelOffset: {
          width: 0,
          height: -35,
        },
      },
      infoWinContent: null,
      techs: [],
      configTech: [],
    };
  },
  mounted() {
    this.loadTechs();
    this.$nextTick(() => {
      this.$refs["gmap-map"].$mapPromise.then((map) => {
        map.setOptions({
          styles: [
            {
              featureType: "poi.business",
              stylers: [{ visibility: "off" }],
            },
            {
              featureType: "poi.medical",
              stylers: [{ visibility: "off" }],
            },
            {
              featureType: "poi.government",
              stylers: [{ visibility: "off" }],
            },
            {
              featureType: "poi.school",
              stylers: [{ visibility: "off" }],
            },
            {
              featureType: "transit",
              elementType: "labels.icon",
              stylers: [{ visibility: "off" }],
            },
            {
              featureType: "poi.park",
              stylers: [{ visibility: "off" }],
            },
            {
              featureType: "poi.attraction",
              stylers: [{ visibility: "off" }],
            },
            {
              featureType: "poi.sports_complex",
              stylers: [{ visibility: "off" }],
            },
            {
              featureType: "poi.place_of_worship",
              stylers: [{ visibility: "off" }],
            },
          ],
        });
      });
    });
  },
  computed: {
    ...mapGetters({
      getTecnical: "technical/getTecnical",
    }),
  },
  methods: {
    ...mapActions({
      changeValueTech: "technical/changeValueTech",
    }),
    can: can,
    toggleInfoWindow(marker, idx) {
      this.infoWindowPos = marker.position;
      this.center = marker.position;
      this.infoOptions.content = marker.infoText;

      if (this.currentMidx == idx) {
        this.infoWinOpen = !this.infoWinOpen;
      } else {
        this.infoWinOpen = true;
        this.currentMidx = idx;
      }
    },
    contentMarker(el, indicator) {
      let hora = new Date(el.fecha + " " + el.hora).toLocaleTimeString("es-CO");
      let info = {
        id: el.usuario,
        position: {
          lat: parseFloat(el.latitud),
          lng: parseFloat(el.longitud),
        },
        icon: `http://138.197.75.131:9009/img/icons_cld/icons/${
          indicator == 0 ? "start" : "end"
        }.png`,
        infoText: ` <div id="content" style="width: 300px !important;">
                                    <div id="bodyContent">
                                        <strong>
                                            <div style="font-weight: bold;">
                                                <span class="text-danger">  ${
                                                  el.informacion_brigada.nombre
                                                } </span> <br>
                                                <span class="text-danger">  Fecha:  </span> ${moment(
                                                  el.fecha
                                                ).format("LL")} <br>
                                                <span class="text-danger">  Hora ${
                                                  indicator == 0
                                                    ? "inicio"
                                                    : "fin"
                                                }: </span> ${hora} <br>
                                                <span class="text-danger">  Télefono: </span> <a href="tel:${
                                                  el.informacion_brigada
                                                    .telefono
                                                }"> ${
          el.informacion_brigada.telefono
        } </a><br>
                                            </div> 
                                        </strong>
                                    </div>
                                </div>`,
      };

      return info;
    },
    reset() {
      this.form = {
        usuario: -1,
        fecha: moment().format("YYYY-MM-DD"),
        horai: null,
        horaf: null,
      };
    },
    searchRoute() {
      if (
        this.form.usuario == -1 ||
        this.form.fecha == null ||
        this.form.horai == null ||
        this.form.horaf == null
      ) {
        this.$swal.fire({
          title: "Información",
          text: "Debe completar toda la información para poder filtrar",
          icon: "info",
        });
      } else {
        this.isLoading = true;

        console.log(this.form);

        axios.post("recorridos", this.form).then((res) => {
          if (res.data.gps.length != 0) {
            this.path = [];
            let data = res.data;
            data.gps.map((el) => {
              this.path.push({
                lat: parseFloat(el.latitud),
                lng: parseFloat(el.longitud),
              });
            });
            this.center = {
              lat: this.path[this.path.length - 1].lat,
              lng: this.path[this.path.length - 1].lng,
            };
            this.markers = [];
            this.markers.push(this.contentMarker(data.gps[0], 0));
            this.markers.push(
              this.contentMarker(data.gps[data.gps.length - 1], 1)
            );
            this.zoom = 13;

            let mak = [];
            data.ot.map((ot) => {
              ot.information.map((info) => {
                info.avisos.map((el) => {
                  if (el.pivot.id_aviso_ref == el.id_aviso) {
                    this.pathOt.push({
                      lat: parseFloat(el.latitud),
                      lng: parseFloat(el.longitud),
                    });
                    mak.push({
                      id: el.id_aviso,
                      position: {
                        lat: parseFloat(el.latitud),
                        lng: parseFloat(el.longitud),
                      },
                      address: el.dir_suscriptor,
                      description: el.observacion,
                      priority: el.ind_peligro,
                      ordenNumber: el.num_aviso,
                      dir_ref: el.dir_ref,
                      icono: el.icono,
                      ot: true,
                      type: el.tipo_trabajo,
                      ind_cli_vip: el.ind_cli_vip == 0 ? false : true,
                      // icon: el.icono ? `http://138.197.75.131:9009/img/icons_cld/icons/${el.icono.icono}.png` : '',
                      // <span style="color: #F64E60; font-weight: bold;">#:</span> ${el.id_aviso} <br>
                      infoText: ` <div id="content" style="width: 600px !important;">
                                                                    <div id="bodyContent">
                                                                        <strong>
                                                                            <div class="text-center mb-1">
                                                                                <p>INFORMACIÓN DE OT</p>
                                                                            </div>
                                                                            <b><span class="text-danger">N° OT :</span> ${
                                                                              info.num_ot
                                                                            }</b> <br> 
                                                                            <b><span class="text-danger">Observación :</span> ${
                                                                              info.observacion
                                                                            }</b> <br> 
                                                                            <b><span class="text-danger">Brigada :</span> ${
                                                                              info.user ==
                                                                              null
                                                                                ? "NO ASIGNADO"
                                                                                : info
                                                                                    .user
                                                                                    .nombre
                                                                            }</b> <br> 
                                                                            <b><span class="text-danger">Estado :</span> <i class="${
                                                                              info
                                                                                .estado_o_t_s
                                                                                .icono
                                                                            }"></i> ${
                        info.estado_o_t_s.descripcion
                      }</b> <br> 
                                                                            <hr>
                                                                            <div class="text-center mb-1">
                                                                                <p>INFORMACIÓN DE AVISO DE REFERENCIA</p>
                                                                            </div>
                                                                            <div style="font-weight: bold;">
                                                                                <span class="text-danger">N° de aviso :</span> ${
                                                                                  el.num_aviso
                                                                                } <br>
                                                                                <span class="text-danger">Subestación :</span> ${
                                                                                  el.subestacion
                                                                                } <br>
                                                                                <span class="text-danger">Circuito :</span> ${
                                                                                  el.circuito_sm
                                                                                }
                                                                            </div> 
                                                                                <div class="separator separator-dashed mt-1 mb-1"></div>
    
                                                                            <b><span class="text-danger">Nombre de suscriptor :</span> ${
                                                                              el.nombre_suscriptor
                                                                            }</b> <br> 
                                                                            <b><span class="text-danger">Télefono: :</span> ${
                                                                              el.telefono_suscriptor
                                                                            }</b> <br> 
                                                                            <div class="separator separator-dashed mt-1 mb-1"></div>
                                                                            <b><span class="text-danger">Tipo de aviso :</span> ${
                                                                              el.tipo_aviso
                                                                            }</b> <br> 
                                                                            <b><span class="text-danger">Descripción:</span> ${
                                                                              el.observacion
                                                                            }</b> <br> 
                                                                            <b><span class="text-danger">Referencia:</span> ${
                                                                              el.dir_ref
                                                                            }</b> <br> 
                                                                            <b><span class="text-danger">Dirección:</span> ${
                                                                              el.dir_suscriptor
                                                                            }</b>
                                                                        </strong>
                                                                    </div>
                                                                </div>`,
                    });
                  }
                });
              });
              this.items = [];
              this.zoom = 16;
              this.items = mak;
            });
          } else {
            this.items = [];
            this.markers = [];
            this.path = [];
            this.$swal.fire({
              title: "Información",
              text: "No se ha encontrado información :(",
              icon: "info",
            });
          }
          this.isLoading = false;
        });
      }
    },
    loadTechs() {
      let first = [];
      first.push({ id: -1, text: "Seleccione" });
      axios.post("tecnico-bol-all").then((res) => {
        res.data.map((worker) => {
          first.push({
            id: worker.id,
            nombre: worker.nombre,
            usuario: worker.usuario,
            telefono: worker.telefono,
            placa: worker.vehiculo_info.placa,
          });
        });

        this.techs = first;
        let options = [];
        options.push({ id: -1, text: "Seleccione" });
        this.techs.map((tech) => {
          options.push({
            id: tech.usuario,
            text:
              tech.placa || tech.nombre || tech.telefono
                ? `${tech.placa.toUpperCase()} - ${tech.nombre} - ${
                    tech.telefono
                  }`
                : null,
          });
        });

        this.configTech = options.filter(opt=>opt.text !== null);        

      });
    },
  },
};
</script>
