<template>
  <b-row>
    <b-col>
      <b-row>
        <b-col>
          <div class="alert bg-secondary">
            Num OT: {{ information.ot }} - {{ information.num_aviso }}
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group>
            <label class="mr-sm-2">Seleccione</label>
            <Select2
              class="select2 mb-3"
              v-model="selected"
              :options="options"
              @change="onChangeImproper()"
              placeholder="Filtrar"
            />
          </b-form-group>
          <b-form-group v-if="nanexo != 0 && selected != -1">
            <label> {{ anexo.description }} </label>
            <input
              :type="anexo.type"
              class="form-control form-control-sm"
              v-model="anexo.model"
            />
          </b-form-group>
          <b-form-group v-if="selected != -1">
            <label>Observación</label>
            <textarea
              class="form-control form-control-sm"
              v-model="observacion"
            ></textarea>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <button
          class="btn btn-sm btn-success font-weight-bold"
          v-if="selected != -1"
          @click="sendImproper()"
        >
          <i class="flaticon2-box-1"></i>
          {{ isLoading ? message : "Confirmar" }}
        </button>
      </b-row>
    </b-col>
  </b-row>
</template>
<style>
.modal-backdrop {
  z-index: 0 !important;
}
.bounce-enter-active {
  animation: bounce-in 0.5s;
}
.bounce-leave-active {
  animation: bounce-in 0.5s reverse;
}
@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}
</style>
<script>
import Select2 from "v-select2-component";
import axios from "axios";
import { mapActions, mapGetters } from "vuex";
import toastr from "toastr";
import * as $ from "jquery";
import moment from "moment";
export default {
  name: "improper-warning-modal",
  components: { Select2 },
  mounted() {
    this.configOptionSelect();
  },
  props: {
    information: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      showModal: false,
      id: null,
      //   ot: null,
      message: null,
      isLoading: false,
      num_aviso: [],
      selected: -1,
      options: [],
      descrip: null,
      nanexo: 0,
      observacion: null,
      anexo: {
        description: null,
        type: null,
        model: "",
      },
    };
  },
  computed: {
    today() {
      return moment().format("YYYY-MM-DDTHH:mm:ss");
    },
    ...mapGetters({
      user: "auth/user",
    }),
  },
  filters: {
    performanceA(values) {
      return values.join(" ");
    },
  },
  methods: {
    ...mapActions({
      resetWarning: "location/resetWarning",
      setDeleted: "warningsOT/setDeleted",
    }),
    deleteTabIndex() {
      setInterval(() => {
        $("#improper-modal___BV_modal_content_").attr("tabindex", null);
      }, 2000);
    },
    onChangeImproper() {
      if (this.selected != -1) {
        let change = this.options.filter((f) => f.id == this.selected)[0];
        this.nanexo = change.nanexo;
        this.anexo.description = change.tituloanx;
        this.anexo.type = change.type;

        if (change.type == "datetime-local") {
          this.anexo.model = this.today;
        }
      }
    },
    searchAv(ot) {
      axios
        .get(`/ots/${ot}`)
        .then((res) => {
          console.log(res.data);
        })
        .catch((e) => {
          console.log(e);
        });
    },
    sendImproper() {
      let causa = this.options.filter((f) => f.id == this.selected)[0];

      let data = {
        id: this.id,
        ot: this.information.ot,
        userId: this.user.id,
        usuario: this.user.usuario,
        causa: this.selected,
        num_aviso: this.information.num_aviso,
        observacion: `${causa ? causa.text : ""}:${
          this.anexo.model == "" ? "" : this.anexo.model
        }:${this.observacion == null ? "N/A" : this.observacion} `,
      };

      this.isLoading = true;
      this.message = "Cargando...";

      axios
        .post("/avisos-improcedentes", data)
        .then((res) => {
          this.isLoading = false;

          if (!res.data.error) {
            toastr.success(res.data.message);
            let data = this.$root.$children[0].$refs.menuComponent.data;
            this.$root.$children[0].$refs.menuComponent.treeMenu(data);
            this.$emit("improcedente", true)
          } else {
            toastr.warning(res.data.message);
          }
        })
        .catch((e) => {
          console.log(e);
          this.isLoading = false;
        });
    },
    configOptionSelect() {
      this.options = [];
      this.options.push({
        id: -1,
        text: "Seleccione",
      });
      axios.get("/causas-improcedentes").then((res) => {
        res.data.map((el) => {
          el.id = el.idcausaimproce;
          el.text = el.descripcion;
          this.options.push(el);
        });
      });
      this.deleteTabIndex();
    },
  },
};
</script>
