<template>
  <b-row v-if="rechazos.length">
    <DxDataGrid
      :data-source="rechazos"
      :show-borders="true"
      :show-column-lines="true"
      :show-row-lines="true"
      :row-alternation-enabled="true"
      :columnAutoWidth="true"
      :allow-column-resizing="true"
    >
      <DxSearchPanel :visible="false" :width="240" placeholder="Buscar..." />
      <DxPaging :page-size="15" />
      <DxHeaderFilter :visible="true" />
      <DxFilterRow :visible="true">
        <DxOperationDescriptions
          equal="Igual a"
          notEqual="Diferente de"
          startsWith="Empieza con"
          endsWith="Termina con"
          contains="Contiene"
          between="Entre"
          notContains="No contiene"
          lessThanOrEqual="Menor o igual"
          greaterThanOrEqual="Mayor o igual"
          lessThan="Menor que"
          greaterThan="Mayor que"
        />
      </DxFilterRow>
      <DxPager
        :visible="false"
        infoText="Página {0} of {1} ({2} registros)"
        :allowed-page-sizes="pageSizes"
        display-mode="full"
        :show-page-size-selector="true"
        :show-info="true"
        :show-navigation-buttons="true"
      />
      <DxColumn data-field="brigada" caption="Brigada" />
      <DxColumn data-field="motivo.descripcion" caption="Motivo" />
      <DxColumn
        data-field="observacion"
        caption="Observación brigada"
        width="400"
      />
      <DxColumn
        data-field="fecha_rechazo"
        caption="Fecha rechazo"
        data-type="date"
        format="dd/MM/yyyy, HH:mm:ss"
      />
    </DxDataGrid>
  </b-row>
  <b-row v-else>
    <b-col class="text-center">
      <img src="assets/img/empty.png" alt="Empty" />
      <p class="text-muted">No hay motivos de rechazo</p>
    </b-col>
  </b-row>
</template>
<script>
import {
  DxDataGrid,
  DxPaging,
  DxColumn,
  DxSearchPanel,
  DxFilterRow,
  DxHeaderFilter,
  DxOperationDescriptions,
  DxPager,
} from "devextreme-vue/data-grid";
export default {
  components: {
    DxDataGrid,
    DxPaging,
    DxColumn,
    DxSearchPanel,
    DxFilterRow,
    DxHeaderFilter,
    DxOperationDescriptions,
    DxPager,
  },
  props: {
    rechazos: {
      type: Array,
      default: () => [],
    },
    pageSizes: {
      type: Array,
      default: () => [5, 10, 15, 30, 50, 100],
    },
  },
};
</script>