<template>
  <b-modal
    title="Buscar trabajo"
    ref="workConsult"
    id="workConsult"
    v-model="show"
    size="xl"
    no-close-on-backdrop
    scrollable
  >
    <b-row>
      <b-col md="4">
        <label>Criterio</label>
        <select class="form-control form-control-sm" v-model="criterion">
          <option :value="1">Código aviso</option>
          <option :value="2">Código OT</option>
          <option :value="3">Código Tarea</option>
          <option :value="4">Código Evento</option>
        </select>
      </b-col>
      <b-col md="4">
        <label>Párametro de Búsqueda</label>
        <input
          v-model="busqueda"
          @keyup.enter="search()"
          type="text"
          :list="criterion == 2 ? 'optionsOT' : ''"
          class="form-control form-control-sm"
          minlength="4"
          placeholder="Número de OT/Aviso"
        />
        <datalist id="optionsOT" v-if="criterion == 2">
          <option
            v-for="(item, idx) in otsAll"
            :key="idx"
            :value="item.num_ot"
          ></option>
        </datalist>
      </b-col>
      <b-col md="4" class="d-flex align-items-end">
        <button class="btn btn-sm btn-outline-primary" @click="search()">
          <i class="la la-search"></i>
          {{ isLoading ? "Buscando..." : "Buscar" }}
        </button>
        <button
          class="ml-3 btn btn-outline-success btn-sm"
          v-if="activation.length == 0 && codcierre == 11"
          @click="emitEventActive()"
        >
          <i class="la la-folder-open"></i> Habilitar aviso
        </button>
      </b-col>
    </b-row>
    <transition-group name="fade">
      <div :key="1" class="mt-3" v-show="items.length != 0">
        <b-card no-body>
          <b-tabs card small>
            <b-tab title="Básico" active>
              <b-row class="mt-3">
                <b-col md="12">
                  <gmap-map
                    :center="center"
                    :zoom="zoom"
                    style="width: 100%; height: 300px"
                    ref="map"
                    v-show="items.length != 0"
                  >
                    <gmap-info-window
                      :options="infoOptions"
                      :position="infoWindowPos"
                      :opened="infoWinOpen"
                      @closeclick="infoWinOpen = false"
                    >
                    </gmap-info-window>
                    <gmap-marker
                      :key="i"
                      v-for="(m, i) in markers"
                      :position="m.position"
                      :icon="m.icon"
                      :clickable="true"
                      :animation="2"
                      @click="toggleInfoWindow(m, i)"
                    >
                    </gmap-marker>
                  </gmap-map>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="12" class="mt-3">
                  <DxDataGrid
                    :allow-column-resizing="true"
                    :data-source="ot"
                    :show-borders="true"
                    :show-column-lines="true"
                    :show-row-lines="true"
                    :row-alternation-enabled="true"
                    :columnAutoWidth="true"
                    v-if="ot.length != 0"
                  >
                    <DxPaging :page-size="10" />
                    <DxHeaderFilter :visible="true" />
                    <DxPager
                      :visible="false"
                      infoText="Página {0} of {1} ({2} registros)"
                      :allowed-page-sizes="pageSizes"
                      display-mode="full"
                      :show-page-size-selector="true"
                      :show-info="true"
                      :show-navigation-buttons="true"
                    />
                    <DxColumn data-field="num_ot" caption="N° OT" />
                    <DxColumn
                      data-field="estado"
                      caption="Estado"
                      cell-template="cellTemplate"
                    />

                    <DxColumn data-field="nombre_brigada" caption="Brigada" />
                    <DxColumn
                      data-field="fecha_asigna"
                      caption="Fecha"
                      data-type="date"
                      format="dd/MM/yyyy, HH:mm:ss"
                    />
                    <template #cellTemplate="{ data: cellData }">
                      <div>
                        <i :class="cellData.data.icono"></i>
                        {{ cellData.data.estado }}
                      </div>
                    </template>
                  </DxDataGrid>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <DxDataGrid
                    :allow-column-resizing="true"
                    :data-source="items"
                    :show-borders="true"
                    :show-column-lines="true"
                    :show-row-lines="true"
                    :row-alternation-enabled="true"
                    :columnAutoWidth="true"
                    v-if="items.length != 0"
                  >
                    <DxPaging :page-size="10" />
                    <DxHeaderFilter :visible="true" />
                    <DxPager
                      :visible="false"
                      infoText="Página {0} of {1} ({2} registros)"
                      :allowed-page-sizes="pageSizes"
                      display-mode="full"
                      :show-page-size-selector="true"
                      :show-info="true"
                      :show-navigation-buttons="true"
                    />
                    <DxColumn
                      caption=""
                      type="buttons"
                      cell-template="cellTemplate"
                    />
                    <DxColumn
                      data-field="num_aviso"
                      caption="N° Aviso"
                      alignment="center"
                    />
                    <DxColumn
                      caption="Brigada"
                      cell-template="templateBrigada"
                    />
                    <DxColumn
                      data-field="fecha_alta"
                      caption="Fecha de ingreso"
                      data-type="date"
                      format="dd/MM/yyyy, HH:mm:ss"
                    />
                    <DxColumn
                      data-field="departamento"
                      caption="Departamento"
                    />
                    <DxColumn data-field="municipio" caption="Municipio" />
                    <DxColumn data-field="circuito_sm" caption="Circuito" />
                    <DxColumn data-field="barrio" caption="Barrio" />
                    <DxColumn data-field="dir_suscriptor" caption="Dirección" />
                    <DxColumn
                      data-field="nombre_suscriptor"
                      caption="Cliente"
                    />
                    <template #cellTemplate="{ data: cellData }">
                      <div>
                        <img
                          width="27"
                          :src="`${URL_ICON}${cellData.data.icono}.png`"
                          :alt="
                            cellData.data.icon
                              ? cellData.data.icon.descripcion
                              : 'Icono'
                          "
                        />
                      </div>
                    </template>
                    <template #templateBrigada>
                      <div>
                        {{ ot.length != 0 ? ot[0].nombre_brigada : "" }}
                      </div>
                    </template>
                  </DxDataGrid>
                </b-col>
              </b-row>
            </b-tab>
            <b-tab title="Aviso" v-if="items2.length">
              <b-row>
                <b-col md="12">
                  <DxDataGrid
                    :data-source="items2"
                    :show-borders="true"
                    :show-column-lines="true"
                    :show-row-lines="true"
                    :row-alternation-enabled="true"
                    :columnAutoWidth="true"
                    :allow-column-resizing="true"
                  >
                    <DxSearchPanel
                      :visible="true"
                      :width="240"
                      placeholder="Buscar..."
                    />
                    <DxPaging :page-size="30" />
                    <DxHeaderFilter :visible="true" />
                    <DxFilterRow :visible="true" />
                    <DxPager
                      :visible="true"
                      infoText="Página {0} of {1} ({2} registros)"
                      :allowed-page-sizes="pageSizes"
                      display-mode="full"
                      :show-page-size-selector="true"
                      :show-info="true"
                      :show-navigation-buttons="true"
                    /> </DxDataGrid
                ></b-col>
              </b-row>
            </b-tab>
            <b-tab title="Bitacora" v-if="showBitacora">
              <DxDataGrid
                :data-source="binnacle"
                :show-borders="true"
                :show-column-lines="true"
                :show-row-lines="true"
                :row-alternation-enabled="true"
                :columnAutoWidth="true"
                :allow-column-resizing="true"
              >
                <DxSearchPanel
                  :visible="true"
                  :width="240"
                  placeholder="Buscar..."
                />
                <DxPaging :page-size="10" />
                <DxHeaderFilter :visible="true" />
                <DxFilterRow :visible="true">
                  <DxOperationDescriptions
                    equal="Igual a"
                    notEqual="Diferente de"
                    startsWith="Empieza con"
                    endsWith="Termina con"
                    contains="Contiene"
                    between="Entre"
                    notContains="No contiene"
                    lessThanOrEqual="Menor o igual"
                    greaterThanOrEqual="Mayor o igual"
                    lessThan="Menor que"
                    greaterThan="Mayor que"
                  />
                </DxFilterRow>
                <DxPager
                  :visible="true"
                  infoText="Página {0} of {1} ({2} registros)"
                  :allowed-page-sizes="pageSizes"
                  display-mode="full"
                  :show-page-size-selector="true"
                  :show-info="true"
                  :show-navigation-buttons="true"
                />
                <DxColumn
                  data-field="fecha"
                  caption="Fecha"
                  data-type="date"
                  format="dd/MM/yyyy, HH:mm:ss"
                />
                <DxColumn data-field="detalle" caption="Detalle" />
              </DxDataGrid>
            </b-tab>
            <b-tab
              v-if="Boolean(this.events?.work?.outage || this.events?.workTask?.aviso_id)"
              title="Evento"
            > 
              <b-row>
                <b-row>
                  <OrderEventoTab :work="events?.work" :workTask="events?.workTask" />
                </b-row>
              </b-row>
            </b-tab>
            <b-tab lazy title="Información de precierre" v-if="movil">
              <div v-if="movil_2">
                <div
                  class="alert"
                  :style="`background-color: ${color.background}; color: #FFF;`"
                >
                  {{ movil_2.pregunta ? movil_2.pregunta.pregunta_desc : "" }}
                </div>
                <div>
                  <b>TIPO DE CIERRE:</b>
                  {{ movil_2.cierre ? movil_2.cierre.descripcion : "N/A" }}
                </div>
              </div>
              <hr />
              <div class="form-group" v-if="movil">
                <label>Observación BOL</label>
                <textarea
                  rows="3"
                  :value="movil.observacion"
                  class="mb-3 form-control form-control-sm"
                  readonly
                ></textarea>
              </div>
              <b-row class="mb-3">
                <b-col>
                  <b v-if="movil.accion.length">ACCIONES</b>
                  <DxDataGrid
                    :data-source="movil.accion"
                    :show-borders="true"
                    :show-column-lines="true"
                    :show-row-lines="true"
                    :row-alternation-enabled="true"
                    :columnAutoWidth="true"
                    :allow-column-resizing="true"
                    v-if="movil.accion.length"
                  >
                    <DxSearchPanel
                      :visible="false"
                      :width="240"
                      placeholder="Buscar..."
                    />
                    <DxPaging :page-size="10" />
                    <DxHeaderFilter :visible="true" />
                    <DxFilterRow :visible="true">
                      <DxOperationDescriptions
                        equal="Igual a"
                        notEqual="Diferente de"
                        startsWith="Empieza con"
                        endsWith="Termina con"
                        contains="Contiene"
                        between="Entre"
                        notContains="No contiene"
                        lessThanOrEqual="Menor o igual"
                        greaterThanOrEqual="Mayor o igual"
                        lessThan="Menor que"
                        greaterThan="Mayor que"
                      />
                    </DxFilterRow>
                    <DxPager
                      :visible="false"
                      infoText="Página {0} of {1} ({2} registros)"
                      :allowed-page-sizes="pageSizes"
                      display-mode="full"
                      :show-page-size-selector="true"
                      :show-info="true"
                      :show-navigation-buttons="true"
                    />
                    <DxColumn data-field="descripcion" caption="Descripción" />
                    <DxColumn
                      data-field="fecha_ejecuta"
                      caption="Fecha"
                      data-type="date"
                      format="dd/MM/yyyy, HH:mm:ss"
                    />
                  </DxDataGrid>
                </b-col>
              </b-row>

              <b-row>
                <b-col class="mb-3">
                  <b v-if="movil.causa.length != 0">CAUSAS</b>
                  <DxDataGrid
                    :data-source="movil.causa"
                    :show-borders="true"
                    :show-column-lines="true"
                    :show-row-lines="true"
                    :row-alternation-enabled="true"
                    :columnAutoWidth="true"
                    :allow-column-resizing="true"
                    v-if="movil.causa.length != 0"
                  >
                    <DxSearchPanel
                      :visible="false"
                      :width="240"
                      placeholder="Buscar..."
                    />
                    <DxPaging :page-size="15" />
                    <DxHeaderFilter :visible="true" />
                    <DxFilterRow :visible="true">
                      <DxOperationDescriptions
                        equal="Igual a"
                        notEqual="Diferente de"
                        startsWith="Empieza con"
                        endsWith="Termina con"
                        contains="Contiene"
                        between="Entre"
                        notContains="No contiene"
                        lessThanOrEqual="Menor o igual"
                        greaterThanOrEqual="Mayor o igual"
                        lessThan="Menor que"
                        greaterThan="Mayor que"
                      />
                    </DxFilterRow>
                    <DxPager
                      :visible="false"
                      infoText="Página {0} of {1} ({2} registros)"
                      :allowed-page-sizes="pageSizes"
                      display-mode="full"
                      :show-page-size-selector="true"
                      :show-info="true"
                      :show-navigation-buttons="true"
                    />
                    <DxColumn data-field="titulo" caption="Causa" />
                    <DxColumn data-field="detalle" caption="Descripción" />
                  </DxDataGrid>
                </b-col>
              </b-row>

              <b-row>
                <b-col>
                  <b v-if="movil.reglas_oro.length != 0">REGLAS DE ORO</b>
                  <DxDataGrid
                    :data-source="movil.reglas_oro"
                    :show-borders="true"
                    :show-column-lines="true"
                    :show-row-lines="true"
                    :row-alternation-enabled="true"
                    :columnAutoWidth="true"
                    :allow-column-resizing="true"
                    v-if="movil.reglas_oro.length != 0"
                  >
                    <DxSearchPanel
                      :visible="false"
                      :width="240"
                      placeholder="Buscar..."
                    />
                    <DxPaging :page-size="15" />
                    <DxHeaderFilter :visible="true" />
                    <DxFilterRow :visible="true">
                      <DxOperationDescriptions
                        equal="Igual a"
                        notEqual="Diferente de"
                        startsWith="Empieza con"
                        endsWith="Termina con"
                        contains="Contiene"
                        between="Entre"
                        notContains="No contiene"
                        lessThanOrEqual="Menor o igual"
                        greaterThanOrEqual="Mayor o igual"
                        lessThan="Menor que"
                        greaterThan="Mayor que"
                      />
                    </DxFilterRow>
                    <DxPager
                      :visible="false"
                      infoText="Página {0} of {1} ({2} registros)"
                      :allowed-page-sizes="pageSizes"
                      display-mode="full"
                      :show-page-size-selector="true"
                      :show-info="true"
                      :show-navigation-buttons="true"
                    />
                    <DxColumn data-field="codigo" caption="Código" />
                    <DxColumn data-field="descripcion" caption="Descripción" />
                    <DxColumn
                      data-field="fechaEjecuta"
                      caption="Fecha ejecución"
                      data-type="date"
                      format="dd/MM/yyyy, HH:mm:ss"
                    />
                  </DxDataGrid>
                </b-col>
              </b-row>
            </b-tab>
            <b-tab
              lazy
              title="Materiales"
              v-if="
                retirados &&
                instalados &&
                (retirados.length || instalados.length)
              "
            >
              <b-row v-if="retirados && retirados.length">
                <b-col>
                  <b>MATERIALES RETIRADOS</b>
                  <DxDataGrid
                    :data-source="retirados"
                    :show-borders="true"
                    :show-column-lines="true"
                    :show-row-lines="true"
                    :row-alternation-enabled="true"
                    :columnAutoWidth="true"
                    :allow-column-resizing="true"
                  >
                    <DxGroupPanel
                      :visible="true"
                      emptyPanelText="Arrastre una columna aquí para agrupar"
                    />
                    <DxSearchPanel
                      :visible="false"
                      :width="240"
                      placeholder="Buscar..."
                    />
                    <DxPaging :page-size="15" />
                    <DxHeaderFilter :visible="true" />
                    <DxFilterRow :visible="true">
                      <DxOperationDescriptions
                        equal="Igual a"
                        notEqual="Diferente de"
                        startsWith="Empieza con"
                        endsWith="Termina con"
                        contains="Contiene"
                        between="Entre"
                        notContains="No contiene"
                        lessThanOrEqual="Menor o igual"
                        greaterThanOrEqual="Mayor o igual"
                        lessThan="Menor que"
                        greaterThan="Mayor que"
                      />
                    </DxFilterRow>
                    <DxPager
                      :visible="false"
                      infoText="Página {0} of {1} ({2} registros)"
                      :allowed-page-sizes="pageSizes"
                      display-mode="full"
                      :show-page-size-selector="true"
                      :show-info="true"
                      :show-navigation-buttons="true"
                    />
                    <DxColumn
                      data-field="cod_articulo"
                      caption="Código"
                      alignment="center"
                    />
                    <DxColumn
                      data-field="articulo.descripcion"
                      :group-index="0"
                      caption="Articulo"
                    />
                    <DxColumn
                      data-field="cod_ucc"
                      caption="UCC"
                      alignment="center"
                    />
                    <DxColumn
                      data-field="cantidad"
                      caption="Cantidad"
                      alignment="center"
                    />
                    <DxColumn
                      data-field="created_at"
                      caption="Fecha de creación"
                      data-type="date"
                      format="yyyy/MM/dd HH:mm:ss"
                      alignment="center"
                    />
                    <DxSummary>
                      <DxTotalItem
                        column="cantidad"
                        summary-type="count"
                        display-format="Total: {0}" />
                      <DxGroupItem
                        :align-by-column="true"
                        column="cantidad"
                        summary-type="count"
                        display-format="{0}"
                    /></DxSummary>
                  </DxDataGrid>
                </b-col>
              </b-row>
              <b-row v-else>
                <b-col class="text-center">
                  <img src="assets/img/empty.png" alt="Empty" />
                  <p class="text-muted">No hay materiales retirados</p>
                </b-col>
              </b-row>

              <b-row v-if="instalados && instalados.length">
                <b-col>
                  <b>MATERIALES INSTALADOS</b>
                  <DxDataGrid
                    :data-source="instalados"
                    :show-borders="true"
                    :show-column-lines="true"
                    :show-row-lines="true"
                    :row-alternation-enabled="true"
                    :columnAutoWidth="true"
                    :allow-column-resizing="true"
                  >
                    <DxGroupPanel
                      :visible="true"
                      emptyPanelText="Arrastre una columna aquí para agrupar"
                    />
                    <DxSearchPanel
                      :visible="false"
                      :width="240"
                      placeholder="Buscar..."
                    />
                    <DxPaging :page-size="15" />
                    <DxHeaderFilter :visible="true" />
                    <DxFilterRow :visible="true">
                      <DxOperationDescriptions
                        equal="Igual a"
                        notEqual="Diferente de"
                        startsWith="Empieza con"
                        endsWith="Termina con"
                        contains="Contiene"
                        between="Entre"
                        notContains="No contiene"
                        lessThanOrEqual="Menor o igual"
                        greaterThanOrEqual="Mayor o igual"
                        lessThan="Menor que"
                        greaterThan="Mayor que"
                      />
                    </DxFilterRow>
                    <DxPager
                      :visible="false"
                      infoText="Página {0} of {1} ({2} registros)"
                      :allowed-page-sizes="pageSizes"
                      display-mode="full"
                      :show-page-size-selector="true"
                      :show-info="true"
                      :show-navigation-buttons="true"
                    />
                    <DxColumn data-field="cliente.nombre" caption="Brigada" :group-index="0" />
                    <DxColumn data-field="cod_articulo" caption="Código" />
                    <DxColumn
                      data-field="articulo.descripcion"
                      caption="Articulo"
                    />
                    <DxColumn data-field="cod_ucc" caption="UCC" />
                    <DxColumn
                      data-field="cantidad"
                      caption="Cantidad"
                      alignment="center"
                    />
                    <DxColumn
                      data-field="created_at"
                      caption="Fecha de creación"
                      data-type="date"
                      format="yyyy/MM/dd HH:mm:ss"
                      alignment="center"
                    />
                  </DxDataGrid>
                </b-col>
              </b-row>
              <b-row v-else>
                <b-col class="text-center">
                  <img src="assets/img/empty.png" alt="Empty" />
                  <p class="text-muted">No hay materiales instalados</p>
                </b-col>
              </b-row>
            </b-tab>
            <b-tab
              lazy
              title="Materiales"
              v-if="materiales && materiales.length != 0"
            >
              <b-row>
                <b-col>
                  <b>INFORMACIÓN DE MATERIALES</b>
                  <DxDataGrid
                    :data-source="materiales"
                    :show-borders="true"
                    :show-column-lines="true"
                    :show-row-lines="true"
                    :row-alternation-enabled="true"
                    :columnAutoWidth="true"
                    :allow-column-resizing="true"
                  >
                    <DxSearchPanel
                      :visible="false"
                      :width="240"
                      placeholder="Buscar..."
                    />
                    <DxPaging :page-size="15" />
                    <DxHeaderFilter :visible="true" />
                    <DxFilterRow :visible="true">
                      <DxOperationDescriptions
                        equal="Igual a"
                        notEqual="Diferente de"
                        startsWith="Empieza con"
                        endsWith="Termina con"
                        contains="Contiene"
                        between="Entre"
                        notContains="No contiene"
                        lessThanOrEqual="Menor o igual"
                        greaterThanOrEqual="Mayor o igual"
                        lessThan="Menor que"
                        greaterThan="Mayor que"
                      />
                    </DxFilterRow>
                    <DxPager
                      :visible="false"
                      infoText="Página {0} of {1} ({2} registros)"
                      :allowed-page-sizes="pageSizes"
                      display-mode="full"
                      :show-page-size-selector="true"
                      :show-info="true"
                      :show-navigation-buttons="true"
                    />
                    <DxColumn data-field="codmaterial" caption="Código" />
                    <DxColumn data-field="observaciones" caption="Detalle" />
                  </DxDataGrid>
                </b-col>
              </b-row>
            </b-tab>
            <b-tab title="Galeria" v-if="galeria && galeria.length != 0">
              <b-container fluid class="p-4 bg-secondary">
                <b-row>
                  <b-col
                    md="6"
                    class="mb-3"
                    v-for="(item, index) in galeria"
                    :key="index"
                  >
                    <b-img
                      thumbnail
                      fluid
                      :src="servidor + item.path"
                      :alt="item.nombre"
                    ></b-img>
                  </b-col>
                </b-row>
              </b-container>
            </b-tab>

            <b-tab
              lazy
              title="Datos de certificación"
              v-if="certificate && certificate.length"
            >
              <b-row>
                <b-col>
                  <b>INFORMACIÓN DE CERTIFICACIÓN</b>
                  <DxDataGrid
                    :data-source="certificate"
                    :show-borders="true"
                    :show-column-lines="true"
                    :show-row-lines="true"
                    :row-alternation-enabled="true"
                    :columnAutoWidth="true"
                    :allow-column-resizing="true"
                  >
                    <DxPaging :page-size="30" />
                    <DxHeaderFilter :visible="true" />
                    <DxFilterRow :visible="true">
                      <DxOperationDescriptions
                        equal="Igual a"
                        notEqual="Diferente de"
                        startsWith="Empieza con"
                        endsWith="Termina con"
                        contains="Contiene"
                        between="Entre"
                        notContains="No contiene"
                        lessThanOrEqual="Menor o igual"
                        greaterThanOrEqual="Mayor o igual"
                        lessThan="Menor que"
                        greaterThan="Mayor que"
                      />
                    </DxFilterRow>
                    <DxPager
                      :visible="true"
                      infoText="Página {0} of {1} ({2} registros)"
                      :allowed-page-sizes="pageSizes"
                      display-mode="full"
                      :show-page-size-selector="true"
                      :show-info="true"
                      :show-navigation-buttons="true"
                    />
                  </DxDataGrid>
                </b-col>
              </b-row>
            </b-tab>
            <b-tab v-if="activation.length" title="Datos de activación">
              <b-row>
                <b-col>
                  <b>ACTIVACIÓN DE AVISO</b>
                  <DxDataGrid
                    v-if="activation && activation.length != 0"
                    :data-source="activation"
                    :show-borders="true"
                    :show-column-lines="true"
                    :show-row-lines="true"
                    :row-alternation-enabled="true"
                    :columnAutoWidth="true"
                    :allow-column-resizing="true"
                  >
                    <DxSearchPanel
                      :visible="true"
                      :width="240"
                      placeholder="Buscar..."
                    />
                    <DxPaging :page-size="30" />
                    <DxHeaderFilter :visible="true" />
                    <DxFilterRow :visible="true">
                      <DxOperationDescriptions
                        equal="Igual a"
                        notEqual="Diferente de"
                        startsWith="Empieza con"
                        endsWith="Termina con"
                        contains="Contiene"
                        between="Entre"
                        notContains="No contiene"
                        lessThanOrEqual="Menor o igual"
                        greaterThanOrEqual="Mayor o igual"
                        lessThan="Menor que"
                        greaterThan="Mayor que"
                      />
                    </DxFilterRow>
                    <DxPager
                      :visible="activation.length > 15"
                      infoText="Página {0} of {1} ({2} registros)"
                      :allowed-page-sizes="pageSizes"
                      display-mode="full"
                      :show-page-size-selector="true"
                      :show-info="true"
                      :show-navigation-buttons="true"
                    />

                    <DxColumn data-field="observacion" caption="Observación" />
                    <DxColumn
                      data-field="created_at"
                      caption="Fecha"
                      data-type="date"
                      format="dd/MM/yyyy, HH:mm:ss"
                    />
                  </DxDataGrid>
                </b-col>
              </b-row>
            </b-tab>
            <b-tab title="Aviso pendiente" v-if="pendiente && pendiente.length">
              <b>INFORMACIÓN DE AVISO</b>
              <DxDataGrid
                v-if="pendiente && pendiente.length != 0"
                :data-source="pendiente"
                :show-borders="true"
                :show-column-lines="true"
                :show-row-lines="true"
                :row-alternation-enabled="true"
                :columnAutoWidth="true"
                :allow-column-resizing="true"
              >
                <DxSearchPanel
                  :visible="true"
                  :width="240"
                  placeholder="Buscar..."
                />
                <DxPaging :page-size="30" />
                <DxHeaderFilter :visible="true" />
                <DxFilterRow :visible="true">
                  <DxOperationDescriptions
                    equal="Igual a"
                    notEqual="Diferente de"
                    startsWith="Empieza con"
                    endsWith="Termina con"
                    contains="Contiene"
                    between="Entre"
                    notContains="No contiene"
                    lessThanOrEqual="Menor o igual"
                    greaterThanOrEqual="Mayor o igual"
                    lessThan="Menor que"
                    greaterThan="Mayor que"
                  />
                </DxFilterRow>
                <DxPager
                  :visible="pendiente.length > 15"
                  infoText="Página {0} of {1} ({2} registros)"
                  :allowed-page-sizes="pageSizes"
                  display-mode="full"
                  :show-page-size-selector="true"
                  :show-info="true"
                  :show-navigation-buttons="true"
                />

                <DxColumn
                  data-field="causa"
                  caption="Causa"
                  cell-template="cellTemplate"
                />
                <DxColumn data-field="observacion" caption="Observación" />
                <DxColumn
                  data-field="created_at"
                  caption="Fecha"
                  data-type="date"
                  format="dd/MM/yyyy, HH:mm:ss"
                />

                <template #cellTemplate="{ data: cellData }">
                  <div>
                    {{ cellData.data.causa.causa }}
                  </div>
                </template>
              </DxDataGrid>
            </b-tab>
            <b-tab
              lazy
              title="Motivo rechazos"
              v-if="rechazos && rechazos.length"
            >
              <DxDataGrid
                :data-source="rechazos"
                :show-borders="true"
                :show-column-lines="true"
                :show-row-lines="true"
                :row-alternation-enabled="true"
                :columnAutoWidth="true"
                :allow-column-resizing="true"
              >
                <DxSearchPanel
                  :visible="false"
                  :width="240"
                  placeholder="Buscar..."
                />
                <DxPaging :page-size="15" />
                <DxHeaderFilter :visible="true" />
                <DxFilterRow :visible="true">
                  <DxOperationDescriptions
                    equal="Igual a"
                    notEqual="Diferente de"
                    startsWith="Empieza con"
                    endsWith="Termina con"
                    contains="Contiene"
                    between="Entre"
                    notContains="No contiene"
                    lessThanOrEqual="Menor o igual"
                    greaterThanOrEqual="Mayor o igual"
                    lessThan="Menor que"
                    greaterThan="Mayor que"
                  />
                </DxFilterRow>
                <DxPager
                  :visible="false"
                  infoText="Página {0} of {1} ({2} registros)"
                  :allowed-page-sizes="pageSizes"
                  display-mode="full"
                  :show-page-size-selector="true"
                  :show-info="true"
                  :show-navigation-buttons="true"
                />
                <DxColumn data-field="brigada" caption="Brigada" />
                <DxColumn data-field="motivo.descripcion" caption="Motivo" />
                <DxColumn
                  data-field="observacion"
                  caption="Observación brigada"
                  width="400"
                />
                <DxColumn
                  data-field="fecha_rechazo"
                  caption="Fecha rechazo"
                  data-type="date"
                  format="dd/MM/yyyy, HH:mm:ss"
                />
              </DxDataGrid>
            </b-tab>
          </b-tabs>
        </b-card>
      </div>
      <div :key="2" v-show="isShowEvents">
        <b-card no-body>
          <b-tabs card small>
            <b-tab
              v-if="true"
              title="Avisos"
            > 
              <b-row>
                <b-row>
                  <DxDataGrid
                    :data-source="avisosV2"
                    :show-borders="true"
                    :show-column-lines="true"
                    :show-row-lines="true"
                    :row-alternation-enabled="true"
                    :columnAutoWidth="true"
                    :allow-column-resizing="true"
                  >
                    <DxSearchPanel
                      :visible="true"
                      :width="240"
                      placeholder="Buscar..."
                    />
                    <DxPaging :page-size="30" />
                    <DxHeaderFilter :visible="true" />
                    <DxFilterRow :visible="true" />
                    <DxPager
                      :visible="true"
                      infoText="Página {0} of {1} ({2} registros)"
                      :allowed-page-sizes="pageSizes"
                      display-mode="full"
                      :show-page-size-selector="true"
                      :show-info="true"
                      :show-navigation-buttons="true"
                    /> 

                    <DxColumn
                      data-field="icono_estado"
                      caption="Estado"
                      cell-template="cellTemplate"
                    />
                    <template #cellTemplate="{ data: cellData }">
                      <div>
                        <i :class="cellData.data.ot.icono_estado"></i>
                        {{ cellData.data.ot.desc_estado }}
                      </div>
                    </template>

                    <DxColumn
                      v-for="(header, idx) in headerAvisosV2"
                      :key="idx"
                      :data-field="header.dataField"
                      :caption="header.caption"
                    />
                  </DxDataGrid>
                </b-row>
              </b-row>
            </b-tab>

            <b-tab
              v-if="isShowEvents"
              title="Evento"
            > 
              <b-row>
                <b-row>
                  <OrderEventoTab :work="events?.work" :workTask="events?.workTask" />
                </b-row>
              </b-row>
            </b-tab>
          </b-tabs>  
        </b-card>
      </div>
    </transition-group>
    <template #modal-footer>
      <button class="btn btn-sm btn-link" @click="() => (show = false)">
        <i class="la la-reply"></i> Cancelar
      </button>
    </template>
  </b-modal>
</template>
<style>
.table-response {
  width: 100% !important;
  display: inline-table !important;
}
</style>
<script>
import axios from "axios";
import toastr from "toastr";
import moment from "moment";
import { mapGetters } from "vuex";
import _ from "lodash";
import {
  DxDataGrid,
  DxPaging,
  DxColumn,
  DxSearchPanel,
  DxFilterRow,
  DxHeaderFilter,
  DxPager,
  DxOperationDescriptions,
} from "devextreme-vue/data-grid";
import OrderEventoTab from '@/components/admin/map/modal/OrderEventoTab.vue';

import ENV from "@/environment";
export default {
  name: "search-ot",
  components: {
    DxDataGrid,
    DxPaging,
    DxColumn,
    DxSearchPanel,
    DxFilterRow,
    DxHeaderFilter,
    DxPager,
    DxOperationDescriptions,
    OrderEventoTab,
  },
  data() {
    return {
      servidor:
        process.env.NODE_ENV == "development" ? ENV.SERVER_DEV : ENV.SERVER,
      // "http://138.197.75.131:9000",
      URL_ICON:
        process.env.NODE_ENV == "development" ? ENV.ICONS_DEV : ENV.ICONS,

      pageSizes: [5, 10, 15, 30],
      show: false,
      showBitacora: true,
      isLoading: false,

      criterion: 1,
      busqueda: null,
      otsAll: [],
      ot: [],
      items: [],
      events: null,
      isShowEvents: false,
      avisosV2: [],
      headerAvisosV2: [
        { caption: "Numero OT", dataField: "ot.num_ot" },
        { caption: "Numero Aviso", dataField: "num_aviso" },
        { caption: "Brigada", dataField: "brigada" },
        { caption: "Tipo Aviso", dataField: "tipo_aviso" },
        { caption: "Fecha Asignada", dataField: "ot.fecha_asigna" },
        { caption: "Departamento", dataField: "departamento" },
        { caption: "Municipio", dataField: "municipio" },
        { caption: "Circuito_sm", dataField: "circuito_sm" },
        { caption: "Barrio", dataField: "barrio" },
        { caption: "Direccion", dataField: "dir_suscriptor" },
        { caption: "Observacion", dataField: "ot.observacion" },
      ],

      markers: [],
      zoom: 12,
      center: {
        lat: 10.9784174,
        lng: -74.8144132,
      },
      infoOptions: {
        content: "",
        pixelOffset: {
          width: 0,
          height: -35,
        },
      },
      infoWindowPos: null,
      infoWinOpen: false,
      currentMidx: null,
      binnacle: null,
      movil: {
        causa: [],
        reglas_oro: [],
        accion: [],
      },
      galeria: [],
      materiales: [],
      certificate: [],
      acciones: [],
      activation: [],
      rechazos: [],
      pendiente: [],
      // materiales
      retirados: [],
      instalados: [],

      // ----
      codcierre: null,
      items2: [],
      movil_2: null,
    };
  },
  filters: {
    replace_(values) {
      return values.replace("_", " ");
    },
    upperCaseWithReplace(value) {
      return value.replace(/_/g, " ").toUpperCase();
    },
    upperCase(values) {
      return values.toUpperCase();
    },
    format(values) {
      return moment(values).format("LLL");
    },
    simpleFormat(value) {
      return moment(value).format("l") + " " + moment(value).format("LTS");
    },
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
    color() {
      if (this.movil_2.pregunta_id) {
        let color = {};
        if (this.movil_2.pregunta_id == 1) {
          color = {
            background: "#AD631D",
            color: "#FFF",
          };
        } else if (this.movil_2.pregunta_id == 2) {
          color = {
            background: "#185967",
            color: "#FFF",
          };
        } else if (this.movil_2.pregunta_id == 3) {
          color = {
            background: "#156C5A",
            color: "#FFF",
          };
        } else if (this.movil_2.pregunta_id == 4) {
          color = {
            background: "#9V7910",
            color: "#FFF",
          };
        } else if (this.movil_2.pregunta_id == 5) {
          color = {
            background: "#5B3071",
            color: "#FFF",
          };
        } else if (this.movil_2.pregunta_id == 6) {
          color = {
            background: "#189E85",
            color: "#FFF",
          };
        }

        return color;
      } else {
        return {
          background: "#F3F3F3",
          color: "#333",
        };
      }
    },
  },
  methods: {
    reset() {
      this.isLoading = false;
      this.criterion = 1;
      this.busqueda = null;
      this.items = [];
      this.ot = [];
      this.activation = [];
      this.certificate = [];
      this.movil_2 = null;
    },
    loadAllOT() {
      axios.get("ots").then((res) => (this.otsAll = res.data));
    },
    showCenterMap: function (place) {
      if (place.lat != undefined && place.lng != undefined) {
        this.center = { lat: place.lat, lng: place.lng };
      }
    },
    updateCenter: function (place) {
      this.center = {
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      };
    },
    emitEventActive() {
      this.$emit("openActive", this.ot[0]);
    },
    toggleInfoWindow: function (marker, idx) {
      this.markerSelected = [];
      this.markerSelected.push(marker);
      this.infoWindowPos = marker.position;
      this.infoOptions.content = marker.infoText;

      if (this.currentMidx == idx) {
        this.infoWinOpen = !this.infoWinOpen;
      } else {
        this.infoWinOpen = true;
        this.currentMidx = idx;
      }
    },

    search() {
      this.showBitacora = true;
      if (this.busqueda == null || this.busqueda == "") {
        this.$swal.fire({
          title: "Información",
          text: `Debe diligenciar este campo`,
          icon: "info",
        });
      } else {
        this.isLoading = true;
        let config = {
          criterio: this.criterion,
          busqueda: this.busqueda,
        };
        this.ot = [];
        this.items = [];
        axios
          .post("buscar-trabajos", config)
          .then((res) => {
            let data = res.data;
            if(data.is_event){
              this.events = data.evento;
              this.avisosV2 = data.avisos;
              this.isShowEvents = Boolean(this.events?.work?.outage || this.events?.workTask?.aviso_id);
            } else {
              this.isShowEvents = false;
              this.events = data.evento;
              this.rechazos = data.rechazo;
              this.pendiente = data.historico;
              this.certificate = data.certificate_info;
              this.retirados = data.dato_materiales_retirados;
              this.instalados = data.dato_materiales;
              this.activation = data.activation ? [data.activation] : [];
              if (!data.records.length) {
                toastr.info(data.message);
                this.codcierre = null;
              } else {
                if (data.message == "not asociated to OT") {
                  this.items = data.records;
                  this.markers = [];
                  this.items.map((el) => {
                    this.markers.push({
                      position: {
                        lat: parseFloat(el.latitud),
                        lng: parseFloat(el.longitud),
                      },
                      icon: el.icon ? `${this.URL_ICON}${el.icon}.png` : "",
                    });
                    el.icono = el.icon;
                  });
                }
                if (data.message == "success") {
                  if (data.records.avisos == undefined) {
                    this.ot = data.records;
                    this.items = data.records[0].avisos;
                    this.markers = [];
                    this.items.map((el) => {
                      this.markers.push({
                        position: {
                          lat: parseFloat(el.latitud),
                          lng: parseFloat(el.longitud),
                        },
                        icon: `${this.URL_ICON}${el.icono}.png`,
                      });
                    });
                  } else {
                    this.ot = data.records;
                    this.items = data.records.avisos;
                    this.markers = [];
                    this.items.map((el) => {
                      this.markers.push({
                        position: {
                          lat: parseFloat(el.latitud),
                          lng: parseFloat(el.longitud),
                        },
                        icon: `${this.URL_ICON}${el.icono}.png`,
                      });
                    });
                  }
                }
              }
              this.center =
                this.markers.length != 0 ? this.markers[0].position : this.center;
              this.zoom = 16;
              if (this.ot.length != 0) {
                this.binnacle = res.data.bitacora_ot;
              } else {
                if (res.data.bitacora_aviso) {
                  if (res.data.bitacora_aviso.length != 0) {
                    this.binnacle = res.data.bitacora_aviso;
                  } else {
                    this.showBitacora = false;
                  }
                }
              }
              this.movil = res.data.movil;
              const aux = this.movil;
              this.movil_2 = aux;
              let ref = [],
                cause = [
                  "principal_id",
                  "causa_id",
                  "pregunta_id",
                  "tipo_os",
                  "resultado",
                  "contrata",
                  "tipo_cierre",
                  "obj",
                  "trafo_parcial",
                  "created_at",
                  "updated_at",
                  "deleted_at",
                ];
              if (this.movil) {
                this.movil.causa.map((el) => {
                  for (const key in el) {
                    if (!cause.includes(key) && !_.isObject(el[key])) {
                      ref.push({
                        titulo: key.replace("_", " ").toUpperCase(),
                        detalle: el[key],
                      });
                    }
                    if (key == "pregunta_id") {
                      ref.push({
                        titulo: "¿HUBO DAÑO?",
                        detalle: el[key] == 1 ? "NO" : "SI",
                      });
                    }
                    if (key === "trafo_parcial") {
                      ref.push({
                        titulo: "HAY " + key.replace("_", " ").toUpperCase(),
                        detalle: el[key] == 1 || el[key] == null ? "NO" : "SI",
                      });
                    }
                  }
                });
                this.movil.causa = ref;
              }
  
              this.materiales = res.data.materiales;
              this.acciones = res.data.acciones;
              this.galeria = res.data.anexos.filter((f) => f.path != "");
              this.codcierre =
                res.data.codcierre == undefined ? null : res.data.codcierre;
              this.$emit("openTreeAction", {
                ot: this.ot,
                aviso: this.items,
                iscodCierre: this.codcierre !== undefined,
              });
              this.isLoading = false;
              let cert = [];
              let certCause = [
                "id",
                "ot_id",
                "anexo_cierre_codigo",
                "usuario_con_servicio",
                "causa_codigo",
                "danio",
                "accion",
                "causa_fallida",
                "causal",
                "familia",
                "tipo_incidencia",
                "tipo_instalacion",
                "cod_cierre",
                "animal_aereo",
                "sync",
                "message_response",
                "created_at",
                "updated_at",
                "tipo",
              ];
              this.certificate.map((el) => {
                for (const key in el) {
                  if (!certCause.includes(key) && !_.isObject(el[key])) {
                    cert.push({
                      titulo: key.replaceAll("_", " ").toUpperCase(),
                      detalle: el[key],
                    });
                  }
                  if (key == "animal_name") {
                    if (el[key] !== "" && el[key]) {
                      cert.push({
                        titulo: key.replaceAll("_", " ").toUpperCase(),
                        detalle: el[key],
                      });
                    }
                  }
                  if (key === "usuario_con_servicio") {
                    cert.push({
                      titulo: "USUARIO QUEDA CON SERVICIO?",
                      detalle: el[key] == 1 ? "SI" : "NO",
                    });
                  }
                }
              });
              let causeW = [
                "pivot",
                "id_aviso",
                "tipo_trabajo_id",
                "icon",
                "icono",
                "causa_improcedente",
                "updated_at",
              ];
              let warning = [];
              this.items.map((el) => {
                if (el.pivot.id_aviso_ref == el.id_aviso) {
                  for (const key in el) {
                    if (!causeW.includes(key) && !_.isObject(el[key])) {
                      warning.push({
                        titulo: key.replaceAll("_", " ").toUpperCase(),
                        detalle: el[key],
                      });
                    }
                    if (key == "causa_improcedente") {
                      if (el[key]) {
                        warning.push({
                          titulo: "CAUSA IMPROCEDENTE",
                          detalle: el[key],
                        });
                      }
                    }
                  }
                }
              });
              this.items2 = warning;
              this.certificate = cert;
            }
          })
          .catch((e) => {
            console.log(e);
            this.isLoading = false;
          });
      }
    },
  },
};
</script>
