<template>
  <b-modal
    title="Asignar OT"
    ref="moveot"
    id="moveot"
    v-model="show"
    size="xl"
    no-close-on-backdrop
    scrollable
    hide-header-close
  >
    <b-row>
      <b-col md="6">
        <div class="form-group">
          <Select2 class="select2 mb-3" v-model="worker" :options="techs" />
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <DxDataGrid
          id="TurnsTable"
          :data-source="data"
          :show-borders="true"
          :show-column-lines="true"
          :show-row-lines="true"
          :row-alternation-enabled="true"
          :columnAutoWidth="true"
          :allow-column-resizing="true"
          @selection-changed="onSelectionChanged"
        >
          <DxExport :enabled="true" />
          <DxSelection v-if="!assignUnique" mode="multiple" />
          <DxGroupPanel
            :visible="true"
            emptyPanelText="Arrastre una columna aquí para agrupar"
          />
          <DxSearchPanel :visible="true" :width="240" placeholder="Buscar..." />
          <DxPaging :page-size="15" />
          <DxPager
            :visible="true"
            infoText="Página {0} de {1} ({2} registros)"
            :allowed-page-sizes="[5, 10, 15, 30, 50]"
            display-mode="full"
            :show-page-size-selector="true"
            :show-info="true"
            :show-navigation-buttons="true"
          />
          <DxHeaderFilter :visible="true" />
          <DxColumnFixing :enabled="true" />
          <DxFilterRow :visible="true">
            <DxOperationDescriptions
              equal="Igual a"
              notEqual="Diferente de"
              startsWith="Empieza con"
              endsWith="Termina con"
              contains="Contiene"
              between="Entre"
              notContains="No contiene"
              lessThanOrEqual="Menor o igual"
              greaterThanOrEqual="Mayor o igual"
              lessThan="Menor que"
              greaterThan="Mayor que"
            />
          </DxFilterRow>
          <DxColumn
            data-field="num_ot"
            caption="OT"
            alignment="center"
            :group-index="0"
          />
          <DxColumn data-field="num_aviso" caption="Aviso" alignment="center" />
          <DxColumn data-field="tipo" caption="Tipo" alignment="center" />
          <DxColumn data-field="subestacion" caption="Subestación" />
          <DxColumn
            data-field="circuito_sm"
            caption="Circuito"
            alignment="center"
          />
          <DxColumn
            data-field="nombre_suscriptor"
            caption="Cliente"
            alignment="center"
          />
          <DxColumn
            data-field="dir_suscriptor"
            caption="Dirección"
            alignment="center"
          />
          <DxColumn data-field="estado" caption="Estado" alignment="center" />
        </DxDataGrid>
      </b-col>
    </b-row>
    <template #modal-footer>
      <button @click="() => (show = false)" class="btn btn-sm btn-primary">
        <i class="flaticon-close"></i> Cerrar
      </button>
      <button
        class="btn btn-primary btn-sm btn-success"
        v-if="ots.length && worker != -1"
        @click="moveOT()"
      >
        <i class="la la-exchange"></i>
        {{ isLoading ? "Cargando..." : "Confirmar" }}
      </button>
    </template>
  </b-modal>
</template>
<script>
import axios from "axios";
import moment from "moment";
import * as $ from "jquery";
import {
  DxDataGrid,
  DxPaging,
  DxColumn,
  DxSearchPanel,
  DxFilterRow,
  DxHeaderFilter,
  DxPager,
  DxOperationDescriptions,
  DxExport,
  DxGroupPanel,
  DxSelection,
  DxColumnFixing,
} from "devextreme-vue/data-grid";
import _ from "lodash";
import { mapGetters } from "vuex";
export default {
  name: "trasladar-ot",
  components: {
    DxDataGrid,
    DxPaging,
    DxColumn,
    DxSearchPanel,
    DxFilterRow,
    DxHeaderFilter,
    DxPager,
    DxOperationDescriptions,
    DxExport,
    DxGroupPanel,
    DxSelection,
    DxColumnFixing,
  },
  data() {
    return {
      assignUnique: false,
      show: false,
      data: [],
      worker: -1,
      techs: [],
      ots: [],
      checkAll: false,
      isLoading: false,
      selectedRowsData: [],
      prefix: "",
    };
  },
  filters: {
    upperCase(value) {
      return value.replace(/_/g, " ").toUpperCase();
    },
    formatDate(value) {
      return moment(value).format("LL");
    },
  },
  watch: {
    ots() {
      if (this.ots.length == this.data.length) {
        this.checkAll = true;
      } else {
        this.checkAll = false;
      }
    },
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
  },
  methods: {
    onSelectionChanged({ selectedRowsData }) {
      this.selectedRowsData = selectedRowsData;
      this.ots = this.selectedRowsData.map((f) => {
        return f.num_ot;
      });
      this.ots = _.uniq(this.ots);
    },
    removeTabIndex() {
      setInterval(() => {
        $("#moveot___BV_modal_content_").attr("tabindex", null);
      }, 2000);
    },
    all() {
      if (this.ots.length == this.data.length) {
        this.ots = [];
      } else {
        this.ots = [];
        this.data.map((el) => {
          this.ots.push(el.num_ot);
        });
      }
    },
    loadOT(idx) {
      axios
        .post("get-ots", idx)
        .then((res) => {
          this.data = [];
          res.data.map((el) => {
            el.avisos.map((av) => {
              av.id_ot = el.id_ot;
              av.num_ot = el.num_ot;
              av.estado = el.estado_o_t_s ? el.estado_o_t_s.descripcion : "";
              av.observacion_ot = el.observacion;
              av.fecha_asigna = el.fecha_asigna;
              av.tipo =
                av.tipo_trabajo_id == 1
                  ? "AV"
                  : av.tipo_trabajo_id == 2
                  ? "IN"
                  : "DES";
              this.data.push(av);
            });
          });
        })
        .catch((e) => {
          console.log(e);
        });
    },
    loadTech() {
      axios.post("tecnico-bol-all").then((res) => {
        this.techs = [];
        res.data.map((el) => {
          let movil = el.cliente ? el.cliente.movil : "";
          let placa = el.vehiculo_info ? el.vehiculo_info.placa : "";
          const brigada = `${placa} - ${el.nombre} - ${movil}`;
          this.techs.push({ id: el.usuario, text: brigada });
        });
      });
    },
    moveOT() {
      this.isLoading = true;
      const full_name = this.techs.find((f) => f.id == this.worker);

      axios
        .post("trasladar-trabajos", {
          assign: true,
          ots: this.ots,
          id_estado: 2,
          full_user: full_name.text,
          brigada: this.worker,
          user: `${this.user.usuario} - ${this.user.nombre}`,
        })
        .then((res) => {
          if (!res.data.error) {
            this.isLoading = false;
            this.$emit("moveOTclose", false);
          }
        })
        .catch((e) => {
          console.log(e);
          this.isLoading = false;
        });
    },
  },
};
</script>
