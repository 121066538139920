<template>
    <div>
        <b-row class="justify-content-center">
            <b-col>
                <div class="form-group">
                    <label>RID: <b>{{ work.mRID }}</b> </label>
                </div>
            </b-col>
        </b-row>
        <b-row class="justify-content-center">
            <b-col cols="12">
                <div class="form-group">
                    <label>Descripcion</label>
                    <b-form-textarea 
                        id="textarea" 
                        v-model="work.WorkTask.subject" 
                        placeholder="describa la descripción"
                        rows="3" 
                        max-rows="6"
                    ></b-form-textarea>
                </div>
            </b-col>
        </b-row>
        <b-row class="justify-content-start">
            <b-col cols="4">
                <b-button 
                    variant="primary" 
                    @click="postTask()" 
                    ref="kt_post_task"
                    :disabled="work.WorkTask.subject.length==0"
                >
                    <i class="fa fa-paper-plane"></i> Enviar
                </b-button>
            </b-col>
        </b-row>
    </div>
</template>
<script>
import toastr from "toastr";
import axios from 'axios'
export default {
    data() {
        return {
            work: {
                mRID: "",
                name: "taskrequest",
                WorkTask: {
                    name: "12248",
                    subject: "",
                }
            },
        }
    },
    methods: {
        async postTask() {
            if ( !this.work.mRID || !this.work.name || !this.work.WorkTask.name || !this.work.WorkTask.subject) {
                toastr.info("Todos los campos deben ser diligenciados!");
            } else {
                try {
                    const response = await axios.post(`/works/${this.work.mRID}/worksTasks/create`, { work: this.work });
                    if(response.status === 200) {
                        toastr.success("todo correcto - se asigno correctamente el evneto!");
                        this.work.WorkTask.subject = "";
                        this.$emit('onGetItems', true)
                    }
                } catch (error) {
                    console.log(error);
                    toastr.error("Algo salio mal - comuniquese con soporte!");
                }
            }
        },
    }
}
</script>
