<template>
  <div class="row">
    <div class="col-md-12">
      <div class="card card-custom">
        <div class="card-body map-card">
          <div class="form-group" v-if="PARENT.municipality != null">
            <b-row>
              <b-col md="6">
                <gmap-autocomplete
                  class="form-control form-control-sm mb-1"
                  :value="place"
                  @place_changed="updateCenter"
                />
              </b-col>
              <b-col md="6">
                <b-form-textarea readonly :value="dir_ref" />
              </b-col>
            </b-row>
          </div>
          <gmap-map
            :center="center"
            :zoom="zoom"
            id="gmap-map"
            ref="map"
            @click="locationNew($event)"
          >
            <template>
              <div id="VUE_FILTERS" class="gm-svpc p-3">
                <button
                  v-if="techMarker.length == 0"
                  @click="() => {
                    this.clearIntervalTech();
                    this.loadWorkers();
                  }"
                >
                  <!-- <img style="width: 90%" :src="`${ICON_URL}/filter.png`" /> -->
                  <i class="fa fa-users text-dark"></i>
                </button>
              </div>
            </template>
            <gmap-drawing-manager
              ref="drawingRef"
              v-if="mapMode === 'edit'"
              :rectangle-options="rectangleOptions"
              :polyline-options="polylineOptions"
              :polygon-options="polygonOptions"
              :shapes="shapes"
            >
              <template v-slot="on">
                <drawing-toolbar
                  @drawingmode_changed="on.setDrawingMode($event)"
                  @delete_selection="
                    () => {
                      on.deleteSelection();
                      paths = [];
                      removePath();
                      polygonGeojson = '';
                      markers = [];
                    }
                  "
                  @rightclick="handleClickForDelete"
                  @paths_changed="updateEdited($event)"
                  :options="{
                    fillColor: '#012060',
                    fillOpacity: 0.3,
                    strokeWeight: 2,
                    strokeColor: '#01194A',
                  }"
                  ref="polygon"
                  :paths="paths"
                  :editable="true"
                  :markers="markers"
                  :polygon="warnings.length > 1"
                  @save="save()"
                />
              </template>
            </gmap-drawing-manager>
            <gmap-drawing-manager ref="drawingRef2" :shapes="[]">
              <template>
                <DarkMood
                  id="dark-mood"
                  :options="{
                    fillColor: '#012060',
                    fillOpacity: 0.3,
                    strokeWeight: 2,
                    strokeColor: '#01194A',
                  }"
                />
              </template>
            </gmap-drawing-manager>
            <!--  deleteBrigadeRoute -->
            <template>
              <DeleteBrigadeRoute
                v-if="
                  brigadeData.polylineBrigade.length !== 0 ||
                  brigadeData.markerBrigade.length !== 0
                "
                :onClick="deletePolylineBrigadeRoute"
              />
            </template>
            <gmap-info-window
              :position="infoWindowPos"
              :opened="infoWinOpen"
              @closeclick="infoWinOpen = false"
              style="gmap-info-window-vue"
            >
              <div class="infoWindow" v-if="infoWinContent.technical">
                <div v-html="infoWinContent.infoText"></div>
                <div v-html="infoWinContent.sComplement"></div>
              </div>
              <div class="infoWindow" v-else-if="infoWinContent.warning">
                <div v-html="infoWinContent.infoText"></div>
                <button
                  style="margin: 10px 8px 0px 0px"
                  class="btn btn-sm btn-primary mt-3 window-vue-button"
                  @click="getInfoWindowContent(infoWinContent)"
                >
                  Generar OT
                </button>
              </div>
              <div class="infoWindow" v-else-if="infoWinContent.warningManaged">
                <div v-html="infoWinContent.infoText"></div>
              </div>

              <div class="infoWindow" v-else-if="infoWinContent.new_ubication">
                <div v-html="infoWinContent.infoText"></div>
              </div>
              <div class="infoWindow" v-else-if="infoWinContent.technicalWar">
                <div v-html="infoWinContent.infoText"></div>
                <div class="d-flex">
                  <InfoCertification :infoWinContent="infoWinContent" />
                </div>
              </div>
              <div class="infoWindow" v-else-if="infoWinContent.ot">
                <div v-html="infoWinContent.infoText"></div>
                <div class="d-flex">
                  <Ubicate :infoWinContent="infoWinContent" />
                  <InfoButton :infoWinContent="infoWinContent" />
                  <CertificateButton
                    :enabled="
                      infoWinContent.estado_ot == 5 ||
                      infoWinContent.estado_ot == 9
                    "
                    :info="infoWinContent"
                  />
                </div>
              </div>
              <!-- Avisos lupa verde menu component - avisos vivos en siprem -->
              <div class="infoWindow" v-else-if="infoWinContent.order">
                <div v-html="infoWinContent.infoText"></div>
                <div class="d-flex">
                  <Ubicate :infoWinContent="infoWinContent" />
                  <InfoButton :infoWinContent="infoWinContent" />
                  <CertificateButton
                    :enabled="
                      infoWinContent.estado_ot == 5 ||
                      infoWinContent.estado_ot == 9
                    "
                    :info="infoWinContent"
                  />
                </div>
              </div>
            </gmap-info-window>
            <gmap-marker
              :key="i + '_CERTIF_'"
              v-for="(cert, i) in certifieds"
              :position="cert.position"
              :clickable="true"
              :animation="2"
              :icon="cert.icon"
              @click="toggleInfoWindow(cert, i)"
            />
            <gmap-marker
              :key="i + '_TECH_'"
              v-for="(tech, i) in techMarker"
              :visible="activeTech && tech.visible"
              :position="tech.position"
              :clickable="true"
              :icon="tech.icon"
              @drag="handleMarkerDrag"
              @click="toggleInfoWindow(tech, i)"
            />
            <gmap-cluster
              :minimumClusterSize="5"
              :position="center"
              :clickable="true"
              :zoomOnClick="true"
              :animation="1"
            >
              <gmap-marker
                :key="i + '_OTS_'"
                v-for="(item, i) in ots"
                :position="item.position"
                :clickable="true"
                :animation="2"
                :icon="item.icon"
                @click="toggleInfoWindow(item, i)"
              />
              <gmap-marker
                :key="i + '_ORDER_'"
                v-for="(item, i) in orders"
                :position="item.position"
                :clickable="true"
                :animation="item.animation"
                :icon="item.icon"
                @click="toggleInfoWindow(item, i)"
              />
              <template v-if="markers.length != 0">
                <gmap-marker
                  :key="i"
                  v-for="(m, i) in markers"
                  :position="m.position"
                  :clickable="true"
                  :animation="!m.ind_cli_vip ? 2 : 1"
                  :icon="m.icon"
                  :draggable="m.draggable"
                  @drag="handleMarkerDrag"
                  @click="toggleInfoWindow(m, i)"
                >
                </gmap-marker>
              </template>
              <template v-else>
                <gmap-marker
                  :key="i"
                  v-for="(m, i) in warnings"
                  :position="m.position"
                  :clickable="true"
                  :animation="!m.ind_cli_vip ? 2 : 1"
                  :icon="m.icon"
                  :draggable="m.draggable"
                  @drag="handleMarkerDrag"
                  @click="toggleInfoWindow(m, i)"
                >
                </gmap-marker>
              </template>

              <gmap-polygon
                v-if="paths.length > 0 && PARENT.currentModel"
                :paths="paths"
                :editable="true"
                @paths_changed="updateEdited($event)"
                :options="{
                  fillColor: '#012060',
                  fillOpacity: 0.3,
                  strokeWeight: 2,
                  strokeColor: '#01194a',
                }"
                ref="polygon"
              >
              </gmap-polygon>
            </gmap-cluster>

            <google-kml-layer
              ref="kmlLayer"
              :key="index + '_KML_'"
              v-for="(l, index) in kmlLayers"
              :url="l.url"
              :clickable="false"
            >
            </google-kml-layer>
            <gmap-polyline
              v-bind:path.sync="technicalRoute"
              :options="poly"
              deepWatch
            />
            <gmap-polyline
              v-if="brigadeData.polylineBrigade.length !== 0"
              v-bind:path.sync="brigadeData.polylineBrigade"
              :options="poly"
              deepWatch
            />
            <div v-if="brigadeData.markerBrigade.length !== 0">
              <gmap-marker
                v-for="(brigade, i) in brigadeData.markerBrigade"
                :key="i + '_BRIGADE_'"
                :position="brigade.position"
                :animation="2"
                :clickable="true"
                @click="toggleInfoBrigade(brigade, i)"
              >
              </gmap-marker>
            </div>
          </gmap-map>

          <b-modal
            ref="reubication"
            title="Actualizar información"
            no-close-on-backdrop
            size="lg"
          >
            <div class="d-block text-center" v-if="infoNotLocated.length != 0">
              <table class="table table-sm table-hover table-bordered">
                <thead>
                  <tr>
                    <th colspan="2">Información de aviso</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Id</td>
                    <td>{{ infoNotLocated[0].id_aviso }}</td>
                  </tr>
                  <tr>
                    <td>N° Aviso</td>
                    <td>{{ infoNotLocated[0].num_aviso }}</td>
                  </tr>
                  <tr>
                    <td>Suscriptor</td>
                    <td>{{ infoNotLocated[0].nombre_suscriptor }}</td>
                  </tr>
                  <tr>
                    <td>Dirección</td>
                    <td>{{ infoNotLocated[0].dir_suscriptor }}</td>
                  </tr>
                  <tr>
                    <td>Dirección referencia</td>
                    <td>{{ infoNotLocated[0].dir_ref }}</td>
                  </tr>
                  <tr>
                    <td>Tipo de aviso</td>
                    <td>{{ infoNotLocated[0].tipo_aviso }}</td>
                  </tr>
                  <tr>
                    <td>Observación</td>
                    <td>{{ infoNotLocated[0].observacion }}</td>
                  </tr>
                  <tr>
                    <td>Ubicación GPS</td>
                    <td>
                      Lat: {{ infoNotLocated[0].latitud }} <br />
                      Long: {{ infoNotLocated[0].longitud }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <b-alert show variant="primary" v-else>
              No hay información
            </b-alert>
            <template #modal-footer>
              <div class="form-group">
                <b-button @click="hide()" size="sm" variant="link" class="mr-1">
                  <i class="fa fa-eye-slash"></i> Cerrar
                </b-button>
                <a
                  class="btn btn-sm btn-primary"
                  @click="ubicateAdvertisement()"
                  ref="button_ubicate"
                >
                  <i class="fa fa-map-marker-alt"></i>
                  {{ !isLoading ? "Ubicar aviso" : "Actualizando..." }}
                </a>
              </div>
            </template>
          </b-modal>

          <b-modal
            ref="assignation"
            id="assignation"
            title="Asignación de OT"
            no-close-on-backdrop
            size="xl"
            hide-footer
          >
            <div class="d-block text-center">
              <Info
                ref="Info"
                @generateOT="generateOT($event)"
                :info="markerSelected"
                :workers="workers"
                @resetMarker="resetMarker($event)"
              />
            </div>
          </b-modal>

          <!-- Formulario para causas Improcendentes -->
          <ModalImproperWarning ref="CausasImprocedentes" id="modalCausas" />
          <!-- Busqueda de avisos vivos en el mapa - avisos no certificados -->
          <SearchOrders
            ref="searchOrders"
            @emitOrders="emitOrders($event)"
            @deleteOrdersMap="deleteOrdersMap($event)"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<style>
@import url("../../helpers/css/proccessWorks.css");
</style>
<script>
import _ from "lodash";
import axios from "axios";
import toastr from "toastr";
import GmapCluster from "vue2-google-maps/dist/components/cluster";
import { mapGetters, mapActions } from "vuex";
function closeLoop(path) {
  return path.concat(path.slice(0, 1));
}

import drawingToolbar from "../../map/editMoodTopbar.vue";
import DarkMood from "@/components/map/DarkMood.vue";
import Info from "./processInfo.vue";
import ModalImproperWarning from "../management/modals/ModalImproperWarning.vue";
import * as $ from "jquery";
import moment from "moment";
import ENV from "@/environment.js";
const BASE_URL_KML =
  "http://138.197.75.131:9000/serviciosCLD/GeneradorKML/Subestaciones";
// const
//         // BASE_URL_LAYERS = 'http://138.197.75.131:9000/test',
//         BASE_URL_LAYERS = `https://cartadi.com/marcadores`,
//         END_URL_LAYERS = `dummy="+${(new Date()).getTime()}`

import infoWinTech from "@/assets/content/operative/infoWinTech";
import firstInfoTech from "@/assets/content/operative/firstInfoTech";
import infoWindowOT from "@/assets/content/operative/infoWindowOT";
import infoWarningManaged from "@/assets/content/operative/infoWarningManaged";
import infoWindoBCOT from "@/assets/content/operative/infoWindoBCOT";
import stylesOptions from "@/assets/content/cleanMap";
import darkMoodMap from "@/assets/content/darkMoodMap";

import infoWindoCertOT from "@/assets/content/operative/infoWindoCertOT";
import infoWinBeforeCircuit from "@/assets/content/operative/infoWinBeforeCircuit";
import infoWinBeforeSubstation from "@/assets/content/operative/infoWinBeforeSubstation";
import infoWinLocationNew from "@/assets/content/operative/infoWinLocationNew";

import DeleteBrigadeRoute from "../../map/DeleteBrigadeRoute.vue";
import SearchOrders from "./modals/SearchOrders.vue";

const symbolOne = {
  path: 0,
  strokeColor: "#0B945C",
  fillColor: "#0B945C",
  fillOpacity: 1,
  strokeWeight: 2,
};
const symbolMedium = {
  path: 1,
  strokeColor: "#0B5C6E",
  fillColor: "#0B5C6E",
  strokeWeight: 3,
  fillOpacity: 1,
};

const symbolEnd = {
  path: 0,
  strokeColor: "#C42E10",
  rotation: 45,
  strokeWeight: 2,
};

function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}
async function showCertifiedOT(data) {
  data.sectores = JSON.parse(sessionStorage.sectores);
  return await axios.post("ots-certificadas", data);
}
// ICONS_DEV - ICONS
const ICONS_CLD =
  process.env.NODE_ENV == "development" ? ENV.ICONS_DEV : ENV.ICONS;
import Ubicate from "./helpers/UbicateTree.vue";
import InfoButton from "./helpers/InfoButton.vue";
import CertificateButton from "./helpers/CertificateButton.vue";
import InfoCertification from "./helpers/InfoCertification.vue";
export default {
  components: {
    "gmap-cluster": GmapCluster,
    Info,
    drawingToolbar,
    DarkMood,
    ModalImproperWarning,
    DeleteBrigadeRoute,
    SearchOrders,
    Ubicate,
    InfoButton,
    CertificateButton,
    InfoCertification,
  },
  mounted() {
    this.deleteTabIndex();

    this.loadWorkers();
    this.mapDefault();
    try {
      if (!this.getSectores.length) {
        this.setSectors(JSON.parse(sessionStorage.sectores));
      }
    } catch (error) {
      console.log(error);
    }
    // this.kmlLayers = [
    //   {
    //     url: "https://sipremsol.co/public/img/icons_cld/example/exampleBarrios.kml",
    //   },
    // ];
  },
  data() {
    return {
      ICON_URL: ICONS_CLD,
      mapMode: null,
      mapDraggable: true,
      mapCursor: null,
      kmlLayers: [],
      shapes: [],
      poly: {
        strokeColor: "rgba(29, 166, 197, 0.7)",
        strokeWeight: 2,
        strokeOpacity: 1.0,
        icons: [
          {
            icon: symbolOne,
            offset: "0%",
          },
          {
            icon: symbolMedium,
            offset: "25%",
          },
          {
            icon: symbolMedium,
            offset: "50%",
          },
          {
            icon: symbolMedium,
            offset: "75%",
          },
          {
            icon: symbolEnd,
            offset: "100%",
          },
        ],
      },

      rectangleOptions: {
        fillColor: "#012060",
        fillOpacity: 0.4,
        strokeWeight: 2,
        strokeColor: "#01194A",
        draggable: false,
        editable: false,
        clickable: true,
      },
      polylineOptions: {
        fillColor: "#012060",
        fillOpacity: 0.3,
        strokeWeight: 2,
        strokeColor: "#01194A",
        draggable: true,
        editable: true,
        clickable: true,
      },
      polygonOptions: {
        fillColor: "#012060",
        fillOpacity: 0.4,
        strokeWeight: 2,
        strokeColor: "#01194A",
        draggable: false,
        editable: false,
        clickable: true,
      },
      zoom: 12,
      center: {
        lat: 10.9784174,
        lng: -74.8144132,
      },
      infoWindowPos: null,
      infoWinOpen: false,
      currentMidx: null,

      infoOptions: {
        content: "",
        pixelOffset: {
          width: 0,
          height: -35,
        },
      },
      markers: [],
      items: [],
      edited: null,
      paths: [],
      mvcPaths: null,
      errorMessage: null,
      polygonGeojson: "",
      check: null,
      otsAll: [],
      markerSelected: [],
      workers: [],
      isSearching: false,
      depData: sessionStorage.cor,

      // data for ubication of new markers -> not located
      infoNotLocated: [],
      place: null,
      dir_ref: null,

      isLoading: false,
      polygon: false,
      selected: [],
      techMarker: [],
      infoWinContent: false,
      technicalRoute: [],
      dates: {
        fechai: moment().format("YYYY-MM-DDT08:00:ss"),
        fechaf: moment().format("YYYY-MM-DDTHH:mm:ss"),
      },
      certifieds: [],
      itemsCert: [],
      ots: [],

      // orders - son los avisos que no han sido certificados - nueva funcionalidad
      // avisos no certificados que sólo se verán en la zona seleccionada y bajo los parametros de busqueda
      // - sector filtrado y zona visible actual del mapa
      orders: [],
      techInterval: null,
      sUbication: "",
    };
  },
  watch: {
    polygonPaths: _.throttle(function (paths) {
      if (paths) {
        this.paths = paths;
        this.markerSelected = this.geoMarkerSize();
        this.polygonGeojson = JSON.stringify(
          {
            type: "Polygon",
            coordinates: this.paths.map((path) =>
              closeLoop(path.map(({ lat, lng }) => [lng, lat]))
            ),
          },
          null,
          1
        );
      }
    }, 1000),

    dep(newValue) {
      this.dep = newValue;
      this.loadMarker();
    },

    mapMode(newMode, oldMode) {
      if (newMode === "ready") {
        if (oldMode === "edit") {
          this.mapDraggable = true;
          this.mapCursor = null;
          return;
        }
      }

      if (newMode === "edit") {
        this.mapDraggable = false;
        this.mapCursor = "default";
      }
    },
    newMarker() {
      this.place = this.municipality;
      this.dir_ref = this.ref;
      this.markers = this.warnings;
      this.mapMode = this.newMarker ? "edit" : "ready";
      if (this.newMarker != null) {
        switch (this.PARENT.case) {
          case "assign_ot":
            this.markers = [];
            this.paths = [];
            this.beforeCreationOT(this.newMarker);
            break;

          case "circuit":
            this.beforeCircuit(this.newMarker);
            this.markers = [];
            break;

          case "subestacion_avisos":
            this.beforeSubstation(this.newMarker);
            this.markers = [];
            break;

          case "sector_avisos":
            this.beforeSubstation(this.newMarker);
            this.markers = [];
            break;

          case "technical":
            this.mapMode = "ready";
            this.beforeTech(this.newMarker);
            break;

          case "aviso_gestionado":
            this.beforeShowWarningManaged();
            break;
          case "circuito_aviso_gestionado":
            this.beforeShowWarningManaged();
            break;
          case "subestacion_aviso_gestionado":
            this.beforeShowWarningManaged();
            break;
          case "ot_managed_tree":
            this.removePath();
            this.mapMode = "ready";
            this.loadInformationOT(this.newMarker);
            break;
          case "CIRCUITO_OT":
            this.removePath();
            this.mapMode = "ready";
            this.loadInformationOT(this.newMarker);
            this.infoWinOpen = false;
            break;
          case "SUBESTACION_OT":
            this.removePath();
            this.mapMode = "ready";
            this.loadInformationOT(this.newMarker);
            break;
          case "trabajos_particular":
            this.removePath();
            this.mapMode = "ready";
            this.loadInformationOT(this.newMarker);
            break;

          case "OTTECH":
            this.removePath();
            this.mapMode = "ready";
            this.loadInformationOT(this.newMarker);
            break;
          case "aviso_pendiente":
            this.removePath();
            this.mapMode = "ready";
            this.loadInformationOT(this.newMarker);
            break;

          default:
            break;
        }
      }

      if (this.newMarker == null && this.mapMode == "ready") {
        this.markers = [];
        this.kmlLayers = [];
        if (this.case === "subestacion_avisos") {
          this.zoom = 13;
        }
        this.infoWinOpen = false;
      }
    },
    municipality() {
      if (this.municipality == null) {
        this.mapMode = "ready";
        this.markers = [];
      } else {
        this.mapMode = "edit";
      }
    },
    kml() {
      if (this.PARENT.kml.length == 0) {
        this.kmlLayers = [];
      } else {
        let kmls = [];
        this.PARENT.kml.map((k) => {
          kmls.push(k.url);
        });
        // if(this.kmlLayers.length !== 0 ) {
        if (this.kmlLayers.length != 0) {
          // console.log(kmls);
          let fk = this.kmlLayers.filter((f) => kmls.includes(f.url));
          fk.map((f) => {
            _.remove(this.kmlLayers, function (n) {
              return n.url == f.url;
            });
          });

          this.kmlLayers = _.union(this.PARENT.kml, this.kmlLayers);
        } else {
          this.kmlLayers = this.PARENT.kml;
        }
        this.kmlLayers.map((el) => {
          let kml = new window.google.maps.KmlLayer(el.url);
          kml.setMap(this.$refs.map);
          this.zoom = 12;
        });
        // }
      }

      this.zoom = 12;
    },
    getSearchCert() {
      if (this.getSearchCert) {
        var cornersMap = [];
        let data = this.getSearchCert;
        this.$refs.map.$mapPromise.then(() => {
          const bounds = this.$refs.map.$mapObject.getBounds();
          const NE = bounds.getNorthEast();
          const SW = bounds.getSouthWest();
          cornersMap = [
            [
              {
                lat: NE.lat(),
                lng: NE.lng(),
              },
              {
                lat: NE.lat(),
                lng: SW.lng(),
              },
              {
                lat: SW.lat(),
                lng: SW.lng(),
              },
              {
                lat: SW.lat(),
                lng: NE.lng(),
              },
            ],
          ];
          this.setSearching(true);

          showCertifiedOT(data)
            .then((res) => {
              this.setSearching(false);
              const markers = (data, icon) => {
                let mak = [];
                if(data) {
                  data.map((el) => {
                    mak.push({
                      id: el.id_aviso,
                      position: {
                        lat: parseFloat(el.latitud),
                        lng: parseFloat(el.longitud),
                      },
                      sector: el.sector,
                      address: el.dir_suscriptor,
                      description: el.observacion,
                      priority: el.ind_peligro,
                      ordenNumber: el.num_aviso,
                      dir_ref: el.dir_ref,
                      icono: el.icono,
                      technicalWar: true,
                      id_ot: el.id_ot,
                      type: el.tipo_trabajo,
                      ind_cli_vip: el.ind_cli_vip == 0 ? false : true,
                      icon: el.tipo_aviso ? `${ICONS_CLD}${icon}.png` : "",
                      // <span style="color: #F64E60; font-weight: bold;">#:</span> ${el.avisos_o_t_s.id_aviso} <br>
                      infoText: infoWindoCertOT(el),
                    });
                  });
                }
                return mak;
              };
              // validamos que la información exista
              if (res.data) {

                let all = [];
                // filtramos las certificadas
                const certificadas = markers(
                  res.data.certificadas,
                  "certified"
                );
                // las agregamos al array 
                all.push(...certificadas);
                // filtramos las fallidas
                const fallidas = markers(res.data.fallidas, "fallida");
                // las agregamos al array
                all.push(...fallidas);
                // filtramos las improcedentes
                const improcedentes = markers(res.data.improcedentes,
                  "improcedente"
                );
                // las agregamos al array
                all.push(...improcedentes);

                // itemCert es la variable que filtra en el zoom
                var polygon = new window.google.maps.Polygon({
                  paths: cornersMap,
                });
                
                this.itemsCert = all;
                this.certifieds = all;
                const markersInsidePolygon = this.itemsCert.filter((marker) =>
                  window.google.maps.geometry.poly.containsLocation(
                    marker.position,
                    polygon
                  )
                );
                var sectores = this.getSectores;
                this.certifieds = markersInsidePolygon.filter((r) =>
                  sectores.includes(r.sector)
                );
                const header = this.$parent.$children[2];
                const topbar = header.$children[header.$children.length - 1];
                topbar.avisos = this.certifieds;

                this.certifieds = markersInsidePolygon;
                if (this.certifieds.length == 0) {
                  toastr.info("No se ha encontrado OT para este rango de fechas");
                  this.showCertificatedAction(false);
                }
              } else {
                toastr.info("No se ha encontrado OT certificadas para esta zona");
                this.showCertificatedAction(false);
              }
            })
            .catch((e) => {
              console.log(e);
            });
        });
      } else {
        this.certifieds = [];
        this.infoWinOpen = false;
      }
    },
    getDeleteImproper() {
      if (this.getDeleteImproper) {
        this.ots = [];
        this.infoWinOpen = false;
        this.setIdxAll([]);
      }
    },
    dark() {
      setTimeout(() => {
        this.$refs.map.$mapPromise.then((map) => {
          map.setOptions({
            styles: this.dark ? darkMoodMap : stylesOptions,
          });
        });
      }, 500);
    },
    getSectores() {
      clearInterval(this.techInterval);
      this.loadWorkers();
    },
  },
  computed: {
    kml() {
      return this.PARENT.kml;
    },
    polygonPaths: function () {
      if (!this.mvcPaths) return null;

      let paths = [];
      for (let i = 0; i < this.mvcPaths.getLength(); i++) {
        let path = [];
        for (let j = 0; j < this.mvcPaths.getAt(i).getLength(); j++) {
          let point = this.mvcPaths.getAt(i).getAt(j);
          path.push({ lat: point.lat(), lng: point.lng() });
        }
        paths.push(path);
      }
      return paths;
    },
    ...mapGetters({
      idWar: "location/getIds",
      warnings: "location/getWarning",
      authenticated: "auth/authenticated",
      user: "auth/user",
      tech: "technical/getTecnical",
      techId: "technical/getTechId",
      getIds: "location/getIds",
      getIdx: "warningsOT/getIdx",
      getDeleteImproper: "warningsOT/getDeleteImproper",
      markersFromOt: "warningsOT/getInformationOT",
      getTecnical: "technical/getTecnical",
      getSearchCert: "warningsOT/getSearchCert",
      brigadeData: "technical/getBrigadeData",
      activeTech: "technical/getActive",
      dark: "mode/getTheme",
      getSectores: "auth/getSectores",
    }),
    PARENT() {
      return this.$root.$children[0];
    },
    dep: {
      get() {
        return this.$parent.dep
          ? this.$parent.dep
          : sessionStorage.getItem("cor");
      },
      set(newVal) {
        this.depData = newVal;
      },
    },
    newMarker() {
      return this.PARENT.newMarker;
    },
    case() {
      return this.PARENT.case;
    },
    municipality: {
      get() {
        return this.PARENT.municipality;
      },
      set(val) {
        this.municipality = val;
      },
    },
    ref: {
      get() {
        return this.PARENT.dir_ref;
      },
      set(val) {
        this.dir_ref = val;
      },
    },
    locationMarker() {
      return this.markers;
    },
  },

  methods: {
    ...mapActions({
      changeValue: "location/changeValue",
      resetWarning: "location/resetWarning",
      changeIds: "location/changeIds",
      changeWarningManaged: "location/changeWarningManaged",
      loadWarningFromOT: "warningsOT/loadWarningFromOT",
      changeValueTech: "technical/changeValueTech",
      showCertificatedAction: "warningsOT/showCertificatedAction",
      setIdxAll: "warningsOT/setIdxAll",
      resetInformationOT: "warningsOT/resetInformationOT",
      clearBrigadeData: "technical/clearBrigadeData",
      setSectors: "auth/setSectors",
      setSearching: "warningsOT/setSearching",
    }),
    deleteOrdersMap(event) {
      this.orders = event;
      this.infoWinOpen = false;
    },
    emitOrders(event) {
      const orders = event.orders;
      let mak = [];
      this.$refs.map.$mapPromise.then(() => {
        const bounds = this.$refs.map.$mapObject.getBounds();
        const NE = bounds.getNorthEast();
        const SW = bounds.getSouthWest();
        var cornersMap = [
          [
            {
              lat: NE.lat(),
              lng: NE.lng(),
            },
            {
              lat: NE.lat(),
              lng: SW.lng(),
            },
            {
              lat: SW.lat(),
              lng: SW.lng(),
            },
            {
              lat: SW.lat(),
              lng: NE.lng(),
            },
          ],
        ];
        var polygon = new window.google.maps.Polygon({
          paths: cornersMap,
        });
        orders.map((ot) => {
          ot.avisos.map((el) => {
            if (el.pivot.id_aviso_ref == el.id_aviso) {
              if (this.getSectores.includes(el.sector)) {
                mak.push({
                  id: el.id_aviso,
                  id_ot: ot.id_ot,
                  position: {
                    lat: parseFloat(el.latitud),
                    lng: parseFloat(el.longitud),
                  },
                  address: el.dir_suscriptor,
                  description: el.observacion,
                  priority: el.ind_peligro,
                  ordenNumber: el.num_aviso,
                  dir_ref: el.dir_ref,
                  icono: el.icono,
                  order: true,
                  num_ot: ot.num_ot,
                  estado_ot: ot.id_estado,
                  animation: 2,
                  type: el.tipo_trabajo,
                  ind_cli_vip: el.ind_cli_vip == 0 ? false : true,
                  icon: el.path_icono,
                  infoText: infoWindowOT(ot, el),
                });
              }
            }
          });
          //corregir aqui
          this.mapMode = "ready";
        });
        const markersInsidePolygon = mak.filter((marker) =>
          window.google.maps.geometry.poly.containsLocation(
            marker.position,
            polygon
          )
        );
        this.orders = markersInsidePolygon;
        this.$refs.searchOrders.counter = markersInsidePolygon.length;
      });
    },
    deleteTabIndex() {
      setInterval(() => {
        $("#assignation___BV_modal_content_").attr("tabindex", null);
      }, 2000);
    },
    onKML(data) {
      this.kmlLayers = data;
    },
    async beforeTech(item) {
      if (item == "technicalOff") {
        this.technicalRoute = [];
        this.resetWarning([]);
        this.infoWinOpen = false;
        this.techMarker = [];
        this.techMarker = this.getTecnical;
        this.techMarker.map((e) => {
          e.visible = e.status == 1;
        });
        this.markers = [];
        this.workers = [];
        this.zoom = 14;
        if (this.dep == "ATLANTICO") {
          this.center = { lat: 10.9784174, lng: -74.8144132 };
        }
      } else {
        this.infoWinOpen = false;

        const filt = this.workers.filter((f) => f.usuario == item);
        const filt2 = this.getTecnical.filter((f) => f.usuario == item);
        const newCenter = filt.length == 0 ? filt2 : filt;

        this.techMarker = newCenter;
        if (newCenter.length) {
          const [info] = newCenter;
          info.visible = true;
          if (info.position) {
            this.center = info.position;
            const [Tmaker] = this.techMarker;
            this.toggleInfoWindow(Tmaker, 0);
          } else {
            toastr.info("Esta brigada no tiene una posición GPS");
          }
          this.zoom = 13;
          this.infoWinOpen = true;

          if (info.ot && !info.ot.length) {
            toastr.options = {
              closeButton: true,
            };
            toastr.info("Esta brigada no tiene ordenes asignadas");
          } else {
            let mak = [];
            this.technicalRoute = [];
            console.log(info);
            if (info.ot) {
              info.ot.map((orden) => {
                orden.avisos.map((el) => {
                  const aviso = el.avisos_o_t_s;
                  if (el.id_aviso == el.id_aviso_ref) {
                    this.technicalRoute.push({
                      lat: parseFloat(aviso.latitud),
                      lng: parseFloat(aviso.longitud),
                    });
                    mak.push({
                      id: aviso.id_aviso,
                      position: {
                        lat: parseFloat(aviso.latitud),
                        lng: parseFloat(aviso.longitud),
                      },
                      address: aviso.dir_suscriptor,
                      description: aviso.observacion,
                      priority: aviso.ind_peligro,
                      ordenNumber: aviso.num_aviso,
                      id_ot: orden.id_ot,
                      dir_ref: aviso.dir_ref,
                      icono: aviso.icono,
                      technicalWar: true,
                      type: aviso.tipo_trabajo,
                      ind_cli_vip: aviso.ind_cli_vip == 0 ? false : true,
                      icon: aviso.tipo_aviso
                        ? `${ICONS_CLD}${aviso.tipo_aviso
                            .replace(/ /g, "_")
                            .toLowerCase()}.png`
                        : "",
                      infoText: infoWinTech(orden, aviso),
                    });
                  }
                });
              });
            }
            this.changeWarningManaged(mak);
            this.markers = this.warnings;
            // }
          }
        } else if (newCenter.length == 0) {
          this.zoom = 6;
          this.techMarker = this.getTecnical;
          toastr.warning(
            "la brigada no se encuentra disponible en estos momentos"
          );
        }
      }
    },
    loadInformationOT(id) {
      if (id.length != 0) {
        axios.post(`get-ots`, { ots: id }).then((res) => {
          res.data.map((ot) => {
            this.showGeoJson(ot.geojson);
            let mak = [];
            ot.avisos.map((el) => {
              if (el.pivot.id_aviso_ref == el.id_aviso) {
                mak.push({
                  id: el.id_aviso,
                  id_ot: ot.id_ot,
                  position: {
                    lat: parseFloat(el.latitud),
                    lng: parseFloat(el.longitud),
                  },
                  address: el.dir_suscriptor,
                  description: el.observacion,
                  priority: el.ind_peligro,
                  ordenNumber: el.num_aviso,
                  num_ot: ot.num_ot,
                  dir_ref: el.dir_ref,
                  icono: el.icono,
                  estado_ot: ot.id_estado,
                  ot: true,
                  type: el.tipo_trabajo,
                  ind_cli_vip: el.ind_cli_vip == 0 ? false : true,
                  icon: el.icon ? this.URL_ICONS(el.icon) : "",
                  infoText: infoWindowOT(ot, el),
                });
              }
            });
            this.center = mak[0].position;
            this.zoom = 14;
            this.loadWarningFromOT(mak);
            //corregir aqui
            this.ots = this.markersFromOt;

            this.toggleInfoWindow(
              this.ots[this.ots.length - 1],
              this.ots.length
            );
            this.mapMode = "ready";
            if (this.case == "CIRCUITO_OT" || this.case == "SUBESTACION_OT") {
              this.infoWinOpen = false;
            }
          });
        });
      } else {
        this.infoWinOpen = false;
        this.zoom = 12;
        this.ots = [];
      }
    },
    mapDefault() {
      this.markers = [];
      this.$refs.map.$mapPromise.then((map) => {
        map.setOptions({
          styles: stylesOptions,
        });
      });

      if (this.cor !== "ATLANTICO") {
        this.center = {
          lat: 10.983352,
          lng: -74.817278,
        };
      } else {
        this.center = {
          lat: 11.244573,
          lng: -74.199309,
        };
      }
    },
    async loadWorkers() {
      let first = [];
      var data = {
        sectores: this.getSectores,
      };
      let response = await axios.post("brigadas");

      response.data = response.data.filter(
        (r) => data.sectores.includes(r.delegacion) && r.vehiculo_info
      );
      const load = () => {
        response.data.map((worker) => {
          if (
            worker.ultima_posicion &&
            worker.cliente &&
            worker.usuario_sesion
          ) {
            first.push({
              ot: worker.ots,
              id: worker.id,
              sector: worker.delegacion,
              codempresa: worker.codempresa,
              nombre: worker.nombre,
              telefono: worker.telefono,
              usuario: worker.usuario,
              text: `${worker.cliente.vehiculo} - ${worker.cliente.nombre} - ${worker.cliente.movil}`,
              technical: true,
              usuario_sesion: worker.usuario_sesion,
              token: worker.token,
              ultima_posicion: worker.ultima_posicion,
              position: {
                lat: parseFloat(worker.ultima_posicion.latitud),
                lng: parseFloat(worker.ultima_posicion.longitud),
              },
              status: worker.usuario_sesion.estado,
              visible: worker.usuario_sesion.estado == 1,
              icon:
                worker.vehiculo_info && worker.usuario_sesion.estado == 1
                  ? `${ICONS_CLD}tipo/${worker.vehiculo_info.tipo_vehiculo
                      .replace(/ /g, "")
                      .toLowerCase()}.png`
                  : `${ICONS_CLD}tipo/fuera_de_turno.png`,
              infoText: firstInfoTech(worker),
            });
          }
        });

        this.zoom = 13;
        this.workers = first;

        this.techMarker = [];
        this.techMarker = first;
        this.changeValueTech(first);
      };
      load();

      this.techInterval = setInterval( () => {
        load();
      }, 120000);
    },
    clearIntervalTech() {
      clearInterval(this.techInterval);
    },
    hide() {
      this.$refs["reubication"].hide();
    },
    ubicateAdvertisement() {
      const submitButton = this.$refs.button_ubicate;
      submitButton.classList.add(
        "disabled",
        "spinner",
        "spinner-light",
        "spinner-left"
      );
      this.isLoading = !this.isLoading;
      let data = this.infoNotLocated[0];
      delete data.icono;
      delete data.tipo_trabajo;
      data.bitacora = true;
      data.descripcion = `NOVEDAD: EL USUARIO [${this.user.nombre}] HA ASIGNADO UNA POSICIÓN GPS AL AVISO`;

      axios
        .put("avisos/" + this.newMarker, data)
        .then((res) => {
          if (res.data.message == "success") {
            this.place = null;
            toastr.success("Aviso actualizado");
            this.hide();
          }
          this.infoWinOpen = false;
          this.isLoading = !this.isLoading;
          submitButton.classList.remove(
            "disabled",
            "spinner",
            "spinner-light",
            "spinner-left"
          );
          this.$emit("loadMenu", { load: 1 });
          this.zoom = 12;
        })
        .catch((e) => {
          console.log(e);
          this.isLoading = !this.isLoading;
          submitButton.classList.remove(
            "disabled",
            "spinner",
            "spinner-light",
            "spinner-left"
          );
        });
    },
    beforeShowWarningManaged() {
      this.polygon = false;
      this.mapMode = "ready";
      if (this.getIds.length != 0) {
        axios.post("getavisos", { avisos: this.getIds }).then((res) => {
          let mak = [];
          res.data.forEach((el) => {
            mak.push({
              id: el.id_aviso,
              position: {
                lat: parseFloat(el.latitud),
                lng: parseFloat(el.longitud),
              },
              address: el.dir_suscriptor,
              description: el.observacion,
              priority: el.ind_peligro,
              ordenNumber: el.num_aviso,
              dir_ref: el.dir_ref,
              icono: el.icono,
              warningManaged: true,
              ind_cli_vip: el.ind_cli_vip === 0 ? false : true,
              type: el.tipo_trabajo,
              icon: this.URL_ICONS(el.icono ? el.icono.icono : ""),
              infoText: infoWarningManaged(el),
            });
          });
          this.markers = [];
          this.reloadMarkerCenter(mak);
          this.changeWarningManaged(mak);
          this.markers = this.warnings;
          this.polygon = false;
          this.mapMode = "ready";

          if (this.getIds.length != 0) {
            let idx = this.markers.findIndex(
              (f) => f.id == this.getIds[this.getIds.length - 1]
            );
            this.toggleInfoWindow(this.markers[idx], idx);
          }
        });
      } else {
        this.infoWinOpen = false;
        this.zoom = 12;
        this.markers = this.warnings;
      }
    },
    URL_ICONS(image) {
      return image ? `${ICONS_CLD}/${image}.png` : "";
    },

    // clic a los avisos
    async beforeCreationOT(marker) {
      let mak = [];
      axios
        .get("avisos/" + marker)
        .then((res) => {
          this.center = {
            lat: parseFloat(res.data[0].latitud),
            lng: parseFloat(res.data[0].longitud),
          };
          this.zoom = 16;
          res.data.forEach((el) => {
            mak.push({
              id: el.id_aviso,
              position: {
                lat: parseFloat(el.latitud),
                lng: parseFloat(el.longitud),
              },
              address: el.dir_suscriptor,
              description: el.observacion,
              priority: el.ind_peligro,
              ordenNumber: el.num_aviso,
              dir_ref: el.dir_ref,
              icono: el.icono,
              warning: true,
              type: el.tipo_trabajo,
              ind_cli_vip: el.ind_cli_vip == 0 ? false : true,
              icon:
                el.ind_cli_vip != 0
                  ? this.URL_ICONS("vipmovil")
                  : this.URL_ICONS(el.icono != null ? el.icono.icono : ""),
              infoText: infoWindoBCOT(el),
            });
            /** CLICK PARA MOSTRAR EL CIRCUITO DEL AVISO **/
            // if(el.subs.length != 0){
            //     let option = [];
            //     el.subs.map(item => {
            //         item.circuitos.map(c => {
            //             c.elemento.map(e => {
            //                 let qa = parseInt(e.total/1000) + 1;
            //                 for(let i = 1; i<=qa; i++) {
            //                     if(e) {
            //                         let url = `${item.codigo}/${c.codigo_circuito}/${e.elemento}@siprem@${qa}/${e.elemento}${i}.KML`
            //                         option.push({url: this.onUrl(url), selected: true})
            //                     }
            //                 }
            //             })
            //         })
            //     })
            //     console.log(option);
            //     this.kmlLayers = option;
            // }
          });
          this.polygon = this.warnings.length != 1;
          this.changeValue(mak);
          this.markers = this.warnings;
          // this.center = this.markers[0].position
          // this.zoom = 16;
          let [dato] = mak;

          let index = this.markers.findIndex(f => f.id == dato.id )
          if(index !== -1) {
            this.toggleInfoWindow(
              this.markers[index],
              this.markers.length
            );
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    onUrl(url) {
      return `${BASE_URL_KML}/${url}`;
      // ?dummy=${(new Date()).getTime()}`
    },
    beforeCircuit(items) {
      if (items != null && items != undefined && items.length != 0) {
        axios.post("getavisos", { avisos: items }).then((res) => {
          let mak = [];
          res.data.forEach((el) => {
            mak.push({
              id: el.id_aviso,
              position: {
                lat: parseFloat(el.latitud),
                lng: parseFloat(el.longitud),
              },
              address: el.dir_suscriptor,
              description: el.observacion,
              priority: el.ind_peligro,
              ordenNumber: el.num_aviso,
              dir_ref: el.dir_ref,
              icono: el.icono,
              warning: true,
              type: el.tipo_trabajo,
              ind_cli_vip: el.ind_cli_vip == 0 ? false : true,
              icon:
                el.ind_cli_vip != 0
                  ? this.URL_ICONS("vipmovil")
                  : this.URL_ICONS(el.icono ? el.icono.icono : null),
              infoText: infoWinBeforeCircuit(el),
            });
          });
          this.polygon = this.warnings.length != 1;
          this.reloadMarkerCenter(mak);
          this.changeValue(mak);
          this.markers = this.warnings;
          this.zoom = 14;
        });
      } else {
        if (this.warnings.length != 0) {
          //code
        } else {
          this.mapMode = "ready";
          this.markers = [];
          this.polygon = false;
          // this.warnings = []
          this.changeValue([]);
        }
      }
    },
    beforeSubstation(items) {
      if (items != null && items != undefined && items.length != 0) {
        axios.post("getavisos", { avisos: items }).then((res) => {
          let mak = [];
          res.data.forEach((el) => {
            mak.push({
              id: el.id_aviso,
              position: {
                lat: parseFloat(el.latitud),
                lng: parseFloat(el.longitud),
              },
              address: el.dir_suscriptor,
              description: el.observacion,
              priority: el.ind_peligro,
              ordenNumber: el.num_aviso,
              dir_ref: el.dir_ref,
              icono: el.icono,
              warning: true,
              type: el.tipo_trabajo,
              ind_cli_vip: el.ind_cli_vip == 0 ? false : true,
              icon:
                el.ind_cli_vip != 0
                  ? this.URL_ICONS("vipmovil")
                  : this.URL_ICONS(el.icono ? el.icono.icono : null),
              // <span style="color: #F64E60; font-weight: bold;">#:</span> ${el.id_aviso} <br>
              infoText: infoWinBeforeSubstation(el),
            });
          });
          this.polygon = this.warnings.length != 1;
          this.reloadMarkerCenter(mak);
          this.changeValue(mak);
          this.markers = [];
          this.markers = _.uniqBy(this.warnings, "id");
          this.zoom = 15;
        });
      } else {
        this.mapMode = "ready";
        this.markers = [];
        this.polygon = false;
        this.techMarker = this.getTecnical;
        this.changeValue([]);
      }
    },
    save() {
      if (this.case == "not_ubicated_second") {
        if (this.markers.length == 0) {
          this.$swal.fire({
            title: "Información",
            text: "Debe ubicar el aviso para poder continuar",
            icon: "info",
          });
        } else {
          this.$refs.reubication.show();
          this.infoNotLocated = [];
          axios.get("avisos/" + this.newMarker).then((res) => {
            this.infoNotLocated = res.data;
            this.infoNotLocated[0].latitud = this.markers[0].position.lat;
            this.infoNotLocated[0].longitud = this.markers[0].position.lng;
          });
        }
      } else if (
        this.case == "sector_avisos" ||
        this.case == "assign_ot" ||
        this.case == "circuit" ||
        this.case == "subestacion_avisos"
      ) {
        this.shapes.forEach((shape) => {
          var coords = [];
          let path = [];
          this.paths = [];
          shape.overlay.latLngs.getArray().forEach((latLng) => {
            const [obj] = Object.keys(latLng);
            // let obj = Object.keys(latLng)[0]
            latLng[obj].forEach((location) => {
              path.push({
                lat: location.lat(),
                lng: location.lng(),
              });
            });
            this.paths.push(path);
          });
          coords = this.paths.map((path) =>
            closeLoop(path.map(({ lat, lng }) => [lng, lat]))
          );

          this.polygonGeojson = JSON.stringify(
            {
              type: "Polygon",
              coordinates: coords,
            },
            null,
            1
          );
        });
        if (this.paths.length != 0) {
          var data =
            this.geoMarkerSize().length == 0
              ? this.markers
              : this.geoMarkerSize();
          this.techMarker = this.getTecnical;
          // console.log(data);
          let counter = 0;
          data.map((type) => {
            if (type.type.codigo == 2 || type.type.codigo == 3) {
              counter = counter + 1;
            }
          });
          if (counter <= 1) {
            var info = [];
            data.forEach((el) => {
              info.push(el.id);
            });
            if (info.length != 0) {
              axios.post("getavisos", { avisos: info }).then((res) => {
                this.markerSelected = res.data;
              });
            }
            this.$refs.assignation.show();
          } else {
            this.$swal.fire({
              title: "Información",
              text: "Sólo puede agrupar avisos",
              icon: "info",
            });
          }
        }
      }
    },
    handleMarkerDrag(e) {
      this.markers = [];
      this.markers.push({
        id: this.newMarker,
        position: {
          lat: e.latLng.lat(),
          lng: e.latLng.lng(),
        },
        draggable: true,
      });
    },
    locationNew(event) {
      if (this.mapMode == "edit" && this.PARENT.case == "not_ubicated_second") {
        axios.get("avisos/" + this.newMarker).then((res) => {
          let el = res.data[0];
          this.markers = [];
          this.markers.push({
            id: this.newMarker,
            new_ubication: true,
            // <span style="color: #F64E60; font-weight: bold;">#:</span> ${el.id_aviso} <br>
            infoText: infoWinLocationNew(el),
            position: {
              lat: event.latLng.lat(),
              lng: event.latLng.lng(),
            },
            draggable: true,
          });
        });
      }
    },
    geoMarkerSize() {
      var polygon = new window.google.maps.Polygon({ paths: this.paths });
      const markersInsidePolygon = this.warnings.filter((marker) =>
        window.google.maps.geometry.poly.containsLocation(
          marker.position,
          polygon
        )
      );
      let markersFiltered = [];
      markersInsidePolygon.forEach((el) => {
        markersFiltered.push(el);
      });

      return markersFiltered;
    },
    reloadMarkerCenter(info) {
      if (info.length != 0) {
        let ramdom = Math.floor(Math.random() * info.length);
        this.center = info[ramdom].position;
        if (!isNaN(this.center.lat) && !isNaN(this.center.lng)) {
          this.zoom = 16;
          return;
        } else {
          this.reloadMarkerCenter(info);
        }
      }
    },
    getInfoWindowContent(marker) {
      axios.post("getavisos", { avisos: [marker.id] }).then((res) => {
        this.markerSelected = res.data;
      });
      this.$refs.assignation.show();
    },
    // Modal para crear causas improcendentes
    getCausasImprocedentes(marker) {
      let spInfo = marker.split("-");
      let IMP = this.$refs.CausasImprocedentes;
      IMP.id = spInfo[0];
      //numero del aviso
      IMP.num_aviso = spInfo[1];
      //seleccion del select
      IMP.selected = -1;
      //abre el modal
      IMP.showModal = true;
      if (IMP.showModal) {
        sleep(3000);
        IMP.deleteTabIndex();
      }
    },
    async toggleInfoWindow(marker, idx) {
      let p = this.polygonPaths;
      if (p) {
        if (p.length) {
          this.$swal.fire(
            "Información",
            "En estos momentos no puede seleccionar un marcador",
            "info"
          );
        }
      } else {
        if (marker.technical && this.currentMidx !== idx) {
          this.infoWinContent = marker;
          await this.simpleGeocode(marker.ultima_posicion);
          let sComplement = `<span style="color: #F64E60; font-weight: bold;">Dirección:</span> <b>${this.sUbication}</b> <br> `;
          this.infoWinContent.sComplement = sComplement;
        } else  {
          // infoWinContent pasa a ser nuevamente un objeto 
          this.infoWinContent = {};
          this.infoWinContent = marker;
        }
        this.infoWindowPos = marker.position;
        this.center = marker.position;
        this.infoOptions.content = marker.infoText;

        if (this.currentMidx == idx) {
          this.infoWinOpen = !this.infoWinOpen;
        } else {
          this.infoWinOpen = true;
          this.currentMidx = idx;
        }
      }
    },
    simpleGeocode(position) {
      let geocoder = new window.google.maps.Geocoder();
      if (_.isObject(position)) {
        let newPosition = {
          lat: parseFloat(position.latitud),
          lng: parseFloat(position.longitud),
        };
        return new Promise(function (resolve, reject) {
          geocoder.geocode(
            { location: newPosition },
            function (results, status) {
              // console.log({ date, hour, status });
              if (status === "OK") {
                if (results[0]) {
                  resolve({ address: results[0].formatted_address });
                } else {
                  reject(null);
                }
              }
            }
          );
        }).then((data) => {
          this.sUbication = data.address;
        });
      }
    },
    resetMarker(resetInfo) {
      this.$refs.assignation.hide();
      this.$refs.CausasImprodentes.hide();
      this.markerSelected = resetInfo;
    },
    generateOT(values) {
      this.removePath();
      this.$refs.favorite = this.markerSelected[0].id_aviso;

      let [init] = values;
      let ot = {
        usuario_genera: this.user.id,
        avisos: init.avisos,
        aviso_ref: init.aviso_referencia,
        id_estado: init.trabajador == null ? 1 : 2,
        observacion: init.observacion,
        geojson: this.polygonGeojson,
        brigada: init.trabajador,
        full_user: init.full_name,
        created_at: moment().format("YYYY-MM-DD h:mm:ss"),
      };

      axios.post("/ots", ot).then((res) => {
        this.infoWinOpen = false;
        if (res.data.message == "failed") {
          toastr.info(JSON.stringify(res.data.error.errorInfo));
          this.$refs.Info.removeLoaderSpinClass();
        } else {
          toastr.success("se ha generado la OT");
          this.$emit("loadMenu", { load: 1 });
          this.markers = [];
          this.paths = [];
          this.resetWarning([]);
          this.$refs.assignation.hide();
        }
      });
    },
    updateCenter: function (place) {
      this.zoom = 18;
      this.center = {
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      };
    },
    updateEdited: function (mvcPaths) {
      this.mvcPaths = mvcPaths;
    },
    addPath: function () {
      var bounds = this.$refs.map.$mapObject.getBounds();
      var northEast = bounds.getNorthEast();
      var southWest = bounds.getSouthWest();
      var center = bounds.getCenter();
      var degree = this.paths.length + 1;
      var f = Math.pow(0.25, degree);

      var path = [
        {
          lng: center.lng(),
          lat: (1 - f) * center.lat() + f * northEast.lat(),
        },
        {
          lng: (1 - f) * center.lng() + f * southWest.lng(),
          lat: (1 - f) * center.lat() + f * southWest.lat(),
        },
        {
          lng: (1 - f) * center.lng() + f * northEast.lng(),
          lat: (1 - f) * center.lat() + f * southWest.lat(),
        },
      ];

      this.paths.push(path);
    },
    removePath: function () {
      this.paths.splice(this.paths.length - 1, 1);
    },
    handleClickForDelete($event) {
      if ($event.vertex) {
        this.$refs.polygon.$polygonObject
          .getPaths()
          .getAt($event.path)
          .removeAt($event.vertex);
      }
    },
    readGeojson($event) {
      try {
        this.polygonGeojson = $event.target.value;

        var v = JSON.parse($event.target.value);

        this.paths = v.coordinates.map((linearRing) =>
          linearRing
            .slice(0, linearRing.length - 1)
            .map(([lng, lat]) => ({ lat, lng }))
        );

        this.errorMessage = null;
      } catch (err) {
        this.errorMessage = err.message;
      }
    },
    showGeoJson(data) {
      try {
        this.polygonGeojson = data;

        var v = JSON.parse(data);

        this.paths = v.coordinates.map((linearRing) =>
          linearRing
            .slice(0, linearRing.length - 1)
            .map(([lng, lat]) => ({ lat, lng }))
        );
        this.errorMessage = null;
      } catch (err) {
        this.errorMessage = err.message;
      }
    },
    deletePolylineBrigadeRoute() {
      this.infoWinOpen = false;
      this.clearBrigadeData();
    },
    toggleInfoBrigade(brigade, idx) {
      this.infoWinContent = brigade;
      this.infoWindowPos = brigade.position;
      this.center = brigade.position;
      this.infoOptions.content = brigade.infoText;

      if (this.currentMidx == idx) {
        this.infoWinOpen = !this.infoWinOpen;
      } else {
        this.infoWinOpen = true;
        this.currentMidx = idx;
      }
    },
  },
};
</script>
