<template>
  <b-modal
    ref="improper-modal"
    id="improper-modal"
    v-model="showModal"
    size="lg"
    centered
    no-close-on-backdrop
    title="Declarar improcedente"
    scrollable
  >
    <b-row>
      <b-col>
        <div class="alert alert-secondary">
          <b>Avisos: </b> {{ num_aviso | joinArray }}
        </div>
        <div class="separator separator-dashed mt-3 mb-3"></div>
      </b-col>
      <b-col md="12">
        <b-form-group>
          <label class="mr-sm-2">Seleccione</label>
          <Select2
            class="select2 mb-3"
            v-model="selected"
            :options="options"
            @change="onChangeImproper()"
            placeholder="Filtrar"
          />
        </b-form-group>
        <b-form-group v-if="nanexo != 0 && selected != -1">
          <label> {{ anexo.description }} </label>
          <input
            :type="anexo.type"
            class="form-control form-control-sm"
            v-model="anexo.model"
          />
        </b-form-group>
        <b-form-group v-if="selected != -1">
          <label>Observación</label>
          <textarea
            class="form-control form-control-sm"
            v-model="observacion"
          ></textarea>
        </b-form-group>
      </b-col>
    </b-row>
    <template #modal-footer="{ cancel }">
      <b-button size="sm" variant="link" @click="cancel()"> Cancelar </b-button>
      <button
        class="btn btn-sm btn-success font-weight-bold"
        v-if="selected != -1"
        @click="sendImproper()"
      >
        <i class="flaticon2-box-1"></i> {{ isLoading ? message : "Confirmar" }}
      </button>
    </template>
  </b-modal>
</template>
<style>
.modal-backdrop {
  z-index: 0 !important;
}
.bounce-enter-active {
  animation: bounce-in 0.5s;
}
.bounce-leave-active {
  animation: bounce-in 0.5s reverse;
}
@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}
</style>
<script>
import Select2 from "v-select2-component";
import axios from "axios";
import { mapActions, mapGetters } from "vuex";
import toastr from "toastr";
import * as $ from "jquery";
import moment from "moment";
export default {
  name: "improper-warning-modal",
  components: { Select2 },
  mounted() {
    this.configOptionSelect();
  },
  data() {
    return {
      showModal: false,
      id: null,
      ot: null,
      message: null,
      isLoading: false,
      num_aviso: [],
      selected: -1,
      options: [],
      descrip: null,
      nanexo: 0,
      observacion: null,
      anexo: {
        description: null,
        type: null,
        model: "",
      },
    };
  },
  filters: {
    joinArray(values) {
      return values.join(", ");
    },
  },
  computed: {
    today() {
      return moment().format("YYYY-MM-DDTHH:mm:ss");
    },
    ...mapGetters({
      user: "auth/user",
    }),
  },
  methods: {
    ...mapActions({
      resetWarning: "location/resetWarning",
      setDeleted: "warningsOT/setDeleted",
    }),
    deleteTabIndex() {
      setInterval(() => {
        $("#improper-modal___BV_modal_content_").attr("tabindex", null);
      }, 2000);
    },
    onChangeImproper() {
      if (this.selected != -1) {
        let change = this.options.filter((f) => f.id == this.selected)[0];
        this.nanexo = change.nanexo;
        this.anexo.description = change.tituloanx;
        this.anexo.type = change.type;

        if (change.type == "datetime-local") {
          this.anexo.model = this.today;
        }
      }
    },
    sendImproper() {
      let causa = this.options.filter((f) => f.id == this.selected)[0];

      let data = {
        id: this.id,
        ot: this.ot,
        userId: this.user.id,
        usuario: this.user.usuario,
        causa: this.selected,
        num_aviso: this.num_aviso,
        observacion: `${causa ? causa.text : ""}:${
          this.anexo.model == "" ? "" : this.anexo.model
        }:${this.observacion == null ? "N/A" : this.observacion} `,
      };

      this.isLoading = true;
      this.message = "Cargando...";

      axios
        .post("/avisos-improcedentes", data)
        .then((res) => {
          this.isLoading = false;

          if (!res.data.error) {
            toastr.success(res.data.message);
            let data = this.$root.$children[0].$refs.menuComponent.data;
            this.$root.$children[0].$refs.menuComponent.treeMenu(data);
            this.setDeleted({ information: "deleted", event: true });
            this.id = null;
            this.resetWarning([]);
            this.$parent.markers = [];
            this.$parent.infoWinOpen = false;
            this.showModal = false;
            this.$refs["improper-modal"].hide();
          } else {
            toastr.warning(res.data.message);
          }
        })
        .catch((e) => {
          console.log(e);
          this.isLoading = false;
        });
    },
    configOptionSelect() {
      this.options = [];
      this.options.push({
        id: -1,
        text: "Seleccione",
      });
      axios.get("/causas-improcedentes").then((res) => {
        res.data.map((el) => {
          el.id = el.idcausaimproce;
          el.text = el.descripcion;
          this.options.push(el);
        });
      });
      this.deleteTabIndex();
    },
  },
};
</script>
