<template>
  <div>
    <b-row v-if="retirados && retirados.length">
      <b-col>
        <b>MATERIALES RETIRADOS</b>
        <DxDataGrid
          :data-source="retirados"
          :show-borders="true"
          :show-column-lines="true"
          :show-row-lines="true"
          :row-alternation-enabled="true"
          :columnAutoWidth="true"
          :allow-column-resizing="true"
        >
          <DxGroupPanel
            :visible="true"
            emptyPanelText="Arrastre una columna aquí para agrupar"
          />
          <DxSearchPanel
            :visible="false"
            :width="240"
            placeholder="Buscar..."
          />
          <DxPaging :page-size="15" />
          <DxHeaderFilter :visible="true" />
          <DxFilterRow :visible="true">
            <DxOperationDescriptions
              equal="Igual a"
              notEqual="Diferente de"
              startsWith="Empieza con"
              endsWith="Termina con"
              contains="Contiene"
              between="Entre"
              notContains="No contiene"
              lessThanOrEqual="Menor o igual"
              greaterThanOrEqual="Mayor o igual"
              lessThan="Menor que"
              greaterThan="Mayor que"
            />
          </DxFilterRow>
          <DxPager
            :visible="false"
            infoText="Página {0} of {1} ({2} registros)"
            :allowed-page-sizes="[5, 10, 30, 50, 'all']"
            display-mode="full"
            :show-page-size-selector="true"
            :show-info="true"
            :show-navigation-buttons="true"
          />
          <DxColumn
            data-field="cod_articulo"
            caption="Código"
            alignment="center"
          />
          <DxColumn
            data-field="articulo.descripcion"
            :group-index="0"
            caption="Articulo"
          />
          <DxColumn data-field="cod_ucc" caption="UCC" alignment="center" />
          <DxColumn
            data-field="cantidad"
            caption="Cantidad"
            alignment="center"
          />
          <DxColumn
            data-field="created_at"
            caption="Fecha de creación"
            data-type="date"
            format="yyyy/MM/dd HH:mm:ss"
            alignment="center"
          />
          <DxSummary>
            <DxTotalItem
              column="cantidad"
              summary-type="count"
              display-format="Total: {0}" />
            <DxGroupItem
              :align-by-column="true"
              column="cantidad"
              summary-type="count"
              display-format="{0}"
          /></DxSummary>
        </DxDataGrid>
      </b-col>
    </b-row>
    <b-row v-else>
      <b-col class="text-center">
        <img src="assets/img/empty.png" alt="Empty" />
        <p class="text-muted">No hay materiales retirados</p>
      </b-col>
    </b-row>

    <b-row v-if="instalados && instalados.length">
      <b-col>
        <b>MATERIALES INSTALADOS</b>
        <DxDataGrid
          :data-source="instalados"
          :show-borders="true"
          :show-column-lines="true"
          :show-row-lines="true"
          :row-alternation-enabled="true"
          :columnAutoWidth="true"
          :allow-column-resizing="true"
          id="instalados"
        >
          <DxGroupPanel
            :visible="true"
            emptyPanelText="Arrastre una columna aquí para agrupar"
          />
          <DxSearchPanel
            :visible="false"
            :width="240"
            placeholder="Buscar..."
          />
          <DxPaging :page-size="15" />
          <DxHeaderFilter :visible="true" />
          <DxFilterRow :visible="true">
            <DxOperationDescriptions
              equal="Igual a"
              notEqual="Diferente de"
              startsWith="Empieza con"
              endsWith="Termina con"
              contains="Contiene"
              between="Entre"
              notContains="No contiene"
              lessThanOrEqual="Menor o igual"
              greaterThanOrEqual="Mayor o igual"
              lessThan="Menor que"
              greaterThan="Mayor que"
            />
          </DxFilterRow>
          <DxPager
            :visible="false"
            infoText="Página {0} of {1} ({2} registros)"
            :allowed-page-sizes="[5, 10, 30, 50, 'all']"
            display-mode="full"
            :show-page-size-selector="true"
            :show-info="true"
            :show-navigation-buttons="true"
          />
          <DxColumn
            data-field="cliente.nombre"
            caption="Brigada"
            :group-index="0"
          />
          <DxColumn data-field="cod_articulo" caption="Código" />
          <DxColumn data-field="articulo.descripcion" caption="Articulo" />
          <DxColumn data-field="cod_ucc" caption="UCC" />
          <DxColumn
            data-field="cantidad"
            caption="Cantidad"
            alignment="center"
          />
          <DxColumn
            data-field="created_at"
            caption="Fecha de creación"
            data-type="date"
            format="yyyy/MM/dd HH:mm:ss"
            alignment="center"
          />
        </DxDataGrid>
      </b-col>
    </b-row>
    <b-row v-else>
      <b-col class="text-center">
        <img src="assets/img/empty.png" alt="Empty" />
        <p class="text-muted">No hay materiales instalados</p>
      </b-col>
    </b-row>
  </div>
</template>
<style>
.dx-header-row > td[aria-label="Column UCC"],
.dx-header-row > td[aria-label="Column Código"],
.dx-header-row > td[aria-label="Column Articulo"],
.dx-header-row > td[aria-label="Column Cantidad"],
.dx-header-row > td[aria-label="Column Fecha de creación"] {
  overflow-x: hidden !important;
}
 
</style>
<script>
import {
  DxDataGrid,
  DxPaging,
  DxColumn,
  DxOperationDescriptions,
  DxFilterRow,
  DxHeaderFilter,
  DxPager,
  DxGroupPanel,
  DxSearchPanel,
  DxSummary,
  DxGroupItem
} from "devextreme-vue/data-grid";
export default {
  components: {
    DxDataGrid,
    DxPaging,
    DxColumn,
    DxOperationDescriptions,
    DxFilterRow,
    DxHeaderFilter,
    DxPager,
    DxGroupPanel,
    DxSearchPanel,
    DxSummary,
    DxGroupItem
  },
  props: {
    instalados: {
      type: Array,
      default: () => [],
    },
    retirados: {
      type: Array,
      default: () => [],
    },
  },
};
</script>
