/**
 * Menu para click derecho
 * The right menu is active when is selected the index that have the component <VJstree /> in it's props
 * with the @click.right function
 * v-on:contextmenu=openMenu
 * openMenu function 
 */
const MENU = [
    // slug == brigadas_disponibles_all
    // pos: 0
    [
        { id: 1, text: "Generar informe." },
    ]
];

export default MENU;