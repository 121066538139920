<template>
  <b-container
    fluid
    class="p-4 bg-secondary"
    v-if="galeria && galeria.length != 0"
  >
    <b-row>
      <b-col md="4" class="mb-3" v-for="(item, index) in galeria" :key="index">
        <b-img
          thumbnail
          fluid
          :src="servidor + item.path"
          :alt="item.nombre"
        ></b-img>
      </b-col>
    </b-row>
  </b-container>
  <b-card v-else>
    <b-row>
      <b-col>
        <div class="text-center">
          <img src="assets/img/empty.png" alt="Empty" />
          <p class="text-muted">No hay información para mostrar</p>
        </div>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import ENV from "@/environment";

export default {
  name: "order-galeria-tap",
  props: {
    galeria: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      servidor:
        process.env.NODE_ENV == "development" ? ENV.SERVER_DEV : ENV.SERVER,
    };
  },
};
</script>
