<template>
  <b-row v-if="informationOT.length">
    <b-col>
      <DxDataGrid :data-source="informationOT" :show-borders="true" :show-column-lines="true" :show-row-lines="true"
        :row-alternation-enabled="true" :columnAutoWidth="true" :allow-column-resizing="true">
        <DxHeaderFilter :visible="false" />
        <DxFilterRow :visible="false" />
        <DxHeaderFilter :visible="true" />
        <DxFilterRow :visible="true">
          <DxOperationDescriptions equal="Igual a" notEqual="Diferente de" startsWith="Empieza con" endsWith="Termina con"
            contains="Contiene" between="Entre" notContains="No contiene" lessThanOrEqual="Menor o igual"
            greaterThanOrEqual="Mayor o igual" lessThan="Menor que" greaterThan="Mayor que" />
        </DxFilterRow>
        <DxColumn data-field="num_ot" caption="N° OT" data-type="number" alignment="center" />
        <DxColumn data-field="brigada" caption="Brigada" />
        <DxColumn data-field="fecha_asigna" caption="Fecha asignación" data-type="date" format="dd/MM/yyyy, HH:mm:ss" />
        <DxColumn width="300" data-field="observacion" caption="Observaciones" cell-template="cellTemplateObs" />
        <template #cellTemplateObs="{ data: cellData }">
          <div class="cell">
            {{ cellData.data.observacion }}
          </div>
        </template>
        <DxColumn data-field="estado" caption="Estado" cell-template="cellTemplate" />
        <template #cellTemplate="{ data: cellData }">
          <div>
            <i :class="cellData.data.estado_o_t_s.icono"></i>
            {{ cellData.data.estado_o_t_s.descripcion }}
          </div>
        </template>
        {/* */}
        
        <DxColumn data-field="Ubicar" caption="Ubicar" cell-template="cellTemplateUbicate" />
        <template #cellTemplateUbicate="{ data: cellData }">
          <div>
            {{  }}
            <UbicateTree  :infoWinContent="cellData.data"/>
          </div>
        </template>
      </DxDataGrid>
    </b-col>
  </b-row>
  <b-row v-else>
    <b-col class="text-center">
      <img src="assets/img/empty.png" alt="Empty" />
      <p class="text-muted">No hay información</p>
    </b-col>
  </b-row>
</template>
<script>
import {
  DxDataGrid,
  DxColumn,
  DxFilterRow,
  DxHeaderFilter,
  DxOperationDescriptions,
} from "devextreme-vue/data-grid";
import UbicateTree from "../../../processes/works/helpers/UbicateTree.vue";
export default {
  components: {
    DxDataGrid,
    DxColumn,
    DxFilterRow,
    DxHeaderFilter,
    DxOperationDescriptions,
    UbicateTree
},
  props: {
    informationOT: {
      type: Array,
      default: () => [],
    },
  },
};
</script>
<style>
.cell {
  width: 100% !important;
  height: auto !important;
  overflow: hidden !important;
  white-space: normal !important;
  padding: 3px;
  text-align: justify;
}
</style>