import moment from "moment"
const infoWinLocationNew = (el) => {
    return ` <strong>
                <span style="color: #F64E60; font-weight: bold;">N° Orden :</span> ${el.num_aviso} <br>
                <span style="color: #F64E60; font-weight: bold;">Fecha de ingreso :</span> ${moment(el.fecha_alta).format("LLL")} <br>
                <span style="color: #F64E60; font-weight: bold;">VIP :</span> ${el.ind_cli_vip == 0? "NO": "SI"} <br>
                <span style="color: #F64E60; font-weight: bold;">Tipo trabajo :</span> ${el.tipo_trabajo_id == 1? "AVISO": el.tipo_trabajo_id ==2? "INCIDENCIA": "DESCARGO"} <br>
                <span style="color: #F64E60; font-weight: bold;">Sector :</span> ${el.sector} <br>
                <span style="color: #F64E60; font-weight: bold;">Subestación :</span> ${el.subestacion} <br>
                <span style="color: #F64E60; font-weight: bold;">Circuito :</span> ${el.circuito_sm} <br> 
                <span style="color: #F64E60; font-weight: bold;">Municipio :</span> ${el.municipio} <br>
                <span style="color: #F64E60; font-weight: bold;">Tipo de aviso :</span> ${el.tipo_aviso} <br> 
                <span style="color: #F64E60; font-weight: bold;">Descripción:</span> ${el.observacion} ${el.tipo_trabajo_id == 3 || el.tipo_trabajo_id == 2? el.obs_estado_incidencia: ""} <br> 
                <span style="color: #F64E60; font-weight: bold;">Referencia:</span> ${el.dir_ref} ${el.dir_suscriptor} ${el.tipo_trabajo_id == 3 || el.tipo_trabajo_id == 2? el.desc_incidencia: ""} <br> 
                <span style="color: #F64E60; font-weight: bold;">Dirección:</span> ${el.dir_suscriptor} 
            </strong>`
}
export default infoWinLocationNew