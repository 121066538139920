<template>
  <b-modal
    title="Información de OT"
    ref="information"
    id="information"
    v-model="show"
    size="xl"
    no-close-on-backdrop
    scrollable
  >
    <b-row>
      <b-col md="12">
        <div>
          <OrderDetails :idOt="parseInt(id)" :showOt="true" />
        </div>
      </b-col>
    </b-row>

    <template #modal-footer>
      <button @click="() => (show = false)" class="btn btn-sm btn-primary">
        <i class="flaticon-close"></i> Cerrar
      </button>
    </template>
  </b-modal>
</template>
<script>
import axios from "axios";
import moment from "moment";
import _ from "lodash";

import ENV from "@/environment";
import OrderDetails from "@/components/admin/map/modal/OrderDetails.vue";
export default {
  name: "informacion-ot",
  components: {
    OrderDetails
},
  data() {
    return {
      servidor:
        process.env.NODE_ENV == "development" ? ENV.SERVER_DEV : ENV.SERVER,
      pageSizes: [5, 10, 15, 30, 50],
      show: false,
      data: [],
      id: null,
      ot: [],
      ref: [],
      bitacora: [],
      movil: [],
      reglas_oro: [],
      galeria: [],
      info_aviso: {},
      certificate: [],
      mano_obra: [],
      acciones: [],
      activation: [],
      pendiente: [],
      info_principal: [],
      observacion_bol: "",
      rechazos: [],
      retirados: [],
      instalados: [],
      movil_2: null,
    };
  },
  computed: {
    color() {
      if (this.movil_2.pregunta_id) {
        let color = {};
        if (this.movil_2.pregunta_id == 1) {
          color = {
            background: "#AD631D",
            color: "#FFF",
          };
        } else if (this.movil_2.pregunta_id == 2) {
          color = {
            background: "#185967",
            color: "#FFF",
          };
        } else if (this.movil_2.pregunta_id == 3) {
          color = {
            background: "#156C5A",
            color: "#FFF",
          };
        } else if (this.movil_2.pregunta_id == 4) {
          color = {
            background: "#9V7910",
            color: "#FFF",
          };
        } else if (this.movil_2.pregunta_id == 5) {
          color = {
            background: "#5B3071",
            color: "#FFF",
          };
        } else if (this.movil_2.pregunta_id == 6) {
          color = {
            background: "#189E85",
            color: "#FFF",
          };
        }

        return color;
      } else {
        return {
          background: "#F3F3F3",
          color: "#333",
        };
      }
    },
  },
  filters: {
    upperCase(value) {
      return value.replace(/_/g, " ").toUpperCase();
    },
    format(values) {
      return moment(values).format("LLL");
    },
    simpleFormat(value) {
      return moment(value).format("l") + " " + moment(value).format("LTS");
    },
  },
  methods: {
    load(id) {
      this.id = id;
      axios
        .get(`ots/${id}`)
        .then((res) => {
          this.data = [];
          this.ref = [];

          this.ot = res.data;
          this.activation = this.ot[0].activation;
          this.bitacora = this.ot[0].bitacora;
          this.movil = this.ot[0].movil;
          const [aux] = this.movil;
          this.movil_2 = aux;
          this.reglas_oro = this.ot[0].reglas_oro;
          this.galeria = this.ot[0].imagenes;
          this.certificate = this.ot[0].sync;
          this.mano_obra = this.ot[0].manoObra;
          this.acciones = this.ot[0].acciones;
          this.rechazos = this.ot[0].rechazo;

          this.retirados = this.ot[0].dato_materiales_retirados;
          this.instalados = this.ot[0].dato_materiales;

          if (this.ot[0].historico) {
            this.pendiente = this.ot[0].historico;
          }

          this.ot[0].brigada = this.ot[0].user
            ? this.ot[0].user.nombre
            : "NO ASIGNADO";
          this.ot[0].estado = this.ot[0].estado_o_t_s.descripcion;
          let movil_temp = [];
          let cause = [
            "principal_id",
            "causa_id",
            "pregunta_id",
            "tipo_os",
            "resultado",
            "contrata",
            "tipo_cierre",
            "obj",
            "trafo_parcial",
            "created_at",
            "updated_at",
            "deleted_at",
          ];
          this.movil.map((el) => {
            for (const key in el) {
              if (!cause.includes(key) && !_.isObject(el[key])) {
                movil_temp.push({
                  titulo: key.replace("_", " ").toUpperCase(),
                  detalle: el[key],
                });
              }
              if (key == "observacion") {
                this.observacion_bol = el[key];
              }
              if (key === "trafo_parcial") {
                movil_temp.push({
                  titulo: "HAY " + key.replace("_", " ").toUpperCase(),
                  detalle: el[key] == 1 || el[key] == null ? "NO" : "SI",
                });
              }
            }
          });
          this.movil = movil_temp;

          res.data.map((o) => {
            o.avisos.map((el) => {
              el.ind_cli_vip = el.ind_cli_vip ? "SI" : "NO";

              if (el.pivot.id_aviso_ref == el.pivot.id_aviso) {
                this.info_aviso = el;
                delete el.tipo_trabajo;
                delete el.updated_at;
                delete el.created_at;
                delete el.icono;
                delete el.estado;
                delete el.id_aviso;
                delete el.tipo_trabajo_id;
                delete el.pivot;
                this.ref.push(el);
              } else {
                delete el.tipo_trabajo;
                delete el.updated_at;
                delete el.created_at;
                delete el.icono;
                delete el.estado;
                delete el.tipo_coordenada;
                delete el.id_aviso;
                delete el.tipo_trabajo_id;
                delete el.pivot;
                this.data.push(el);
              }
            });
          });
          let main = [];
          this.ref.map((el) => {
            for (const key in el) {
              main.push({
                informacion: key.replace("_", " ").toUpperCase(),
                detalle: el[key],
              });
            }
          });
          this.ref = main;
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
};
</script>
