export default {
    namespaced: true,
    state: {
        roles: []
    },
    getters: {
        getRoles(state) {
            return state.roles
        },
    },
    mutations: {
        SET_ROLES(state, value) {
            state.roles = value
        },
    },
    actions: {
        setRoles({ commit }, value) {
            commit('SET_ROLES', value)
        },

    }
}