<template>
  <b-row>
    <transition name="bounce">
      <b-col md="12" v-if="can('/home')">
        <div class="card">
          <div class="card-body">
            <HomeReport v-if="can('home-report')" />
          </div>
        </div>
      </b-col>
    </transition>

    <b-modal
      id="modal-center"
      ref="modal"
      centered
      title="Zona de trabajo"
      v-model="showModal"
      no-close-on-backdrop
      hide-header-close
    >
      <div class="row justify-content-center">
        <div class="col-md-12">
          <label>Seleccione zona para trabajar</label>
          <select
            @keyup.enter="confirmWorkZone()"
            v-model="cor"
            class="form-control"
          >
            <option :value="null">Seleccione</option>
            <option
              v-for="(item, index) in items"
              :key="index"
              :value="`'${item.nombre}'`"
            >
              {{ item.nombre }}
            </option>
          </select>
        </div>
      </div>
      <template #modal-footer>
        <div class="w-100">
          <b-button
            @click="confirmWorkZone()"
            variant="outline-primary"
            size="sm"
            class="float-right mr-2"
          >
            <i class="la la-map-marked-alt"></i> Confirmar
          </b-button>
        </div>
      </template>
    </b-modal>
    <selectSectors
      ref="sectors"
      @loadTree="confirmWorkZone()"
      v-if="can('change-zone')"
    />
  </b-row>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import axios from "axios";
import _ from "lodash";
import toastr from "toastr";

import can from "@/permission";
import selectSectors from "@/components/helpers/modals/selectSectors.vue";
import HomeReport from "./HomeReport.vue";
// import ChartHome from "./chartHome.vue";
// import AvisosMes from "./AvisosMes.vue";
// import CountDown from "./CountDown.vue";
export default {
  components: {
    selectSectors,
    HomeReport,
  },
  mounted() {
    this.loadCor();
    // setInterval(() => {
    //   this.refreshToken();
    // }, 30 * 1000);

    // }, 3600*1000)
  },
  data() {
    return {
      cor: null,
      items: [],
      showModal: false,
    };
  },
  computed: {
    ...mapGetters({
      getCor: "auth/cor",
      user: "auth/user",
    }),
  },
  methods: {
    ...mapActions({
      changeCor: "auth/zoneWork",
      refreshToken: "auth/refreshToken",
    }),
    can: can,
    loadCor() {
      axios
        .get("/cors")
        .then((res) => {
          this.items = res.data;

          if (
            sessionStorage.getItem("cor") == undefined &&
            this.can("change-zone")
          ) {
            let modal = this.$refs["sectors"];
            let c = this.items.map((e) => e.nombre);
            modal.loadSector();
            modal.cors = _.uniq(c);
            modal.defaultInfo();
            modal.isShow = true;
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    setCor(val) {
      this.cor = val;
    },
    confirmWorkZone() {
      if (sessionStorage.cor == "") {
        toastr.info("Seleccione una zona de trabajo para continuar");
      } else {
        this.changeCor(sessionStorage.cor);
        this.$emit("loadCor", this.cor);
        window.location.href = "";
      }
    },
  },
};
</script>
