<template>
  <b-modal
    title="Busqueda de avisos"
    ref="search"
    id="search"
    v-model="show"
    size="md"
    no-close-on-backdrop
    scrollable
    hide-header-close
  >
    <b-row>
      <b-col>
        <h6>Filtro por estado</h6>
        <div class="separator separator-dashed mt-2 mb-2"></div>
        <div class="checkbox-inline">
          <label class="checkbox checkbox-success">
            <input
              type="checkbox"
              name="Checkboxes5"
              @click="selectAll"
              v-model="allSelected"
            />
            <span></span>Seleccionar todo</label
          >
        </div>
        <hr />
        <div class="checkbox-list">
          <label :class="item.class" v-for="(item, idx) in status" :key="idx">
            <input
              type="checkbox"
              :value="item.id"
              @click="select"
              v-model="selected"
            />
            <span></span> <i :class="item.icon"></i> {{ item.text }}</label
          >
        </div>
      </b-col>
    </b-row>

    <template #modal-footer>
      <b-row>
        <b-col md="12" class="mb-3">
          <span class="text-muted" v-if="counter !== 0">
            Se encontraron {{ counter }} registros... :)
          </span>
        </b-col>
        <b-col md="12">
          <transition name="bounce">
            <button
              v-if="selected.length"
              @click="searchOrders()"
              class="btn btn-sm btn-primary mr-2"
            >
              <i
                class="flaticon-search-magnifier-interface-symbol text-white"
              ></i>
              {{ loading ? "Consultando..." : "Consultar" }}
            </button>
          </transition>
          <transition name="bounce">
            <button @click="deleteMarkers()" class="btn btn-sm btn-danger mr-2">
              <i class="la la-trash text-white"></i>
              Limpiar
            </button>
          </transition>
          <button
            @click="
              () => {
                showSearch(false);
              }
            "
            class="btn btn-sm btn-link"
          >
            <i class="flaticon-close"></i> Cancelar
          </button>
        </b-col>
      </b-row>
    </template>
  </b-modal>
</template>
<script>
import toastr from "toastr";
import { mapActions, mapGetters } from "vuex";
import axios from "axios";
export default {
  name: "search-certified",
  data() {
    return {
      loading: false,
      counter: 0,
      status: [
        {
          id: 1,
          text: "Pendiente",
          icon: "fa fa-fire text-danger",
          class: "checkbox checkbox-danger",
        },
        {
          id: 2,
          text: "Asignado",
          icon: "fa fa-handshake text-primary",
          class: "checkbox checkbox-primary",
        },
        {
          id: 3,
          text: "Descargado",
          icon: "fa fa-cloud-download-alt",
          class: "checkbox checkbox-secondary",
        },
        {
          id: 4,
          text: "En ejecución",
          icon: "fa fa-forward text-info",
          class: "checkbox checkbox-info",
        },
        {
          id: 5,
          text: "Ejecutada",
          icon: "fa fa-check-circle text-success",
          class: "checkbox checkbox-success",
        },
        {
          id: 8,
          text: "Rechazada",
          icon: "la la-window-close-o text-danger",
          class: "checkbox checkbox-danger opacity-70",
        },
        {
          id: 9,
          text: "Cerrado por el operador",
          icon: "la la-folder-o text-success font-weight-bold",
          class: "checkbox checkbox-success opacity-90",
        },
      ],
      allSelected: false,
      selected: [],
    };
  },
  computed: {
    ...mapGetters({
      show: "mode/getSearching",
    }),
  },
  mounted() {
    this.checkedAll();
  },
  watch: {
    selected() {
      if (this.selected.length == this.status.length) {
        this.allSelected = true;
      }
    },
  },
  methods: {
    ...mapActions({
      showSearch: "mode/setSearching",
    }),
    checkedAll() {
      for (let s in this.status) {
        this.selected.push(this.status[s].id);
      }
      this.allSelected = true;
    },
    deleteMarkers() {
      this.counter = 0;
      this.$emit("deleteOrdersMap", []);
    },
    async searchOrders() {
      if (this.selected.length) {
        this.loading = true;
        try {
          const response = await axios.post(`/ots-no-certificadas`, {
            status: this.selected,
          });
          this.counter = response.data.count;
          this.$emit("emitOrders", response.data);
        } catch (error) {
          console.log(error);
        }
        this.loading = false;
      } else {
        toastr.info("Debe seleccionar al menos un estado");
      }
    },
    selectAll: function () {
      this.selected = [];

      if (!this.allSelected) {
        for (let s in this.status) {
          this.selected.push(this.status[s].id);
        }
      }
    },
    select() {
      this.allSelected = false;
    },
  },
};
</script>
