import Vue from "vue";
import Vuex from 'vuex'
import auth from './auth'
import location from './location'
import management from './management'
import technical from './technical'
import warnings from './warnings'
import warningsOT from './OT'
import circuitTreeNode from './circuitTreeNode'
import Roles from './admin/Roles'
import Contratas from './admin/Contratas'
import SettLement from './admin/SettLement'
import orders from './admin/map/orders'
import technicals from './admin/techs/technical'
import mode from './map'
Vue.use(Vuex)

export default new Vuex.Store({
    state: {},
    mutations: {},
    getters: {},
    actions: {},
    modules: {
        auth,
        location,
        management,
        technical,
        warnings,
        warningsOT,
        circuitTreeNode,
        Roles,
        Contratas,
        SettLement,

        /** ------ Admin map  ------- */
        orders,
        technicals,

        // mode
        mode
    }
})