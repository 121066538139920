<template>
  <b-modal
    title="Reabrir caso"
    ref="information"
    id="information"
    v-model="show"
    size="xl"
    no-close-on-backdrop
    scrollable
  >
    <b-row>
      <b-col md="12">
        <div>
          <b-tabs content-class="mt-3" small>
            <b-tab title="Reabrir aviso" active>
              <b-row>
                <b-col md="4">
                  <div class="form-group">
                    <label>Causa</label>
                    <select
                      class="form-control form-control-sm"
                      v-model="causa"
                    >
                      <option
                        :value="item.id"
                        v-for="(item, idx) in causas"
                        :key="idx + '_CA_'"
                      >
                        {{ item.causa }}
                      </option>
                    </select>
                  </div>
                </b-col>
                <b-col md="12">
                  <label>Observación</label>
                  <textarea
                    class="form-control form-control-sm"
                    cols="5"
                    rows="5"
                    v-model="observacion"
                  ></textarea>
                </b-col>
              </b-row>
            </b-tab>
            <b-tab title="Información OT">
              <b-row>
                <b-col>
                  <DxDataGrid
                    :data-source="ot"
                    :show-borders="true"
                    :show-column-lines="true"
                    :show-row-lines="true"
                    :row-alternation-enabled="true"
                    :columnAutoWidth="true"
                    :allow-column-resizing="true"
                  >
                    <DxPaging :page-size="10" />
                    <DxHeaderFilter :visible="false" />
                    <DxFilterRow :visible="true">
                      <DxOperationDescriptions
                        equal="Igual a"
                        notEqual="Diferente de"
                        startsWith="Empieza con"
                        endsWith="Termina con"
                        contains="Contiene"
                        between="Entre"
                        notContains="No contiene"
                        lessThanOrEqual="Menor o igual"
                        greaterThanOrEqual="Mayor o igual"
                        lessThan="Menor que"
                        greaterThan="Mayor que"
                      />
                    </DxFilterRow>
                    <DxPager
                      :visible="true"
                      infoText="Página {0} of {1} ({2} registros)"
                      :allowed-page-sizes="pageSizes"
                      display-mode="full"
                      :show-page-size-selector="true"
                      :show-info="true"
                      :show-navigation-buttons="true"
                    />
                    <DxColumn data-field="num_ot" caption="N° OT" />
                    <DxColumn data-field="brigada" caption="Descripción" />
                    <DxColumn
                      data-field="fecha_asigna"
                      caption="Fecha asignación"
                      data-type="date"
                      format="dd/MM/yyyy, HH:mm:ss"
                    />

                    <DxColumn data-field="estado" caption="Estado" />
                  </DxDataGrid>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <DxDataGrid
                    :data-source="ref"
                    :show-borders="true"
                    :show-column-lines="true"
                    :show-row-lines="true"
                    :row-alternation-enabled="true"
                    :columnAutoWidth="true"
                    :allow-column-resizing="true"
                  >
                    <DxPaging :page-size="30" />
                    <DxHeaderFilter :visible="true" />
                    <DxFilterRow :visible="true">
                      <DxOperationDescriptions
                        equal="Igual a"
                        notEqual="Diferente de"
                        startsWith="Empieza con"
                        endsWith="Termina con"
                        contains="Contiene"
                        between="Entre"
                        notContains="No contiene"
                        lessThanOrEqual="Menor o igual"
                        greaterThanOrEqual="Mayor o igual"
                        lessThan="Menor que"
                        greaterThan="Mayor que"
                      />
                    </DxFilterRow>
                    <DxPager
                      :visible="true"
                      infoText="Página {0} of {1} ({2} registros)"
                      :allowed-page-sizes="pageSizes"
                      display-mode="full"
                      :show-page-size-selector="true"
                      :show-info="true"
                      :show-navigation-buttons="true"
                    />
                  </DxDataGrid>
                </b-col>
              </b-row>
            </b-tab>
            <b-tab lazy title="Bitacora" v-if="bitacora.length">
              <DxDataGrid
                :data-source="bitacora"
                :show-borders="true"
                :show-column-lines="true"
                :show-row-lines="true"
                :row-alternation-enabled="true"
                :columnAutoWidth="true"
                :allow-column-resizing="true"
              >
                <DxPaging :page-size="10" />
                <DxHeaderFilter :visible="true" />
                <DxFilterRow :visible="true">
                  <DxOperationDescriptions
                    equal="Igual a"
                    notEqual="Diferente de"
                    startsWith="Empieza con"
                    endsWith="Termina con"
                    contains="Contiene"
                    between="Entre"
                    notContains="No contiene"
                    lessThanOrEqual="Menor o igual"
                    greaterThanOrEqual="Mayor o igual"
                    lessThan="Menor que"
                    greaterThan="Mayor que"
                  />
                </DxFilterRow>
                <DxPager
                  :visible="true"
                  infoText="Página {0} of {1} ({2} registros)"
                  :allowed-page-sizes="pageSizes"
                  display-mode="full"
                  :show-page-size-selector="true"
                  :show-info="true"
                  :show-navigation-buttons="true"
                />
                <DxColumn
                  data-field="fecha"
                  caption="Fecha"
                  data-type="date"
                  format="dd/MM/yyyy, HH:mm:ss"
                />
                <DxColumn data-field="detalle" caption="Detalle" />
              </DxDataGrid>
            </b-tab>
            <b-tab
              lazy
              title="Información de precierre"
              v-if="movil.length || acciones.length"
            >
              <div class="form-group" v-if="movil.length">
                <label>Observación BOL</label>
                <textarea
                  rows="3"
                  :value="movil[0].observacion"
                  class="mb-3 form-control form-control-sm"
                  readonly
                ></textarea>
              </div>
              <b-row v-if="mano_obra.length">
                <b-col>
                  <DxDataGrid
                    :data-source="mano_obra"
                    :show-borders="true"
                    :show-column-lines="true"
                    :show-row-lines="true"
                    :row-alternation-enabled="true"
                    :columnAutoWidth="true"
                    :allow-column-resizing="true"
                  >
                    <DxPaging :page-size="10" />
                    <DxHeaderFilter :visible="true" />
                    <DxFilterRow :visible="true">
                      <DxOperationDescriptions
                        equal="Igual a"
                        notEqual="Diferente de"
                        startsWith="Empieza con"
                        endsWith="Termina con"
                        contains="Contiene"
                        between="Entre"
                        notContains="No contiene"
                        lessThanOrEqual="Menor o igual"
                        greaterThanOrEqual="Mayor o igual"
                        lessThan="Menor que"
                        greaterThan="Mayor que"
                      />
                    </DxFilterRow>
                    <DxPager
                      :visible="true"
                      infoText="Página {0} of {1} ({2} registros)"
                      :allowed-page-sizes="pageSizes"
                      display-mode="full"
                      :show-page-size-selector="true"
                      :show-info="true"
                      :show-navigation-buttons="true"
                    />
                    <DxColumn
                      data-field="fecha_ejecuta"
                      caption="Fecha ejecuta"
                      data-type="date"
                      format="dd/MM/yyyy, HH:mm:ss"
                    />
                    <DxColumn data-field="descripcion" caption="Descripción" />
                  </DxDataGrid>
                </b-col>
              </b-row>
              <b-row v-if="movil.length">
                <b-col>
                  <DxDataGrid
                    :data-source="movil"
                    :show-borders="true"
                    :show-column-lines="true"
                    :show-row-lines="true"
                    :row-alternation-enabled="true"
                    :columnAutoWidth="true"
                    :allow-column-resizing="true"
                  >
                    <DxPaging :page-size="10" />
                    <DxHeaderFilter :visible="true" />
                    <DxFilterRow :visible="true">
                      <DxOperationDescriptions
                        equal="Igual a"
                        notEqual="Diferente de"
                        startsWith="Empieza con"
                        endsWith="Termina con"
                        contains="Contiene"
                        between="Entre"
                        notContains="No contiene"
                        lessThanOrEqual="Menor o igual"
                        greaterThanOrEqual="Mayor o igual"
                        lessThan="Menor que"
                        greaterThan="Mayor que"
                      />
                    </DxFilterRow>
                    <DxPager
                      :visible="true"
                      infoText="Página {0} of {1} ({2} registros)"
                      :allowed-page-sizes="pageSizes"
                      display-mode="full"
                      :show-page-size-selector="true"
                      :show-info="true"
                      :show-navigation-buttons="true"
                    />
                    <!-- <DxColumn
                      data-field="fecha_ejecuta"
                      caption="Fecha ejecuta"
                      data-type="date"
                      format="dd/MM/yyyy, HH:mm:ss"
                    />
                    <DxColumn data-field="descripcion" caption="Descripción" /> -->
                  </DxDataGrid>
                </b-col>
              </b-row>
              <table
                class="table table-sm table-striped table-pane"
                v-if="movil.length && movil[0].causa"
              >
                <thead>
                  <tr>
                    <td colspan="2" class="text-center bg-secondary">CAUSAS</td>
                  </tr>
                  <tr>
                    <td>TITULO</td>
                    <td>DETALLE</td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>¿Hubo daño?</td>
                    <td>
                      {{ movil[0].causa.principal_id == 1 ? "SI" : "NO" }}
                    </td>
                  </tr>
                  <tr>
                    <td>Fecha recepción</td>
                    <td>{{ movil[0].causa.fecha_recepcion }}</td>
                  </tr>
                  <tr>
                    <td>Fecha arribo</td>
                    <td>{{ movil[0].causa.fecha_arribo_sitio }}</td>
                  </tr>
                  <tr>
                    <td>Fecha causa</td>
                    <td>{{ movil[0].causa.fecha_causa }}</td>
                  </tr>
                  <tr>
                    <td>Fecha finaliza</td>
                    <td>{{ movil[0].causa.fecha_finaliza }}</td>
                  </tr>
                  <tr>
                    <td>Coordenadas</td>
                    <td>
                      <span class="badge badge-secondary mr-2"
                        ><b>Lat:</b> {{ movil[0].causa.latitud }}
                      </span>
                      <span class="badge badge-secondary">
                        <b>Lng:</b> {{ movil[0].causa.longitud }}
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
              <b-row v-if="reglas_oro.length">
                <b-col>
                  <label>
                    <b>REGLAS DE ORO</b>
                  </label>
                  <DxDataGrid
                    :data-source="reglas_oro"
                    :show-borders="true"
                    :show-column-lines="true"
                    :show-row-lines="true"
                    :row-alternation-enabled="true"
                    :columnAutoWidth="true"
                    :allow-column-resizing="true"
                  >
                    <DxPaging :page-size="10" />
                    <DxHeaderFilter :visible="false" />
                    <DxFilterRow :visible="false">
                      <DxOperationDescriptions
                        equal="Igual a"
                        notEqual="Diferente de"
                        startsWith="Empieza con"
                        endsWith="Termina con"
                        contains="Contiene"
                        between="Entre"
                        notContains="No contiene"
                        lessThanOrEqual="Menor o igual"
                        greaterThanOrEqual="Mayor o igual"
                        lessThan="Menor que"
                        greaterThan="Mayor que"
                      />
                    </DxFilterRow>
                    <DxPager
                      :visible="true"
                      infoText="Página {0} of {1} ({2} registros)"
                      :allowed-page-sizes="pageSizes"
                      display-mode="full"
                      :show-page-size-selector="true"
                      :show-info="true"
                      :show-navigation-buttons="true"
                    />
                    <DxColumn data-field="codigo" caption="Código" />
                    <DxColumn data-field="descripcion" caption="Descripción" />
                    <DxColumn
                      data-field="fechaEjecuta"
                      caption="Fecha ejecuta"
                      data-type="date"
                      format="dd/MM/yyyy, HH:mm:ss"
                    />
                  </DxDataGrid>
                </b-col>
              </b-row>
            </b-tab>
            <b-tab title="Galeria" v-if="galeria.length != 0">
              <b-container fluid class="p-4 bg-secondary">
                <b-row>
                  <b-col
                    md="6"
                    class="mb-3"
                    v-for="(item, index) in galeria"
                    :key="index"
                  >
                    <b-img
                      thumbnail
                      fluid
                      :src="servidor + item.path"
                      :alt="item.nombre"
                    ></b-img>
                  </b-col>
                </b-row>
              </b-container>
            </b-tab>
            <b-tab
              lazy
              title="Datos de certificación"
              v-if="certificate.length"
            >
              <table class="table table-sm table-striped table-hover">
                <thead>
                  <tr>
                    <th class="text-center" colspan="2">
                      INFORMACIÓN DE CERTIFICACIÓN
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Observación operador</td>
                    <td>{{ certificate[0].observacion_cor }}</td>
                  </tr>
                  <tr>
                    <td>Observación brigada</td>
                    <td>{{ certificate[0].observacion_bol }}</td>
                  </tr>
                  <tr>
                    <td>¿Usuario queda con servicio?</td>
                    <td>
                      {{
                        certificate[0].usuario_con_servicio == 1 ? "SI" : "NO"
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td>Fecha de asignación</td>
                    <td>{{ certificate[0].fecha_asigna }}</td>
                  </tr>
                  <tr>
                    <td>Fecha recibe</td>
                    <td>{{ certificate[0].fecha_recibe }}</td>
                  </tr>
                  <tr>
                    <td>Fecha de arribo al sitio</td>
                    <td>{{ certificate[0].fecha_arribo }}</td>
                  </tr>
                  <tr>
                    <td>Fecha de resolución</td>
                    <td>{{ certificate[0].fecha_resolucion }}</td>
                  </tr>
                  <tr>
                    <td>Tipo incidencia</td>
                    <td>{{ certificate[0].tipo_incidencia_name }}</td>
                  </tr>
                  <tr>
                    <td>Tipo instalación</td>
                    <td>{{ certificate[0].tipo_instalacion_name }}</td>
                  </tr>
                  <tr>
                    <td>Tipo</td>
                    <td>{{ certificate[0].tipo_name }}</td>
                  </tr>
                  <tr>
                    <td>Causa</td>
                    <td>{{ certificate[0].causa_cert_name }}</td>
                  </tr>

                  <tr class="bg-secondary font-weight-bold">
                    <td>Usuario certifica</td>
                    <td>{{ certificate[0].usuario | upperCase }}</td>
                  </tr>
                </tbody>
              </table>
            </b-tab>
            <b-tab v-if="data.length != 0" title="Avisos">
              <b-row>
                <b-col>
                  <DxDataGrid
                    :allow-column-resizing="true"
                    :data-source="movil"
                    :show-borders="true"
                    :show-column-lines="true"
                    :show-row-lines="true"
                    :row-alternation-enabled="true"
                    :columnAutoWidth="true"
                    v-if="movil && movil.length"
                  >
                    <DxPaging :page-size="15" />
                    <DxHeaderFilter :visible="true" />
                    <DxPager
                      :visible="true"
                      infoText="Página {0} of {1} ({2} registros)"
                      :allowed-page-sizes="pageSizes"
                      display-mode="full"
                      :show-page-size-selector="true"
                      :show-info="true"
                      :show-navigation-buttons="true"
                    />
                  </DxDataGrid>
                </b-col>
              </b-row>
              <table
                class="table table-sm table-striped table-hover"
                v-for="(item, idx) in data"
                :key="idx + 'T_T'"
              >
                <thead>
                  <tr>
                    <td class="font-weight-bold">INFORMACIÓN</td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="font-weight-bold">N° de aviso</td>
                    <td>{{ item.num_aviso }}</td>
                  </tr>
                  <tr>
                    <td>Ind Peligro</td>
                    <td>{{ item.ind_peligro }}</td>
                  </tr>
                  <tr>
                    <td>VIP</td>
                    <td>{{ item.ind_cli_vip == 1 ? "SI" : "NO" }}</td>
                  </tr>
                  <tr>
                    <td>Tipo aviso</td>
                    <td>{{ item.tipo_aviso }}</td>
                  </tr>
                  <tr>
                    <td>Coordenadas</td>
                    <td>Lat: {{ item.latitud }} - Lng: {{ item.longitud }}</td>
                  </tr>
                  <tr>
                    <td>Municipio</td>
                    <td>{{ item.municipio }}</td>
                  </tr>
                  <tr>
                    <td>Subestación</td>
                    <td>{{ item.subestacion }}</td>
                  </tr>
                  <tr>
                    <td>Circuito</td>
                    <td>{{ item.circuito_sm }}</td>
                  </tr>
                  <tr>
                    <td>Tipo trabajo</td>
                    <td>
                      {{
                        item.tipo_trabajo_id == 1
                          ? "AVISO"
                          : item.tipo_trabajo_id == 2
                          ? "INCIDENCIA"
                          : "DESCARGO"
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td>Suscriptor</td>
                    <td>{{ item.nombre_suscriptor }}</td>
                  </tr>
                  <tr>
                    <td>Dirección suscriptor</td>
                    <td>{{ item.dir_suscriptor }}</td>
                  </tr>
                  <tr>
                    <td>Dirección referencia</td>
                    <td>{{ item.dir_ref }}</td>
                  </tr>
                  <tr>
                    <td>Observación</td>
                    <td>{{ item.observacion }}</td>
                  </tr>
                </tbody>
              </table>
            </b-tab>
            <b-tab v-if="activation.length" title="Datos de activación">
              <table
                class="table table-sm table-striped table-pane"
                v-if="activation && activation.length != 0"
              >
                <thead>
                  <tr>
                    <td colspan="3" class="text-center bg-secondary">
                      ACTIVACIÓN DE AVISO
                    </td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="text-center">#</td>
                    <td class="text-center">Descripción</td>
                    <td class="text-center">Fecha</td>
                  </tr>
                  <tr v-for="(item, idx) in activation" :key="'_ACT_' + idx">
                    <td class="text-center">{{ idx + 1 }}</td>
                    <td class="text-center">{{ item.observacion }}</td>
                    <td class="text-center">{{ item.created_at | format }}</td>
                  </tr>
                </tbody>
              </table>
            </b-tab>
          </b-tabs>
        </div>
      </b-col>
    </b-row>

    <template #modal-footer>
      <button @click="() => (show = false)" class="btn btn-sm btn-link">
        <i class="flaticon-close"></i> Cancelar
      </button>
      <button class="btn btn-primary btn-sm ml-2" @click="reabrirCaso()">
        <i class="la la-save"></i>
        {{ loading ? "Cargando..." : "Guardar" }}
      </button>
    </template>
  </b-modal>
</template>
<script>
import axios from "axios";
import moment from "moment";
import { mapGetters } from "vuex";
import toastr from "toastr";
import {
  DxDataGrid,
  DxPaging,
  DxColumn,
  // DxSearchPanel,
  DxOperationDescriptions,
  DxFilterRow,
  DxHeaderFilter,
  DxPager,
} from "devextreme-vue/data-grid";
import _ from "lodash";
export default {
  name: "informacion-ot",
  components: {
    DxDataGrid,
    DxPaging,
    DxColumn,
    // DxSearchPanel,
    DxOperationDescriptions,
    DxFilterRow,
    DxHeaderFilter,
    DxPager,
  },
  data() {
    return {
      servidor: "http://138.197.75.131:9000",
      pageSizes: [5, 10, 15, 30],
      show: false,
      data: [],
      ot: [],
      ref: [],
      bitacora: [],
      movil: [],
      reglas_oro: [],
      galeria: [],
      info_aviso: {},
      certificate: [],
      mano_obra: [],
      acciones: [],
      activation: [],
      //causas reabrir caso
      causas: [],
      causa: -1,
      observacion: "",
      loading: false,
    };
  },
  filters: {
    upperCase(value) {
      return value.replace(/_/g, " ").toUpperCase();
    },
    format(values) {
      return moment(values).format("LLL");
    },
    simpleFormat(value) {
      return moment(value).format("l") + " " + moment(value).format("LTS");
    },
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
  },
  methods: {
    reset() {
      this.data = [];
      this.ot = [];
      this.ref = [];
      this.bitacora = [];
      this.movil = [];
      this.reglas_oro = [];
      this.galeria = [];
      this.info_aviso = {};
      this.certificate = [];
      this.mano_obra = [];
      this.acciones = [];
      this.activation = [];
      this.causas = [];
      this.observacion = "";
      this.causa = -1;
    },
    loadCausas() {
      axios.get("causa-pendiente").then((res) => {
        this.causas = res.data;
        this.causas.push({
          id: -1,
          causa: "Seleccione",
        });
        this.causa = -1;
      });
    },
    load(id) {
      axios
        .get(`ots/${id}`)
        .then((res) => {
          this.data = [];
          this.ref = [];

          this.ot = res.data;
          this.activation = this.ot[0].activation;
          this.bitacora = this.ot[0].bitacora;
          this.movil = this.ot[0].movil;
          this.reglas_oro = this.ot[0].reglas_oro;
          this.galeria = this.ot[0].imagenes;
          this.certificate = this.ot[0].sync;
          this.mano_obra = this.ot[0].manoObra;
          this.acciones = this.ot[0].acciones;

          this.ot[0].brigada = this.ot[0].user
            ? this.ot[0].user.nombre
            : "NO ASIGNADO";
          this.ot[0].estado = this.ot[0].estado_o_t_s.descripcion;
          res.data.map((o) => {
            o.avisos.map((el) => {
              el.ind_cli_vip = el.ind_cli_vip != 0 ? "SI" : "NO";

              if (el.pivot.id_aviso_ref == el.pivot.id_aviso) {
                this.info_aviso = el;
                delete el.tipo_trabajo;
                delete el.updated_at;
                delete el.created_at;
                delete el.icono;
                delete el.estado;
                delete el.tipo_coordenada;
                delete el.id_aviso;
                delete el.tipo_trabajo_id;
                delete el.pivot;
                this.ref.push(el);
              } else {
                delete el.tipo_trabajo;
                delete el.updated_at;
                delete el.created_at;
                delete el.icono;
                delete el.estado;
                delete el.tipo_coordenada;
                delete el.id_aviso;
                delete el.tipo_trabajo_id;
                delete el.pivot;
                this.data.push(el);
              }
            });
          });
          let main = [];
          this.ref.map((el) => {
            for (const key in el) {
              main.push({
                informacion: key.replace("_", " ").toUpperCase(),
                detalle: el[key],
              });
            }
          });
          this.ref = main;

          let movil_temp = [];
          let cause = [
            "principal_id",
            "causa_id",
            "pregunta_id",
            "tipo_os",
            "resultado",
            "contrata",
            "tipo_cierre",
            "obj",
            "trafo_parcial",
            "created_at",
            "updated_at",
            "deleted_at",
          ];
          this.movil.map((el) => {
            for (const key in el) {
              if (!cause.includes(key) && !_.isObject(el[key])) {
                movil_temp.push({
                  titulo: key.replace("_", " ").toUpperCase(),
                  detalle: el[key],
                });
              }
              if (key === "trafo_parcial") {
                movil_temp.push({
                  titulo: "HAY " + key.replace("_", " ").toUpperCase(),
                  detalle: el[key] == 1 || el[key] == null ? "NO" : "SI",
                });
              }
            }
          });
          this.movil = movil_temp;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    reabrirCaso() {
      if (this.observacion === "" || this.causa == -1) {
        toastr.info("Complete la información");
      } else {
        this.loading = true;
        let data = {
          causa_id: this.causa,
          num_ot: this.ot[0].num_ot,
          observacion: this.observacion,
          usuario_genera: this.user.usuario,
        };
        axios.post("aviso-pendiente", data).then((res) => {
          if (!res.data.error) {
            this.$emit("loadMenu", true);
            this.loading = false;
            this.show = false;
          } else {
            toastr.info(res.data.message);
          }
        });
      }
    },
  },
};
</script>
