<template>
  <button
    class="btn btn-link btn-sm btn-circle window-vue-button-information ml-2 mt-2"
    @click="informationOrder()"
  >
    <i class="fas fa-info-circle text-info"></i> Información
  </button>
</template>
<script>
export default {
  props: {
    infoWinContent: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    informationOrder() {
      const information = this.infoWinContent;
      const [app] = this.$root.$children;
      const OTInformation = app.$children[3];
      OTInformation.load(information.id_ot);
      OTInformation.show = true;
    },
  },
};
</script>
<style>
.window-vue-button-information:hover {
  text-decoration: none;
}
</style>
