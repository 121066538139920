<template>
    <b-modal
        ref="certifcate-modal"
        id="certifcate-modal"
        v-model="showCertificate"
        size="xl"
        no-close-on-backdrop
        title="Certificar OT"
        scrollable
        hide-header-close
        >
        <b-row>
            <b-col md="6">
                <b-row>
                    <b-col md="12">
                        <div>
                            <b-card no-body>
                                <b-tabs content-class="mt-3" card small >
                                    <b-tab lazy title="Información OT" active>
                                        <table class="table table-hover table-striped table-sm">
                                            <thead>
                                                <tr>
                                                    <td colspan="2">Información de OT</td>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td> N° OT </td>
                                                    <td> {{ informationOT.num_ot }} </td>
                                                </tr>
                                                <tr>
                                                    <td> Fecha </td>
                                                    <td> {{ informationOT.created_at | format}} </td>
                                                </tr>
                                                <tr>
                                                    <td> Estado </td>
                                                    <td> <i :class="informationOT.icon"></i> {{ informationOT.estado }} </td>
                                                </tr>
                                                <tr>
                                                    <td colspan="2">
                                                        <strong>INFORMACIÓN AVISO</strong>
                                                    </td>
                                                </tr>
                                                <tr v-for="(aviso, index) in informationWarningOT" :key="index+'__'">
                                                    <td class="pl-3" v-if="index != 'tipo_trabajo_id' && index != 'tipo_trabajo_nombre' "> {{ index | upperCaseWithReplace }} </td>
                                                    <td class="pl-3" v-if="index != 'tipo_trabajo_id' && index != 'tipo_trabajo_nombre' "> {{ aviso }} </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </b-tab>
                                    <b-tab lazy title="Bitacora">
                                        <table class="table table-hover table-striped table-sm" >
                                            <thead>
                                                <tr>
                                                    <td colspan="2">Bitácora</td>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(item, index) in binnacle" :key="index">
                                                    <td class="datatable-cell">
                                                        <small>{{ item.fecha | simpleFormat }}</small>
                                                    </td>
                                                    <td>
                                                        <small>{{ item.detalle }}</small>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </b-tab>
                                    <b-tab lazy title="Información de precierre">
                                        <label>Observación BOL</label>
                                        <textarea 
                                            v-if="movil.length && movil[0].causa[0]"
                                            rows="3" 
                                            :value="movil[0].causa[0].observacion" 
                                            class="mb-3 form-control form-control-sm" 
                                            readonly></textarea>
                                        <table class="table table-sm table-striped" v-if="movil.length && movil[0].accion">
                                            <thead>
                                                <tr>
                                                    <td colspan="2" class="text-center bg-secondary">MANO DE OBRA</td>
                                                </tr>
                                                <tr>
                                                    <td> Descripción </td>
                                                    <td> Fecha ejecuta </td>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr 
                                                    v-for="(item, idx) in movil[0].accion" 
                                                    :key="idx">
                                                    <td> {{ item.descripcion }} </td>
                                                    <td> {{ item.fecha_ejecuta | format }} </td>
                                                </tr>
                                            </tbody>
                                        </table>

                                        <table class="table table-sm table-striped" v-if="movil.length && movil[0].causa.length != 0">
                                            <thead>
                                                <tr>
                                                    <td colspan="2" class="text-center bg-secondary"> CAUSAS </td>
                                                </tr>
                                                <tr>
                                                    <td> TITULO </td>
                                                    <td> DETALLE </td>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td> ¿Hubo daño? </td>
                                                    <td> {{ movil[0].causa[0].principal_id == 1 ? 'SI' : 'NO' }} </td>
                                                </tr>
                                                <tr>
                                                    <td> Causa </td>
                                                    <td> {{ movil[0].causa[0].causa_name }} </td>
                                                </tr>
                                                <tr>
                                                    <td> Fecha recepción </td>
                                                    <td> {{ movil[0].causa[0].fecha_recepcion }} </td>
                                                </tr>
                                                <tr>
                                                    <td> Fecha arribo </td>
                                                    <td> {{ movil[0].causa[0].fecha_arribo_sitio }} </td>
                                                </tr>
                                                <tr>
                                                    <td> Fecha causa </td>
                                                    <td> {{ movil[0].causa[0].fecha_causa }} </td>
                                                </tr>
                                                <tr>
                                                    <td> Fecha finaliza </td>
                                                    <td> {{ movil[0].causa[0].fecha_finaliza }} </td>
                                                </tr>
                                                <tr>
                                                    <td> Coordenadas </td>
                                                    <td> 
                                                        <span class="badge badge-secondary mr-2"><b>Lat:</b>  {{ movil[0].causa[0].latitud }} </span>  
                                                        <span class="badge badge-secondary"> <b>Lng:</b> {{ movil[0].causa[0].longitud }} </span>  
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>

                                        <table class="table table-sm table-striped table-pane" v-if="acciones && acciones.length != 0" >
                                            <thead>
                                                <tr>
                                                    <td colspan="3" class="text-center bg-secondary"> ACCIONES </td>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td class="text-center"> Código </td>
                                                    <td class="text-center"> Descripción </td>
                                                    <td class="text-center"> Fecha ejecuta </td>
                                                </tr>
                                                <tr v-for="(item, idx) in acciones" :key="'_ACCION_' + idx ">
                                                    <td class="text-center"> {{ idx +1 }} </td>
                                                    <td class="text-center"> {{ item.descripcion }} </td>
                                                    <td class="text-center"> {{ item.fecha_ejecuta }} </td>
                                                </tr>
                                            </tbody>
                                        </table>

                                        <table class="table table-sm table-striped" v-if="movil.length && movil[0].reglas_oro.length != 0">
                                            <thead>
                                                <tr>
                                                    <td colspan="3" class="text-center bg-secondary"> REGLAS DE ORO </td>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td class="text-center"> Código </td>
                                                    <td class="text-center"> Descripción </td>
                                                    <td class="text-center"> Fecha ejecuta </td>
                                                </tr>
                                                <tr>
                                                    <td class="text-center"> {{ movil[0].reglas_oro[0].codigo }} </td>
                                                    <td class="text-center"> {{ movil[0].reglas_oro[0].descripcion }} </td>
                                                    <td class="text-center"> {{ movil[0].reglas_oro[0].fechaEjecuta }} </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </b-tab>
                                    <b-tab lazy title="Materiales" v-if="materiales.length !=0">
                                        <table class="table table-sm table-striped table-hover table-borderless table-bordered">
                                            <thead>
                                                <tr>
                                                    <td colspan="4" class="text-center">
                                                        INFORMACIÓN DE MATERIALES
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="text-center">COD</td>
                                                    <td class="text-center">CANT</td>
                                                    <td class="text-center">DETALLE</td>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(item, idx) in materiales" :key="idx+'M'">
                                                    <td> {{ item.codmaterial }} </td>
                                                    <td> {{ item.cant_material }} </td>
                                                    <td> {{ item.descripcion }} </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </b-tab>
                                    <b-tab lazy title="Galería" v-if="images.length != 0">
                                        <b-container fluid class="p-4 bg-secondary">
                                            <b-row>
                                                <b-col md="6" class="mb-3" v-for="(item,index) in images" :key="index">
                                                    <b-img thumbnail fluid :src="servidor+item.path" :alt="item.nombre"></b-img>
                                                </b-col>
                                            </b-row>
                                        </b-container>
                                    </b-tab>
                                </b-tabs>
                            </b-card>
                        </div>
                        
                    </b-col>
                </b-row>
            </b-col>
            

            <b-col md="6">
                <b-row>
                    <b-col md="12">
                        <p>Certificar OT</p>
                        <b-form-group>
                            <b-form-textarea
                                rows="3"
                                v-model="data.observacion_cor"
                            />
                        </b-form-group>
                    </b-col>
                    <b-col md="6" class="mb-3">
                        <div class="checkbox-inline">
                            <label class="checkbox checkbox-outline checkbox-success">
                                <input
                                    type="checkbox"
                                    value="1"
                                    v-model="form.certificate.checked"
                                    @change="checkboxSelect(1)"
                                >
                                    <span></span> Certificar OT
                            </label>
                        </div>
                    </b-col>
                    <b-col md="6" class="mb-3">
                        <div class="checkbox-inline">
                            <label class="checkbox checkbox-outline checkbox-primary">
                                <input
                                    type="checkbox"
                                    v-model="form.check.checked"
                                    @change="checkboxSelect(2)">
                                    <span></span> Aviso pendiente
                            </label>
                        </div>
                    </b-col>
                    <transition name="component-fade" mode="out-in">
                        <b-col md="12" v-if="form.certificate.value == 1">
                            <b-row class="bg-secondary p-3">
                                <b-col md="12" class="mb-2">
                                    <label>Acción</label>
                                    <b-form-select
                                        @change="searchAction2('accion')"
                                        v-model="value.accion"
                                        :options="actions"
                                        size="sm"
                                        />
                                </b-col>
                                <b-col 
                                    md="12" 
                                    class="mb-2" 
                                    v-for="(item,index) in items"
                                    :key="index">
                                    <div v-if="item.show">
                                        <label>{{item.label}}</label>
                                        <b-form-select
                                            @change="searchAction2(item.vmodel)"
                                            v-model="value[item.vmodel]"
                                            :options="item.options"
                                            size="sm"
                                        />
                                    </div>
                                </b-col>
                                <b-col md="12" v-if="formSearch.tipo_incidencia == 4">
                                    <label>Lista incidencia </label>
                                    <Select2
                                        class="select2 mb-3"
                                        v-model="moop"
                                        :options="optionsTipInc"
                                        placeholder="Filtrar"
                                    />
                                </b-col>
                                <b-col md="12" v-if="formSearch.causal == 57">
                                    <label>Seleccione animal aéreo </label>
                                    <Select2 
                                        class="select2 mb-3"
                                        v-model="ave"
                                        :options="options"
                                        placeholder="Filtrar"
                                    />
                                </b-col>
                                
                            </b-row>
                        </b-col>

                        <b-col md="12" class="bg-secondary p-4" v-else-if="form.check.value == 2 ">
                            <b-row>
                                <b-col md="12" class="mt-4">
                                    <label>Motivo</label>
                                    <select class="form-control form-control-sm" v-model="causa">
                                        <option
                                            :value="item.id"
                                            v-for="(item, idx) in list"
                                            :key="idx"
                                            >
                                        {{ item.causa }}
                                        </option>
                                    </select>
                                </b-col>
                                <b-col>
                                    <label>Observación</label>
                                    <textarea
                                        cols="6"
                                        rows="6"
                                        v-model="observacion"
                                        class="form-control form-control-sm"
                                    ></textarea>
                                </b-col>
                            </b-row>
                        </b-col>
                    </transition>
                </b-row>
            </b-col>
        </b-row>
        <template #modal-footer>
            <button 
                class="btn btn-sm btn-link" 
                @click="hideModalCertificate()">
                <i class="la la-reply"></i> Cancelar
            </button>
            <b-button
                variant="outline-primary"
                size="sm"
                v-if="form.check.checked"
                @click="reabrirCaso()"
            >
                <i class="la la-save"></i> {{ loading ? "Cargando..." : "Guardar" }}
            </b-button>
            <b-button
                ref="buttonLoader"
                variant="primary"
                size="sm"
                @click="certify()"
                v-if="form.certificate.checked">
                <i class="la la-certificate"></i> Certificar
            </b-button>
        </template>
    </b-modal>
</template>
<script>
    import moment from 'moment'
    import toastr from 'toastr'
    import axios from 'axios'
    import Select2 from 'v-select2-component'
    import * as $ from 'jquery'
    import { mapGetters } from 'vuex'
    import _ from 'lodash'
    export default {
        name: 'ModalCertificate',
        components: { Select2 },
        props: {
            showCertificate: {
                type: Boolean,
                default: () => false
            },
            informationOT: {
                type: Object,
                default: () => {}
            },
            informationWarningOT: {
                type: Object,
                default: () => {}
            },
            binnacle: {
                type: Array,
                default: () => []
            },
            movil: {
                type: Array,
                default: () => []
            },
            causal: {
                type: Array,
                default: () => []
            },
            observation: {
                type: String,
                default: () => ''
            },
            causas: {
                type: Array,
                default: () => []
            },
            materiales: {
                type: Array,
                default: () => []
            },
            images: {
                type: Array,
                default: () => []
            },
            acciones: {
                type: Array,
                default: () => []
            }
        },
        data() {
            return {
                servidor: 'http://138.197.75.131:9000',
                options: [],
                form: {
                    check: {
                        checked: false,
                        value: null
                    },
                    certificate: {
                        checked: false,
                        value: null
                    }
                },
                settings: {
                    minimumResultsForSearch: Infinity
                },
                isLoading: false,
                data: {
                    cod_cierre: 1,
                    num_ot: this.informationOT.num_ot,
                    usuario_servicio: null,
                    causa: this.causal.length && this.causal[0].value ? this.causal[0].value : '',
                    fecha_asigna: this.informationOT.fecha_asigna,
                    fecha_recibe: this.movil[0].fecha_recepcion,
                    fecha_arribo: this.movil[0].fecha_arribo_sitio,
                    fecha_ot_fallida: this.movil[0].resultado != 1 ? this.movil[0].fecha_arribo_sitio : null,
                    fecha_localiza_causa: this.movil[0].fecha_causa,
                    fecha_resolucion: this.movil[0].fecha_finaliza,
                    observacion_cor: `AZT=${this.informationOT.user.nombre}[${this.informationOT.brigada}] ${moment().format('LLL')} - OT ${this.informationOT.num_ot}`,
                    observacion_bol: this.observation,
                    brigada: this.informationOT.brigada,
                    danio: this.movil[0].pregunta_id,
                    accion: this.informationWarningOT.tipo_trabajo_nombre == "AVISO" ? 0 : this.informationWarningOT.num_aviso,
                    usuario: this.$store.getters['auth/user'].usuario,
                    informacion: {}
                },
                actions: [
                    {
                        value: -1,
                        text: 'Seleccione',
                    },
                    {
                        value: 1,
                        text: 'Crear orden de trabajo'
                    },
                    {
                        value: 2,
                        text: 'Crear incidencia'
                    }
                ],
                formSearch: {
                    accion: -1,
                    tipo_incidencia: null,
                    usuario_servicio: null,
                    tipo_instalacion: null,
                    familia: null,
                    tipo: null,
                    causal: null,
                    causa_fallida: null
                },
                items: {
                    tipo_incidencia: {
                        id: 1,
                        show: false,
                    },
                    usuario_servicio: {
                        id: 2,
                        show: false,
                    },
                    tipo_instalacion: {
                        id: 3,
                        show: false,
                    },
                    familia: {
                        id: 4,
                        show: false,
                    },
                    tipo: {
                        id: 5,
                        show: false,
                    },
                    causal: {
                        id: 6,
                        show: false,
                    },
                    causa_fallida: {
                        id: 7,
                        show: false,
                    }
                },
                value: {
                    accion: -1,
                    tipo_incidencia: 0,
                    usuario_servicio: 0,
                    tipo_instalacion: 0,
                    familia: 0,
                    tipo: 0,
                    causal: 0,
                    causa_fallida: 0
                },
                levels: {
                    parent: null,
                    accion: null,
                    description: null,
                    data: [],
                    model: null
                },
                level_TI: {
                    descripcion: null,
                    accion: null,
                    parent: null,
                    description: null,
                    data: [],
                    model: null
                },
                level_US: {
                    descripcion: null,
                    accion: null,
                    parent: null,
                    description: null,
                    data: [],
                    model: null
                },
                ave: -1,
                optionsTipInc: [],
                moop: -1,
                //causas reabrir caso
                list: [],
                causa: -1,
                observacion: "",
                loading: false,
            }
        },
        computed: {
            ...mapGetters({
                user: "auth/user",
            }),
        },
        mounted() {
            this.loadCausasPendiente()
            this.loadAviso()
            this.configOptionSelect()
            let info = ''
            this.movil[0].accion.map((el) => {
                info += el.descripcion + '  \n';
            })
            this.data.observacion_cor = this.data.observacion_cor + '\n' + info
        },
        filters: {
            replace_(values) {
                return values.replace('_', ' ')
            },
            upperCaseWithReplace(value) {
                return value.replace(/_/g, " ").toUpperCase()
            },
            upperCase(values) {
                return values.toUpperCase()
            },
            format(values) {
                return moment(values).format('LLL')
            },
            simpleFormat(value) {
                return moment(value).format('l') + ' ' + moment(value).format('LTS')
            }
        },
        methods: {
            reabrirCaso() {
                if (this.observacion === "" || this.causa == -1) {
                    toastr.info("Complete la información");
                } else {
                    this.loading = true;
                    let data = {
                        causa_id: this.causa,
                        num_ot: this.informationOT.num_ot,
                        observacion: this.observacion,
                        usuario_genera: this.user.usuario,
                    };
                    axios.post("aviso-pendiente", data).then((res) => {
                    if (!res.data.error) {
                        this.$emit("loadMenu", true);
                        this.loading = false;
                        this.showCertificate = false;
                    } else {
                        toastr.info(res.data.message);
                    }
                    });
                }
            },
            loadCausasPendiente() {
                axios.get("causa-pendiente").then((res) => {
                    this.list = _.filter(res.data, function (o) {
                        return o.isCertificate;
                    });
                    this.list.push({
                        id: -1,
                        causa: "Seleccione",
                    });
                    this.causa = -1;
                });
            },
            loadAviso() {
                axios.get('ots')
                    .then((res) => {

                        this.optionsTipInc = [] 
                        this.optionsTipInc.push({id: -1, text: `Seleccione`})
                        res.data.map(el => {
                            if(el.tipo_trabajo_id == 1 && el.estado != 3 
                                && (el.id_ot == 1 || el.id_ot == 2 || el.id_ot == 3 || el.id_ot == 4 || el.id_ot == 5) 
                            )
                            {
                                this.optionsTipInc.push({id: el.num_aviso, text: `OT ${el.num_ot} - ${el.num_aviso}`})
                            }
                        })
                    })
            },
            hideModalCertificate() {
                this.checkWar = null;
                this.$emit('hideModalCertificate', false)
            },
            checkboxSelect(check) {
                if(check === 1) {
                    this.form.certificate.value = this.form.certificate.value == check ? null : check
                    this.form.check.value = null
                    this.form.check.checked = false

                } else {
                    this.form.check.value = this.form.check.value == check ? null : check
                    this.form.certificate.value = null
                    this.form.certificate.checked = false
                }
            },
            certify() {
                // this.data.informacion = this.formSearch
                this.data.fecha_arribo = this.data.fecha_arribo.replace(/-/g, "/")
                this.data.fecha_asigna = this.data.fecha_asigna.replace(/-/g, "/")
                this.data.fecha_localiza_causa = this.data.fecha_localiza_causa.replace(/-/g, "/")
                this.data.fecha_ot_fallida  = this.data.fecha_ot_fallida == null ? "null" : this.data.fecha_ot_fallida.replace(/-/g, "/")
                this.data.fecha_recibe      = this.data.fecha_recibe.replace(/-/g, "/")
                this.data.fecha_resolucion  = this.data.fecha_resolucion.replace(/-/g, "/")
                let F = this.formSearch
                let R =  this.data
                R.accion = 0
                R.causa_fallida = F.causa_fallida
                R.causal = F.causal
                R.familia = F.familia
                R.tipo = F.tipo
                R.tipo_incidencia = F.tipo_incidencia
                R.tipo_instalacion = F.tipo_instalacion
                R.usuario_con_servicio = F.usuario_servicio
                R.animal_aereo = this.ave
                R.incidencia = parseInt(this.moop)
                if(this.value.accion == 1) {
                    R.cod_cierre = 11
                } else if( this.value.accion == 2 ) {
                    if(F.tipo_incidencia == 1 ) {
                        R.cod_cierre = F.tipo_instalacion == 1 ? 22 : 23
                    }
                    if(F.tipo_incidencia == 2 ) {
                        R.cod_cierre = 41
                    }
                    if(F.tipo_incidencia == 3) {
                        R.cod_cierre = F.causa_fallida
                    }
                }

                if(this.validate())
                {
                    this.loaderSpinClasss()
                    axios.post('ots/certify', R)
                        .then((res) => {
                            this.removeLoaderSpinClass()
                            if(res.data.message == "success") {
                                toastr.success(res.data.message)
                                this.$emit('hideModalCertificate', false)
                            } else {
                                toastr.info(res.data.message);
                            }
                        })
                        .catch((e) => {
                            console.log(e);
                        })
                }
            },
            searchAction() {
                axios.post('createMatrizCts', this.formSearch)
                    .then((res) => {
                        var x = this.formSearch
                        var data = res.data
                        var l = this.levels
                        l.data = []
                        x.accion = data.accion
                        x.tipo_incidencia = data.tipo_incidencia != undefined ? data.tipo_incidencia : null
                        x.usuario_servicio = null
                        x.tipo_instalacion = null
                        x.familia = null
                        x.tipo = null
                        x.causal = null
                        x.causa_fallida = null



                        data.data.map(el => {
                            l.data.push({
                                text: el.descripcion,
                                value: el.idtipoincidencias
                            })
                        })
                        l.model = data.vmodel
                        l.description = data.descripcion
                        l.accion = data.accion
                        this.level_TI = {
                            descripcion: null,
                            accion: null,
                            parent: null,
                            description: null,
                            data: [],
                            model: null
                        }
                    })
            },
            searchAction2(vmodal) {
                $('#certifcate-modal___BV_modal_content_').attr('tabindex', null);
                this.formSearch[vmodal] = this.value[vmodal];
                let idChange = 0;
                if(vmodal != 'accion') idChange = this.items[vmodal].id;
                for(let item in this.items){
                    if(this.items[item].id > idChange){
                        this.items[item].show = false;
                        this.value[item] = null;
                        this.formSearch[item] = null;
                    }
                }
                if(this.formSearch.accion != -1 && vmodal != 'causa_fallida') {
                    axios.post('createMatrizCts', this.formSearch)
                        .then((res) => {
                            let vmodelNext = res.data.vmodelNext
                            let data = res.data.data;
                            if(data.length != 0){
                                if(vmodelNext != ''){
                                    let dataOptions = [];
                                    for(let c of data) {
                                        dataOptions.push({
                                            value: c.id,
                                            text: c.descripcion
                                        })
                                    }
                                    let title = res.data.descripcion ? res.data.descripcion : 'title'
                                    let idVmodalNext = this.items[vmodelNext].id
                                    this.items[vmodelNext] = {
                                        id: idVmodalNext,
                                        vmodel: vmodelNext,
                                        show: true,
                                        label: title,
                                        options: dataOptions,
                                    }

                                }
                            }
                            else{
                                this.formSearch[vmodelNext] = 0;
                                this.value[vmodelNext] = 0;
                                if(vmodelNext != 'causa_fallida') this.searchAction2(vmodelNext)
                            }
                    })
                }
            },
            configOptionSelect() {

                this.options = []
                this.options.push({
                    id: -1,
                    text: 'Seleccione'
                })
                axios.get('/aves')
                    .then((res) => {
                        res.data.map(el => {
                            this.options.push({
                                value:  el.cdmattoaves,
                                id:     el.codigo,
                                text:   el.nombreave,

                            })
                        })
                    })
            },

            validate() {
                let R = this.data
                let result = true;
                if(R.cod_cierre == -1 ) {
                    result = false;
                    toastr.info('Debe seleccionar una acción')
                }
                
                if(R.observacion_cor == '' || R.observacion_cor == null){
                    result = false;
                    toastr.info('Se require alguna observación para continuar')
                }
                if(this.formSearch.accion == 2){
                    for(let c in this.formSearch){
                        if(this.formSearch[c] == null){
                            result = false;
                            if(this.items[c].label != undefined)
                                toastr.info('El campo "'+ this.items[c].label +'" no debe aparecer vacio')
                        }
                    }
                }

                return result;

            },
            loaderSpinClasss(){
                this.isLoading = !this.isLoading
                const submitButton = this.$refs.buttonLoader;
                submitButton.classList.add("disabled","spinner", "spinner-success", "spinner-left");
            },
            removeLoaderSpinClass() {
                this.isLoading = !this.isLoading
                const submitButton = this.$refs.buttonLoader;
                submitButton.classList.remove("disabled","spinner", "spinner-success", "spinner-left");
            }

        }
    }
</script>