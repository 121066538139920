const MENU = [
    {
        id: 1,
        text: "Camb. zona",
        redirection: false,
        icon: "flaticon-placeholder-3 text-primary opacity-70",
    },
    {
        id: 2,
        text: "Home",
        name: "home",
        permission: "/home",
        redirection: true,
        icon: "fa fa-home text-info",
    },
    {
        id: 3,
        name: "ProcessesWorks",
        text: "Mapa",
        permission: "/works",
        icon: "flaticon-map-location text-primary",
        redirection: true,
    },
    {
        id: 4,
        name: "adminMap",
        text: "Mapa",
        permission: "/admin-map",
        icon: "flaticon-map-location text-primary",
        redirection: true,
    },

    {
        id: 5,
        name: "ProcessesReports",
        permission: "/reports",
        icon: "fa fa-file-alt text-primary",
        text: "Reportes",
        redirection: true,
    },
    {
        id: 7,
        name: "managementOT",
        text: "Admin. OT",
        permission: "/processes/management",
        icon: "fa fa-object-group text-primary",
        redirection: true,
    },
    {
        id: 8,
        name: "users",
        text: "Usuarios",
        permission: "/users",
        redirection: true,
        icon: "flaticon-user text-success",
    },
    {
        id: 9,
        name: "permissions",
        text: "Permisos",
        permission: "/permissions",
        redirection: true,
        icon: "flaticon-safe-shield-protection text-primary",
    },
    {
        id: 10,
        name: "roles",
        text: "Roles",
        permission: "/roles",
        redirection: true,
        icon: "flaticon-users-1 text-primary",
    },
    {
        id: 11,
        name: "turns",
        text: "Turnos",
        permission: "/turns",
        redirection: true,
        icon: "flaticon-calendar-with-a-clock-time-tools text-dark",
    },
    {
        id: 12,
        name: "vehicles",
        text: "Vehiculos",
        permission: "/vehicles",
        redirection: true,
        icon: "flaticon2-lorry text-dark opacity-80",
    },
    {
        id: 13,
        name: "days",
        text: "Jornadas",
        permission: "/days",
        redirection: true,
        icon: "flaticon-event-calendar-symbol text-info",
    },
    {
        id: 14,
        name: "settlement",
        text: "Liquidación",
        permission: "/settlement",
        redirection: true,
        icon: "la la-money-bill-wave-alt text-success",
    },
    {
        id: 15,
        name: "vehicleType",
        text: "Tipo Vehic.",
        permission: "/vehicle-type",
        redirection: true,
        icon: "flaticon2-lorry text-dark opacity-80",
    },
    {
        id: 16,
        name: "marks",
        text: "Marcas",
        permission: "/marks",
        redirection: true,
        icon: "flaticon-clipboard text-warning opacity-80",
    },
    {
        id: 17,
        name: "actions",
        text: "Acciones.",
        permission: "/actions",
        redirection: true,
        icon: "flaticon-web text-danger opacity-80",
    },
    {
        id: 17,
        name: "consulta-ws",
        text: "WS",
        permission: "/consulta-ws",
        redirection: true,
        icon: "flaticon-technology-2 text-info",
    },
    {
        id: 17,
        name: "brigadeTour",
        text: "Recorridos",
        permission: "/brigadeTour",
        redirection: true,
        icon: "flaticon-location text-primary",
    },
    {
        id: 17,
        name: "reportAdmin",
        text: "Informes",
        permission: "/reports-admin",
        redirection: true,
        icon: "flaticon-folder-1 text-danger opacity-80",
    },
    {
        id: 18,
        name: "OtherReport",
        text: "Detalle reporte",
        permission: "/other-report",
        redirection: true,
        icon: "flaticon-folder-1 text-danger opacity-80",
    },
    /**
     * PERMISOS CREADOS
     * name: /admin-warnings
     * Alias: Admin. Avisos SIEMENS
     */
    {
        id: 19,
        name: "AdminWarning",
        text: "Adm. Aviso",
        permission: "/admin-warnings",
        redirection: true,
        icon: "flaticon-exclamation text-info opacity-90",
    }
];

export default MENU;