export default {
    namespaced: true,
    state: {
        contratas: []
    },
    getters: {
        getContratas(state) {
            return state.contratas
        },
    },
    mutations: {
        SET_CONTRATA(state, value) {
            state.contratas = value
        },
    },
    actions: {
        setContratas({ commit }, value) {
            commit('SET_CONTRATA', value)
        },

    }
}