<template>
    <b-modal
        title="Cerrar turnos"
        ref="turnoff"
        id="turnoff"
        v-model="show"
        size="lg"
        no-close-on-backdrop
        scrollable>
        <div>
            <b-tabs content-class="mt-3" small>
                <b-tab title="Opciones" active>
                    <b-row>
                        <b-col md="6">
                            <label>Motivo de cierre de turno</label> 
                            <select v-model="causa" class="form-control form-control-sm mb-3">
                                <option 
                                    v-for="(item, idx) in items" 
                                    :key="idx"
                                    :value="item.causa_id"
                                >
                                    {{ item.causa }}
                                </option>
                            </select>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col md="12">
                            <label>Observación</label>
                            <textarea cols="3" rows="3" class="form-control form-control-sm" v-model="observacion"></textarea>
                        </b-col>
                    </b-row>
                </b-tab>
                <b-tab title="Ordenes" v-if="ots.length">
                    <b-row>
                        <b-col md="12">
                            <table class="table table-sm table-hover table-striped">
                                <thead>
                                    <tr>
                                        <td> N° de OT </td> 
                                        <td> Aviso </td>
                                        <td> Circuito </td>
                                        <td> Barrio </td>
                                        <td> Dirección </td>
                                        <td> Fecha de asignación </td>
                                        <td> Estado </td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr 
                                        v-for="(item, idx) in ots"
                                        :key="idx+'_OTS'">
                                        <td> {{ item.num_ot }} </td>
                                        <td> {{ item.aviso.num_aviso }} </td>
                                        <td> {{ item.aviso.circuito_sm }} </td>
                                        <td> {{ item.aviso.barrio }} </td>
                                        <td> {{ item.aviso.dir_suscriptor }} </td>
                                        <td> {{ item.fecha_asigna | formatDate}} </td>
                                        <td> 
                                            <i :class="item.estado_o_t_s.icono"></i> {{ item.estado_o_t_s.descripcion }} 
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </b-col>
                    </b-row>
                </b-tab>
            </b-tabs>
        </div>
        <template #modal-footer>
            <button 
                @click="() => { show = false;}"
                class="btn btn-sm btn-link">
                Cancelar
            </button>
            <button 
                class="btn btn-sm btn-primary"
                @click="turnOffClick()"
                >
                <i class="la la-hand-point-down"></i> {{ isLoading ? 'Cargando...' : 'Cerrar turno'}}
            </button>
        </template>
    </b-modal>
</template>
<script>
    import axios from 'axios'
    import moment from 'moment'
    import toastr from 'toastr'
    import { mapGetters } from 'vuex'
    export default {
        name: 'turn-off',
        data() {
            return {
                show: false,
                items: [],
                causa: null,
                ots: [],
                current: null,
                observacion: null,
                isLoading: false
            }
        },
        filters: {
            formatDate(values) {
                return moment(values).format('LL')
            }
        },
        computed: {
            ...mapGetters({
                user: 'auth/user'
            })
        },
        methods: {
            turnOffClick() {
                if(this.causa == null) {
                    toastr.info("Debe seleccionar una causa de cierre de turno")
                } else {
                    let config = {
                        origen: 0,
                        nif: this.current.cliente ? this.current.cliente.nif : '',
                        usuario_genera: this.user.usuario,
                        usuario: this.user.usuario,
                        brigada: this.current.usuario,
                        observacion: this.observacion == null ? 'N/A' : this.observacion ,
                        causal_id: this.causa,
                        base_nombre: this.current.base_nombre
                    }
                    this.isLoading = true
                    axios.post('cerrar-turno', config)
                        .then(() => {
                            this.$parent.treeMenu(this.$parent.data)
                            this.isLoading = false
                            this.show = false
                            this.$emit('loadMenu', {eventTurnOff: true})
                            toastr.success(`Se ha cerrado el turno a la brigada [${this.current.nombre}]`)
                        })
                        .catch(() => {
                            this.isLoading = false
                        })
                }
            },
            loadItems() {
                this.$nextTick(() => {

                    this.items = []
                    if(this.current) {
                        let data = {
                            brigada: this.current.usuario
                        }
                        axios.post('/informacion-turnos', data)
                            .then((res) => {
                                this.items = res.data.estados
                                this.ots   = res.data.ots
                            }) 
                    }
                })
            },
            reset() {
                this.items = []
                this.causa = null
            }
        }
    }
</script>