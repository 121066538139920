<template>
  <b-card class="w-full" v-if="movil">
    <b-row v-if="movil_2.pregunta && movil_2.cierre && movil_2.observacion">
      <b-col md="12">
        <div>
          <div
            class="alert"
            :style="`background-color: ${color.background}; color: #FFF;`"
          >
            {{ movil_2.pregunta ? movil_2.pregunta.pregunta_desc : "" }}
          </div>
          <div>
            <b>TIPO DE CIERRE:</b>
            {{ movil_2.cierre ? movil_2.cierre.descripcion : "" }}
          </div>
        </div>
        <hr />
        <div class="form-group">
          <label>Observación BOL</label>
          <textarea
            rows="3"
            :value="movil_2.observacion ? movil_2.observacion : ''"
            class="mb-3 form-control form-control-sm"
            readonly
          ></textarea>
        </div>
      </b-col>
    </b-row>
    <b-row v-else>
      <b-col>
        <div class="text-center">
          <img src="assets/img/empty.png" alt="Empty" />
          <p class="text-muted">No hay información para mostrar</p>
        </div>
      </b-col>
    </b-row>
    <b-row class="d-flex flex-colunm" v-if="acciones.length && movil.causa.length">
      <b-col md="12" class="mb-10">
        <b>ACCIONES</b>
        <DxDataGrid
          id="no-scroll-0"
          :data-source="acciones"
          :show-borders="true"
          :show-column-lines="true"
          :show-row-lines="true"
          :row-alternation-enabled="true"
          :columnAutoWidth="true"
          :allow-column-resizing="true"
        >
          <DxSearchPanel
            :visible="false"
            :width="240"
            placeholder="Buscar..."
          />
          <DxPaging :page-size="10" />
          <DxHeaderFilter :visible="true" />
          <DxFilterRow :visible="true">
            <DxOperationDescriptions
              equal="Igual a"
              notEqual="Diferente de"
              startsWith="Empieza con"
              endsWith="Termina con"
              contains="Contiene"
              between="Entre"
              notContains="No contiene"
              lessThanOrEqual="Menor o igual"
              greaterThanOrEqual="Mayor o igual"
              lessThan="Menor que"
              greaterThan="Mayor que"
            />
          </DxFilterRow>
          <DxPager
            :visible="false"
            infoText="Página {0} of {1} ({2} registros)"
            :allowed-page-sizes="pageSizes"
            display-mode="full"
            :show-page-size-selector="true"
            :show-info="true"
            :show-navigation-buttons="true"
          />
          <DxColumn
            css-class="scroll-x-hidden"
            data-field="descripcion"
            caption="Descripción"
          />
          <DxColumn
            css-class="scroll-x-hidden"
            data-field="fecha_ejecuta"
            caption="Fecha"
            data-type="date"
            format="dd/MM/yyyy, HH:mm:ss"
          />
        </DxDataGrid>
      </b-col>
      <b-col class="mb-3">
        <b>CAUSAS</b>
        <DxDataGrid
          id="no-scroll-1"
          :data-source="movil.causa"
          :show-borders="true"
          :show-column-lines="true"
          :show-row-lines="true"
          :row-alternation-enabled="true"
          :columnAutoWidth="true"
          :allow-column-resizing="true"
        >
          <DxSearchPanel
            :visible="false"
            :width="240"
            placeholder="Buscar..."
          />
          <DxPaging :page-size="15" />
          <DxHeaderFilter :visible="true" />
          <DxFilterRow :visible="true">
            <DxOperationDescriptions
              equal="Igual a"
              notEqual="Diferente de"
              startsWith="Empieza con"
              endsWith="Termina con"
              contains="Contiene"
              between="Entre"
              notContains="No contiene"
              lessThanOrEqual="Menor o igual"
              greaterThanOrEqual="Mayor o igual"
              lessThan="Menor que"
              greaterThan="Mayor que"
            />
          </DxFilterRow>
          <DxPager
            :visible="false"
            infoText="Página {0} of {1} ({2} registros)"
            :allowed-page-sizes="pageSizes"
            display-mode="full"
            :show-page-size-selector="true"
            :show-info="true"
            :show-navigation-buttons="true"
          />
          <DxColumn data-field="titulo" caption="Causa" />
          <DxColumn data-field="detalle" caption="Descripción" />
        </DxDataGrid>
      </b-col>
      <b-col class="mb-3" v-if="rulesGold.length">
        <b>REGLAS DE ORO</b>
        <DxDataGrid
          id="no-scroll-1"
          :data-source="rulesGold"
          :show-borders="true"
          :show-column-lines="true"
          :show-row-lines="true"
          :row-alternation-enabled="true"
          :columnAutoWidth="true"
          :allow-column-resizing="true"
        >
          <DxSearchPanel
            :visible="false"
            :width="240"
            placeholder="Buscar..."
          />
          <DxPaging :page-size="15" />
          <DxHeaderFilter :visible="true" />
          <DxFilterRow :visible="true">
            <DxOperationDescriptions
              equal="Igual a"
              notEqual="Diferente de"
              startsWith="Empieza con"
              endsWith="Termina con"
              contains="Contiene"
              between="Entre"
              notContains="No contiene"
              lessThanOrEqual="Menor o igual"
              greaterThanOrEqual="Mayor o igual"
              lessThan="Menor que"
              greaterThan="Mayor que"
            />
          </DxFilterRow>
          <DxPager
            :visible="false"
            infoText="Página {0} of {1} ({2} registros)"
            :allowed-page-sizes="pageSizes"
            display-mode="full"
            :show-page-size-selector="true"
            :show-info="true"
            :show-navigation-buttons="true"
          />
          <DxColumn data-field="fechaEjecuta" caption="Fecha" />
          <DxColumn data-field="descripcion" caption="Descripción" />
        </DxDataGrid>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  DxDataGrid,
  DxSearchPanel,
  DxPaging,
  DxHeaderFilter,
  DxFilterRow,
  DxPager,
  DxColumn,
  DxOperationDescriptions,
} from "devextreme-vue/data-grid";

export default {
  components: {
    DxDataGrid,
    DxSearchPanel,
    DxPaging,
    DxHeaderFilter,
    DxFilterRow,
    DxPager,
    DxColumn,
    DxOperationDescriptions,
  },
  props: {
    movil: {
      type: Array,
      default: () => [],
    },
    movil_2: {
      type: Object,
      default: () => ({}),
    },
    acciones: {
      type: Array,
      default: () => [],
    },
    rulesGold: {
      type: Array,
       default: () => [],
    }
  },
  computed: {
    color() {
      if (this.movil_2.pregunta_id) {
        let color = {};
        if (this.movil_2.pregunta_id == 1) {
          color = {
            background: "#AD631D",
            color: "#FFF",
          };
        } else if (this.movil_2.pregunta_id == 2) {
          color = {
            background: "#185967",
            color: "#FFF",
          };
        } else if (this.movil_2.pregunta_id == 3) {
          color = {
            background: "#156C5A",
            color: "#FFF",
          };
        } else if (this.movil_2.pregunta_id == 4) {
          color = {
            background: "#9V7910",
            color: "#FFF",
          };
        } else if (this.movil_2.pregunta_id == 5) {
          color = {
            background: "#5B3071",
            color: "#FFF",
          };
        } else if (this.movil_2.pregunta_id == 6) {
          color = {
            background: "#189E85",
            color: "#FFF",
          };
        }

        return color;
      } else {
        return {
          background: "#F3F3F3",
          color: "#333",
        };
      }
    },
  },
  data() {
    return {
      pageSizes: [5, 10, 15, 30],
    };
  },
};
</script>
<style>
/* no scroll-x tables[DxDataGrid] in tabs aviso, bitacora, informe de precierre */
#no-scroll-0
  > .dx-datagrid
  > .dx-datagrid-headers
  > .dx-datagrid-content
  > .dx-datagrid-table
  > tbody
  > tr
  > td {
  overflow-x: hidden !important;
}

#no-scroll-1
  > .dx-datagrid
  > .dx-datagrid-headers
  > .dx-datagrid-content
  > .dx-datagrid-table
  > tbody
  > tr
  > td {
  overflow-x: hidden !important;
}

#no-scroll-2
  > .dx-datagrid
  > .dx-datagrid-headers
  > .dx-datagrid-content
  > .dx-datagrid-table
  > tbody
  > tr
  > td {
  overflow-x: hidden !important;
}

#no-scroll-3
  > .dx-datagrid
  > .dx-datagrid-headers
  > .dx-datagrid-content
  > .dx-datagrid-table
  > tbody
  > tr
  > td {
  overflow-x: hidden !important;
}
</style>
