<template>
    <div class="floating-container" :style="'height:' + (!isSendMessage ? ' 0px ' : '350px ') + '!important;' ">
        <div 
            class="floating-button" 
            @click="() => isSendMessage = !isSendMessage ">
            <i class="fas fa-comments ico"></i>
        </div>
        <transition name="slide-fade">
            <div class="element-container" v-if="isSendMessage">
                <div class="ba-we-love-subscribers popup-ani float-element" >
                    <h5>Envio de notificaciones </h5>
                     <Select2 
                        class="select2 mb-3"
                        v-model="worker"
                        :multiple='true'
                        :options="configOptionSelect"
                    />

                    <b-form-textarea
                        id="textarea"
                        v-model="message"
                        placeholder="Mensaje..." 
                        rows="7"
                        :maxlength="max"
                    ></b-form-textarea>
                    <label class="limit" align="left"> Caracteres restantes: {{max - message.length}} </label>
                    <br>
                    <div class="d-flex justify-content-end">
                        <b-button size="sm" variant="success" v-on:click="sendNotifBrigada()" >
                            <i class="fas fa-paper-plane"></i> Enviar
                        </b-button>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>
<style>
    @import url('../css/floatingButtonNotification.css');
</style>
<script>
    import { mapGetters } from 'vuex'
    import Select2 from 'v-select2-component'
    import axios from 'axios'

    const FCM = 'https://fcm.googleapis.com/fcm/send';
    const API = 'AAAAg0ddbp0:APA91bHGr-ogZzdWgDSeW_Kb8a3a6QVQBfqqLBfjPQe-PqwF3sSfstEKFTAbYfT9suMVOe5MeTQKrpZUvgP3Hh1qy1n0OsAr8PngZ9ZOkyASytOZMp_7I-BlKBpGUEXKjjTH9KKie8sC';

    export default {
        name: 'button-floating-notification',
        components: {Select2},
        data() {
            return {
                isSendMessage: false,
                worker: -1,
                message: '',
                max: 300
            }
        },
        computed: {
            ...mapGetters({
                getTecnical: 'technical/getTecnical'
            }),
            configOptionSelect() {
                let options = []
                options.push({
                    id: -1,
                    text: 'Seleccione'
                })
                this.getTecnical.map(worker => {
                    options.push({
                        id: worker.usuario,
                        text: worker.nombre
                    })
                })
                return options;
            },
        },
        methods: {
            sendNotifBrigada(){
                // Duplicar variable this
                let self = this
                // Validacion de campos 
                if(self.message=='' || self.worker == -1){
                    this.$swal.fire({
                            title: 'Error',
                            buttonDenied: true,
                            text: 'Los campos no pueden estar vacio',
                            icon: 'error'
                        })
                } else {

                    // Envio de datos
                   var data={
                        message:self.message,
                        worker:self.worker
                    }
                    axios.post(FCM, data,API)
                        // Success
                        .then((res) => {
                            console.log(res)
                            //Action - success
                            axios.post('/send-notificaciones-brigadas')
                                .then((res) => {
                                    console.log(res)
                                    self.worker= -1
                                    self.message= ''
                                })
                        })
                        // Error en el envio
                        .catch((res) => {
                            this.$swal.fire({
                                title: 'Error',
                                text: 'El mensaje no se pudo enviar.Intenta Nuevamente' + res,
                                icon: 'error'
                            })
                        })
    
                    console.log(data)
    
                    }
                }
        }
    }
</script>