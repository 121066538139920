<template>
  <b-modal
    title="Gestión de peligro"
    ref="gestionPeligro"
    id="gestionPeligro"
    v-model="isShow"
    size="md"
    no-close-on-backdrop
    scrollable
  >
    <b-row center class="mb-3">
      <b-col md="12">
        <table class="table table-sm table-striped table-hover">
          <thead>
            <tr>
              <td>N° aviso</td>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in num_aviso" :key="index">
              <td class="font-weight-bold">{{ item }}</td>
            </tr>
          </tbody>
        </table>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="6">
        <div class="form-group">
          <div class="checkbox-inline">
            <label class="checkbox checkbox-outline checkbox-success">
              <input type="checkbox" v-model="isDanger" @change="validate" />
              <span></span>
              ¿Es un aviso de peligro?
            </label>
          </div>
        </div>
      </b-col>
    </b-row>
    <transition name="fade">
      <b-row v-if="isDanger">
        <b-col md="12">
          <div class="form-group">
            <label>Seleccione Causa</label>
            <select
              class="form-control form-control-sm"
              v-model="selectCheckedC"
              @change="filterActions"
            >
              <option
                v-for="(item, index) of optionCheckedC"
                :key="index"
                :value="item.id_causa_peligro"
                @click="optionCheckedA = item.acciones"
              >
                {{ item.descripcion }}
              </option>
            </select>
          </div>
          <div class="form-group" v-if="selectCheckedC != -1">
            <label>Acción</label>
            <select
              class="form-control form-control-sm"
              v-model="selectCheckedA"
              @change="validate"
            >
              <option
                v-for="(item, index) of optionCheckedA"
                :key="index"
                :value="item.id_accion_peligro"
              >
                {{ item.descripcion }}
              </option>
            </select>
          </div>
        </b-col>
      </b-row>
      <b-row v-else>
        <b-col md="12">
          <label>Seleccione Causa</label>
          <select
            class="form-control form-control-sm"
            v-model="selectUnchecked"
            @change="validate"
          >
            <option :value="-1">Seleccione</option>
            <option
              v-for="(item, index) of optionUnchecked"
              :key="index"
              :value="item.id_causa_peligro"
            >
              {{ item.descripcion }}
            </option>
          </select>
        </b-col>
      </b-row>
    </transition>
    <template #modal-footer>
      <button @click="() => (isShow = false)" class="btn btn-sm btn-link">
        <i class="flaticon-close"></i> Cancelar
      </button>
      <button
        v-if="showBtnCheck"
        @click="sendRequest"
        class="btn btn-primary btn-sm"
      >
        <i class="la la-check-double"></i>
        {{ isLoading ? "Cargando..." : "Confirmar" }}
      </button>
    </template>
  </b-modal>
</template>
<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
<script>
import axios from "axios";
import _ from "lodash";
import { mapGetters } from "vuex";
export default {
  name: "gestion-peligro",
  data() {
    return {
      showBtnCheck: false,
      isShow: false,
      num_aviso: null,
      estado: null,
      isDanger: false,
      accion: 0,
      optionCheckedC: [],
      optionCheckedA: [],
      optionUnchecked: [],
      selectCheckedC: -1,
      selectCheckedA: -1,
      selectUnchecked: -1,
      isLoading: false,
    };
  },
  mounted() {
    this.loadOptions();
  },
  watch: {
    isDanger: function (value) {
      if (value) this.accion = 1;
      else this.accion = 0;
    },
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
  },
  methods: {
    loadOptions() {
      axios.get("/gestion-peligro").then((res) => {
        if (res.data) {
          let dataAccion0 = _.filter(res.data.causa, ["accion", 0]);
          let dataAccion1 = _.filter(res.data.causa, ["accion", 1]);
          this.optionUnchecked = dataAccion0;
          this.optionCheckedC = dataAccion1;
        }
      });
    },
    filterActions() {
      let filter = _.filter(this.optionCheckedC, [
        "id_causa_peligro",
        this.selectCheckedC,
      ]);
      if (filter.length > 0) {
        this.optionCheckedA = filter[0].acciones;
      }
    },
    validate() {
      this.$nextTick(() => {
        if (!this.isDanger) {
          if (this.selectUnchecked != -1) this.showBtnCheck = true;
          else this.showBtnCheck = false;
        } else {
          if (this.selectCheckedC != -1 && this.selectCheckedA != -1)
            this.showBtnCheck = true;
          else this.showBtnCheck = false;
        }
      });
    },
    sendRequest() {
      let action = null;
      if (this.isDanger) {
        action = this.selectCheckedA;
      }
      let config = {
        num_aviso: this.num_aviso,
        usuario: this.user.usuario,
        indicador_peligro: this.accion,
        causa:
          this.selectCheckedC != -1
            ? this.selectCheckedC
            : this.selectUnchecked,
        accion: action,
        estado: this.estado,
      };
      this.isLoading = true;
      axios
        .post("ind-peligro-aviso", config)
        .then((res) => {
          console.log(res.data);
          this.$emit("emitGestionPeligro", {
            message: "Operación realizada con éxito",
            close: false,
          });
          this.isLoading = false;
          this.isShow = false;
        })
        .catch((e) => {
          console.log(e);
          this.isLoading = false;
        });
    },
  },
};
</script>
