<template>
  <div class="topbar mt-2">
    <div class="topbar-item ml-5">
      <div class="dropdown">
        <!--begin::Toggle-->
        <div
          class="topbar-item"
          data-toggle="dropdown"
          data-offset="10px,0px"
          v-if="can('notifications')"
        >
          <div
            class="btn btn-icon btn-clean btn-dropdown btn-lg mr-1 pulse pulse-primary"
          >
            <span class="notification" v-if="nNotifications > 0">
              {{ nNotifications }}
            </span>
            <i
              :class="
                'flaticon2-bell-2 ' +
                (nNotifications > 0 ? 'text-danger' : 'text-primary')
              "
            ></i>
            <span class="pulse-ring" v-if="nNotifications > 0"></span>
          </div>
        </div>

        <div
          class="dropdown-menu p-0 m-0 dropdown-menu-right dropdown-menu-anim-up dropdown-menu-lg"
          style=""
        >
          <div>
            <div
              class="d-flex flex-column pt-12 pb-12 bgi-size-cover bgi-no-repeat rounded-top"
              style="background-image: url(/assets/media/misc/bg-1.jpg)"
            >
              <h4 class="d-flex flex-center rounded-top">
                <span class="text-white">Notificaciones</span>
              </h4>
            </div>
            <b-tabs v-model="tabIndex" card>
              <b-tab title="Autorizaciones" class="nav-link">
                <transition name="slide-fade">
                  <div
                    class="tab-pane bg-white rounded-sm"
                    id="topbar_notifications_events"
                    role="tabpanel"
                    tabindex="0"
                  >
                    <div
                      class="navi navi-hover scroll my-4 ps"
                      data-scroll="true"
                      data-height="300"
                      data-mobile-height="200"
                      style="max-height: 300px; overflow: auto !important"
                    >
                      <div
                        class="navi-item"
                        v-for="(item, idx) in notifications"
                        :key="idx"
                      >
                        <div v-if="idx < 10">
                          <router-link
                            :to="{
                              name: 'authorization',
                              params: { id: item.id },
                            }"
                          >
                            <div class="navi-link">
                              <div class="navi-icon mr-2">
                                <i class="flaticon2-mail-1 text-primary"></i>
                              </div>
                              <div class="navi-text">
                                <div class="font-weight-bold">
                                  {{ item.text }}
                                </div>
                                <div class="text-muted">{{ item.from }}</div>
                              </div>
                            </div>
                          </router-link>
                        </div>
                      </div>
                      <div class="d-flex justify-content-center">
                        <router-link to="/notifications"> Ver más </router-link>
                      </div>
                    </div>
                    <!--end::Nav-->
                  </div>
                </transition>
              </b-tab>
              <b-tab :title="sTitleTask" class="nav-link">
                <transition name="slide-fade">
                  <div
                    class="tab-pane bg-white rounded-sm"
                    id="topbar_notifications_tasks"
                    role="tabpanel"
                    tabindex="0"
                  >
                    <div
                      class="navi navi-hover scroll my-4 ps"
                      data-scroll="true"
                      data-height="300"
                      data-mobile-height="200"
                      style="max-height: 300px; overflow: auto !important"
                    >
                      <div
                        class="navi-item"
                        v-for="(item, idx) in aTareas"
                        :key="idx"
                      >
                        <div v-if="idx < 20">
                          <!-- <router-link
                      :to="{ name: 'task', params: { id: item.id } }"
                      > -->
                          <div class="navi-link">
                            <div class="navi-icon mr-2">
                              <i class="flaticon2-mail-1 text-primary"></i>
                            </div>
                            <div class="navi-text">
                              <div class="font-weight-bold">
                                {{ item.text }}
                              </div>
                              <div class="text-muted">{{ item.from }}</div>
                            </div>
                          </div>
                          <!-- </router-link> -->
                        </div>
                      </div>
                      <!-- <div class="d-flex justify-content-center">
                    <router-link to="/notifications"> Ver más </router-link>
                  </div> -->
                    </div>
                    <!--end::Nav-->
                  </div>
                </transition>
              </b-tab>
              <b-tab :title="sTitleFailSoaEvent" class="nav-link">
                <transition name="slide-fade">
                  <div
                    class="tab-pane bg-white rounded-sm"
                    id="topbar_notifications_tasks_2"
                    role="tabpanel"
                    tabindex="0"
                  >
                    <div
                      class="navi navi-hover scroll my-4 ps"
                      data-scroll="true"
                      data-height="300"
                      data-mobile-height="200"
                      style="max-height: 300px; overflow: auto !important"
                    >
                      <div
                        class="navi-item"
                        v-for="(item, idx) in notificationsFailEvents"
                        :key="idx"
                      >
                        <div v-if="idx < 20">
                      
                          <div class="navi-link">
                            <div class="navi-icon mr-2">
                              <i class="flaticon2-mail-1 text-primary"></i>
                            </div>
                            <div class="navi-text">
                              <div class="font-weight-bold">
                                {{ item.text }}
                              </div>
                              <div class="text-muted">{{ item.from }}</div>
                            </div>
                          </div>
                          <!-- </router-link> -->
                        </div>
                      </div>
                    
                    </div>
                    <!--end::Nav-->
                  </div>
                </transition>
              </b-tab>
              <b-tab :title="sTitleSuccessSoaEvent" class="nav-link">
                <transition name="slide-fade">
                  <div
                    class="tab-pane bg-white rounded-sm"
                    id="topbar_notifications_tasks_2"
                    role="tabpanel"
                    tabindex="0"
                  >
                    <div
                      class="navi navi-hover scroll my-4 ps"
                      data-scroll="true"
                      data-height="300"
                      data-mobile-height="200"
                      style="max-height: 300px; overflow: auto !important"
                    >
                      <div
                        class="navi-item"
                        v-for="(item, idx) in notificationsSuccessEvents"
                        :key="idx"
                      >
                        <div v-if="idx < 20">
                          <!-- <router-link
                      :to="{ name: 'task', params: { id: item.id } }"
                      > -->
                          <div class="navi-link">
                            <div class="navi-icon mr-2">
                              <i class="flaticon2-mail-1 text-primary"></i>
                            </div>
                            <div class="navi-text">
                              <div class="font-weight-bold">
                                {{ item.text }}
                              </div>
                              <div class="text-muted">{{ item.from }}</div>
                            </div>
                          </div>
                          <!-- </router-link> -->
                        </div>
                      </div>
                      <!-- <div class="d-flex justify-content-center">
                    <router-link to="/notifications"> Ver más </router-link>
                  </div> -->
                    </div>
                    <!--end::Nav-->
                  </div>
                </transition>
              </b-tab>
            </b-tabs>
          </div>
        </div>
      </div>
      <transition name="slide-fade">
        <div
          class="row mr-2 ml-1"
          v-if="this.$route.path == '/works' && can('view-certificated-ot')"
        >
          <div class="dropdown d-flex justify-content-end">
            <a
              @click="hideOrShow()"
              class="btn btn-sm btn-icon btn-outline-success btn-circle mr-2 dropbtn"
            >
              <i class="flaticon-pin"></i>
            </a>
            <transition name="slide-fade">
              <div class="dropdown-content" v-if="showing" :style="blocking">
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group form-group-sm">
                      <label>Fecha inicial</label>
                      <input
                        type="datetime-local"
                        v-model="form.fechai"
                        class="form-control form-control-sm"
                      />
                      <label class="mt-1">Fecha final</label>
                      <input
                        type="datetime-local"
                        v-model="form.fechaf"
                        :min="form.fechai"
                        class="form-control form-control-sm"
                      />
                    </div>
                    <div class="form-group">
                      <label>Tipo de aviso</label>
                      <div class="checkbox-list">
                        <label
                          :class="check.class"
                          v-for="(check, idx) in checks"
                          :key="idx"
                        >
                          <input
                            :id="`${check.id}`"
                            type="checkbox"
                            :value="check.value"
                            v-model="form.params"
                          />
                          <span></span>
                          {{ check.text }}
                        </label>
                      </div>
                    </div>
                    <div>
                      <button
                        class="btn btn-sm btn-outline-success mt-3"
                        :disabled="bLoad"
                        @click="
                          () => {
                            searchParams();
                          }
                        "
                      >
                        <i class="la la-search"></i>
                        {{ bLoad ? "Cargando..." : "Buscar" }}
                      </button>
                      <button
                        class="btn btn-sm btn-link"
                        @click="
                          () => {
                            setFormSearch(null);
                            reset();
                          }
                        "
                      >
                        <i class="la la-trash"></i> Limpiar
                      </button>
                    </div>
                    <transition name="bounce">
                      <div class="row mt-3" v-if="avisos.length != 0">
                        <b-col md="9">
                          <label>Ubicar aviso</label>
                          <input
                            v-model="sAviso"
                            type="text"
                            class="form-control form-control-sm"
                            maxlength="8"
                            placeholder="N° de aviso"
                            name="location-warning"
                            list="location-warning"
                          />
                          <datalist id="location-warning">
                            <option
                              v-for="(item, idx) in avisos"
                              :value="item.ordenNumber"
                              :key="`${idx}_AV__`"
                            >
                              {{ item.ordenNumber }}
                            </option>
                          </datalist>
                        </b-col>
                        <b-col md="3">
                          <button
                            class="btn btn-sm btn-icon btn-circle btn-outline-primary mt-8"
                            :disabled="!sAviso.length"
                            @click="ubicateCertified()"
                          >
                            <i class="la la-map-marker"></i>
                          </button>
                        </b-col>
                      </div>
                    </transition>
                  </div>
                </div>
              </div>
            </transition>
          </div>
        </div>
      </transition>
      <div
        class="btn btn-icon w-auto btn-clean d-flex align-items-center btn-lg px-2"
        id="kt_quick_user_toggle"
        @click="quickUserPanelToggle()"
      >
        <span
          class="text-muted font-weight-bold font-size-base d-none d-md-inline mr-1"
          >Hola,</span
        >
        <span
          class="text-dark-50 font-weight-bolder font-size-base d-none d-md-inline mr-3"
          >{{ user.nombre | upperCase }}</span
        >
        <span class="symbol symbol-35 symbol-light-primary">
          <span class="symbol-label font-size-h5 font-weight-bold">
            {{ user.nombre.charAt(0).toUpperCase() }}
          </span>
        </span>
      </div>
    </div>
  </div>
</template>
<script>
import moment from "moment";
import { mapActions, mapGetters } from "vuex";
import _ from "lodash";
import can from "@/permission";
import axios from "axios";
import toastr from "toastr";
export default {
  data() {
    return {
      tabIndex: 0,
      notifications: [],
      aTareas: [],
      sTitleTask: "",
      avisos: [],
      sAviso: "",
      items: [
        {
          id: "1_1",
          name: "ProcessesWorks",
          text: "trabajos",
          image: "assets/media/svg/icons/Text/Text-height.svg",
          submenu: false,
          redirection: true,
        },
        {
          id: "1_3",
          name: "ProcessesReports",
          text: "Reportes",
          image: "assets/media/svg/icons/Files/File.svg",
        },
        {
          id: "1_4",
          name: "stadistics",
          text: "estadísticas",
          image: "assets/media/svg/icons/Shopping/Chart-bar1.svg",
        },
        {
          id: 21,
          name: "managementOT",
          text: "Admin. OT",
          image: "assets/media/svg/icons/Devices/Diagnostics.svg",
          submenu: false,
          redirection: true,
        },
        {
          id: 2,
          name: "geoanalisys",
          text: "geoanálisis",
          image: "assets/media/svg/icons/Map/Marker1.svg",
          submenu: false,
          redirection: true,
        },
        {
          id: 3,
          image: "assets/media/svg/icons/Communication/Group.svg",
          name: "management",
          text: "Administración",
        },
        {
          id: 4,
          image: "assets/media/svg/icons/Files/User-folder.svg",
          name: "users",
          text: "Usuarios",
        },
      ],
      showing: false,
      isLoading: false,
      form: {
        idx: 0,
        fechai: moment().format("YYYY-MM-DDT08:00"),
        fechaf: moment().format("YYYY-MM-DDTHH:mm"),
        params: [6, -6, 10],
      },
      checks: [
        {
          value: 6,
          text: "Certificados",
          class: "checkbox checkbox-success",
        },
        {
          value: -6,
          text: "Fallidos",
          class: "checkbox checkbox-danger",
        },
        {
          value: 10,
          text: "Improcedentes",
          class: "checkbox checkbox-warning",
        },
      ],

      // succes - fails events
      sTitleSuccessSoaEvent: "",
      sTitleFailSoaEvent: "",

      notificationsSuccessEvents: [],
      notificationsFailEvents: [],
    };
  },
  mounted() {
    if (this.can("notifications")) {
      this.loadNotifications();

      setInterval(() => {
        this.loadNotifications();
      }, 60 * 1000);
    }

    this.onGetTasksNotifications();

    setInterval(() => {
      this.onGetTasksNotifications();
    }, 60 * 2000);
  },
  computed: {
    ...mapGetters({
      authenticated: "auth/authenticated",
      user: "auth/user",
      showCertificated: "warningsOT/getOTCertificated",
      bLoad: "warningsOT/getBsearching",
    }),
    blocking() {
      return `display: ${this.showing ? "block" : "none"};`;
    },
    nNotifications() {
      const nNotifys =
        this.notifications.length +
        this.aTareas.length +
        this.notificationsSuccessEvents.length +
        this.notificationsFailEvents.length;
      return nNotifys >= 100 ? 99 + "+" : nNotifys;
    },
  },
  filters: {
    upperCase(value) {
      if (!value) return "";
      value = value.toString();
      return value.toUpperCase();
    },
  },

  methods: {
    can: can,
    ...mapActions({
      setFormSearch: "warningsOT/setFormSearch",
    }),
    ubicateCertified() {
      const num_aviso = this.avisos.find((f) => f.ordenNumber == this.sAviso);
      if (num_aviso) {
        this.$emit("ubicateCertified", num_aviso);
      } else {
        toastr.info("Este aviso no se encuentra dentro del listado");
      }
    },
    hideOrShow() {
      this.showing = !this.showing;
    },
    reset() {
      this.form = {
        idx: 0,
        fechai: moment().format("YYYY-MM-DDT08:00"),
        fechaf: moment().format("YYYY-MM-DDTHH:mm"),
        params: [],
      };
      this.avisos = [];
    },
    getRandomInt(min, max) {
      return Math.floor(Math.random() * (max - min)) + min;
    },
    searchParams() {
      this.isLoading = true;
      this.form.idx = this.getRandomInt(1, 10);
      let aux = {
        fechai: this.form.fechai.replace("T", " "),
        fechaf: this.form.fechaf.replace("T", " "),
        idx: this.form.idx,
        params: this.form.params,
      };
      this.setFormSearch(aux);
      this.isLoading = false;
    },
    quickUserPanelToggle() {
      const body = document.body;
      if (body.classList.contains("offcanvas-on")) {
        body.classList.remove("offcanvas-on");
        document
          .getElementById("kt_quick_user")
          .classList.remove("offcanvas-on");
      } else {
        body.classList.add("offcanvas-on");
        document.getElementById("kt_quick_user").classList.add("offcanvas-on");
      }
    },
    loadNotifications() {
      axios
        .get(`/turns-notifications`)
        .then((res) => {
          this.notifications = res.data.map((el) => {
            return {
              text: `Autorización para turno ${el.observacion}`,
              from: moment(el.updated_at).fromNow(),
            };
          });
          this.notifications = _.orderBy(this.notifications, ["id"], "DESC");
        })
        .catch((e) => {
          console.log(e);
        });
    },
    convertStringToArray(inputStr) {
      if (inputStr) {
        const splittedArray = inputStr.split(",").map((item) => item.trim());
        const resultArray = splittedArray.map((item) => item.replace(/'/g, ""));

        return resultArray;
      }
      return [];
    },
    async onGetTasksNotifications() {
      try {
        const cor = this.convertStringToArray(sessionStorage.getItem("cor"));
        const payload = {
          sectores: sessionStorage.sectores
            ? JSON.parse(sessionStorage.sectores)
            : [],
          cor,
        };

        const { status, data } = await axios.post(
          "/work-task-notifications",
          payload
        );
        if (status === 200 && !data.error ) {
          this.sTitleTask = `Tareas ${data.total}`;
          this.aTareas = data.notifications.map((notify) => ({
            text: notify.obs,
            from: moment(notify.updated_at).fromNow(),
          }));

          this.sTitleFailSoaEvent = `Eventos Fallidos ${data.failedSoaEventos.length}`;
          this.notificationsFailEvents = data.failedSoaEventos.map((failEvent)=>({
            text: failEvent.obs,
            from: moment(failEvent.created_at).fromNow(),
          })).reverse();

          this.sTitleSuccessSoaEvent = `Eventos Exitosos ${data.successSoaEventos.length}`;
          this.notificationsSuccessEvents = data.successSoaEventos.map((successEvent)=>({
            text: successEvent.obs,
            from: moment(successEvent.created_at).fromNow(),
          })).reverse();
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
<style>
.router-link-exact-active,
.router-link-active {
  transition: background-color 0.3s;
  background-color: #f3f6f9 !important;
}

.router-link-exact-active,
.router-link-active .menu-text {
  color: #6993ff !important;
}
.rigth-separator {
  padding: 0 20px;
  border-right: 1px solid #f5f5f5;
  border-left: 1px solid #f5f5f5;
}

.notification {
  position: absolute;
  top: -10px;
  right: -5px;
  padding: 5px 10px;
  color: rgba(21, 66, 107, 0.8);
  background-color: rgba(189, 187, 187, 0.377) !important;
  border-radius: 50%;
  font-weight: 500;
}
input[type="datetime-local"] {
  border: 1px solid #aaa;
  border-radius: 4px;
  margin: 2px 0;
  outline: none;
  padding: 2px;
  box-sizing: border-box;
  transition: 0.5s;
}

input[type="datetime-local"]:focus {
  border-color: #21b59c;
  box-shadow: 0 0 8px 0 #21b59c;
}

.dropdown {
  position: relative;
  display: inline-block;
  max-width: 80px !important;
}

.dropdown-content {
  border-radius: 10px;
  margin-top: 80% !important;
  display: none;
  position: absolute;
  margin: auto;
  background-color: #ffffff;
  min-width: 340px;
  max-width: 400px;
  max-height: 800px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  padding: 12px;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  background-color: #ddd;
}
.dropdown:hover .dropbtn {
  background-color: #3e8e41;
}
</style>
