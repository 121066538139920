<template>
  <b-modal
    title="Asignación de OT"
    ref="assignation-modal"
    id="assignationModal"
    v-model="isAssignation"
    size="xl"
    no-close-on-backdrop
    scrollable
    hide-header-close
  >
    <b-row>
      <b-col md="12">
        <table class="table table-sm table-striped table-hover mb-3">
          <thead>
            <tr>
              <td>Num OT</td>
              <td>Estado</td>
              <td>Fecha de asignación</td>
              <td>Asignado a</td>
              <td>Observación</td>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in ot" :key="index">
              <td>{{ item.num_ot }}</td>
              <td>
                <i :class="item.estado_o_t_s.icono"></i>
                {{ item.estado_o_t_s.descripcion }}
              </td>
              <td>{{ item.fecha_asigna | dateFormat }}</td>
              <td>
                {{ item.user == null ? "NO ASIGNADO" : item.user.nombre }}
              </td>
              <td>{{ item.observacion }}</td>
            </tr>
          </tbody>
        </table>
        <table class="table table-sm table-striped table-hover">
          <thead class="thead-dark">
            <tr>
              <td colspan="9" class="text-center">AVISOS RELACIONADOS A OT</td>
            </tr>
            <tr>
              <td>N° Aviso</td>
              <td>Subestación</td>
              <td>Circuito</td>
              <td>Tipo</td>
              <td>Dirección</td>
              <td>Referencia</td>
              <td>Observación</td>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(aviso, index) in warnings" :key="index">
              <td class="text-center">{{ aviso.num_aviso }}</td>
              <td>{{ aviso.subestacion }}</td>
              <td>{{ aviso.circuito_sm }}</td>
              <td>
                {{ aviso.tipo_aviso }}
              </td>
              <td>{{ aviso.dir_suscriptor }}</td>
              <td>{{ aviso.dir_ref }}</td>
              <td>{{ aviso.observacion }}</td>
            </tr>
          </tbody>
        </table>
      </b-col>
      <b-col md="4" class="mt-3">
        <label>Brigada seleccionada</label>
        <select class="form-control form-control-sm">
          <option
            v-for="(tech, index) in technical"
            :key="index"
            :value="tech.id"
          >
            {{ tech.text }}
          </option>
        </select>
      </b-col>
    </b-row>

    <template #modal-footer>
      <b-form-group>
        <b-button
          variant="link"
          class="mr-2 text-mute"
          size="sm"
          @click="windowCloseAssign()"
        >
          <i class="la la-undo"></i> Cancelar
        </b-button>
        <a
          class="btn btn-sm btn-outline-primary"
          size="sm"
          ref="buttonLoader"
          @click="updateOT()"
        >
          <i class="la la-user-plus" v-if="!isLoading"></i> Asignar OT
        </a>
      </b-form-group>
    </template>
  </b-modal>
</template>
<script>
import axios from "axios";
import moment from "moment";
import toastr from "toastr";
import { mapGetters } from "vuex";
export default {
  name: "assignation-ot",
  data() {
    return {
      isAssignation: false,
      warnings: [],
      technical: [],
      ot: [],
      isLoading: false,
      current: {},
    };
  },
  filters: {
    dateFormat(values) {
      return moment(values).format("LL");
    },
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
  },
  methods: {
    loadOT(id) {
      axios.get(`ots/${id}`).then((res) => {
        this.ot = res.data;
        this.warnings = res.data[0].avisos;
      });
    },
    updateOT() {
      this.loaderSpinClasss();
      let usuario = this.technical[0].id + this.technical[0].text;
      if (JSON.stringify(this.current) !== "{}") {
        usuario = this.current.obs_tech ? this.current.obs_tech : this.current.caption;
      }
      let data = {
        num_ot: this.ot[0].num_ot,
        brigada: this.technical[0].id,
        id_estado: 2,
        bitacora: true,
        descripcion: `NOVEDAD: EL OPERADOR [${this.user.usuario} ${this.user.nombre}]  ASIGNA LA ORDEN DE TRABAJO A:  [${usuario}]`,
        fecha_bitacora: moment().format("YYYY-MM-DD h:mm:ss"),
      };
      axios.put(`ots/${this.ot[0].id_ot}`, data).then(() => {
        toastr.success(
          "Se ha asignado la OT a la brigada " + this.technical[0].text
        );
        this.isAssignation = false;
        this.$emit("loadTree", true);
        this.$parent.treeMenu(this.$parent.data);

        this.removeLoaderSpinClass();
      });
    },
    setTech(value) {
      this.technical = value;
    },
    windowCloseAssign() {
      this.$emit("windowCloseAssign", false);
    },
    loaderSpinClasss() {
      this.isLoading = !this.isLoading;
      const submitButton = this.$refs.buttonLoader;
      submitButton.classList.add(
        "disabled",
        "spinner",
        "spinner-primary",
        "spinner-left"
      );
    },
    removeLoaderSpinClass() {
      this.isLoading = !this.isLoading;
      const submitButton = this.$refs.buttonLoader;
      submitButton.classList.remove(
        "disabled",
        "spinner",
        "spinner-primary",
        "spinner-left"
      );
    },
  },
};
</script>
