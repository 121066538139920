<template>
  <b-row class="mt-5 dx-viewport">
    <b-col md="12">
      <b-card>
        <template #header>
          <div class="d-flex justify-content-between">
            <div class="title">
              <h3 class="card-title">Administración de OT</h3>
            </div>
            <div class="options">
              <button
                class="btn btn-sm btn-outline-primary"
                @click="loadItems"
                v-if="can('/processes/management')"
              >
                <i
                  :class="isLoading ? 'flaticon2-hourglass-1' : 'la la-sync'"
                ></i>
              </button>
            </div>
          </div>
        </template>

        <div class="d-flex">
          <span class="switch switch-icon switch-primary switch-sm">
            <label>
              <input type="checkbox" v-model="showingPanel" name="select" />
              <span></span>
            </label>
          </span>
          <span class="mt-2 ml-1"
            >{{ showingPanel ? "Ocultar" : "Mostrar" }} Panel</span
          >
        </div>

        <transition name="slide-fade">
          <b-row class="bg-secondary p-4 mb-3" v-show="showingPanel">
            <b-col md="12">
              <b-row>
                <div class="form-group col-6">
                  <label>Fecha inicio:</label>
                  <input
                    v-model="formDates.date_init"
                    :disabled="isLoadingTech"
                    type="date"
                    class="form-control form-control-sm"
                  />
                </div>
                <div class="form-group col-6">
                  <label>Fecha fin:</label>
                  <input
                    v-model="formDates.date_end"
                    :disabled="isLoadingTech"
                    :min="formDates.date_init"
                    type="date"
                    class="form-control form-control-sm"
                  />
                </div>
              </b-row>
            </b-col>
            <b-col md="12">
              <transition name="bounce">
                <div
                  class="accordion accordion-solid accordion-toggle-plus"
                  id="accordionExample3"
                >
                  <div class="card">
                    <div class="card-header" id="headingOne3">
                      <div
                        class="card-title collapsed text-primary"
                        data-toggle="collapse"
                        data-target="#collapseOne3"
                        aria-expanded="false"
                      >
                        Busqueda avanzada
                      </div>
                    </div>
                    <div
                      id="collapseOne3"
                      class="collapse"
                      data-parent="#accordionExample3"
                      style=""
                    >
                      <div class="card-body">
                        <b-row>
                          <b-col md="3">
                            Ordenes de trabajo - estados
                            <hr />
                            <div class="checkbox-inline">
                              <label class="checkbox checkbox-success">
                                <input
                                  type="checkbox"
                                  name="Checkboxes5"
                                  @click="selectAll"
                                  v-model="allSelected"
                                />
                                <span></span>Seleccionar todo</label
                              >
                            </div>
                            <hr />
                            <div
                              class="checkbox-list"
                              v-for="(item, idx) in statusList"
                              :key="idx"
                            >
                              <label :class="`checkbox ${item.class}`">
                                <input
                                  type="checkbox"
                                  name="Checkboxes1"
                                  :value="item.id"
                                  v-model="formDates.status"
                                  @click="select"
                                />
                                <span></span> {{ item.text }}</label
                              >
                            </div>
                          </b-col>
                        </b-row>
                      </div>
                    </div>
                  </div>
                </div>
              </transition>
            </b-col>
            <b-col md="4">
              <b-button
                @click="searchRegisters()"
                class="mr-3 mt-3"
                size="sm"
                variant="primary"
              >
                <i class="la la-search"></i>
                Consultar
              </b-button>
            </b-col>
          </b-row>
        </transition>

        <b-row>
          <b-col md="12">
            <transition>
              <b-row v-if="items.length">
                <b-col>
                  <DxDataGrid
                    :data-source="items"
                    :show-borders="true"
                    :show-column-lines="true"
                    :show-row-lines="true"
                    :row-alternation-enabled="true"
                    :columnAutoWidth="true"
                    :allow-column-resizing="true"
                    :allow-column-reordering="true"
                  >
                    <DxPaging :page-size="10" />
                    <DxExport :enabled="true" />
                    <DxSearchPanel
                      :visible="true"
                      :width="240"
                      placeholder="Buscar..."
                    />
                    <DxGroupPanel
                      :visible="true"
                      emptyPanelText="Arrastre una columna aquí para agrupar"
                    />
                    <DxHeaderFilter :visible="true" />
                    <DxFilterRow :visible="true" />
                    <DxPager
                      :visible="true"
                      infoText="Página {0} of {1} ({2} registros)"
                      :allowed-page-sizes="pageSizes"
                      display-mode="full"
                      :show-page-size-selector="true"
                      :show-info="true"
                      :show-navigation-buttons="true"
                    />
                    <DxColumn
                      v-for="(column, idx) in columns"
                      :key="idx"
                      :data-field="column.dataField"
                      :caption="column.caption"
                      :data-type="column.type"
                      :alignment="column.alignment"
                      :group-index="column.group"
                      :sort-order="idx == 0 ? 'desc' : ''"
                    />
                    <DxColumn
                      data-field="created_at"
                      caption="Creado"
                      data-type="date"
                      format="dd/MM/yyyy, HH:mm:ss"
                    />
                    <DxColumn
                      data-field="actions"
                      alignment="center"
                      caption="Acción"
                      type="buttons"
                      cell-template="cellTemplate"
                    />
                    <template #cellTemplate="{ data: cellData }">
                      <div
                        class="btn-group btn-group-sm justify-content-center"
                      >
                        <button
                          title="Asignar OT"
                          @click="assignOrNot(cellData.data)"
                          v-if="
                            (cellData.data.id_estado == 1 ||
                              cellData.data.id_estado == 2 ||
                              cellData.data.id_estado == 8) &&
                            can('assign-ot')
                          "
                          :class="
                            'btn btn-sm btn-icon btn-outline-' +
                            colorButton(cellData.data.id_estado)
                          "
                        >
                          <i
                            :class="
                              'fa fa-user-' +
                              iconButton(cellData.data.id_estado)
                            "
                          >
                          </i>
                        </button>
                        <router-link
                          :to="{
                            name: 'individualOT',
                            params: { id: cellData.data.id_ot },
                          }"
                          v-if="can('detalle-ot')"
                          title="Ver detalles"
                          class="btn btn-sm btn-primary btn-icon"
                        >
                          <i class="la la-eye"></i>
                        </router-link>
                        <a
                          :href="`${PDF}${cellData.data.num_ot}`"
                          target="_blank"
                          title="Ver acta"
                          class="btn btn-sm btn-danger btn-icon"
                          v-if="can('acta-ot')"
                        >
                          <i class="la la-file-pdf-o"></i>
                        </a>
                        <button
                          @click="traslateOT(cellData.data)"
                          class="btn btn-sm btn-outline-success btn-icon"
                          title="Trasladar"
                          v-if="
                            cellData.data.id_estado == 2 && can('traslate-ot')
                          "
                        >
                          <i class="la la-exchange-alt"></i>
                        </button>
                        <button
                          @click="certificate(cellData.data)"
                          class="btn btn-sm btn-outline-warning btn-icon"
                          title="Certificar"
                          v-if="
                            cellData.data.id_estado == 5 &&
                            can('certify') &&
                            false
                          "
                        >
                          <i class="la la-certificate"></i>
                        </button>
                      </div>
                    </template>
                  </DxDataGrid>
                </b-col>
              </b-row>
              <b-row v-else>
                <b-col>
                  <div v-if="!isLoading">
                    <div class="text-center">
                      <img src="assets/img/empty.png" alt="Empty" />
                      <p class="text-muted">No hay información para mostrar</p>
                    </div>
                  </div>
                  <div class="text-center" v-else>
                    <img src="assets/img/loader.gif" alt="Loading..." />
                  </div>
                </b-col>
              </b-row>
            </transition>
          </b-col>
        </b-row>
      </b-card>
    </b-col>

    <ModalCertificate
      v-if="binnacle.length != 0"
      :showCertificate="showCertificate"
      :materiales="materiales"
      :binnacle="binnacle"
      :informationOT="informationOT"
      :informationWarningOT="informationWarningOT"
      :movil="movil"
      :images="images"
      :observation="observation"
      :causal="causal"
      :acciones="acciones"
      :causas="causas"
      @hideModalCertificate="hideModalCertificate($event)"
    />
    <ModalTraslate
      :showTraslate="showTraslate"
      :infoTraslate="infoTraslate"
      @hideModalTraslate="hideModalTraslate($event)"
      :techs="configOptionSelect"
      @emitLoadItems="emitLoadItems($event)"
    />
    <b-modal
      title="Asignar OT"
      ref="assign-ot"
      v-model="showAssign"
      size="xl"
      no-close-on-backdrop
    >
      <b-tabs card small>
        <b-tab title="Asignar OT">
          <b-row>
            <b-col md="4">
              <label>Brigada a la que desea asignar la OT</label>
              <Select2
                class="select2 mb-3"
                v-model="worker"
                :options="configOptionSelect"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col md="4">
              <div class="form-group">
                <b-button
                  variant="outline-primary"
                  size="sm"
                  ref="buttonLoader"
                  class="mt-3"
                  @click="updateOT()"
                >
                  <i class="fa fa-save"></i>
                  {{ infoAssign.id_estado == 8 ? "Reasignar" : "Asignar" }}
                </b-button>
              </div>
            </b-col>
          </b-row>
        </b-tab>
        <b-tab title="Información de OT">
          <b-col md="12">
            <b-row>
              <b-col :md="12" class="mt-5">
                <div class="h-20">
                  <table class="table table-sm table-striped table-hover">
                    <thead>
                      <tr>
                        <td colspan="2" class="bg-secondary text-center">
                          <h5>INFORMACIÓN DE OT</h5>
                        </td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(item, key) in infoAssign" :key="key + '_V_'">
                        <td
                          class="pl-3"
                          v-if="key != 'estado_o_t_s' && key != 'id_ot'"
                        >
                          {{ key | replace_ | upperCase }}
                        </td>
                        <td
                          class="pl-3"
                          v-if="key != 'estado_o_t_s' && key != 'id_ot'"
                        >
                          {{ item }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </b-col>
            </b-row>
          </b-col>
        </b-tab>
        <b-tab lazy title="Bitacora" v-if="bitacora.length">
          <table class="table table-hover table-striped table-sm">
            <thead>
              <tr>
                <td colspan="2">Bitácora</td>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in bitacora" :key="index">
                <td class="datatable-cell">
                  <small>{{ item.fecha | simpleFormat }}</small>
                </td>
                <td>
                  <small>{{ item.detalle }}</small>
                </td>
              </tr>
            </tbody>
          </table>
        </b-tab>
        <b-tab lazy title="Información de precierre" v-if="manoObra.length">
          <table class="table table-sm table-striped table-pane">
            <thead>
              <tr>
                <td colspan="2" class="text-center bg-secondary">
                  MANO DE OBRA
                </td>
              </tr>
              <tr>
                <td>Descripción</td>
                <td>Fecha ejecuta</td>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, idx) in manoObra" :key="idx">
                <td>{{ item.descripcion }}</td>
                <td>{{ item.fecha_ejecuta | format }}</td>
              </tr>
            </tbody>
          </table>
        </b-tab>
      </b-tabs>

      <template #modal-footer>
        <b-form-group>
          <b-button
            @click="
              showAssign = false;
              worker = -1;
            "
            variant="link"
          >
            <i class="la la-rotate-left"></i> Cancelar
          </b-button>
        </b-form-group>
      </template>
    </b-modal>
  </b-row>
</template>
<style>
.table-management-ot tbody tr td:nth-child(5) div {
  width: 100px;
}
.table-management-ot tbody tr td:nth-child(6) div {
  width: 180px;
}
.table-management-ot tbody tr td:nth-child(7) div {
  width: 150px;
  font-size: 0.85rem;
}
.table-management-ot tbody tr td:nth-child(8) div,
.table-management-ot tbody tr td:nth-child(9) div,
.table-management-ot tbody tr td:nth-child(10) div {
  width: 130px;
}
.table-management-ot > thead > tr > th[aria-sort="ascending"]::after {
  opacity: 1;
  content: "\2191";
}
.table-management-ot > thead > tr > th[aria-sort="descending"]::after {
  opacity: 1;
  content: "\2193";
}

.ranges ul {
  display: initial;
}
.vue-daterange-picker {
  display: block;
  width: 100% !important;
}
.component-fade-enter-active,
.component-fade-leave-active {
  transition: opacity 0.3s ease;
}
.component-fade-enter,
.component-fade-leave-to {
  opacity: 0;
}
.h-20 {
  max-height: 450px;
  overflow-y: auto;
}
.stick {
  z-index: 5;
  position: sticky;
}
</style>
<script>
import axios from "axios";
import moment from "moment";
import Select2 from "v-select2-component";
import { mapActions, mapGetters } from "vuex";
import toastr from "toastr";
import ModalCertificate from "./modals/ModalCertificate.vue";
import ModalTraslate from "./modals/ModalTraslate.vue";
import {
  DxDataGrid,
  DxPaging,
  DxColumn,
  DxSearchPanel,
  DxFilterRow,
  DxHeaderFilter,
  DxPager,
  DxExport,
  DxGroupPanel,
} from "devextreme-vue/data-grid";
import can from "@/permission";
import ENV from "@/environment";
export default {
  components: {
    Select2,
    ModalCertificate,
    ModalTraslate,
    DxDataGrid,
    DxPaging,
    DxColumn,
    DxSearchPanel,
    DxFilterRow,
    DxHeaderFilter,
    DxPager,
    DxExport,
    DxGroupPanel,
  },
  mounted() {
    this.allSelected = true;
    this.checkedAll();
    this.loadItems(this.formDates);
  },
  data() {
    return {
      PDF: `${
        process.env.NODE_ENV == "development" ? ENV.API_DEV : ENV.API
      }acta-ot/`,
      info: {},
      //ranges
      ranges: {},
      startDate: new Date(),
      endDate: new Date(),
      form: {
        keyboard: null,
        type: null,
        dateRange: {
          startDate: new Date(),
          endDate: new Date(),
        },
        status: null,
      },
      locale: {
        separator: " / ",
        applyLabel: "Aplicar",
        cancelLabel: "Cancelar",
        monthNames: moment.months(),
        daysOfWeek: moment.weekdaysMin(),
        firstDay: 1,
      },

      value: Math.random() * 100,

      // form data
      showingPanel: true,
      formDates: {
        status: [],
        date_init: moment().format("YYYY-MM-01"),
        date_end: moment().format("YYYY-MM-DD"),
      },
      items: [],
      isLoadingTech: false,
      configTech: [],

      // checkbox
      allSelected: false,
      status: [],
      statusList: [
        {
          id: 2,
          text: "Asignadas",
          class: "checkbox-primary",
        },
        {
          id: 3,
          text: "Descargadas en el movil",
          class: "checkbox-dark",
        },
        {
          id: 4,
          text: "En ejecución",
          class: "checkbox-info",
        },
        {
          id: 5,
          text: "Ejecutadas",
          class: "checkbox-success",
        },
        {
          id: 6,
          text: "Certificadas",
          class: "checkbox-warning",
        },
        {
          id: 8,
          text: "Rechazadas",
          class: "checkbox-danger",
        },
      ],

      pageSizes: [5, 10, 30, 50],
      columns: [
        {
          dataField: "num_ot",
          caption: "N° OT",
          type: "number",
          alignment: "center",
        },
        {
          dataField: "num_aviso",
          caption: "N° Aviso",
          alignment: "center",
        },
        {
          dataField: "brigada",
          caption: "Cod. Brigada",
          alignment: "center",
        },
        {
          dataField: "completedInfo",
          caption: "Técnico asignado",
          group: 0,
        },
        {
          dataField: "sector",
          caption: "Sector",
        },
        {
          dataField: "estado_o_t_s.descripcion",
          caption: "Estado",
          group: 1,
        },
        {
          dataField: "circuito_sm",
          caption: "Circuito",
        },
        {
          dataField: "dir_suscriptor",
          caption: "Dirección",
        },
      ],

      //certificate check
      checkWar: null,

      // modals
      showCertificate: false,
      showTraslate: false,
      showAssign: false,

      // props
      infoTraslate: [],
      binnacle: [],
      movil: [],
      actions: [],
      causal: [],
      causas: [],
      observation: "",

      //
      informationOT: {},
      informationWarningOT: {},
      infoAssign: {},
      workers: [],
      worker: -1,
      isLoading: false,
      materiales: [],
      bitacora: [],
      manoObra: [],
      images: [],
      acciones: [],
    };
  },
  computed: {
    configOptionSelect() {
      let options = [];
      options.push({
        id: -1,
        text: "Seleccione",
      });
      this.tech.map((worker) => {
        let text = worker.text ? worker.text.toUpperCase() : worker.nombre;
        options.push({
          id: worker.usuario,
          text: text,
        });
      });
      return options;
    },
    ...mapGetters({
      selectedOt: "management/getOts",
      tech: "technical/getTecnical",
      getCor2: "auth/cor",
      user: "auth/user",
    }),
  },
  filters: {
    replace_(values) {
      return values.replace("_", " ");
    },
    upperCase(values) {
      return values.toUpperCase();
    },
    format(values) {
      return moment(values).format("LLL");
    },
    simpleFormat(value) {
      return moment(value).format("l") + " " + moment(value).format("LTS");
    },
  },
  methods: {
    ...mapActions({
      changeValueOts: "management/changeValueOts",
      setTech: "technical/changeValueTech",
    }),
    can: can,
    hideModalCertificate(values) {
      this.showCertificate = values;
      this.loadItems();
      let data = this.$root.$children[0].$refs.menuComponent.data;
      this.$root.$children[0].$refs.menuComponent.treeMenu(data);
    },
    searchImages(id) {
      axios.get(`principal-images/${id}`).then((res) => {
        this.images = res.data;
      });
    },
    hideModalTraslate(values) {
      this.showTraslate = values;
    },
    goDetails(item) {
      this.$router.push({ name: "individualOT", params: { id: item } });
    },
    emitLoadItems(event) {
      if (event) {
        this.loadItems();
      }
    },
    iconButton(item) {
      if (item == 1 || item == 8) {
        return "plus";
      } else if (item == 2) {
        return "minus";
      }
    },
    colorButton(item) {
      if (item == 1 || item == 8) {
        return "primary";
      } else if (item == 2) {
        return "warning";
      }
    },
    certificate(item) {
      this.showCertificate = true;
      this.informationOT = item;
      this.informationOT.estado = item.estado_o_t_s.descripcion;
      this.informationOT.icono = item.estado_o_t_s.icono;
      this.findBinnacle(item.num_ot);
      this.searchImages(item.num_ot);
    },
    findBinnacle(num_ot) {
      if (num_ot) {
        axios.get("/bitacora-ot/" + num_ot).then((res) => {
          this.binnacle = [];
          this.materiales = res.data.materiales;
          this.bitacora = res.data.bitacora;
          this.acciones = res.data.acciones;
          this.manoObra = res.data.manoObra;
          res.data.bitacora.map((bin) => {
            this.binnacle.push({
              id: bin.bitacora_id,
              created_at: moment(bin.created_at).format("L"),
              detalle: bin.detalle,
              estado: bin.estado,
              num_ot: bin.num_ot,
              fecha: bin.fecha,
            });
          });

          res.data.avisos.map((aviso) => {
            if (aviso.id_aviso == aviso.id_aviso_ref) {
              // ______________________________________
              delete aviso.avisos_o_t_s.id_aviso;
              delete aviso.avisos_o_t_s.tipo_trabajo_id;
              delete aviso.avisos_o_t_s.ind_peligro;
              delete aviso.avisos_o_t_s.ind_cli_vip;
              delete aviso.avisos_o_t_s.tipo_coordenada;
              delete aviso.avisos_o_t_s.latitud;
              delete aviso.avisos_o_t_s.longitud;
              delete aviso.avisos_o_t_s.CT;
              delete aviso.avisos_o_t_s.TRAFO;
              delete aviso.avisos_o_t_s.created_at;
              delete aviso.avisos_o_t_s.updated_at;
              // ______________________________________

              this.informationWarningOT = aviso.avisos_o_t_s;
              this.informationWarningOT.tipo_trabajo_id = aviso.tipo_trabajo_id;
              this.informationWarningOT.tipo_trabajo_nombre =
                aviso.tipo_trabajo_nombre;
            }
          });
          let option = [];
          option.push({
            value: -1,
            text: "Seleccione",
          });

          res.data.acciones.map((el) => {
            option.push({
              value: el.codigo,
              text: el.descripcion,
            });
          });

          // this.actions = option
          this.movil = [];
          this.causal = [];
          if (res.data.movil) {
            this.movil.push(res.data.movil);
            this.movil[0].fecha_asigna = res.data.ot.fecha_asigna;
            this.observation = this.movil[0].observacion;
          }

          this.causas = [];
          res.data.causas.map((causa) => {
            this.causas.push({
              value: causa.codcausa,
              text: causa.descripcion,
            });
          });
          if (this.movil[0]) {
            this.causal.push({
              value: this.movil[0].causa[0].causa_id,
              text: this.movil[0].causa[0].causa_id,
            });
          }
        });
      }
    },
    loadWorker() {
      if (this.tech.length == 0) {
        axios
          .post("/tecnico-bol-all", {
            sectores: JSON.parse(sessionStorage.sectores),
          })
          .then((res) => {
            this.workers = res.data;
            let tech = [];
            res.data.map((worker) => {
              if (worker.ultima_posicion !== null) {
                tech.push(worker);
              }
            });
            this.workers = tech;
            this.setTech(this.workers);
          })
          .catch((e) => {
            console.log(e);
          });
      }
    },
    traslateOT(item) {
      this.loadWorker();
      this.showTraslate = true;
      this.infoTraslate = [item];
    },
    updateOT() {
      if (this.worker == -1) {
        toastr.info("Debe seleccionar una brigada !!");
      } else {
        let data = {
          id_ot: this.infoAssign.id_ot,
          id_estado: 2,
          num_ot: this.infoAssign.num_ot,
          brigada: this.worker,
          bitacora: true,
          descripcion: `NOVEDAD: EL OPERADOR [${this.user.usuario}]  ASIGNA LA ORDEN DE TRABAJO A:  ${this.worker}`,
          fecha_bitacora: moment().format("YYYY-MM-DD h:mm:ss"),
        };
        this.loaderSpinClasss();
        axios
          .put("ots/" + this.infoAssign.id_ot, data)
          .then((res) => {
            if (res.data.message == "success") {
              toastr.success("Se ha asignado la OT a la brigada ");
              this.loadItems();
              this.removeLoaderSpinClass();
              this.worker = -1;
              this.showAssign = false;
              this.informationOT = {};
            }
          })
          .catch((e) => {
            console.log(e);
            this.removeLoaderSpinClass();
          });
      }
    },
    assignOrNot(data) {
      // console.log(data);
      if (data.id_estado === 1) {
        this.loadWorker();
        this.showAssign = true;
        //------------------------
        delete data.geojson;
        delete data.usuario_genera;
        delete data.user;
        delete data.icono;
        delete data.id_aviso_ref;
        delete data.created_at;
        delete data.updated_at;
        delete data.sync_certificar;
        delete data.fecha_asigna;
        //------------------------

        this.infoAssign = data;
        this.infoAssign.estado = data.estado_o_t_s.descripcion;
        this.findBinnacle(data.num_ot);
      } else if (data.id_estado === 2) {
        this.$swal
          .fire({
            title: "¿Desea quitar la asignación a esta brigada?",
            icon: "warning",
            cancelButtonText: "Cancelar",
            showCancelButton: true,
            confirmButtonText: "Si, Quitar asignación",
          })
          .then((result) => {
            if (result.isConfirmed) {
              let data2 = {
                id_estado: 1,
                brigada: null,
                brigadaDesasigna: data.brigada,
                num_ot: data.num_ot,
                bitacora: true,
                descripcion: `NOVEDAD: SE DESASIGNA LA ORDEN DE TRABAJO POR EL OPERADOR ${this.user.nombre} [${this.user.usuario}]`,
              };
              axios
                .put("/ots/" + data.id_ot, data2)
                .then((res) => {
                  if (res.data.message == "success") {
                    this.loadItems();
                    this.$swal.fire(
                      "Información",
                      "¡Se ha quitado la asignación!",
                      "success"
                    );
                  } else {
                    this.$swal.fire(
                      "Información",
                      res.data.information,
                      "warning"
                    );
                  }
                })
                .catch((e) => {
                  console.log(e);
                });
            }
          });
      } else if (data.id_estado == 8) {
        this.showAssign = true;
        this.loadWorker();
        //------------------------
        delete data.id_ot;
        delete data.geojson;
        delete data.usuario_genera;
        delete data.user;
        delete data.icono;
        delete data.id_aviso_ref;
        delete data.created_at;
        delete data.updated_at;
        delete data.sync_certificar;
        delete data.fecha_asigna;
        //------------------------

        this.infoAssign = data;
        this.infoAssign.estado = data.estado_o_t_s.descripcion;

        this.findBinnacle(data.num_ot);
      }
    },
    clearFilter() {
      this.form = {
        keyboard: null,
        type: null,
        dateRange: {
          startDate: moment().format("L"),
          endDate: moment().format("L"),
        },
        status: null,
      };
    },
    searchWithFilter() {
      if (
        this.form.keyboard == null &&
        this.type == null &&
        this.form.dateRange.startDate == null &&
        this.form.dateRange.endDate == null &&
        this.form.status == null
      ) {
        this.$swal({
          title: "Información",
          text: "Para buscar información debe seleccionar al menos un filtro",
          icon: "info",
        });
      } else {
        console.log(this.form);
      }
    },

    loadItems(formDates) {
      let today = new Date();
      today.setHours(0, 0, 0, 0);
      let todayEnd = new Date();
      todayEnd.setHours(11, 59, 59, 999);

      let yesterdayStart = new Date();
      yesterdayStart.setDate(today.getDate() - 1);
      yesterdayStart.setHours(0, 0, 0, 0);

      let yesterdayEnd = new Date();
      yesterdayEnd.setDate(today.getDate() - 1);
      yesterdayEnd.setHours(11, 59, 59, 999);

      let thisMonthStart = new Date(today.getFullYear(), today.getMonth(), 1);
      let thisMonthEnd = new Date(
        today.getFullYear(),
        today.getMonth() + 1,
        0,
        11,
        59,
        59,
        999
      );

      this.ranges = {
        Hoy: [today, todayEnd],
        Ayer: [yesterdayStart, yesterdayEnd],
        "Este mes": [thisMonthStart, thisMonthEnd],
        "Mes pasado": [
          new Date(today.getFullYear(), today.getMonth() - 1, 1),
          new Date(today.getFullYear(), today.getMonth(), 0, 11, 59, 59, 999),
        ],
        "Este año": [
          new Date(today.getFullYear(), 0, 1),
          new Date(today.getFullYear(), 11, 31, 11, 59, 59, 999),
        ],
      };
      var corS = this.getCor ? this.getCor : sessionStorage.cor;
      this.isLoading = true;
      this.items = [];
      axios
        .post("/param-ots", {
          cor: corS.replaceAll("'", "").split(","),
          date_end: formDates.date_end + " 23:59:59",
          date_init: formDates.date_init + " 00:00:00",
          status: formDates.status,
        })
        .then((res) => {
          this.isLoading = false;
          this.items = res.data;
          this.totalRows = this.items.length;
        })
        .catch((e) => {
          this.isLoading = false;
          console.log(e);
        });
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    loaderSpinClasss() {
      this.isLoading = !this.isLoading;
      const submitButton = this.$refs.buttonLoader;
      submitButton.classList.add(
        "disabled",
        "spinner",
        "spinner-primary",
        "spinner-left"
      );
    },
    removeLoaderSpinClass() {
      this.isLoading = !this.isLoading;
      const submitButton = this.$refs.buttonLoader;
      submitButton.classList.remove(
        "disabled",
        "spinner",
        "spinner-primary",
        "spinner-left"
      );
    },

    //formDate +
    searchRegisters() {
      this.loadItems(this.formDates);
    },

    checkedAll() {
      for (let s in this.statusList) {
        this.formDates.status.push(this.statusList[s].id);
      }
    },

    selectAll() {
      this.formDates.status = [];

      if (!this.allSelected) {
        for (let s in this.statusList) {
          this.formDates.status.push(this.statusList[s].id);
        }
      }
    },

    select() {
      this.allSelected = false;
    },
  },
};
</script>
