export default {
    namespaced: true,
    state: {
        circuit: []
    },
    getters: {
        getChildren(state) {
            return state.circuit
        }
    },
    mutations: {
        SET_CIRCUIT(state, val) {
            state.circuit = val
        }
    },
    actions: {
        loadCircuit({commit}, value) {
            commit('SET_CIRCUIT', value)
        }
    }
}