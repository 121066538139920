<template>
    <b-row>
        <b-col md="12" v-if="markers.length != 0">
            <gmap-map 
                :center="center" 
                :zoom="zoom"
                style="width: 100%; height: 300px"
                ref="map">
                <gmap-info-window 
                    :options="infoOptions" 
                    :position="infoWindowPos" 
                    :opened="infoWinOpen" 
                    @closeclick="infoWinOpen=false">
                </gmap-info-window>
                <gmap-marker 
                    :key="i" 
                    v-for="(m,i) in markers" 
                    :position="m.position" 
                    :clickable="true"
                    :animation="2"
                    @click="toggleInfoWindow(m,i)">
                </gmap-marker>
                <gmap-polygon 
                    v-if="paths.length > 0" 
                    :paths="paths" 
                    :editable="true" 
                    @paths_changed="updateEdited($event)"
                    :options="{
                        fillColor: '#012060',
                        fillOpacity: 0.3,
                        strokeWeight: 2,
                        strokeColor: '#01194a',
                    }"
                    ref="polygon">
                </gmap-polygon>
            </gmap-map>
        </b-col>
        <b-col v-else>
            <b-alert show variant="secondary">
                <i class="fa fa-info-circle text-primary mr-3"></i> No hay posiciones que mostrar para esta OT
            </b-alert>
        </b-col>
    </b-row>
</template>
<script>
    import _ from 'lodash'
    import stylesOptions from '@/assets/content/cleanMap'
    function closeLoop (path) {
        return path.concat(path.slice(0, 1))
    }

    export default {
        props: {
            markers: {
                type: Array,
                default: () => []
            },
            center: {
                type: Object
            },
            geojson: {
                type: String,
                default: () => ''
            },
            zoom: {
                type: Number,
                default: () => 16
            }
        },
        data(){
            return {
                infoWindowPos: null,
                infoWinOpen: false,
                currentMidx: null,

                infoOptions: {
                    content: '',
                    pixelOffset: {
                        width: 0,
                        height: -35
                    }
                },
                paths: []
            }
        },
        
        watch: {
            geojson() {
                this.readGeojson()
                this.loadPath()
            },
            polygonPaths: _.throttle(function (paths) {
                if (paths) {
                this.paths = paths
                    this.polygonGeojson = JSON.stringify({
                        type: 'Polygon',
                        coordinates: this.paths.map(path => closeLoop(path.map(({lat, lng}) => [lng, lat])) )
                    }, null, 1)
                }
            }, 1000)
        },
        computed: {
            
            polygonPaths: function () {
                if (!this.mvcPaths) return null
                
                let paths = [];
                for (let i=0; i < this.mvcPaths.getLength(); i++) {
                    let path = [];
                    for (let j=0; j<this.mvcPaths.getAt(i).getLength(); j++) {
                        let point = this.mvcPaths.getAt(i).getAt(j);
                        path.push({lat: point.lat(), lng: point.lng()});
                    }
                    paths.push(path);
                }
                return paths
            }, 
        },
        methods: {
            cleanMap() {
                this.$refs.map.$mapPromise.then(map => {
                    map.setOptions({
                        styles: stylesOptions
                    })
                })
            },
            showCenterMap: function (place) {
                if(place.lat != undefined && place.lng != undefined) {
                    this.center = { lat: place.lat, lng: place.lng }
                }
            },
            updateCenter: function (place) {
                this.center = {
                    lat: place.geometry.location.lat(),
                    lng: place.geometry.location.lng(),
                }
            },
            updateEdited: function (mvcPaths) {
                this.mvcPaths = mvcPaths
            },
            removePath: function () {
                this.paths.splice(this.paths.length - 1, 1)
                this.markerSelected = []
            },
            handleClickForDelete($event) {
                if ($event.vertex) {
                this.$refs.polygon.$polygonObject.getPaths()
                    .getAt($event.path)
                    .removeAt($event.vertex)
                }
            },
            readGeojson() {
                try {
                    var v = JSON.parse(this.geojson)
                    
                    this.paths = v.coordinates.map(linearRing =>
                        linearRing.slice(0, linearRing.length - 1)
                        .map(([lng, lat]) => ({lat, lng}))
                    )
                } catch (err) {
                    this.paths = []
                }
            },
            loadPath() {
                try {                    
                    return JSON.parse(this.geojson)
                } catch (e) {
                    return 0
                }
            },
            toggleInfoWindow: function(marker, idx) {
                this.markerSelected = []
                this.markerSelected.push(marker)
                this.infoWindowPos = marker.position;
                this.infoOptions.content = marker.infoText;

                if (this.currentMidx == idx) {
                    this.infoWinOpen = !this.infoWinOpen;
                } else {
                    this.infoWinOpen = true;
                    this.currentMidx = idx;
                }            
            },
        }
    }
</script>