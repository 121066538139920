<template>
  <div>
    <transition name="bounce">
      <div class="text-center letter-holder mt-3" v-if="loading">
        <div class="load-6 text-center justify-content-center bg-secondary">
          <small class="l-1 letter">C</small>
          <small class="l-2 letter">a</small>
          <small class="l-3 letter">r</small>
          <small class="l-4 letter">g</small>
          <small class="l-5 letter">a</small>
          <small class="l-6 letter">n</small>
          <small class="l-7 letter">d</small>
          <small class="l-8 letter">o</small>
          <small class="l-9 letter">.</small>
          <small class="l-10 letter">.</small>
          <small class="l-11 letter">.</small>
        </div>
      </div>
      <VJstree
        v-else
        id="admin-tree-information"
        ref="tree"
        multiple
        allow-batch
        draggable
        class="mt-5 mb-3 main-tree-default-vue"
        :data="proccess"
        show-checkbox
        @item-click="itemClick"
      >
        <template slot-scope="_">
          <div
            style="display: inherit; width: 200px"
            v-on:contextmenu="openMenu"
            v-if="_.model.slug == 'brigadas_disponibles_all'"
            @click.right="
              indexDataMenu = 0;
              current = _.model;
            "
          >
            <i
              :class="_.vm.themeIconClasses"
              role="presentation"
              v-if="!_.model.loading"
            ></i>
            <span v-html="_.model.text"></span>
            <button
              style="
                border: 0px;
                background-color: transparent;
                cursor: pointer;
              "
              @click="customItemClick(_.vm, _.model, $event)"
            >
              <i class="fa fa-remove"></i>
            </button>
          </div>
          <div style="display: inherit; width: 200px" v-else>
            <i
              :class="_.vm.themeIconClasses"
              role="presentation"
              v-if="!_.model.loading"
            ></i>
            <span v-html="_.model.text"></span>
            <button
              style="
                border: 0px;
                background-color: transparent;
                cursor: pointer;
              "
              @click="customItemClick(_.vm, _.model, $event)"
            >
              <i class="fa fa-remove"></i>
            </button>
          </div>
        </template>
      </VJstree>
    </transition>
    <ul
      id="right-click-menu"
      tabindex="-1"
      ref="right"
      v-on:blur="closeMenu"
      v-if="viewMenu"
      :style="'top:' + top + ';left:' + left"
    >
      <li
        v-for="(item, index) in dataMenuRight[indexDataMenu]"
        :key="index"
        @click="selectMenuRight(item.id)"
        :class="'rightMenu' + item.id"
      >
        <div
          v-if="item.id == 16"
          class="tooltipRightMenu d-flex justify-content-between"
        >
          {{ item.text }}
          <i class="la la-chevron-right"></i>
          <ul class="tooltiptextRightMenu">
            <li
              v-for="(i, index) in dataMenuRight[6]"
              :key="index + '2'"
              @click="selectMenuRight(i.id)"
            >
              {{ i.text }}
            </li>
          </ul>
        </div>
        <div v-else>{{ item.text }}</div>
      </li>
    </ul>

    <ReportTech ref="RTCH" />
  </div>
</template>
<style>
@keyframes loadingF {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.load-6 {
  display: flex;
}
.load-6 .letter {
  animation-name: loadingF;
  animation-duration: 1.6s;
  animation-iteration-count: infinite;
  animation-direction: linear;
}
.letter {
  font-size: 1.1em !important;
}

.l-1 {
  animation-delay: 0.48s;
}
.l-2 {
  animation-delay: 0.6s;
}
.l-3 {
  animation-delay: 0.72s;
}
.l-4 {
  animation-delay: 0.84s;
}
.l-5 {
  animation-delay: 0.96s;
}
.l-6 {
  animation-delay: 1.08s;
}
.l-7 {
  animation-delay: 1.2s;
}
.l-8 {
  animation-delay: 1.32s;
}
.l-9 {
  animation-delay: 1.44s;
}
.l-10 {
  animation-delay: 1.56s;
}
.l-11 {
  animation-delay: 1.65s;
}
</style>
<script>
import axios from "axios";
import VJstree from "vue-jstree";
import _ from "lodash";
import can from "@/permission";
import { mapActions } from "vuex";
const URL = "/admin/tree";
import MENU_ADMIN from "@/assets/menu/menuAdmin.js";
import Vue from "vue";
import ReportTech from "@/components/helpers/modals/ReportTech.vue";

export default {
  components: {
    VJstree,
    ReportTech
  },
  data() {
    return {
      proccess: [],
      dataCor: null,
      loading: false,

      // menu
      rightClick: false,
      viewMenu: false,
      top: "0px",
      left: "0px",
      indexDataMenu: 1,
      current: {},
      currentOver: {},
      dataMenuRight: MENU_ADMIN,
    };
  },
  mounted() {
    if (this.can("procesos-administrativo")) {
      this.treeMenuAdmin(this.proccess);
    }
  },
  computed: {
    cor() {
      return this.dataCor ? this.dataCor : sessionStorage.cor;
    },
  },
  methods: {
    can: can,
    ...mapActions({
      setIdOT: "orders/setIdOT",
      setCurrentIdx: "orders/setCurrentIdx",
      setAdminTech: "technicals/changeValueTech",
      setCurrentTech: "technicals/setCurrentTech",
      changeTechId: "technicals/changeTechIds",
    }),
    setMenu: function (top, left) {
      let offsetHeight =
        document.getElementById("right-click-menu").offsetHeight;
      let dif = window.innerHeight - top;
      if (dif < offsetHeight) top = top - offsetHeight;
      this.top = top + "px";
      this.left = left + "px";
    },
    closeMenu() {
      this.viewMenu = false;
      this.current = {};
    },
    openMenu(e) {
      this.viewMenu = true;
      Vue.nextTick(
        function () {
          this.$refs.right.focus();
          this.setMenu(e.y, e.x);
        }.bind(this)
      );
      e.preventDefault();
    },
    selectMenuRight(id) {
      var curr = this.current;

      switch (id) {
        case 1:
          var RTCH = this.$refs.RTCH;
          RTCH.show = true;
          // RTCH.items = curr.children;
          RTCH.columns = [];
          curr.children.map((el) => {
            RTCH.columns.push({ caption: el.caption });
          });
          RTCH.items = this.reportTurn(curr.children);
          break;
      }
    },
    reportTurn(data) {
      if (!_.isArray(data)) return [];
      var info = [];
      data.map((brigada) => {
        brigada.children.map((ot) => {
          info.push({
            brigada: brigada.caption,
            num_ot: ot.num_ot,
            aviso: ot.aviso,
            circuito_sm: ot.circuito_sm,
            direccion: ot.direccion,
            estado: ot.estado_o_t_s.descripcion,
          });
        });
      });

      return info;
    },
    
    loadTree() {
      this.$nextTick(() => {
        // this.dataCor = value.cor;
        if (this.can("procesos-administrativo")) {
          this.treeMenuAdmin(this.proccess);
        }
      });
    },
    async treeMenuAdmin(old = []) {
      this.sects = sessionStorage.sectores
        ? _.join(JSON.parse(sessionStorage.sectores), ", ")
        : [];
      if (this.cor != undefined) {
        this.loading = true;
        let config = {
          tree: old,
          cor: this.cor,
          sectores:
            sessionStorage.sectores != undefined
              ? JSON.parse(sessionStorage.sectores)
              : [],
        };

        var response = null;
        if (this.can("procesos-administrativo")) {
          response = await axios.post(URL, config);
          let res = response.data;
          if (res) {
            this.proccess = [];
            for (const key in res) {
              this.proccess.push(res[key]);
            }
          }
          this.loading = false;
        }
      }
    },
    itemClick(node) {
      let global = () => {
        node.model.selected = false;
        node.model.children.map((el) => {
          el.selected = false;
          el.children.map((sector) => {
            sector.selected = false;
            sector.children.map((sub) => {
              sub.selected = false;
              sub.children.map((c) => {
                c.selected = false;
                if (c.children) {
                  c.children.map((a) => {
                    a.selected = false;
                  });
                }
              });
            });
          });
        });
      };

      let slug = node.model.slug;
      if (slug != undefined) {
        switch (slug) {
          case "GLOBAL_ANG":
            global();
            break;
          case "GLOBAL_AG":
            global();
            break;
          case "GLOBAL_OT":
            global();
            break;
          case "not_ubicated":
            if (!node.model.selected) {
              for (let i = 0; i < node.model.children.length; i++) {
                node.model.children[i].selected = false;
              }
            }
            break;
          case "not_ubicated_second":
            if (node.model.parent != undefined) {
              if (node.model.selected) {
                var war = this.data[0].children[node.model.parent].children;
                for (let i = 0; i < war.length; i++) {
                  if (war[i].id != node.model.id) {
                    war[i].selected = false;
                  }
                }
                this.data[0].children[node.model.parent].children = war;
              }
            }
            this.$emit("locatePlaceMarker", node.model ? node.model : null);
            break;

          case "circuit":
            this.$emit("loadMarkerCircuit", node.model ? node.model : null);
            break;
          case "brigadas_disponibles_all":
            // console.log(node.model);
            break;
          case "GBRI":
            node.model.selected = false;
            node.model.children.map((sector) => {
              sector.selected = false;
              sector.children.map((estados) => {
                estados.selected = false;
                estados.children.map((bri) => {
                  bri.selected = false;
                  bri.children.map((ot) => {
                    ot.selected = false;
                  });
                });
              });
            });
            break;
          case "technical_delegation":
            node.model.selected = false;
            node.model.children.map((del) => {
              del.selected = false;
              del.children.map((estados) => {
                estados.selected = false;
                estados.children.map((bri) => {
                  bri.selected = false;
                });
              });
            });
            break;
          case "technical":
            this.setCurrentTech(node.model.selected ? node.model.usuario : -1);
            this.changeTechId(node.model);
            break;
          case "ot_managed_tree":
            this.setIdOT(node.model);
            this.setCurrentIdx(node.model.selected ? node.model.id_ot : -1);
            break;
          case "CIRCUITO_OT":
            this.$emit("loadInformationOT", node.model ? node.model : null);
            break;
          case "SUBESTACION_OT":
            this.$emit("loadInformationOT", node.model ? node.model : null);
            break;
          case "OTTECH":
            this.$emit("loadInformationOT", node.model ? node.model : null);
            break;
          case "subestacion_avisos":
            this.$emit("loadMarkerSubstation", node.model ? node.model : null);
            break;
          case "sector_avisos":
            this.$emit("loadMarkerSector", node.model ? node.model : null);
            break;
          default:
            break;
        }
      }
    },
  },
};
</script>
