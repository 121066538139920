<template>
  <b-row :key="nKey">
    <b-col md="6">
      <b-row>
        <b-col md="12">
          <div>
            <b-card no-body>
              <b-tabs content-class="mt-3" card small>
                <b-tab lazy title="Información OT" active>
                  <CInformacionOT :informationOT="informationOT" />
                  <CInformacionAviso :aDataSource="informationWarningOT" />
                </b-tab>
                <b-tab title="Información de precierre" lazy>
                  <CInformacionCierre
                    :aData="movil.causa"
                    :oMovil="oMovil2"
                    :oMovil2="movil"
                  />
                </b-tab>
                <b-tab lazy title="Bitacora" v-if="aBitacora.length">
                  <CBitacora :binnacle="aBitacora" />
                </b-tab>
                <b-tab lazy title="Materiales" v-if="aMaterialesRetirados.length || aMaterialesInstalados.length">
                  <CMateriales :retirados="aMaterialesRetirados" />
                  <CMaterialesInstalados :instalados="aMaterialesInstalados" />
                </b-tab>
                <b-tab lazy title="Motivo rechazos" v-if="aMotivoRechazo.length">
                  <CMotivoRechazo :rechazos="aMotivoRechazo" />
                </b-tab>
                <b-tab lazy title="Aviso pendiente" v-if="aPendiente.length">
                  <cAvisoPendiente :aPendiente="aPendiente" />
                </b-tab>
              </b-tabs>
            </b-card>
          </div>
        </b-col>
      </b-row>
    </b-col>
    <b-col md="6">
      <b-row>
        <b-col md="12">
          <b-row>
            <b-col>
              <div :class=" 'form-text ' + (data.trafo.id !== 1 && data.trafo.id != -1 ? 'text-success' : 'text-danger') ">
                {{ data.trafo.id !== 1 && data.trafo.id != -1 ? "Esta orden tiene información de trafo parcial" : "No se tiene información de trafo parcial para esta Orden" }}
              </div>
              <div class="form-text text-success" v-if="oMovil2">
                {{ oMovil2.causa ? oMovil2.causa[0].causa_name : '' }} :
                {{ oMovil2 && oMovil2.info_ave ? oMovil2.info_ave.codigo : '' }} - 
                {{ oMovil2 && oMovil2.info_ave ? oMovil2.info_ave.nombreave : '' }}
              </div>
              <transition name="fade">
                <b-row v-if="data.trafo.id !== 1" class="mt-2">
                  <b-col>
                    <label>Fecha apertura: <b>{{ trafo.inicio | dateT }}</b></label>
                  </b-col>
                  <b-col>
                    <label>Fecha cierre: <strong>{{ trafo.fin | dateT }}</strong></label>
                  </b-col>
                </b-row>
              </transition>
            </b-col>
          </b-row>
        </b-col>

        <b-col md="12" class="mt-2">
          <hr />
          <p>Certificar OT</p>
          <b-form-group :description="'Caracteres: ' + data.observacion_cor.length">
            <b-form-textarea
              size="sm"
              rows="3"
              :disabled="num_ot == null"
              v-model="data.observacion_cor"
              :state="data.observacion_cor.length <= 1000"
            />
          </b-form-group>
        </b-col>
        <b-col md="4" class="mb-3">
          <div class="checkbox-inline">
            <label :class="`checkbox checkbox-outline checkbox-success ${disabled ? 'checkbox-disabled' : ''}`">
              <input
                type="checkbox"
                value="1"
                :disabled="disabled"
                v-model="form.certificate.checked"
                @change="checkboxSelect(1)"
              />
              <span></span> Certificar OT
            </label>
          </div>
        </b-col>
        <b-col md="4" class="mb-3">
          <div class="checkbox-inline">
            <label :class="`checkbox checkbox-outline checkbox-danger ${ disabledImp ? 'checkbox-disabled' : ''}`">
              <input
                type="checkbox"
                value="3"
                :disabled="disabledImp"
                v-model="valueChecked.check"
                @change="checkboxSelect(3)"
                @click="onSetterVoidSelectsCertificates()"
              />
              <span></span> Improcedente
            </label>
          </div>
        </b-col>

        <b-col md="4" class="mb-3">
          <div class="checkbox-inline">
            <label :class="`checkbox checkbox-outline checkbox-danger ${disabledAv ? 'checkbox-disabled' : ''}`">
              <input
                value="2"
                type="checkbox"
                :disabled="disabledAv"
                v-model="form.check.checked"
                @change="checkboxSelect(2)"
                @click="onSetterVoidSelectsCertificates()"
              />
              <span></span> Aviso pendiente
            </label>
          </div>
        </b-col>
        <transition name="component-fade" mode="out-in">
          <b-col v-if="form.certificate.value == 1">
            <!-- <Certificar
              :sFechaAlta="informationWarningOT.fecha_alta"
              :aPendiente="aPendiente"
              :sBrigada="brigada"
              @onCertify="onCertify"
              :oCurrent="oCurrent"
              ref="rCertificar"
            /> -->
            <b-row class="bg-secondary pt-4">
              <b-col md="12">
                <b-form-checkbox
                  class="checkbox checkbox-outline"
                  id="checkbox-1"
                  v-model="bServicio"
                  :value="true"
                  :unchecked-value="false"
                >
                  ¿Se restauró el servicio?
                </b-form-checkbox>
              </b-col>
              <b-col cols="6" class="mb-2 mt-2">
                <label>Razon</label>
                <!-- <b-form-select
                  v-model="razon"
                  :options="oFiltersActions.razones"
                  size="sm"
                /> -->
                <Select2
                  class="select2 mb-2"
                  v-model="razon"
                  :options="oFiltersActions.razones"
                  placeholder="Seleccione razón"
                />
              </b-col>
              <b-col cols="6" class="mb-2" v-if="razon">
                <label>Tipo</label>
                <!-- <b-form-select
                  v-model="tipo"
                  :options="oFiltersActions.tipos"
                  size="sm"
                /> -->
                <Select2
                  class="select2 mb-2"
                  v-model="tipo"
                  :options="oFiltersActions.tipos"
                  placeholder="Seleccione tipo"
                />
              </b-col>
              <b-col cols="6" class="mb-2" v-if="razon && tipo">
                <label>Grupo</label>
                <!-- <b-form-select
                  v-model="grupo"
                  :options="oFiltersActions.grupos"
                  size="sm"
                /> -->
                <Select2
                  class="select2 mb-2"
                  v-model="grupo"
                  :options="oFiltersActions.grupos"
                  placeholder="Seleccione grupo"
                />
              </b-col>
              <b-col cols="6" class="mb-2" v-if="razon && tipo && grupo">
                <label>Causa</label>
                <Select2
                  class="select2 mb-2"
                  v-model="selectCausa"
                  :options="oFiltersActions.causas"
                  placeholder="Seleccione causa"
                />
              </b-col>
              <b-col cols="12" class="mb-2" v-if="cmpMostrarAve">
                <label>Ave</label>
                <Select2
                  v-model="selectAve"
                  class="select2 mb-2"
                  :options="aAves"
                  placeholder="Seleccione ave"
                />
              </b-col>
            </b-row>
            <b-row class="bg-secondary">
              <b-col class="mb-3 text-center mt-5">
                <b-button
                  variant="primary"
                  size="sm"
                  @click="onCertifyEvento()"
                  class="mr-3"
                >
                  <i class="la la-certificate"></i> Certificar
                </b-button>
              </b-col>
            </b-row>
          </b-col>
          <b-col
            md="12"
            class="bg-secondary p-4"
            v-else-if="form.check.value == 2"
          >
            <AvisoPendiente
              :aListado="list"
              @onGuardarAviso="onGuardarAviso"
              :key="nAKey"
            />
          </b-col>
          <b-col v-else-if="valueChecked.value == 3">
            <Improper
              ref="IMP"
              :information="infoimp"
              @improcedente="improcedente"
            />
          </b-col>
        </transition>
      </b-row>
    </b-col>
  </b-row>
</template>
<script>
import axios from "axios";
import * as $ from "jquery";
// Componentes para las tabs
import CInformacionOT from "@/components/helpers/modals/certificationTabs/CInformacionOT.vue";
import CBitacora from "@/components/helpers/modals/certificationTabs/CBitacora.vue";
import CMateriales from "@/components/helpers/modals/certificationTabs/CMateriales.vue";
import CMaterialesInstalados from "@/components/helpers/modals/certificationTabs/CMaterialesInstalados.vue";
import CMotivoRechazo from "@/components/helpers/modals/certificationTabs/CMotivoRechazo.vue";
import CInformacionAviso from "@/components/helpers/modals/certificationTabs/CInformacionAviso.vue";
import CInformacionCierre from "@/components/helpers/modals/certificationTabs/CInformacionCierre.vue";
import cAvisoPendiente from "@/components/helpers/modals/certificationTabs/CAvisoPendiente.vue";
// aviso improcedente
import Improper from "@/components/helpers/ImproperWarning.vue";
// Certificacion
// import Certificar from "@/components/helpers/modals/content/Certificar.vue";

// Aviso pendiente
import AvisoPendiente from "@/components/helpers/modals/content/AvisoPendiente.vue";
import toastr from "toastr";
import moment from "moment";
import _ from "lodash";
import { mapGetters } from "vuex";
export default {
  components: {
    // componentes de tabs
    CInformacionOT,
    CBitacora,
    CMateriales,
    CMaterialesInstalados,
    CMotivoRechazo,
    CInformacionAviso,
    CInformacionCierre,
    cAvisoPendiente,
    // ventanas de certificación, aviso improcedente y pendiente
    Improper,
    // Certificar,
    AvisoPendiente,
  },
  props: {
    num_ot: {},
    work_id: {
      type: Number,
    },
    aCausas: {
      type: Array,
      default: () => [],
    },
    aAves: {
      type: Array,
      default: () => [],
    },
    oResponseActions: {
      type: Object,
    },
  },
  data() {
    return {
      oCurrent: {},
      // para actualizar el componente de aviso pendiente
      nAKey: 0,
      informationOT: [],
      aBitacora: [],
      aMaterialesRetirados: [],
      aMaterialesInstalados: [],
      aPendiente: [],
      aMotivoRechazo: [],
      images: [],
      informationWarningOT: [],
      // Información para la ventana de certificación.
      disabledImp: false,
      disabledAv: false,
      movil: {
        causa: [],
        reglas_oro: [],
        accion: [],
      },
      data: {
        cod_cierre: 1,
        num_ot: 0,
        usuario_servicio: null,
        causa: null,
        fecha_asigna: 0,
        fecha_recibe: null,
        fecha_arribo: null,
        fecha_ot_fallida: null,
        fecha_localiza_causa: null,
        fecha_resolucion: null,
        observacion_cor: "",
        observacion_bol: null,
        brigada: 0,
        danio: null,
        accion: null,
        usuario: null,
        informacion: {},
        trafo: {
          id: 1,
          inicio: null,
          fin: null,
        },
      },
      oMovil2: null,
      // restaurar servicio
      bServicio: null,

      // Variables para certificar
      // Value se utiliza para seleccionar las causas del formulario para certificación
      value: {
        accion: -1,
        tipo_incidencia: 0,
        usuario_servicio: 0,
        tipo_instalacion: 0,
        familia: 0,
        tipo: 0,
        causal: 0,
        causa_fallida: 0,
      },
      formSearch: {
        accion: -1,
        tipo_incidencia: null,
        usuario_servicio: null,
        tipo_instalacion: null,
        familia: null,
        tipo: null,
        causal: null,
        causa_fallida: null,
      },
      // Select de acciones
      actions: [
        { value: -1, text: "Seleccione" },
        { value: 1, text: "Crear orden de trabajo" },
        { value: 2, text: "Crear incidencia" },
      ],
      // Definimos animal aereo en -1
      ave: -1,
      // Información aviso improcedente
      infoimp: {},

      items: {
        tipo_incidencia: {
          id: 1,
          show: false,
        },
        usuario_servicio: {
          id: 2,
          show: false,
        },
        tipo_instalacion: {
          id: 3,
          show: false,
        },
        familia: {
          id: 4,
          show: false,
        },
        tipo: {
          id: 5,
          show: false,
        },
        causal: {
          id: 6,
          show: false,
        },
        causa_fallida: {
          id: 7,
          show: false,
        },
      },
      disabled: false,
      form: {
        check: {
          checked: false,
          value: null,
        },
        certificate: {
          checked: false,
          value: null,
        },
      },
      valueChecked: {
        check: false,
        value: null,
      },
      //causas reabrir caso
      list: [],
      aAviso: [],
      // Aviso pendiente
      causa: -1,
      sObservacionAP: "",
      brigada: null,
      // Usamos esta variable para actualizar el componente
      nKey: 0,
      trafo: {
        inicio: null,
        fin: null,
      },
      // CERTIFICACION
      // values selects
      razon: null,
      tipo: null,
      grupo: null,
      selectCausa: null,
      selectAve: null,
      // end values selects
      oFiltersActions: {
        razones: [],
        tipos: [],
        grupos: [],
        causas: [],
      },
    };
  },
  mounted() {
    this.onGetItems();
    this.onGetItemsSelects();
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
    cmpMostrarAve() {
      return this.aCausas
        .map((item) => parseInt(item.id))
        .includes(parseInt(this.selectCausa));
    },
  },
  filters: {
    dateT(value, format = "DD/MM/YYYY HH:mm:ss", momentInstance = moment) {
      if (!value || typeof value !== "string") {
        return "";
      }
      const date = momentInstance(value.replace("T", " "));
      if (!date.isValid()) {
        return "";
      }
      return date.format(format);
    },
  },
  methods: {
    async onGetItems() {
      if (this.num_ot) {
        const { data } = await axios.get(`/bitacora-ot/${this.num_ot}`);
        this.aAviso = data.avisos.map((el) => {
          return el.avisos_o_t_s.num_aviso;
        });
        // Validamos la información de la ot
        if (data.ot) {
          const ot = data.ot;
          this.disabled = ot.id_estado != 5 && ot.id_estado != 4 && ot.id_estado != 9;
          this.disabledImp = ot.id_estado === 14 || ot.id_estado === 10 || ot.id_estado === 15 || ot.id_estado == 6;
          this.disabledAv  = ot.id_estado === 14 || ot.id_estado === 10 || ot.id_estado === 15 || ot.id_estado == 6;
          // this.disabledImp = ot.id_estado == 6;
          // this.disabledAv  = ot.id_estado == 6;
        } else {
          this.disabled = true;
        }

        this.informationOT = [data.ot];
        if (data.ot) {
          this.brigada = data.ot.brigada;
          this.oCurrent = data.ot;
        }

        // asociamos los datos de la bitacora a la variable aBitacora
        this.aBitacora = data.bitacora;
        // asociamos los datos de materiales a las variables correspondientes
        this.aMaterialesRetirados = data.dato_materiales_retirados;
        this.aMaterialesInstalados = data.dato_materiales;
        // asociamos los datos de motivos de rechazo a la variable aMotivoRechazo
        this.aMotivoRechazo = data.rechazo;
        this.aPendiente = data.historico;
        // verificamos que hayan avisos dentro de la respuesta
        if (data.avisos.length > 0) {
          // Asignamos el aviso principal a informationWarningOT
          const informationWarningOT = data.avisos[0].avisos_o_t_s;
          // creamos un array de keys las cuales excluiremos al crear el nuevo array
          const keys = ["idAviso", "tipoTrabajoId", "createdAt", "updatedAt"];
          // usamos Object.entries para iterar sobre pares clave-valor
          this.informationWarningOT = Object.entries(informationWarningOT)
            .filter(([key]) => !keys.includes(key))
            .map(([key, value]) => ({
              titulo: key.replace("_", " ").toUpperCase(),
              detalle: value,
            }));
        }

        // construimos la data para mostrar en el tab de precierre
        let mov = data.movil;
        this.oMovil2 = mov;
        // lo empleamos para verificar si 2 (hay apertura de trafo) existe y asignar los valores al realizar la carga de la data inicial
        const configurarTrafo = (mov) => {
          this.data.trafo = {
            id: mov && mov.trafo_parcial == 2 ? 2 : 1,
            inicio: mov && mov.trafo_parcial == 2 ? mov.aperturaTrafo : null,
            fin: mov && mov.trafo_parcial == 2 ? mov.cierreTrafo : null,
          };
        };
        // llamamos la constante
        configurarTrafo(mov);
        // asignamos los valores al formulario por defecto
        if (mov?.trafo_parcial == 2) {
          // datos de la parte superior
          this.trafo.inicio = mov.aperturaTrafo;
          this.trafo.fin = mov.cierreTrafo;

          // le pasamos los datos al componente usando la variable oCurrent
          this.oCurrent["oTrafo"] = this.data.trafo;
        }
        const info =
          this.oMovil2?.accion?.map((el) => el.descripcion).join("\n") || "";
        // Información adicional
        const sBrigada = mov ? `[${mov.brigada}]` : "";
        const sUser = data.ot.user?.nombre ? data.ot.user?.nombre : "";
        const additionalInfo = [
          { title: "AZT", detail: sUser + sBrigada || "" },
          { title: "Fecha ", detail: moment().format("LLL") },
          { title: "Orden N°", detail: this.num_ot },
          { title: "", detail: info },
        ];
        // Concatenamos la información del COR
        this.data.observacion_cor = additionalInfo
          .map((item) => `${item.title} ${item.detail}`)
          .join("\n");
        this.data.observacion_bol = `${mov?.observacion || ""}\n\n${info}`;
        if (mov) {
          mov.observacion = `${mov.observacion || ""}\n\n${info}`;
        }
        this.$emit("onObservacionCor", this.data.observacion_cor);

        // Si viene información de movil ajustamos la información de trafo parcial
        if (this.movil) {
          const mobileInfo = this.movil;
          const ref = [];

          const isCause = (key) => {
            const causeKeys = [
              "principal_id",
              "causa_id",
              "tipo_os",
              "resultado",
              "contrata",
              "tipo_cierre",
              "obj",
              "trafo_parcial",
              "created_at",
              "updated_at",
              "deleted_at",
            ];
            return causeKeys.includes(key);
          };

          const isPreguntaId = (key) => key === "pregunta_id";

          const isTrafoParcial = (key) => key === "trafo_parcial";

          for (const key in mobileInfo) {
            if (!isCause(key) && !_.isObject(mobileInfo[key])) {
              ref.push({
                titulo: key ? key.replace("_", " ").toUpperCase() : "",
                detalle: mobileInfo[key],
              });
            }
            if (isPreguntaId(key)) {
              ref.push({
                titulo: "¿HUBO DAÑO?",
                detalle: mobileInfo[key] == 1 ? "NO" : "SI",
              });
            }
            if (isTrafoParcial(key)) {
              ref.push({
                titulo: "HAY " + key.replace("_", " ").toUpperCase(),
                detalle:
                  mobileInfo[key] === 1 || mobileInfo[key] === null
                    ? "NO"
                    : "SI",
              });
            }
          }

          this.movil.causa = ref;
        }
      } else {
        this.disabled = true;
        this.disabledImp = true;
        this.disabledAv = true;
      }
    },
    checkboxSelect(check) {
      $("#siemensmodal___BV_modal_content_").attr("tabindex", null);
      switch (check) {
        case 1: // Certificar
          this.form.certificate.value =
            this.form.certificate.value == check ? null : check;
          this.form.check.value = null;
          this.form.check.checked = false;
          this.valueChecked.value = null;
          this.valueChecked.check = false;
          this.value.accion = -1;
          this.searchAction2("accion");
          break;

        case 2: //Aviso pendiente
          this.form.check.value = this.form.check.value == check ? null : check;
          this.form.certificate.value = null;
          this.form.certificate.checked = false;
          this.valueChecked.value = null;
          this.valueChecked.check = false;
          // se actualiza el valor de nAKey para actualizar el componente
          this.nAKey++;
          this.onGetCausasPendiente();
          break;
        case 3: // Aviso improcedente
          this.onResetCheck();
          this.form.certificate.value = null;
          this.form.certificate.checked = false;
          this.infoimp = {};

          this.infoimp = {
            ot: this.informationOT[0].num_ot,
            num_aviso: this.aAviso,
          };
          this.valueChecked.value =
            this.valueChecked.value == check ? null : check;
          break;
      }
    },
    onResetCheck() {
      this.form.check.value = null;
      this.form.check.checked = false;
    },
    searchAction2(vmodal) {
      const { items, value, formSearch } = this;
      $("#certifcate-modal___BV_modal_content_").attr("tabindex", null);
      formSearch[vmodal] = value[vmodal];
      let idChange = 0;
      if (vmodal != "accion") idChange = items[vmodal].id;
      for (let item in items) {
        if (items[item].id > idChange) {
          items[item].show = false;
          value[item] = null;
          formSearch[item] = null;
        }
      }
      if (formSearch.accion !== -1 && vmodal !== "causa_fallida") {
        axios.post("createMatrizCts", formSearch).then(async (res) => {
          const { vmodelNext, data, descripcion } = res.data;
          if (data.length !== 0) {
            if (vmodelNext !== "") {
              const dataOptions = data.map((c) => ({
                value: c.id,
                text: c.descripcion,
              }));
              const title = descripcion ?? "title";
              const idVmodalNext = items[vmodelNext].id;
              items[vmodelNext] = {
                id: idVmodalNext,
                vmodel: vmodelNext,
                show: true,
                label: title,
                options: dataOptions,
              };
            }
          } else {
            formSearch[vmodelNext] = 0;
            value[vmodelNext] = 0;
            if (vmodelNext !== "causa_fallida")
              await this.searchAction2(vmodelNext);
          }
        });
      }
    },
    improcedente() {
      this.showCertificate = false;
      this.$emit("hideModalCertificate", true);
    },
    async loadCausas() {
      const response = await axios.get("causa");
      this.causasList = response.data;
    },
    async onGetCausasPendiente() {
      const { data } = await axios.get("causa-pendiente");
      this.list = _.filter(data, function (o) {
        return o.isCertificate;
      });
      this.list.push({ id: -1, causa: "Seleccione" });
      this.causa = -1;
    },
    async onCertify(payload) {
      const aExlude = [
        // "accion",
        // "danio",
        "deleted_at",
        "fecha_arribo",
        "fecha_localiza_causa",
        "fecha_recibe",
        "fecha_resolucion",
        "informacion",
        "pregunta",
        "reglas_oro",
        "updated_at",
        "usuario_servicio",
        "cierre",
      ];
      let oCertificar = Object.assign({}, payload, this.oMovil2);
      oCertificar.accion = payload.accion;
      oCertificar.observacion_cor = this.data.observacion_cor;
      oCertificar.usuario = this.user.usuario;
      // extraemos el objeto de la información de movil
      const [observacion] = oCertificar.causa;
      if (observacion) {
        // asignamos a observacion bol el valor de la observacion que nos envia la movilidad
        oCertificar.observacion_bol = observacion.observacion;
      }
      for (const key in oCertificar) {
        if (aExlude.includes(key)) {
          delete oCertificar[key];
        }
      }
      // console.log(oCertificar, "<<<||");
      const { data } = await axios.post("ots/certify", oCertificar);
      const onMessage = (icon, message) => {
        this.$swal.fire({
          title: "Información",
          icon: icon,
          text: message,
        });
      };
      if (!data.error) {
        onMessage("success", data.message);
        this.onResetCheck();
        // llamo nuevamente la información
        this.onGetItems();
        this.disabled = true;

        //actualizar el arbol
        this.onActualizarArbol();
        // this.$emit("onCerrarModal", { ap: true });
      } else {
        onMessage("error", data.message);
      }
      // console.log(payload, 'Certificar');
      // console.log(this.oMovil2, 'movil');
    },
    async onGuardarAviso(oForm) {
      try {
        // accedemos al objeto de oCurrent que contiene la información del aviso
        const { avisoRef } = this.oCurrent;
        // si existe el objeto
        if (avisoRef) {
          // asignamos a oForm.id_aviso el id del aviso
          oForm.id_aviso = avisoRef.id_aviso;
        }
        // validamos si existe en oCurrent el numero de la OT
        if (this.oCurrent.num_ot) {
          oForm.num_ot = this.oCurrent.num_ot;
        }
        const { usuario } = this.user;
        oForm.usuario_genera = usuario;
        const { data } = await axios.post("aviso-pendiente", oForm);
        const onMessage = (icon, message) => {
          this.$swal.fire({
            title: "Información",
            icon: icon,
            text: message,
          });
        };
        if (!data.error) {
          onMessage("success", data.message);
          this.nKey++;
          this.onResetCheck();
          // this.$emit("onCerrarModal", { ap: true });
          this.$emit("onGetItems");
        } else {
          onMessage("error", data.message);
        }
      } catch (error) {
        console.log(error);
      }
    },
    // certificacion evento
    async onCertifyEvento() {
      try {
        const payload = {
          work_id: this.work_id,
          certificacion_causa_id: this.selectCausa,
          observacion_cor: this.data.observacion_cor,
          ave: this.selectAve,
        };
        let oCertificar = Object.assign({}, payload, this.oMovil2, {
          bServicio: this.bServicio,
        });
        oCertificar.accion = 0;
        if (this.oCurrent) {
          if (this.oCurrent.num_ot) {
            oCertificar.num_ot = this.oCurrent.num_ot;
          }
        }
        if (
          !payload.work_id ||
          !payload.certificacion_causa_id ||
          payload.observacion_cor === null
        ) {
          toastr.info(
            "Debe ingresar la observacion y llenar los campos de los seleccionables"
          );
          return;
        }
        // console.log(oCertificar);
        const { data } = await axios.post("ots/certify", oCertificar);
        // data de pruebas para hacer console log del object
        // const data = {
        //   error: true,
        //   message: "we"
        // }
        if (data.error) {
          toastr.warning(data.message);
        } else {
          toastr.success(data.message);
          // this.onGetItems();
          // con nKey se actualiza el componente
          this.nKey++;
          // se resetean los valores de los select
          this.razon = null;
          this.tipo = null;
          this.grupo = null;
          this.selectCausa = null;
          // consultamos nuevamente la info la tarea
          this.onGetItems();
          // se deshabilita el check de certificar
          this.disabled = true;
          // se desactiva el check de Certificar
          this.form.certificate.value = null;
          this.form.certificate.checked = null;

          this.onResetCheck();
          this.$emit("onGetItems", true);
          // this.$emit("onCerrarModal", { ap: true });
          this.onActualizarArbol();
        }
      } catch (error) {
        console.log({ error: error });
      }
    },
    async onGetItemsSelects() {
      try {
        if (this.oResponseActions) {
          this.oFiltersActions.razones = this.oResponseActions.razones;
          this.oFiltersActions.razones.map((element) => {
            (element.id = element.codigo_razon),
              (element.text = element.descripcion_razon);
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
    onActualizarArbol() {
      const [root] = this.$root.$children;
      const multipane = root.$children[2];
      const [menu] = multipane.$children;
      menu.treeMenu(menu.data);
    },
    onSetterVoidSelectsCertificates() {
      this.tipo = null;
      this.grupo = null;
      this.causa = null;
      this.selectCausa = null;
      this.selectAve = null;
    },
  },
  watch: {
    razon(newValue) {
      this.tipo = null;
      this.grupo = null;
      this.causa = null;
      this.selectCausa = null;
      this.oFiltersActions.tipos = this.oResponseActions.tipos.filter(
        (tipo) => tipo.codigo_razon === newValue
      );
      this.oFiltersActions.tipos.map((element) => {
        (element.id = element.codigo_subtipo),
          (element.text = element.descripcion_subtipo);
      });

      if (this.oFiltersActions.tipos.length == 1) {
        this.tipo = this.oFiltersActions.tipos[0].id;
      } else {
        this.tipo = null;
        this.grupo = null;
        this.causa = null;
        this.selectCausa = null;
      }
    },
    tipo(newValue) {
      this.grupo = null;
      this.causa = null;
      this.oFiltersActions.grupos = this.oResponseActions.grupos.filter(
        (grupo) =>
          grupo.codigo_razon === this.razon && grupo.codigo_subtipo === newValue
      );
      this.oFiltersActions.grupos.map((element) => {
        (element.id = element.codigo_grupo),
          (element.text = element.descripcion_grupo);
      });

      if (this.oFiltersActions.grupos.length == 1) {
        this.grupo = this.oFiltersActions.grupos[0].id;
      } else {
        this.grupo = null;
        this.causa = null;
        this.selectCausa = null;
      }
    },
    grupo(newValue) {
      this.causa = null;
      this.oFiltersActions.causas = this.oResponseActions.causas.filter(
        (causa) =>
          causa.codigo_razon === this.razon &&
          causa.codigo_subtipo === this.tipo &&
          causa.codigo_grupo === newValue
      );
      this.oFiltersActions.causas.map((element) => {
        // element.id   = element.id,
        element.text = element.descripcion_causa;
      });

      if (this.oFiltersActions.causas.length == 1) {
        this.causa = this.oFiltersActions.causas[0].id;
      } else {
        this.causa = null;
        this.selectCausa = null;
      }
    },
    selectCausa() {
      if (this.oMovil2) {
        const [oPrincipal] = this.oMovil2.causa;
        const ave_codigo = oPrincipal.ave_codigo;
        const aAuxiliarAves = this.aAves.filter( (ave) => ave.id === ave_codigo);
        if(aAuxiliarAves.length !== 0) this.selectAve = aAuxiliarAves[0].id
      }
    },
    cmpMostrarAve() {
      if (!this.cmpMostrarAve) {
        this.selectAve = null;
      }
    },
    oResponseActions: {
      handler() {
        this.onGetItemsSelects();
      },
      deep: true,
    },
  },
};
</script>
