<template>
  <b-modal
    size="xl"
    title="Reporte de turnos"
    ref="report"
    id="report"
    v-model="show"
    no-close-on-backdrop
    scrollable
  >
    <DxDataGrid
      :data-source="items"
      :show-borders="true"
      :show-column-lines="true"
      :show-row-lines="true"
      :row-alternation-enabled="true"
      :columnAutoWidth="true"
      :allow-column-resizing="true"
      :allow-column-reordering="true"
      id="report"
    >
      <DxExport :enabled="true" />
      <DxColumnChooser
        emptyPanelText="Arrastre una columna aquí para ocultar"
        :enabled="true"
        title="Arrastre columna aquí..."
      />
      <DxColumnFixing :enabled="true" />
      <DxGroupPanel
        :visible="false"
        emptyPanelText="Arrastre una columna aquí para agrupar"
      />
      <DxSearchPanel :visible="true" :width="240" placeholder="Buscar..." />
      <DxPaging :page-size="30" />
      <DxPager
        :visible="true"
        infoText="Página {0} de {1} ({2} registros)"
        :allowed-page-sizes="[5, 15, 30, 50, 'all']"
        display-mode="full"
        :show-page-size-selector="true"
        :show-info="true"
        :show-navigation-buttons="true"
      />
      <DxHeaderFilter :visible="true" :allowSearch="true" />
      <DxFilterRow :visible="true">
        <DxOperationDescriptions
          equal="Igual a"
          notEqual="Diferente de"
          startsWith="Empieza con"
          endsWith="Termina con"
          contains="Contiene"
          between="Entre"
          notContains="No contiene"
          lessThanOrEqual="Menor o igual"
          greaterThanOrEqual="Mayor o igual"
          lessThan="Menor que"
          greaterThan="Mayor que"
        />
      </DxFilterRow>
      <DxGroupPanel :visible="true" />
      <!-- <DxColumn
        v-for="(item, idx) in columns"
        :key="idx"
        :caption="item.caption"
        alignment="center"
        css-class="cell-grouping-tech"
      > -->
      <DxColumn
        alignment="center"
        caption="Brigada"
        data-field="brigada"
        :group-index="0"
      />
      <DxColumn
        alignment="center"
        caption="OT"
        data-field="num_ot"
      />
      <DxColumn
        alignment="center"
        caption="Aviso"
        data-field="aviso"
      />
      <DxColumn
        alignment="center"
        caption="Dirección"
        data-field="direccion"
      />
      <DxColumn
        alignment="center"
        caption="Circuito"
        data-field="circuito_sm"
      />
      <DxColumn
        alignment="center"
        caption="Estado"
        data-field="estado"
      />
      
      <!-- </DxColumn> -->
      <DxToolbar>
        <DxItem location="after" template="refreshTemplate" />
        <DxItem name="columnChooserButton" />
        <DxItem name="exportButton" />
        <DxItem name="groupPanel" />
        <DxItem name="searchPanel" />
      </DxToolbar>
      <template #refreshTemplate>
        <DxButton icon="refresh" @click="refreshDataGrid()" />
      </template>
    </DxDataGrid>
  </b-modal>
</template>
<style>
.cell-grouping-tech {
  background-color: #062863 !important;
  color: #f9db5c !important;
}
</style>
<script>
import {
  DxDataGrid,
  DxPaging,
  DxSearchPanel,
  DxFilterRow,
  DxHeaderFilter,
  DxPager,
  DxOperationDescriptions,
  DxExport,
  DxGroupPanel,
  DxColumn,
  DxColumnChooser,
  DxColumnFixing,
  DxToolbar,
  DxItem,
  // DxGroupItem
} from "devextreme-vue/data-grid";
import { DxButton } from "devextreme-vue/button";
export default {
  components: {
    DxButton,
    DxDataGrid,
    DxPaging,
    DxSearchPanel,
    DxFilterRow,
    DxHeaderFilter,
    DxPager,
    DxOperationDescriptions,
    DxExport,
    DxGroupPanel,
    DxColumn,
    DxColumnChooser,
    DxColumnFixing,
    DxToolbar,
    DxItem,
  },
  data() {
    return {
      show: false,
      items: [],
      columns: [],
    };
  },
};
</script>
