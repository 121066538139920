<template>
  <b-modal
    title="Asignar vehiculo"
    v-model="show"
    size="xl"
    no-close-on-backdrop
    scrollable
    id="assignVehicle"
  >
    <b-row>
      <b-col md="4">
        <div class="form-group">
          <label>Nombre</label>
          <input
            v-model="item.nombre"
            readonly
            type="text"
            class="form-control form-control-sm"
            placeholder="Nombre de usuario"
          />
        </div>
      </b-col>
      <b-col md="4">
        <div class="form-group">
          <label>Email</label>
          <input
            :value="item.email"
            readonly
            type="text"
            class="form-control form-control-sm"
            placeholder="Nombre de usuario"
          />
        </div>
      </b-col>
      <b-col md="4">
        <div class="form-group">
          <label>Perfil</label>
          <input
            :value="item.perfil"
            readonly
            type="text"
            class="form-control form-control-sm"
            placeholder="Nombre de usuario"
          />
        </div>
      </b-col>
      <b-col md="4">
        <div class="form-group">
          <label>Contrata</label>
          <input
            :value="item.cliente ? item.cliente : ''"
            readonly
            type="text"
            class="form-control form-control-sm"
            placeholder="Nombre de usuario"
          />
        </div>
      </b-col>
      <b-col md="4">
        <div class="form-group">
          <label>Vehículos</label>
          <Select2
            v-model="vehicle"
            :options="vehicles"
            placeholder="Filtrar"
          />
        </div>
      </b-col>
    </b-row>

    <template #modal-footer>
      <div class="form-group">
        <button class="btn btn-sm btn-circle" @click="() => (show = false)">
          <i class="la la-reply"></i> Cancelar
        </button>
        <button class="btn btn-sm btn-primary" @click="assign()">
          <i class="la la-save"></i> {{ loading ? "Cargando..." : "Asignar" }}
        </button>
      </div>
    </template>
  </b-modal>
</template>
<script>
import axios from "axios";
import Select2 from "v-select2-component";
import * as $ from "jquery";
import toastr from "toastr";
export default {
  components: {
    Select2,
  },
  data() {
    return {
      show: false,
      loading: false,
      item: {},
      vehicles: [],
      vehiculesAll: [],
      vehicle: -1,
    };
  },
  methods: {
    removeTabIndex() {
      setInterval(() => {
        $("#assignVehicle___BV_modal_content_").attr("tabindex", null);
      }, 2000);
    },
    loadVehicles(contrata) {
      axios
        .post("/vehicles", { base: contrata })
        .then((res) => {
          if (res.data.message == "success") {
            let veh = [];
            veh.push({
              id: -1,
              text: `Seleccione`,
            });
            this.vehiculesAll = res.data.vehicles;
            res.data.vehicles.map((el) => {
              veh.push({
                id: el.id_vehiculo,
                text: `${el.tipo} - ${el.placa} - ${el.marca}`,
              });
            });
            this.vehicles = veh;
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    assign() {
      if (this.vehicle !== -1) {
        this.loading = true;
        axios
          .post(`vehicles/assign`, {
            base: this.item.base.nombre,
            usuario: this.item.usuario,
            id: this.item.id,
            id_vehiculo: this.vehicle,
            vehiculo: this.vehiculesAll.filter(
              (f) => f.id_vehiculo == this.vehicle
            )[0].placa,
          })
          .then((res) => {
            this.loading = false;
            if (res.data.message == "success") {
              this.$emit("loadItems", true);
              console.log(res.data);
            } else {
                toastr.info(res.data.error)
            }
          })
          .catch((e) => {
            this.loading = false;
            console.log(e);
          });
      } else {
        toastr.info("Debe seleccionar un vehiculo !!");
      }
    },
  },
};
</script>
