<template>
  <b-row v-if="aPendiente.length">
    <b-col>
      <DxDataGrid
        :data-source="aPendiente"
        :show-borders="true"
        :show-column-lines="true"
        :show-row-lines="true"
        :row-alternation-enabled="true"
        :columnAutoWidth="true"
        :allow-column-resizing="true"
      >
        <DxPaging :page-size="10" />
        <DxHeaderFilter :visible="true" />
        <DxFilterRow :visible="true">
          <DxOperationDescriptions
            equal="Igual a"
            notEqual="Diferente de"
            startsWith="Empieza con"
            endsWith="Termina con"
            contains="Contiene"
            between="Entre"
            notContains="No contiene"
            lessThanOrEqual="Menor o igual"
            greaterThanOrEqual="Mayor o igual"
            lessThan="Menor que"
            greaterThan="Mayor que"
          />
        </DxFilterRow>
        <DxPager
          :visible="true"
          infoText="Página {0} of {1} ({2} registros)"
          :allowed-page-sizes="pageSizes"
          display-mode="full"
          :show-page-size-selector="true"
          :show-info="true"
          :show-navigation-buttons="true"
        />
        <DxColumn data-field="causa.causa" caption="Causa" />
        <DxColumn data-field="observacion" caption="Observación" />
        <DxColumn
          data-field="created_at"
          caption="Fecha"
          data-type="date"
          format="dd/MM/yyyy, HH:mm:ss"
        />
        <DxColumn data-field="usuario_genera" caption="Usuario" />
      </DxDataGrid>
    </b-col>
  </b-row>
  <b-row v-else>
    <b-col class="text-center">
      <img src="assets/img/empty.png" alt="Empty" />
      <p class="text-muted">No hay información</p>
    </b-col>
  </b-row>
</template>
<script>
import {
  DxDataGrid,
  DxPaging,
  DxColumn,
  DxFilterRow,
  DxHeaderFilter,
  DxOperationDescriptions,
  DxPager,
} from "devextreme-vue/data-grid";
export default {
  components: {
    DxDataGrid,
    DxPaging,
    DxColumn,
    DxFilterRow,
    DxHeaderFilter,
    DxOperationDescriptions,
    DxPager,
  },
  props: {
    aPendiente: {
      type: Array,
      default: () => [],
    },
    pageSizes: {
      type: Array,
      default: () => [5, 10, 15, 30, 50, 100],
    },
  },
};
</script>