<template>
  <div id="app">
    <LoginComponent v-if="!authenticated" />
    <div v-else>
      <!-- <vue-ins-progress-bar /> -->
      <vue-progress-bar />
      <Mobile />
      <div class="d-flex flex-column flex-root">
        <div class="d-flex flex-row flex-column-fluid page">
          <multipane
            :class="resizer"
            layout="vertical"
            ref="multipane"
            @mousedown.stop
          >
            <MenuComponent
              @locatePlaceMarker="locatePlaceMarker($event)"
              @loadMarkerCircuit="loadMarkerCircuit($event)"
              @loadMarkerSubstation="loadMarkerSubstation($event)"
              @loadTech="loadTech($event)"
              @loadMarkerSector="loadMarkerSector($event)"
              @loadWarningManaged="loadWarningManaged($event)"
              @loadWarningManagedCircuit="loadWarningManagedCircuit($event)"
              @loadInformationOT="loadInformationOT"
              @loadKML="loadKML"
              @brigadeDate="brigadeData = $event"
              ref="menuComponent"
            />
            <multipane-resizer></multipane-resizer>
            <div
              class="d-flex flex-column flex-row-fluid"
              id="kt_wrapper"
              style="height: 100vh; overflow-y: hidden"
            >
              <Header @loadCor="loadCor($event)" />
              <div
                class="content d-flex flex-column flex-column-fluid"
                id="kt_content"
                :style="ktContentHeight"
              >
                <div class="container-fluid content-box-vue">
                  <transition name="fade" mode="out-in">
                    <router-view
                      @loadCor="loadCor($event)"
                      @loadMenu="loadMenu($event)"
                      :brigadeData="brigadeData"
                      ref="routerView"
                    />
                  </transition>
                </div>
              </div>
            </div>
          </multipane>
        </div>
      </div>
      <InformacionAviso ref="INFO" />
      <InformacionOT ref="INFOOT" />
      <UserPanel />
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { Multipane, MultipaneResizer } from "vue-multipane";

import LoginComponent from "@/components/auth/Login.vue";

import Header from "@/components/helpers/Header.vue";
import Mobile from "@/components/helpers/headerMobile.vue";
import MenuComponent from "@/components/helpers/menuComponent.vue";

import UserPanel from "@/components/helpers/quickUser.vue";
import InformacionAviso from "@/components/helpers/modals/informacionAviso.vue";
import InformacionOT from "@/components/helpers/modals/informacionOT.vue";

import axios from "axios";
export default {
  components: {
    LoginComponent,
    Header,
    Mobile,
    MenuComponent,
    UserPanel,
    Multipane,
    MultipaneResizer,
    InformacionAviso,
    InformacionOT,
  },
  mounted() {
    // console.log(this.$root.$children[0].$children[1].$children[0]);
    console.log(
      "%c Bienvenido a SIPREM CLD!!",
      `font-weight: bold; 
        font-size: 32px; `
    );
    if (sessionStorage.sectores) {
      this.changeCor(JSON.parse(sessionStorage.sectores));
    }
    if (this.$route.path == "/works") this.ktContentHeight = "";
    else this.ktContentHeight = "height: 80vh;";

    /**
     * Creamos un axios.interceptors para monitorear las peticiones que se envian a la API
     * de tal forma que si la url es diferente de /turns-notifications, active la barra de progreso
     */
    axios.interceptors.request.use(
      (config) => {
        if (config.url !== "/turns-notifications") {
          this.$Progress.start();
        }
        return config;
      },
      (error) => {
        this.$Progress.fail();
        return Promise.reject(error);
      }
    );

    axios.interceptors.response.use(
      (response) => {
        this.$Progress.finish();
        return response;
      },
      (error) => {
        this.$Progress.fail();
        return Promise.reject(error);
      }
    );
  },
  data() {
    return {
      ktContentHeight: "height: 80vh;",
      resizer: "custom-resizer",
      dep: null,
      newMarker: null,
      case: null,
      municipality: null,
      dir_ref: null,
      load: false,
      currentModel: null,
      ot: null,
      kml: [],
      brigadeData: {},
    };
  },
  watch: {
    $route: {
      handler: function (newRouteValue) {
        if (newRouteValue.path == "/works") this.ktContentHeight = "";
        else this.ktContentHeight = "height: 80vh;";
      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters({
      authenticated: "auth/authenticated",
      user: "auth/user",
      warnings: "location/getWarning",
      getIds: "location/getIds",
      getIdx: "warningsOT/getIdx",
      getIdCert: "warningsOT/getIdCert",
    }),
  },
  methods: {
    ...mapActions({
      changeIds: "location/changeIds",
      loadAllWarning: "location/loadCircuit",
      changeTechId: "technical/changeTechIds",
      setIdOT: "warningsOT/setIdOT",
      removeWarningCircuit: "location/removeWarningCircuit",
      setStaticId: "location/setStaticId",
      setIdCert: "warningsOT/setIdCert",
      setIdxAll: "warningsOT/setIdxAll",
      insertOrUpdate: "warningsOT/insertOrUpdate",
      changeCor: "auth/zoneWork",
    }),
    loadKML(data) {
      this.kml = data;
    },
    locatePlaceMarker(values) {
      let aux = values;
      values = values.id_aviso == undefined ? null : values;
      if (values != null) {
        this.newMarker = values.id_aviso;
        this.case = values.slug;
        this.dir_ref = `${values.dir_suscriptor} - ${values.dir_suscriptor}`;
        if (values.slug == "not_ubicated_second") {
          this.municipality = values.selected ? `${values.municipio}` : null;
        }
        if (values.slug == "assign_ot") {
          this.changeIds(values);
        }
      } else {
        this.newMarker = null;
        this.municipality = null;
      }

      if (aux.alternative != undefined && aux.alternative == "assign_ot") {
        this.case = aux.value.slug;
        this.changeIds(aux.value);
      }
    },
    loadMarkerCircuit(event) {
      if (event != null) {
        this.case = event.slug;
        let ids = [];
        event.children.map((m) => {
          ids.push(m.id_aviso);
        });
        if (event.selected) {
          this.newMarker = ids;
          this.loadAllWarning(this.newMarker);
        } else {
          this.removeWarningCircuit(this.newMarker);
          this.newMarker = this.getIds;
        }
      } else {
        this.loadAllWarning(null);
        this.newMarker = null;
      }
    },
    loadMarkerSubstation(event) {
      if (event != null) {
        this.case = event.slug;
        let ids = [];
        event.children.map((m) => {
          m.children.map((x) => {
            ids.push(x.id_aviso);
          });
        });
        this.newMarker = ids;

        if (event.selected) {
          this.loadAllWarning(this.newMarker);
        } else {
          this.removeWarningCircuit(this.newMarker);
          this.newMarker = this.getIds;
        }
      } else {
        this.loadAllWarning(null);
        this.newMarker = null;
      }
    },
    loadMarkerSector(event) {
      console.log(event.slug);
      if (event != null) {
        this.case = event.slug;
        let ids = [];
        event.children.map((m) => {
          m.children.map((s) => {
            s.children.map((x) => {
              ids.push(x.id_aviso);
            });
          });
        });
        this.newMarker = ids;

        if (event.selected) {
          this.loadAllWarning(this.newMarker);
        } else {
          this.removeWarningCircuit(this.newMarker);
          this.newMarker = this.getIds;
        }
      } else {
        this.loadAllWarning(null);
        this.newMarker = null;
      }
    },
    loadTech(event) {
      if (event.selected) {
        this.case = event.slug;
        this.newMarker = event.usuario;
      } else {
        this.newMarker = "technicalOff";
      }
      this.changeTechId(event);
    },
    loadMenu(values) {
      if (values.load == 1) {
        this.newMarker = null;
        this.$refs.menuComponent.treeMenu(this.$refs.menuComponent.data);
      }
    },
    loadCor(values) {
      this.$refs.menuComponent.setCor(values);
      this.dep = values;
    },
    departmentFromHome(values) {
      this.$refs.routerView.setCor(values);
      this.dep = values;
    },
    loadWarningManaged(event) {
      if (event != null) {
        this.case = event.slug;
        if (event.slug == "aviso_gestionado") {
          this.changeIds(event);
          this.newMarker = this.getIds;
        }
      } else {
        this.case = null;
        this.newMarker = null;
      }
    },
    loadWarningManagedCircuit(event) {
      if (event != null) {
        this.case = event.slug;
        let ids = [];
        event.children.map((m) => {
          if (this.case == "subestacion_aviso_gestionado") {
            m.children.map((x) => {
              ids.push(x.id_aviso);
            });
          } else if (this.case == "circuito_aviso_gestionado")
            ids.push(m.id_aviso);
        });
        if (event.selected) {
          this.newMarker = ids;
          this.loadAllWarning(this.newMarker);
        } else {
          this.removeWarningCircuit(this.newMarker);
          this.newMarker = this.getIds;
        }
      } else {
        this.loadAllWarning(null);
        this.newMarker = null;
      }
    },
    loadInformationOT(event) {
      if (event.slug == "CIRCUITO_OT") {
        if (event) {
          this.case = event.slug;
          event.children.map((el) => {
            this.setIdOT(el);
          });
          this.newMarker = this.getIdx;
        }
      } else if (event.slug == "SUBESTACION_OT") {
        if (event) {
          this.case = event.slug;
          event.children.map((el) => {
            el.children.map((av) => {
              this.setIdOT(av);
            });
          });
          this.newMarker = this.getIdx;
        }
      } else {
        this.setIdOT(event);
        this.currentModel = event;
        if (event.selected) {
          this.case = event.slug;
          if (event.slug == "OTTECH") {
            this.case == "ot_managed_tree";
          }
          this.newMarker = this.getIdx;
        }
      }
    },
  },
  name: "App",
};
</script>
<style>
.component-fade-enter-active,
.component-fade-leave-active {
  transition: opacity 0.3s ease;
}
.component-fade-enter,
.component-fade-leave-to {
  opacity: 0;
}
@media (min-width: 792px) {
  .content {
    padding: 5px 0 !important;
  }
  .content-box-vue {
    padding: 12px 5px !important;
    overflow-x: hidden;
  }
}
@media (max-width: 991.98px) {
  .aside {
    left: 0 !important;
  }
}

#kt_aside {
  position: relative;
  max-width: 45%;
  min-width: 25%;
}
/* #kt_content{
    padding-top: 60px !important;
  } */
/* .multipane > div{
    z-index: 100 !important;
  } */
.layout-v > .multipane-resizer {
  width: 8px !important;
  height: 100% !important;
  margin-left: 0px !important;
  left: 0px !important;
  cursor: col-resize;
}
.multipane {
  width: 100%;
}
.custom-resizer > .multipane-resizer {
  margin: 0;
  left: 0;
  position: relative;
}
.custom-resizer > .multipane-resizer::before {
  display: block;
  content: "";
  width: 3px;
  height: 40px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -20px;
  margin-left: 0 !important;
  background-color: rgb(175, 175, 175);
}
.custom-resizer > .multipane-resizer:hover::before {
  /* border-color: rgb(65, 64, 64); */
  background-color: rgb(94, 94, 94);
}
body {
  overflow-y: hidden;
}
.ins-progress {
  background: #0d7c9e !important;
  background: #0d7c9e -webkit-gradient(linear, left top, right top, from(#0d7c9e), color-stop(#0d7c9e), color-stop(#27c4f5), color-stop(#70c050), color-stop(#fd8d32), to(#0d7c9e)) !important;
  background: #0d7c9e -webkit-linear-gradient(left, #0d7c9e, #27c4f5, #70c050, #fd8d32, #0d7c9e) !important;
  background: #0d7c9e
    linear-gradient(to right, #0d7c9e, #27c4f5, #1da1aa, #2e5bbb, #0d7c9e) !important;
}
</style>
