<template>
  <b-modal
    id="siemensmodal"
    v-model="bShow"
    size="xl"
    no-close-on-backdrop
    title="Certificar OT"
    scrollable
    hide-header-close
  >
    <b-card no-body>
      <b-tabs v-model="tabIndex" card>
        <b-tab
          :title="`${tab.workTask && tab.workTask.numero_aviso ? tab.workTask.numero_aviso : `Tarea ${index + 1}` }`"
          v-for="(tab, index) in works"
          :key="`_${index}_`"
          :class="`tabAction`"
        >
          <Content
            :num_ot="tab.num_ot"
            :work_id="work_id"
            @onCertificar="onCertificarOT"
            @onCerrarModal="onCerrarModal"
            @onObservacionCor="onObservacionCor"
            @onGetItems="onConsultarTareas"
            :oResponseActions="oResponseActions"
            :aCausas="aCausas"
            :aAves="aAves"
          />
        </b-tab>
        <b-tab title="Solicitar tarea">
          <SolicitarTarea ref="rSolicitar" @onGetItems="onConsultarTareas" />
        </b-tab>
        <b-tab title="Evento" :key="nKey">
          <CEventos
            ref="rEvento"
            :eventos="works"
            :work_id="work_id"
            @onCerrarModal="onCerrarModal"
            :aCausas="aCausas"
            :aAves="aAves"
          />
        </b-tab>
      </b-tabs>
    </b-card>
    <template #modal-footer>
      <button
        @click="onCerrarModal()"
        class="btn btn-sm btn-link text-dark opacity-70"
      >
        <i class="la la-reply"></i> Cancelar
      </button>
    </template>
  </b-modal>
</template>
<script>
import axios from "axios";
import Content from "@/components/helpers/modals/content/ContenidoTab.vue";
import CEventos from "@/components/helpers/modals/certificationTabs/CEventos.vue";
import SolicitarTarea from "@/components/helpers/modals/content/SolicitarTarea.vue";
export default {
  components: {
    Content,
    CEventos,
    SolicitarTarea,
  },
  data() {
    return {
      bShow: false,
      oAviso: {},
      works: [],
      work_id: null,
      sObservacion: null,
      tabIndex: 0,
      // nKey
      nKey: 0,
      aCausas: [],
      aAves: [],
      oFiltersActions: {}
    };
  },
  mounted() {
    this.onGetCausaAnimalesAereos()
    this.onGetAves()
    this.onGetMatriz()
    this.onActualizarArbol()
  },
  methods: {
    async onGetCausaAnimalesAereos() {
      try {
        const { data } = await axios.get('/matriz-animales-aereos');
        this.aCausas = data
      }catch(error) {
        error;
      }
    },
    async onGetAves() {
      try {
        const { data } = await axios.get('/aves')
        this.aAves = data.map((item) => {
          return {
            id: item.codigo,
            value: item.codigo,
            text: item.nombreave
          }
        })
      } catch (error) {
        error
      }
    },
    async onConsultarTareas() {
      this.works = [];
      if (this.oAviso.id_aviso) {
        const id = this.oAviso.id_aviso;
        const { data } = await axios.get(`avisos/${id}`);
        const [aviso] = data;
        if (aviso.work && aviso.work.tasks_avisos_ot) {
          this.works = aviso.work.tasks_avisos_ot;
          let sObservacion = "";
          this.works.forEach((element) => {
            if (element.obs_certifiacion) {
              sObservacion += `${element.obs_certifiacion}\n`;
            }
          });
          this.sObservacion = sObservacion;
        }
        this.work_id = aviso.work_id;
        // cambio al aviso de la respuesta ya que este trae el mRID
        // y desde del lado del mapa no estaba apareciendo el mRID para certificar
        this.$refs.rSolicitar.work.mRID = aviso.work.mRID;
        // se inicializa el tabIndex con la longitud del arreglo de works, para que siempre apunte al ultimo tab
        // (nose porque este comportamiento XD)
        this.tabIndex = this.works.length;
      }
    },
    onCertificarOT(oData) {
      // axios para certificar
      console.log(oData);
    },
    onCerrarModal(oData = { ap: false }) {
      this.bShow = false;

      if (oData.ap) {
        this.onActualizarArbol()
      }
    },
    onActualizarArbol() {
      const [root] = this.$root.$children;
      const multipane = root.$children[2];
      const [menu] = multipane.$children;
      menu.treeMenu(menu.data);
    },  
    onObservacionCor(observacion_cor) {
      this.observacion_cor = observacion_cor;
    },
    async onGetMatriz() {
      try {
        const { data, status } = await axios("/matriz-certificaciones");
        if (status === 200) {
          this.oResponseActions = data;
        }
      } catch (error) {
        error;
      }
    }
  },
};
</script>
<style>
#siemensmodal > .modal-dialog.modal-xl {
  max-width: 95% !important;
}
.tabAction {
  padding: 2px !important;
}
</style>
