<template>
    <b-modal
        title="Agregar aviso a orden de trabajo"
        ref="add-child-modal"
        id="addChildModal"
        v-model="addChildShow"
        size="xl"
        no-close-on-backdrop
        scrollable
        hide-header-close
    >
        <table class="table table-sm table-hover table-striped mb-2">
            <thead>
                <tr>
                    <td>N° OT</td>
                    <td>Brigada asignada</td>
                    <td>Fecha de asignación</td>
                    <td>Observación</td>
                </tr>
            </thead>
            <tbody>
                <tr 
                    v-for="(item, index) in data"
                    :key="index">
                    <td> {{ item.num_ot }} </td>
                    <!-- <td> {{item}} </td> -->
                    <td> {{ item.user  ? item.user.nombre : 'NO ASIGNADO' }} </td>
                    <td> {{ item.fecha_asigna | dateFormat }} </td>
                    <td> {{ item.observacion }} </td>
                </tr>
            </tbody>
        </table>
        <hr>
        <table class="table table-hover table-sm table-striped">
            <thead class="thead-dark">
                <tr>
                    <td colspan="9" class="text-center">
                        {{ 'Avisos relacionados a la OT' | upperCase}}
                    </td>
                </tr>
                <tr>
                    <td class="text-center">Aviso de referencia</td>
                    <td class="text-center">N° de orden</td>
                    <td> Subestación </td>
                    <td> Circuito </td>
                    <td> Tipo</td>
                    <td> Dirección</td>
                    <td> Referencia</td>
                    <td> Observación</td>
                </tr>
            </thead>
            <tbody>
                <tr 
                    v-for="(aviso, index) in warnings" 
                    :key="index">
                    <td class="text-center">
                        <div class="checkbox-inline">
                            <label class="checkbox checkbox-outline checkbox-outline-2x checkbox-primary">
                                <input 
                                    type="checkbox" 
                                    name="Checkboxes16"
                                    checked>
                                <span></span>
                            </label>
                        </div>
                    </td>
                    <td class="text-center">
                            <!-- @click="addFavorite(item.id_aviso)" -->
                        <button 
                            class="btn btn-sm btn-link"
                            > 
                            <i :class="'la la-star' + (aviso.id_aviso === ref ? ' text-warning' : '') "></i>
                        </button>
                    </td>
                    <td class="text-center"> {{ aviso.num_aviso }} </td>
                    <td> {{ aviso.subestacion }} </td>
                    <td> {{ aviso.circuito_sm }} </td>
                    <td> {{ aviso.tipo_aviso }} </td>
                    <td> {{ aviso.dir_ref }} </td>
                    <td> {{ aviso.observacion }} </td>
                </tr>
                <tr>
                    <td colspan="8" class="text-center bg-success">
                        <b>AVISO NUEVO</b>    
                    </td>
                </tr>

                <tr 
                    v-for="(aviso, index) in draggedItem" 
                    :key="index + '_' ">
                    <td class="text-center">
                        <div class="checkbox-inline">
                            <label class="checkbox checkbox-outline checkbox-outline-2x checkbox-primary">
                                <input 
                                    type="checkbox" 
                                    name="Checkboxes16"
                                    checked>
                                <span></span>
                            </label>
                        </div>
                    </td>
                    <td class="text-center">
                            <!-- @click="addFavorite(item.id_aviso)" -->
                        <button 
                            class="btn btn-sm btn-link"
                            > 
                            <i :class="'la la-star' + (aviso.id_aviso === ref ? ' text-warning' : '') "></i>
                        </button>
                    </td>
                    <td class="text-center"> {{ aviso.num_aviso }} </td>
                    <td> {{ aviso.subestacion }} </td>
                    <td> {{ aviso.circuito_sm }} </td>
                    <td> {{ aviso.tipo_aviso }} </td>
                    <td> {{ aviso.dir_ref }} </td>
                    <td> {{ aviso.observacion }} </td>
                </tr>
            </tbody>
        </table>

        <template #modal-footer>
            <div class="form-group">
                <button 
                    class="btn btn-link btn-sm" 
                    @click=" $bvModal.hide('addChildModal') ">
                    <i class="flaticon-close"></i> Cancelar
                </button>
                <button class="btn btn-primary btn-sm" ref="buttonLoader" @click="saveWarning">
                    <i class="flaticon-folder-1" v-if="!isLoading"></i> Agregar Aviso
                </button>
            </div>
        </template>
    </b-modal>
</template>
<script>
    import axios from 'axios'
    import moment from 'moment'
    import toastr from 'toastr'
    export default {
        name: 'add-child-ot',
        data() {
            return {
                addChildShow: false,
                data: [],
                warnings: [],
                draggedItem: [],
                ref: null,
                isConfirm: false,
                isLoading: false
            }
        },
        filters: {
            dateFormat(value) {
                return moment(value).format('LL')
            },
            upperCase(value) {
                return value.toUpperCase()
            }
        },
        methods: {
            informationOT(dragged, item) {
                if(dragged) {
                    axios.get(`ots/${dragged}`)
                        .then((res) => {
                            this.data = res.data
                            this.warnings = []
                            res.data.map(el => {
                                el.avisos.map(a => {
                                    this.ref = a.pivot.id_aviso_ref
                                    this.warnings.push(a)
                                })
                            })
                            
                        })
                    axios.get(`avisos/${item}`)
                        .then((res) => {
                            this.draggedItem = res.data
                        })
                }
            },
            closeWindow() {
                this.$emit('closeWindowModal', false)
            },
            async saveWarning() {
                let avisos = []
                    avisos = this.warnings.map(e => {
                        return e.id_aviso
                    })
                
                this.draggedItem.map(dragged => {
                    avisos.push(dragged.id_aviso)
                })
                let info = this.data[0]
                let data = {
                    avisos: avisos,
                }
                // console.log(avisos);
                this.loaderSpinClasss()
                await axios.post(`agregar-aviso-ots/${info.num_ot}`, data)
                    .then((res) => {
                        this.isConfirm = true
                        if(res.data.message == "failed") {
                            toastr.warning('No se ha podido agregar el aviso')
                        }else 
                        {
                            this.$emit('closeWindowModal', false)
                            this.removeLoaderSpinClass()
                        }
                    })
            },
            loaderSpinClasss(){
                this.isLoading = !this.isLoading
                const submitButton = this.$refs.buttonLoader;
                submitButton.classList.add("disabled","spinner", "spinner-white", "spinner-left");
            },
            removeLoaderSpinClass() {
                this.isLoading = !this.isLoading
                const submitButton = this.$refs.buttonLoader;
                submitButton.classList.remove("disabled","spinner", "spinner-white", "spinner-left");
            }
        }
    }
</script>