<template>
  <b-modal
    ref="cerrar-trabajo-modal"
    id="cerrar-trabajo-modal"
    v-model="show"
    size="xl"
    no-close-on-backdrop
    title="Cerrar trabajo"
    scrollable
    hide-header-close
  >
    <b-row>
      <b-col md="4">
        <label>Estado</label>
        <div class="form-group d-flex">
          <select class="form-control form-control-sm mr-2" v-model="status">
            <option
              :value="item.id"
              v-for="(item, idx) in list"
              :key="idx"
              :data-icon="item.icon"
            >
              {{ item.text }}
            </option>
          </select>
          <i :class="icono.icon + ' mt-3'"></i>
        </div>
      </b-col>
      <b-col md="4" v-if="status == 2">
        <label>Listado de brigadas</label>
        <div class="form-group">
          <Select2 :options="optionSelect" v-model="technical" />
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <label>Observación</label>
        <div class="form-group">
          <textarea
            v-model="message"
            cols="5"
            rows="5"
            class="form-control form-control-sm"
          ></textarea>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <small>
          Estimado <b> {{ user.nombre | uppercase }} </b>: recuerde que la OT:
          {{ curr.num_ot }} se encuentra en <b>EJECUCIÓN</b>
        </small>
      </b-col>
    </b-row>
    <template #modal-footer>
      <b-row>
        <b-col>
          <button @click="() => (show = false)" class="btn btn-sm btn-link">
            <i class="flaticon-close"></i> Cancelar
          </button>
          <button class="btn btn-primary btn-sm ml-2" @click="updateOT()">
            <i class="la la-save"></i>
            {{ loading ? 'Cargando...' : 'Confirmar' }}
          </button>
        </b-col>
      </b-row>
    </template>
  </b-modal>
</template>
<script>
import axios from "axios";
import toastr from "toastr";
import moment from "moment";
import { mapGetters } from "vuex";
import Select2 from "v-select2-component";
import * as $ from "jquery";
export default {
  name: "cerrar-trabajo",
  components: {
    Select2,
  },
  data() {
    return {
      show: false,
      techs: [],
      loading: false,
      curr: {},
      message: "",
      technical: -1,
      status: -1,
      list: [
        {
          id: -1,
          text: "Seleccione",
          icon: "fa fa-fire text-danger",
        },
        {
          id: 1,
          text: "Pendiente",
          icon: "fa fa-fire text-danger",
        },
        {
          id: 2,
          text: "Asignar a brigada",
          icon: "fa fa-handshake text-primary",
        },
        {
          id: 9,
          text: "Cerrar trabajo",
          icon: "la la-folder-o text-success font-weight-bold",
        },
      ],
    };
  },
  watch: {
    status() {
      if (this.status === 2) {
        $("#cerrar-trabajo-modal___BV_modal_content_").attr("tabindex", null);
      } else {
        this.technical = -1;
      }
    },
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
    icono() {
      if (this.status == -1) {
        return { icon: "far fa-grimace text-warning" };
      }
      return this.list.filter((f) => f.id == this.status)[0];
    },
    optionSelect() {
      let options = [];
      options.push({
        id: -1,
        text: "Seleccione",
      });
      this.techs.map((el) => {
        options.push({
          id: el.usuario,
          text: `${el.cliente.vehiculo} - ${el.nombre} - ${el.cliente.movil}`,
          completed: `${el.cliente.vehiculo} - ${el.nombre} ${el.usuario} - ${el.cliente.movil}`,
        });
      });
      return options;
    },
  },
  filters: {
    uppercase(values) {
      return values.toUpperCase();
    },
  },
  methods: {
    reset() {
      this.technical = -1;
      this.status = -1;
      this.message = "";
    },
    loadTech() {
      axios.post("tecnico-bol-all").then((res) => {
        // console.log(res.data);
        this.techs = res.data;
      });
    },
    updateOT() {

      let curr = this.curr;
      let description = "";
      if (this.status == -1) {
        toastr.info("Debe seleccionar un estado");
      } else {
        if (this.status == 2) {
          let brigada = this.optionSelect.filter(
            (f) => f.id == this.technical
          )[0];
          description = `NOVEDAD:  ORDEN DE TRABAJO ASIGNADA A [${brigada.completed}] POR EL OPERADOR [ ${this.user.nombre}] \n\n MOTIVO: ${this.message}`;
        } else if (this.status == 1) {
          description = `NOVEDAD:  ORDEN DE TRABAJO DESASIGNADA POR EL OPERADOR [ ${this.user.nombre}]  A [${this.curr.usuario_tech}] \n MOTIVO: ${this.message}`;
        } else if (this.status == 9) {
          description = `NOVEDAD:  ORDEN DE TRABAJO CERRADA POR EL OPERADOR [ ${this.user.nombre}] \n MOTIVO: ${this.message}`;
        }


        if (this.message !== "") {
          let brigada = -1;
          if (this.status == 2 && this.technical === -1) {
            toastr.info(
              "Debe seleccionar una brigada para poder cambiar el estado"
            );
          } else {
            this.loading = true
            brigada = this.technical;
            axios
              .put(`ots/${curr.id_ot}`, {
                brigada: brigada == -1 ? null : brigada,
                brigadaDesasigna: this.status == 1 ? curr.brigada : null,
                num_ot: curr.num_ot,
                id_estado: this.status,
                bitacora: true,
                descripcion: description,
                fecha_bitacora: moment().format("YYYY-MM-DD H:mm:ss"),
              })
              .then((res) => {
                if (res.data.message == "success") {
                  this.$emit('loadMenu', true)
                  this.show = false
                  this.loading = false
                }
              })
              .catch(() => {
                this.loading = false
              });
          }
        } else {
          toastr.info("El campo de observación es obligatorio");
        }
      }
    },
  },
};
</script>
