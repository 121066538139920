export default {
    namespaced: true,
    state: {
        ots: []
    },
    getters: {
        getOts(state) {
            return state.ots
        }
    },
    mutations: {
        SET_OTS(state, val) {
            state.ots = val
        },
    },
    actions: {
        changeValueOts({ commit }, value) {
            commit('SET_OTS', value)
        }
    }
}