<template>
  <b-row class="p-1" :key="nKey">
    <b-col md="12">
      <label class="checkbox checkbox-outline checkbox-success">
        <input type="checkbox" v-model="oForm.bIncompleta" />
        <span></span> ¿Es una tarea incompleta?
      </label>
    </b-col>
    <b-col md="12" v-if="oForm.bIncompleta">
      <label class="checkbox checkbox-outline checkbox-success">
        <input type="checkbox" v-model="oForm.bServicioRestaurado" />
        <span></span> ¿Se restauró servicio?
      </label>
    </b-col>
    
    <b-col md="12" class="mt-3">
      <label>Motivo</label>
      <select class="form-control form-control-sm" v-model="oForm.causa_id">
        <option :value="item.id" v-for="(item, idx) in aListado" :key="idx">
          {{ item.causa }}
        </option>
      </select>
    </b-col>
    <b-col md="12" class="mt-3">
      <label>Observación</label>
      <textarea
        cols="6"
        rows="6"
        v-model="oForm.observacion"
        class="form-control form-control-sm"
      ></textarea>
    </b-col>
    <b-col class="text-right">
      <button @click="onGuardar()" class="btn btn-sm btn-primary mt-3">
        <i class="fa fa-save"></i> Guardar
      </button>
    </b-col>
  </b-row>
</template>
<script>
export default {
  props: {
    aListado: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      oForm: {
        observacion: "",
        causa_id: -1,
        bIncompleta: false,
        bServicioRestaurado: false,
      },
      nKey: 0
    };
  },
  methods: {
    onGuardar() {
      // Validamos que la causa que se escoja sea válida y que la observación no esté vacía
      const formIsValid = this.oForm.causa_id !== -1 && this.oForm.observacion !== "";
      if (formIsValid) {
        this.$swal
          .fire({
            icon: "info",
            title: "¿Estás seguro de realizar esta operación?",
            showCancelButton: true,
            confirmButtonText: "Guardar",
            cancelButtonText: "Cancelar",
          })
          .then((result) => {
            if (result.isConfirmed) {
              this.$emit("onGuardarAviso", this.oForm);
            }
          });
      } else {
        let errorMessage = "";
        if (this.oForm.causa_id === -1 && this.oForm.observacion !== "") {
          errorMessage = "Para continuar, debes seleccionar una causa";
        } else if ( this.oForm.causa_id !== -1 && this.oForm.observacion === "") {
          errorMessage = "Para continuar, debes escribir una observación";
        } else {
          errorMessage = "Para continuar, debes diligenciar los últimos dos campos de forma obligatoria";
        }
        this.$swal.fire({
          icon: "info",
          title: "Faltan datos",
          text: errorMessage,
        });
      }
    },
  },
};
</script>