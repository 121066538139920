export default {
    namespaced: true,

    state: {
        dark: false,
        isSearching: false
    },
    getters: {
        getTheme(state) {
            return state.dark
        },
        getSearching(state) {
            return state.isSearching
        }
    },
    mutations: {
        SET_THEME_MAP(state, val) {
            state.dark = val
        },
        SET_SEARCHING(state, val) {
            state.isSearching = val;
        }
    },
    actions: {
        setTheme({ commit }, val) {
            commit('SET_THEME_MAP', val)
        },
        setSearching({ commit }, val) {
            commit('SET_SEARCHING', val)
        },
    }
}