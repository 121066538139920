import moment from 'moment';

const infoWindowOT = (ot, el) => {

    return ` <div id="content" style="width: 600px !important;">
                <div id="bodyContent">
                    <strong>
                        <div class="text-center mb-1">
                            <p>INFORMACIÓN DE OT</p>
                        </div>
                        <b><span class="text-danger">N° OT :</span> ${ot.num_ot}</b> <br> 
                        <b><span class="text-danger">Observación :</span> ${ot.observacion}</b> <br> 
                        <b><span class="text-danger">Brigada :</span> ${ot.user == null ? "NO ASIGNADO" : ot.user.nombre}</b> <br> 
                        <b><span class="text-danger">Estado :</span> <i class="${ot.estado_o_t_s.icono}"></i> ${ot.estado_o_t_s.descripcion}</b> <br>
                        
                        <b><span class="text-danger">Observacion  Bol:</span> ${ot.observacion_bol}</b> <br> 
                        <div style="max-height: 100px !important; overflow-y: auto !important;">
                            <b>
                                <span class="text-danger">${ot.id_estado == 8 && ot.rechazo ? 'Motivo rechazo: ': ''}</span> 
                                ${ot.id_estado == 8 && ot.rechazo ? ot.rechazo : ''}</b> 
                        </div>
                        <hr>
                        <div class="text-center mb-1">
                            <p>INFORMACIÓN DE AVISO DE REFERENCIA</p>
                        </div>

                        <div class="row" >
                            <div class="col col-6" >
                                <div style="font-weight: bold;">
                                    <span class="text-danger">N° de aviso :</span> ${el.num_aviso} ${''} <br>
                                    <span class="text-danger">Fecha creación: </span> ${moment(el.fecha_alta).format("YYYY-MM-DD HH:mm:ss")} <br>
                                    <span class="text-danger">Fecha ingreso siprem: </span> ${moment(el.created_at).format("YYYY-MM-DD HH:mm:ss")} <br>
                                    <span class="text-danger">Subestación :</span> ${el.subestacion} <br>
                                    <span class="text-danger">Circuito :</span> ${el.circuito_sm}
                                </div> 
                            </div>
                            <div class="col col-6" >
                                <div style="font-weight: bold;">
                                    <span class="text-danger">N° de evento :</span> ${el.work.outage} ${''} <br>
                                    <span class="text-danger">N° de tarea :</span> ${el.work_task.numero_aviso} ${''} <br>
                                </div> 
                            </div>
                        </div>

                        
                            <div class="separator separator-dashed mt-1 mb-1"></div>

                        <b><span class="text-danger">Nombre de suscriptor :</span> ${el.nombre_suscriptor}</b> <br> 
                        <b><span class="text-danger">Télefono: :</span> ${el.telefono_suscriptor}</b> <br> 
                        <div class="separator separator-dashed mt-1 mb-1"></div>
                        <b><span class="text-danger">Tipo de aviso :</span> ${el.tipo_aviso}</b> <br> 
                        <b><span class="text-danger">Descripción:</span> ${el.observacion}</b> <br> 
                        <b><span class="text-danger">Referencia:</span> ${el.dir_ref}</b> <br> 
                        <b><span class="text-danger">Dirección:</span> ${el.dir_suscriptor}</b>
                    </strong>
                </div>
            </div>`
}
export default infoWindowOT